import React, { useContext } from 'react';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';

const ErrorPage: React.FC = () => {
  const { theme } = useContext(AuthContext);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {theme?.logo && <img src={theme?.logo} alt="" className="mx-auto mt-50" loading="lazy" style={{ width: 200 }} />}

      <h1 className="font-bold text-30 pt-30">Ups, etwas ist schiefgelaufen!</h1>
      <p className="mt-20">Die angeforderte Seite existiert nicht oder ein Fehler ist aufgetreten.</p>
      <a href="/">
        <Button className="mx-auto mt-40">Zurück zur Startseite</Button>
      </a>
    </div>
  );
};

export default ErrorPage;
