import React from 'react';

import styles from './styles.module.scss';

interface CustomTextAreaProps {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({ name, value, onChange, placeholder }) => {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      className={styles.customTextArea}
      placeholder={placeholder}
      rows={4}
    />
  );
};

export default CustomTextArea;
