import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toast';
import firebase from 'services/firebase';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import { generateRandomUid } from 'shared/functions/global';
import Select from 'react-select';
import moment from 'moment';
import Button from 'components/Button';
import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  mainPopup?: any;
  popupContainerRef?: any;
};

const NewPlanStep5: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  mainPopup,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [currentMakroChangeCalories, setCurrentMakroChangeCalories] = useState<any>(0);
  const [currentMakroChangeCarbohydrates, setCurrentMakroChangeCarbohydrates] = useState<any>(0);
  const [currentMakroChangeProtein, setCurrentMakroChangeProtein] = useState<any>(0);
  const [currentMakroChangeFat, setCurrentMakroChangeFat] = useState<any>(0);
  const [currentMakroChangeType, setCurrentMakroChangeType] = useState('standard');

  const [currentCarbohydratesPercent, setCurrentCarbohydratesPercent] = useState('');
  const [currentProteinPercent, setCurrentProteinPercent] = useState('');
  const [currentFatPercent, setCurrentFatPercent] = useState('');

  const [changeCaloriesNeed, setChangeCaloriesNeed] = useState(false);

  const [currentMakroChangeCarbohydratesPercent, setCurrentMakroChangeCarbohydratesPercent] = useState('');
  const [currentMakroChangeProteinPercent, setCurrentMakroChangeProteinPercent] = useState('');
  const [currentMakroChangeFatPercent, setCurrentMakroChangeFatPercent] = useState('');

  const changeMakroCarbohydrateRef = useRef<HTMLInputElement>(null);
  const changeMakroProteinRef = useRef<HTMLInputElement>(null);
  const changeMakroFatRef = useRef<HTMLInputElement>(null);
  const changeMakroCaloriesRef = useRef<HTMLInputElement>(null);

  const makroTypes: any = [
    { value: 'standard', label: 'Standard', carbohydrates: '50', protein: '25', fat: '25' },
    { value: 'ketogen', label: 'Ketogen', carbohydrates: '5', protein: '30', fat: '65' },
    { value: 'user', label: 'Benutzerdefiniert' },
  ];

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { userData, tenant, tenantData } = useContext(AuthContext);

  const history = useHistory();

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('4');
  }

  useEffect(() => {
    if (currentStepValue !== '5') {
      setVisible('hidden');
    }

    if (userData?.custom_nutrition_type !== undefined) {
      if (userData?.custom_nutrition_type === 'user') {
        setCurrentCarbohydratesPercent(userData?.custom_carbohydrates_percent!);
        setCurrentProteinPercent(userData?.custom_protein_percent!);
        setCurrentFatPercent(userData?.custom_fat_percent!);
      } else {
        setCurrentCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].carbohydrates
        );
        setCurrentProteinPercent(
          makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].protein
        );
        setCurrentFatPercent(makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat);
      }
    } else {
      setCurrentCarbohydratesPercent('50');
      setCurrentProteinPercent('25');
      setCurrentFatPercent('25');
    }
  }, [currentStepValue, planStateValue, visible, popupContainerRef]);

  useEffect(() => {
    setCurrentMakroChangeType('');
    setCurrentMakroChangeType('standard');
    setCurrentMakroChangeCarbohydrates(0);
    setCurrentMakroChangeProtein(0);
    setCurrentMakroChangeFat(0);
    setCurrentMakroChangeCalories(0);
  }, [changeCaloriesNeed]);

  function keyPress(event: any, submitFunction: any) {
    if (event.charCode === 13) {
      event.preventDefault();
      if (submitFunction !== undefined) {
        submitFunction();
      }
    }
  }

  const changeUserFat = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedFat = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 9.3);

    setCurrentMakroChangeFatPercent(thisValue);
    setCurrentMakroChangeFat(thisCalculatedFat);
  };

  const changeUserProtein = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedProtein = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeProteinPercent(thisValue);
    setCurrentMakroChangeProtein(thisCalculatedProtein);
  };

  const changeUserCarbohydrates = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedCarbohydrates = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeCarbohydratesPercent(thisValue);
    setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
  };

  const updateMakroCalories = (event: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0]
      .carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].fat;

    if (currentMakroChangeType === 'standard') {
      thisCalculatedCarbohydrates = Math.round((event.target.value * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else if (currentMakroChangeType === 'user') {
      thisCalculatedCarbohydrates = Math.round(
        (event.target.value * parseFloat(currentMakroChangeCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round(
        (event.target.value * parseFloat(currentMakroChangeProteinPercent)) / 100 / 4.1
      );
      thisCalculatedFat = Math.round((event.target.value * parseFloat(currentMakroChangeFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      thisCalculatedCarbohydrates = Math.round((event.target.value * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeCalories(event.target.value);
  };

  const updateMakroType = (value: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === value.value)[0].carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === value.value)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === value.value)[0].fat;

    if (value.value === 'user') {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }

      thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = thisProteinPercent;
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = thisFatPercent;
      }

      thisCalculatedCarbohydrates = Math.round(
        (currentMakroChangeCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeType(value.value);
  };

  function jumpToNextStep() {
    let thisCarbohydratesPercent = '';
    let thisProteinPercent = '';
    let thisFatPercent = '';

    if (currentMakroChangeCarbohydratesPercent !== '') {
      thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
    } else {
      thisCarbohydratesPercent = '50';
    }

    if (currentMakroChangeProteinPercent !== '') {
      thisProteinPercent = currentMakroChangeProteinPercent;
    } else {
      thisProteinPercent = '25';
    }

    if (currentMakroChangeFatPercent !== '') {
      thisFatPercent = currentMakroChangeFatPercent;
    } else {
      thisFatPercent = '25';
    }

    if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
    ) {
      toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
    ) {
      toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (changeCaloriesNeed && parseFloat(currentMakroChangeCalories) < 1000) {
      toast.error('Bitte gebe mindestens 1000 Kalorien als Bedarf ein!');
    } else {
      const thisPlanUid = generateRandomUid();

      let newColumns = {
        ...planStateValue,
        uid: thisPlanUid,
        origId: thisPlanUid,
        examplePlan: false,
        created: moment().unix(),
      };

      if (currentMakroChangeCalories !== 0 && changeCaloriesNeed) {
        newColumns = {
          ...newColumns,
          custom_carbohydrates_total: currentMakroChangeCarbohydrates,
          custom_protein_total: currentMakroChangeProtein,
          custom_fat_total: currentMakroChangeFat,
          custom_kcal_total: currentMakroChangeCalories,
          custom_makro_type: currentMakroChangeType,
        };
      } else {
        newColumns = {
          ...newColumns,
          custom_carbohydrates_total: userData?.carbohydrates_total,
          custom_protein_total: userData?.protein_total,
          custom_fat_total: userData?.fat_total,
          custom_kcal_total: userData?.calories,
          custom_makro_type: userData?.custom_nutrition_type ?? 'standard',
        };
      }

      if (currentMakroChangeType === 'user' && changeCaloriesNeed && currentMakroChangeCalories !== 0) {
        newColumns = {
          ...newColumns,
          custom_carbohydrates_percent:
            currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
          custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
          custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
        };
      }

      planState(newColumns);

      setVisible('hidden');
      currentStep('6');
    }
  }

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const savePlanToFirebase = async () => {
    let thisCarbohydratesPercent = '';
    let thisProteinPercent = '';
    let thisFatPercent = '';

    if (currentMakroChangeCarbohydratesPercent !== '') {
      thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
    } else {
      thisCarbohydratesPercent = '50';
    }

    if (currentMakroChangeProteinPercent !== '') {
      thisProteinPercent = currentMakroChangeProteinPercent;
    } else {
      thisProteinPercent = '25';
    }

    if (currentMakroChangeFatPercent !== '') {
      thisFatPercent = currentMakroChangeFatPercent;
    } else {
      thisFatPercent = '25';
    }

    if (changeCaloriesNeed && parseFloat(currentMakroChangeCalories) < 1000) {
      toast.error('Bitte gebe mindestens 1000 Kalorien als Bedarf ein!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
    ) {
      toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
    ) {
      toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else {
      const thisPlanUid = generateRandomUid();

      let newColumns = {
        ...planStateValue,
        uid: thisPlanUid,
        origId: thisPlanUid,
        examplePlan: false,
        created: moment().unix(),
      };

      let newColumnsAdmin = {
        ...planStateValue,
        uid: thisPlanUid,
        origId: thisPlanUid,
        examplePlan: true,
        created: moment().unix(),
      };

      if (currentMakroChangeCalories !== 0 && changeCaloriesNeed) {
        newColumnsAdmin = {
          ...newColumnsAdmin,
          custom_carbohydrates_total: currentMakroChangeCarbohydrates,
          custom_protein_total: currentMakroChangeProtein,
          custom_fat_total: currentMakroChangeFat,
          custom_kcal_total: currentMakroChangeCalories,
          custom_makro_type: currentMakroChangeType,
        };

        newColumns = {
          ...newColumns,
          custom_carbohydrates_total: currentMakroChangeCarbohydrates,
          custom_protein_total: currentMakroChangeProtein,
          custom_fat_total: currentMakroChangeFat,
          custom_kcal_total: currentMakroChangeCalories,
          custom_makro_type: currentMakroChangeType,
        };
      } else {
        newColumns = {
          ...newColumns,
          custom_carbohydrates_total: userData?.carbohydrates_total,
          custom_protein_total: userData?.protein_total,
          custom_fat_total: userData?.fat_total,
          custom_kcal_total: userData?.calories,
          custom_makro_type: userData?.custom_nutrition_type ?? 'standard',
        };
      }

      if (currentMakroChangeType === 'user' && changeCaloriesNeed && currentMakroChangeCalories !== 0) {
        newColumns = {
          ...newColumns,
          custom_carbohydrates_percent:
            currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
          custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
          custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
        };
      }

      const newColumnsFavorite = {
        uid: thisPlanUid,
        name: planStateValue.name,
        origId: thisPlanUid,
      };

      if (userData?.role === 1) {
        try {
          await firebase
            .firestore()
            .collection('plans')
            .doc(thisPlanUid)
            .set(newColumnsAdmin as object);
          toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
          history.push({
            pathname: `/plans/edit/${thisPlanUid}`,
            state: {
              from: 'plan_add',
            },
          });
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      } else {
        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('plans')
            .doc(thisPlanUid)
            .set(newColumns as object);
          try {
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/users`)
              .doc(authContext.user?.uid)
              .collection('favorites_plans')
              .doc()
              .set(newColumnsFavorite as object);
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            toast.error('Es ist leider etwas schief gegangen!');
          }
          toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      }

      currentStep('1');
      mainPopup('hidden');
    }
  };

  return (
    <>
      <div className={visible}>
        <div className="px-20">
          <div className="pb-30">
            <div className="font-bold text-18">Kalorienbedarf</div>
            <div className="pt-10 font-extralight text-base pl-20 pr-15 pb-40">
              <div>Dein aktueller Bedarf aus deinen Profilwerten:</div>
              <div className="pt-10 flex md:pl-20">
                <div className={styles.nutritionBorderItemFirst}>
                  <div>
                    <div className="font-semibold text-base">{Math.round(parseFloat(userData!.calories))}</div>
                    <div className={styles.recipeLabel}>Kalorien</div>
                  </div>
                  <span className="divider" />
                </div>
                <div className={styles.nutritionBorderItem}>
                  <div className="font-semibold text-base">{userData?.carbohydrates_total}g</div>
                  <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                </div>
                <div className={styles.nutritionBorderItem}>
                  <div className="font-semibold text-base">{userData?.protein_total}g</div>
                  <div className={styles.recipeLabel}>{t('Protein')}</div>
                </div>
                <div className="text-center px-20">
                  <div className="font-semibold text-base">{userData?.fat_total}g</div>
                  <div className={styles.recipeLabel}>{t('Fat')}</div>
                </div>
              </div>
              <div className="pt-30">Deine aktuelle Makronährstoff Verteilung:</div>
              <div className="flex">
                <div className={styles.makroBorderFirst}>
                  <span className="font-semibold">{currentCarbohydratesPercent}%</span> Kohlenhydrate
                </div>
                <div className={styles.makroBorder}>
                  <span className="font-semibold">{currentProteinPercent}%</span> Eiweiß
                </div>
                <div className="px-15 text-12">
                  <span className="font-semibold">{currentFatPercent}%</span> Fett
                </div>
              </div>

              {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
                tenantData?.settings?.functions?.caloriesGoal === true ||
                userData?.isAdminUser ||
                userData?.isTrainerUser ||
                userData?.isLocationAdminUser ||
                localStorage.getItem('currentUserToken')) && (
                <div>
                  {changeCaloriesNeed ? (
                    <>
                      <div className="pt-20">
                        <hr />
                      </div>
                      <div className="pt-10 flex justify-between">
                        <div className="pt-10 text-20 font-semibold">Neuer Bedarf</div>
                        <div className="pt-10 my-auto">
                          <Button onClick={() => setChangeCaloriesNeed(false)} buttonStyle="white" className="py-5">
                            Abbrechen
                          </Button>
                        </div>
                      </div>

                      <div className="pt-4">
                        <div className="block font-extralight pb-5">
                          Wenn du für diesen Plan einen anderen Bedarf nutzen möchtest, trage hier bitte deine Kalorien
                          ein:
                        </div>
                        <div className="w-120 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2">
                          <input
                            id="calories"
                            name="calories"
                            type="number"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            className="w-100 my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                            onChange={updateMakroCalories}
                            ref={changeMakroCaloriesRef}
                            onKeyPress={e => keyPress(e, savePlanToFirebase)}
                          />
                        </div>
                      </div>
                      <div className="font-semibold pt-30">Makronährstoff Verteilung</div>
                      <div className="flex w-300 gap-40 pt-2">
                        <div className="flex-1">
                          <Select
                            name="makroType"
                            className="react-select-container-full"
                            classNamePrefix="react-select"
                            unstyled
                            options={makroTypes}
                            value={
                              currentMakroChangeType !== ''
                                ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)
                                : makroTypes[0]
                            }
                            onChange={updateMakroType}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                      <div className="flex gap-20 md:gap-40 pt-20">
                        <div>
                          <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                          <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="carbohydrates"
                              name="carbohydrates"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserCarbohydrates}
                              defaultValue="50"
                              className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                              ref={changeMakroCarbohydrateRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeCarbohydrates}g
                          </div>
                        </div>

                        <div>
                          <div className="block text-sm font-regular mb-1">Eiweiß</div>
                          <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="protein"
                              name="protein"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserProtein}
                              defaultValue="25"
                              className="w-40 my-auto bg-transparent appearance-none block text-18 pl-5 font-semibold focus:outline-none bg-opacity-20 text-textColor "
                              ref={changeMakroProteinRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeProtein}g
                          </div>
                        </div>

                        <div>
                          <div className="block text-sm font-regular mb-1">Fett</div>
                          <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="fat"
                              name="fat"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserFat}
                              defaultValue="25"
                              className="w-40 my-auto appearance-none bg-transparent block text-18 pl-5 font-semibold focus:outline-none bg-opacity-20 text-textColor "
                              ref={changeMakroFatRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeFat}g
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="pt-30 pb-40">
                      <Button onClick={() => setChangeCaloriesNeed(true)}>Bedarf anpassen</Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={planStateValue?.planType === 'alghorithmus' ? jumpToNextStep : savePlanToFirebase}
        currentStepValue="5"
        isFinished={planStateValue?.planType !== 'alghorithmus'}
        totalSteps={planStateValue?.planType === 'alghorithmus' ? 6 : 5}
      />
    </>
  );
};

export default NewPlanStep5;
