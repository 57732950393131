import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toast';

import SocialMediaContainer from 'components/SocialMediaContainer';
import AuthRoutes from 'routes/authRoutes';
import DataPrivacy from 'pages/Legal/DataPrivacy';
import Agb from 'pages/Legal/Agb';
import StoreLandingPage from 'pages/General/StoreLandingpage';
import MainRoutes from 'routes/mainRoutes';
import PaymentDetails from 'pages/Purchase/payment';
import PrivateRoute from 'routes/privateRoute';
import PurchasePlan from 'pages/Purchase/plan';
import CustomerBillingRegister from 'pages/CustomerBilling/Register';
import CustomResetPasswordPage from 'pages/General/ResetPassword';
import { AuthProvider } from 'providers/AuthProvider';
import PurchaseSuccess from 'pages/Purchase/success';
import PurchaseSuccessConnect from 'pages/CustomerBilling/payment/success';
import CustomerBillingCancel from 'pages/CustomerBilling/payment/cancel';
import Theme from 'layouts/Theme';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorPage from 'components/ErrorPage';

function App() {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <div className="w-full relative h-screen overflow-hidden">
          <div className="h-full custom-scrollbar">
            <Theme>
              <Switch>
                <Route path="/auth" component={AuthRoutes} />
                <Route path="/legal/dataprivacy" component={DataPrivacy} />
                <Route path="/legal/agb" component={Agb} />
                <Route path="/company-register/:tenant" component={CustomerBillingRegister} />
                <Route path="/error" component={ErrorPage} />
                <PrivateRoute exact path="/company-payment/:tenant" component={CustomerBillingRegister} />
                <Route path="/purchase-connect/success" component={PurchaseSuccessConnect} />
                <Route path="/store-landingpage" component={StoreLandingPage} />
                <Route path="/password-reset" component={CustomResetPasswordPage} />
                <PrivateRoute exact path="/purchase" component={PurchasePlan} />
                <PrivateRoute exact path="/connect-cancel" component={CustomerBillingCancel} />
                <PrivateRoute exact path="/purchase/success" component={PurchaseSuccess} />
                <PrivateRoute path="/purchase/payment/:type" component={PaymentDetails} />
                <PrivateRoute path="/" component={MainRoutes} />

                <Redirect to="/" />
              </Switch>

              <SocialMediaContainer />
              <ToastContainer delay={3000} position="top-right" />
            </Theme>
          </div>
        </div>
      </ErrorBoundary>
    </AuthProvider>
  );
}

export default App;
