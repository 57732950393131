import React, { useContext, useState, useEffect } from 'react';
import { Line, Circle } from 'rc-progress';
import {
  ChevronRightIcon,
  PencilIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import Icon from 'components/Icon';
import Button from 'components/Button';
import TransitionContainer from 'components/TransitionContainer';
import MakroIcons from 'components/MakroIcons';
import { toast } from 'react-toast';
import {
  getIsWebView,
  updateActivitiesSettings,
  getMobileOperatingSystem,
  deleteActivitiesSettings,
} from 'shared/functions/global';
import { darkenColor, defaultAccentColor, isLight, lightenColor } from 'layouts/Theme';
import styles from './style.module.scss';

type Props = {
  kcal_value?: number;
  carbohydrates_value?: number;
  protein_value?: number;
  fat_value?: number;
  custom_kcal_value?: number;
  custom_carbohydrates_value?: number;
  custom_protein_value?: number;
  custom_fat_value?: number;
  onlyCircle?: boolean;
  onlyHorizontalCharts?: boolean;
  withActivities?: boolean;
  editFunction?: any;
  smallCircles?: boolean;
  dayDate?: string;
  activitiesSaveFunction?: any;
  planDayHealthData?: any;
  setTriggeredStepCount?: any;
  setTriggeredWorkoutCalories?: any;
  setTriggeredWorkoutMinutes?: any;
  setTriggerUpdate?: any;
};

const MakroCircles: React.FC<Props> = ({
  kcal_value = 0,
  carbohydrates_value = 0,
  protein_value = 0,
  fat_value = 0,
  custom_kcal_value = 0,
  custom_carbohydrates_value = 0,
  custom_protein_value = 0,
  custom_fat_value = 0,
  onlyCircle = false,
  onlyHorizontalCharts = false,
  withActivities = false,
  editFunction,
  smallCircles,
  dayDate,
  activitiesSaveFunction,
  planDayHealthData,
  setTriggeredStepCount,
  setTriggeredWorkoutCalories,
  setTriggeredWorkoutMinutes,
  setTriggerUpdate,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, theme, tenant, tenantData } = useContext(AuthContext);

  const [smallCircleType, setSmallCircleType] = useState('small');
  const [isCaloriesSectionShown, setIsCaloriesSectionShown] = useState(true);
  const [isActivitiesSectionShown, setIsActivitiesSectionShown] = useState(false);
  const [isActivitiesSettingsPending, setIsActivitiesSettingsPending] = useState(false);
  const [isNativeView, setIsNativeView] = useState(false);
  const [currentStepCount, setCurrentStepCount] = useState(0);
  const [currentStepCalories, setCurrentStepCalories] = useState(0);
  const [currentWorkoutMinutes, setCurrentWorkoutMinutes] = useState(0);
  const [currentWorkoutCalories, setCurrentWorkoutCalories] = useState(0);

  const [activitiesInfoClass, setActivitiesInfoClass] = useState('hidden');
  const [activitiesLoading, setActivitiesLoading] = useState(false);

  let PercentKcalTotal = 0;
  let PercentCarbohydratesTotal = 0;
  let PercentProteinTotal = 0;
  let PercentFatTotal = 0;

  let thisKcalTotal = 0;
  let thisCarbohydratesTotal = 0;
  let thisProteinTotal = 0;
  let thisFatTotal = 0;

  if (custom_kcal_value) {
    PercentKcalTotal = Math.round((100 * kcal_value) / custom_kcal_value);
    PercentCarbohydratesTotal = Math.round((100 * carbohydrates_value) / custom_carbohydrates_value);
    PercentProteinTotal = Math.round((100 * protein_value) / custom_protein_value);
    PercentFatTotal = Math.round((100 * fat_value) / custom_fat_value);

    thisKcalTotal = custom_kcal_value;
    thisCarbohydratesTotal = custom_carbohydrates_value;
    thisProteinTotal = custom_protein_value;
    thisFatTotal = custom_fat_value;
  } else {
    PercentKcalTotal = Math.round((100 * kcal_value) / parseFloat(userData!.calories));
    PercentCarbohydratesTotal = Math.round((100 * carbohydrates_value) / userData!.carbohydrates_total);
    PercentProteinTotal = Math.round((100 * protein_value) / userData!.protein_total);
    PercentFatTotal = Math.round((100 * fat_value) / userData!.fat_total);

    thisKcalTotal = Math.round(parseFloat(userData!.calories));
    thisCarbohydratesTotal = userData!.carbohydrates_total;
    thisProteinTotal = userData!.protein_total;
    thisFatTotal = userData!.fat_total;
  }

  const handleSmallCircleStatus = () => {
    if (smallCircleType === 'small') {
      setSmallCircleType('big');
    } else {
      setSmallCircleType('small');
    }
  };

  const openSection = (sectionType: string) => {
    if (sectionType === 'activities') {
      const checkIsNativeView = getIsWebView();

      if (checkIsNativeView === true) {
        setIsNativeView(true);
      }

      if (getIsWebView() === false && planDayHealthData !== undefined) {
        setCurrentStepCount(planDayHealthData.stepCount);
        setCurrentWorkoutCalories(planDayHealthData.workoutCalories);
        setCurrentWorkoutMinutes(planDayHealthData.workoutMinutes);
      } else if (getIsWebView() === false) {
        setCurrentStepCount(0);
        setCurrentWorkoutCalories(0);
        setCurrentWorkoutMinutes(0);
      }

      setIsActivitiesSectionShown(true);
      setIsCaloriesSectionShown(false);
    } else {
      setIsActivitiesSectionShown(false);
      setIsCaloriesSectionShown(true);
    }
  };

  const saveActivitiesSettings = async () => {
    setIsActivitiesSettingsPending(true);
    const updateUser = await updateActivitiesSettings(userData, true, authContext.user?.uid!, tenant);

    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      health: {
        useWorkouts: true,
      },
    }));

    authContext.setOriginalUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      health: {
        useWorkouts: true,
      },
    }));

    if (updateUser.status === 'success') {
      if (
        getIsWebView() === true &&
        dayDate !== undefined &&
        tenantData?.features?.health !== undefined &&
        tenantData?.features?.health === true
      ) {
        // @ts-ignore
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ getHealthData: dayDate }));
      }
      setTimeout(() => {
        document.getElementById('mobileScrollViewContainer')?.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 1);
    } else {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
    }
    setIsActivitiesSettingsPending(false);
  };

  const deleteActivitySettings = async () => {
    setIsActivitiesSettingsPending(true);
    const updateUser = await deleteActivitiesSettings(userData, authContext.user?.uid!, tenant);

    authContext.setUserData((prevProfile: UserInfo) => {
      const { health, ...rest } = prevProfile;
      return rest;
    });

    authContext.setOriginalUserData((prevProfile: UserInfo) => {
      const { health, ...rest } = prevProfile;
      return rest;
    });

    if (updateUser.status === 'success') {
      toast.success('Health Verbindung erfolgreich entfernt!');
    } else {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
    }
    setIsActivitiesSettingsPending(false);
  };

  const getStepCalories = () => {
    if (userData?.bodySize && userData?.bodyWeight) {
      const getTotalCalories = Math.round(currentStepCount * 0.05);
      setCurrentStepCalories(getTotalCalories);
    }
  };

  const handleMessageFromRN = (message: any) => {
    if (message && getIsWebView() === true) {
      try {
        setActivitiesLoading(true);
        const parsedMessage = JSON.parse(message.data);

        let thisWorkoutCalories = 0;
        let thisWorkoutMinutes = 0;

        // alert(planDayHealthData);

        setCurrentStepCount(Math.round(parsedMessage.stepCount.value));
        if (setTriggeredStepCount !== undefined) {
          setTriggeredStepCount(Math.round(parsedMessage.stepCount.value));
        }

        if (parsedMessage?.activeEnergyBurned?.data !== undefined && parsedMessage.activeEnergyBurned.data.length > 0) {
          for (let g = 0; g < parsedMessage.activeEnergyBurned.data.length; g += 1) {
            if (parsedMessage.activeEnergyBurned.data[g].calories !== null) {
              thisWorkoutCalories += parseFloat(parsedMessage.activeEnergyBurned.data[g].calories);
            }

            if (parsedMessage.activeEnergyBurned.data[g].duration !== null) {
              thisWorkoutMinutes += parseFloat(parsedMessage.activeEnergyBurned.data[g].duration);
            }
          }

          if (thisWorkoutMinutes > 0 && thisWorkoutCalories > 0) {
            setCurrentWorkoutCalories(Math.round(thisWorkoutCalories));
            setCurrentWorkoutMinutes(Math.round(thisWorkoutMinutes / 60));

            if (setTriggeredWorkoutCalories !== undefined && setTriggeredWorkoutMinutes !== undefined) {
              setTriggeredWorkoutCalories(Math.round(thisWorkoutCalories));
              setTriggeredWorkoutMinutes(Math.round(thisWorkoutMinutes / 60));
            }
          }

          setActivitiesLoading(false);
        } else {
          setCurrentWorkoutCalories(0);
          setCurrentWorkoutMinutes(0);
          setActivitiesLoading(false);
        }

        if (setTriggerUpdate !== undefined) {
          setTriggerUpdate(true);
        }
      } catch (error) {
        if (setTriggerUpdate !== undefined) {
          setTriggerUpdate(true);
        }
        console.error('Invalid JSON:', error);
        setActivitiesLoading(false);
      }
    }
  };

  const updateHealthData = () => {
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ getHealthData: dayDate }));
    toast.success('Die Health Daten wurden neu geladen!');
  };

  const openInfoBox = () => {
    if (activitiesInfoClass === 'block') {
      setActivitiesInfoClass('hidden');
    } else {
      setActivitiesInfoClass('block');
    }
  };

  useEffect(() => {
    const messageListener = window.addEventListener('message', handleMessageFromRN, true);
    return messageListener;
  }, []);

  useEffect(() => {
    getStepCalories();
  }, [currentStepCount]);

  useEffect(() => {
    openSection('calories');

    if (
      getIsWebView() === true &&
      dayDate !== undefined &&
      tenantData?.features?.health !== undefined &&
      tenantData?.features?.health === true &&
      userData?.health !== undefined
    ) {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ getHealthData: dayDate }));
    }
  }, [dayDate]);

  useEffect(() => {
    if (
      isActivitiesSectionShown === true &&
      getIsWebView() === true &&
      tenantData?.features?.health !== undefined &&
      tenantData?.features?.health === true &&
      userData?.health !== undefined
    ) {
      // send user uid to RN
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ getHealthData: dayDate }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivitiesSectionShown]);

  return (
    <div>
      {smallCircles ? (
        <>
          {smallCircleType === 'small' ? (
            <div className={userData?.role === 2 ? '' : 'bg-lightGray rounded-md'}>
              <div className="flex justify-between mx-15 pt-50 pb-10">
                <div>
                  <div className="text-center text-12 pb-5">Kalorien</div>
                  <div className="relative">
                    <Circle
                      className="h-40 mx-auto"
                      percent={PercentKcalTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeLinecap="round"
                      strokeColor={theme?.accentColor ?? defaultAccentColor}
                    />
                    <div className="absolute top-10 text-center h-full w-full left-10 pl-1">
                      <MakroIcons
                        percent={PercentKcalTotal}
                        minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                        maxValue={Math.round(
                          parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                        )}
                        value={Math.round(kcal_value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center text-12 pb-5">Kohlenhydrate</div>
                  <div className="relative">
                    <Circle
                      className="h-40 mx-auto"
                      percent={PercentCarbohydratesTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#DE4C39"
                      strokeLinecap="round"
                    />
                    <div className="absolute top-10 text-center h-full w-full left-30 ">
                      <MakroIcons
                        percent={PercentCarbohydratesTotal}
                        minValue={Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)}
                        maxValue={Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)}
                        value={Math.round(carbohydrates_value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center text-12 pb-5">Eiweiß</div>
                  <div className="relative">
                    <Circle
                      className="h-40 mx-auto"
                      percent={PercentProteinTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#8DC703"
                      strokeLinecap="round"
                    />
                    <div className="absolute top-10 text-center h-full w-full left-10 ">
                      <MakroIcons
                        percent={PercentProteinTotal}
                        minValue={Math.round(thisProteinTotal - thisProteinTotal * 0.15)}
                        maxValue={Math.round(thisProteinTotal + thisProteinTotal * 0.15)}
                        value={Math.round(protein_value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center text-12 pb-5">Fett</div>
                  <div className="relative">
                    <Circle
                      className="h-40 mx-auto"
                      percent={PercentFatTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#FBA500"
                      strokeLinecap="round"
                    />
                    <div className="absolute top-10 text-center h-full w-full left-10">
                      <MakroIcons
                        percent={PercentFatTotal}
                        minValue={Math.round(thisFatTotal - thisFatTotal * 0.15)}
                        maxValue={Math.round(thisFatTotal + thisFatTotal * 0.15)}
                        value={Math.round(fat_value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="my-auto">
                  <ChevronDownIcon
                    width={15}
                    height={15}
                    className="text-textColor cursor-pointer mx-auto"
                    onClick={() => handleSmallCircleStatus()}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={userData?.role === 2 ? '' : 'bg-lightGray rounded-md'}>
              <div>
                <div className="text-12 pb-5 pt-50 pl-15 font-semibold">Kalorien</div>
                <div className="relative mx-20">
                  <Line
                    percent={PercentKcalTotal}
                    strokeWidth={9}
                    trailWidth={9}
                    trailColor={theme?.bgColor ?? '#3c4044'}
                    className="w-full h-20"
                  />
                </div>
                <div className="flex text-12 justify-between mr-20 ml-15 pt-5 font-extralight">
                  <div>Verbraucht: {Math.round(kcal_value)} kcal</div>
                  <div>
                    Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                    {Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05)}{' '}
                    kcal
                  </div>
                </div>
              </div>
              <div className="flex justify-between mx-15 pt-30 pb-10">
                <div>
                  <div className="text-center text-12 pb-5 font-semibold">Kohlenhydrate</div>
                  <div className="relative">
                    <Circle
                      className="h-70 mx-auto"
                      percent={PercentCarbohydratesTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor={theme?.accentColor ?? defaultAccentColor}
                      strokeLinecap="round"
                    />
                    <div className="absolute top-15 text-center h-full w-full left-0 ">
                      <div>{PercentCarbohydratesTotal}%</div>
                      <div className="pl-35">
                        <MakroIcons
                          percent={PercentCarbohydratesTotal}
                          minValue={Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)}
                          maxValue={Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)}
                          value={Math.round(carbohydrates_value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-10 text-12 text-center font-semibold">{Math.round(carbohydrates_value)}g</div>
                  <div className="font-extralight text-12 ">
                    Ziel: {Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)} -{' '}
                    {Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)} g
                  </div>
                </div>
                <div>
                  <div className="text-center text-12 pb-5 font-semibold">Eiweiß</div>
                  <div className="relative">
                    <Circle
                      className="h-70 mx-auto"
                      percent={PercentProteinTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#8DC703"
                      strokeLinecap="round"
                    />
                    <div className="absolute top-15 text-center h-full w-full left-0 ">
                      <div>{PercentProteinTotal}%</div>
                      <div className="pl-30">
                        <MakroIcons
                          percent={PercentProteinTotal}
                          minValue={Math.round(thisProteinTotal - thisProteinTotal * 0.15)}
                          maxValue={Math.round(thisProteinTotal + thisProteinTotal * 0.15)}
                          value={Math.round(protein_value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-10 text-12 text-center font-semibold">{Math.round(protein_value)}g</div>
                  <div className="font-extralight text-12">
                    Ziel: {Math.round(thisProteinTotal - thisProteinTotal * 0.15)} -{' '}
                    {Math.round(thisProteinTotal + thisProteinTotal * 0.15)} g
                  </div>
                </div>
                <div>
                  <div className="text-center text-12 pb-5 font-semibold">Fett</div>
                  <div className="relative">
                    <Circle
                      className="h-70 mx-auto"
                      percent={PercentFatTotal}
                      strokeWidth={10}
                      trailWidth={10}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#FBA500"
                      strokeLinecap="round"
                    />
                    <div className="absolute top-15 text-center h-full w-full left-0">
                      <div>{PercentFatTotal}%</div>
                      <div className="pl-30">
                        <MakroIcons
                          percent={PercentFatTotal}
                          minValue={Math.round(thisFatTotal - thisFatTotal * 0.15)}
                          maxValue={Math.round(thisFatTotal + thisFatTotal * 0.15)}
                          value={Math.round(fat_value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-10 text-12 text-center font-semibold">{Math.round(fat_value)}g</div>
                  <div className="font-extralight text-12">
                    Ziel: {Math.round(thisFatTotal - thisFatTotal * 0.15)} -{' '}
                    {Math.round(thisFatTotal + thisFatTotal * 0.15)} g
                  </div>
                </div>
                <div className="my-auto">
                  <ChevronUpIcon
                    width={15}
                    height={15}
                    className="text-textColor cursor-pointer mx-auto"
                    onClick={() => handleSmallCircleStatus()}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : onlyCircle ? (
        <div className="relative">
          <div className={styles.circleImage}>
            <Circle
              className="mx-auto pt-20"
              percent={PercentKcalTotal}
              strokeWidth={10}
              trailWidth={10}
              trailColor={theme?.secondaryBgColor ?? '#3c4044'}
              strokeLinecap="round"
              strokeColor={{
                '100%': theme?.accentColor
                  ? !isLight(theme?.accentColor)
                    ? lightenColor(theme?.bgColor, 40) ?? defaultAccentColor
                    : darkenColor(theme?.bgColor, 40) ?? defaultAccentColor
                  : defaultAccentColor,
                '0%': theme?.accentColor ?? defaultAccentColor,
              }}
            />
          </div>
          <div className="absolute top-0 text-center h-full w-full pt-120">
            <div>
              <div className="text-3xl font-light pb-10">Kalorien</div>
              <div className="font-bold text-xl">{Math.round(kcal_value)} kcal</div>
              <div className="flex justify-center">
                <div className="font-bold text-l">{PercentKcalTotal}%</div>
                <div className="my-auto pl-1">
                  <MakroIcons
                    percent={PercentKcalTotal}
                    minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                    maxValue={Math.round(
                      parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                    )}
                    value={Math.round(kcal_value)}
                  />
                </div>
              </div>
              <div className="font-extralight text-14 pt-5">
                Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                {Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05)} kcal
              </div>
              {editFunction !== undefined && (
                <div className="pt-10">
                  {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
                    tenantData?.settings?.functions?.caloriesGoal === true ||
                    userData?.isAdminUser ||
                    userData?.isTrainerUser ||
                    userData?.isLocationAdminUser ||
                    localStorage.getItem('currentUserToken')) && (
                    <Button className="text-10 py-1 mx-auto" onClick={() => editFunction('plan')}>
                      Bearbeiten
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : onlyHorizontalCharts ? (
        <div>
          <div className="pt-20 flex mx-20">
            <div className="w-200">
              <div>Kohlenhydrate</div>
              <div className="font-extralight text-12">
                Ziel: {Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)} -{' '}
                {Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)} g
              </div>
            </div>

            <div className="w-full relative my-auto pl-30">
              <div className={styles.percentageLabel}>{PercentCarbohydratesTotal}%</div>
              <Line
                percent={PercentCarbohydratesTotal}
                strokeWidth={9}
                trailWidth={9}
                trailColor={theme?.bgColor ?? '#3c4044'}
                strokeColor="#DE4C39"
                className="w-full h-25"
              />
            </div>
            <div className="pl-30 my-auto flex">
              <div>{Math.round(carbohydrates_value)}g</div>
              <div className="my-auto pl-1">
                <MakroIcons
                  percent={PercentCarbohydratesTotal}
                  minValue={Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)}
                  maxValue={Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)}
                  value={Math.round(carbohydrates_value)}
                />
              </div>
            </div>
          </div>

          <div className="pt-30 flex mx-20">
            <div className="w-200">
              <div>Eiweiß</div>
              <div className="font-extralight text-12">
                Ziel: {Math.round(thisProteinTotal - thisProteinTotal * 0.15)} -{' '}
                {Math.round(thisProteinTotal + thisProteinTotal * 0.15)} g
              </div>
            </div>
            <div className="w-full relative my-auto pl-30">
              <div className={styles.percentageLabel}>{PercentProteinTotal}%</div>
              <Line
                percent={PercentProteinTotal}
                strokeWidth={9}
                trailWidth={9}
                trailColor={theme?.bgColor ?? '#3c4044'}
                strokeColor="#8DC703"
                className="w-full h-25"
              />
            </div>
            <div className="pl-30 my-auto flex">
              <div>{Math.round(protein_value)}g</div>
              <div className="my-auto pl-1">
                <MakroIcons
                  percent={PercentProteinTotal}
                  minValue={Math.round(thisProteinTotal - thisProteinTotal * 0.15)}
                  maxValue={Math.round(thisProteinTotal + thisProteinTotal * 0.15)}
                  value={Math.round(protein_value)}
                />
              </div>
            </div>
          </div>

          <div className="pt-30 flex mx-20">
            <div className="w-200">
              <div>Fett</div>
              <div className="font-extralight text-12">
                Ziel: {Math.round(thisFatTotal - thisFatTotal * 0.15)} -{' '}
                {Math.round(thisFatTotal + thisFatTotal * 0.15)} g
              </div>
            </div>
            <div className="w-full relative my-auto pl-30">
              <div className={styles.percentageLabel}>{PercentFatTotal}%</div>
              <Line
                percent={PercentFatTotal}
                strokeWidth={9}
                trailWidth={9}
                trailColor={theme?.bgColor ?? '#3c4044'}
                strokeColor="#FBA500"
                className="w-full h-25"
              />
            </div>
            <div className="pl-30 my-auto flex">
              <div>{Math.round(fat_value)}g</div>
              <div className="my-auto pl-1">
                <MakroIcons
                  percent={PercentFatTotal}
                  minValue={Math.round(thisFatTotal - thisFatTotal * 0.15)}
                  maxValue={Math.round(thisFatTotal + thisFatTotal * 0.15)}
                  value={Math.round(fat_value)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : withActivities ? (
        <div>
          <TransitionContainer isShown={isActivitiesSectionShown}>
            <div>
              <div className="relative">
                <div className="flex justify-between ">
                  <div className="pl-10 flex space-x-20">
                    <div className="flex gap-5 cursor-pointer" onClick={() => openInfoBox()} aria-hidden="true">
                      <div className="my-auto">Aktivitäten</div>
                      {userData?.health !== undefined && (
                        <div className="my-auto">
                          <InformationCircleIcon width={15} height={15} className="text-textColor" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="pl-10 flex space-x-5 cursor-pointer"
                    onClick={() => openSection('calories')}
                    aria-hidden="true"
                  >
                    <div className="my-auto">Kalorien</div>
                    <div className="my-auto">
                      <ChevronRightIcon width={20} height={20} className="text-accentColor cursor-pointer mx-auto" />
                    </div>
                  </div>
                </div>
                {userData?.health === undefined && getIsWebView() === false ? (
                  <div className="pl-10 pt-20 font-extralight overflow-y-auto">
                    <div>
                      Du kannst deine Workouts und Schritte, die über Apple Health (Apple) oder Health Connect (Android)
                      erfasst werden, tracken.
                    </div>
                    <div className="pt-10">
                      Bitte öffne die App auf deinem Smartphone und schließe dort die Einrichtung ab!
                    </div>
                  </div>
                ) : userData?.health === undefined ? (
                  <div className="pl-10 pt-20 font-extralight overflow-y-auto">
                    <div className="font-bold pb-10">Tracke deine Aktivitäten!</div>
                    <div>
                      Verbinde Tortija jetzt mit Apple Health (Apple) oder Health Connect (Android) und synchronisiere
                      deine Schritte und Aktivitäten.
                    </div>
                    <div className="pt-10">
                      Deine Schritte werden dir angezeigt, aber nicht zu deinem täglichen Kalorienbedarf hinzugerechnet.
                      Da du in deinem Profil bereits deine körperliche Aktivität angegeben hast, ist eine dazugehörige
                      Anzahl an Schritten bereits mit einberechnet.
                    </div>
                    <div className="pt-10">
                      Wenn du an einem Tag besonders viele Schritte gegangen bist kannst du das als zusätzliches
                      Training tracken.
                    </div>
                    <div className="pt-10">
                      <a
                        href="https://www.tortija.de/redirect.php?url=https://www.tortija.de/health-anbindung/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex gap-5">
                          <div className="underline">Anleitung und Hilfe</div>
                          <div className="my-auto">
                            <ChevronRightIcon
                              width={18}
                              height={18}
                              className="text-accentColor cursor-pointer my-auto"
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="pt-20">
                      <Button
                        isPending={isActivitiesSettingsPending}
                        disabled={isActivitiesSettingsPending}
                        className="w-full"
                        onClick={() => saveActivitiesSettings()}
                      >
                        Jetzt verbinden
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={activitiesInfoClass}>
                      <div className="font-extralight text-14 pt-10">
                        An dieser Stelle werden die Schritte und Workouts aus Apple Health (Apple) und Health Connect
                        (Android) angezeigt.
                      </div>
                      <div>
                        <a
                          href="https://www.tortija.de/redirect.php?url=https://www.tortija.de/health-anbindung/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex gap-5">
                            <div className="underline">Anleitung und Hilfe</div>
                            <div className="my-auto">
                              <ChevronRightIcon
                                width={18}
                                height={18}
                                className="text-accentColor cursor-pointer my-auto"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                      {getMobileOperatingSystem() === 'Android' && (
                        <div className="font-extralight text-14">
                          Wenn du die Health Connect App noch nicht installiert hast kannst du diese im Google Play
                          Store herunterladen.
                        </div>
                      )}
                    </div>
                    {activitiesLoading ? (
                      <div>Die aktuellen Health-Daten werden geladen...</div>
                    ) : (
                      <div>
                        <div className="flex justify-between pt-40">
                          <div className="w-1/2">
                            <div className="text-center text-18 font-bold text-accentColor">Schritte</div>
                            <div className="pt-20">
                              <Icon name="walking" className="text-textColor mx-auto" height={38} />
                            </div>
                            <div className="font-bold text-30 text-center pt-10">{currentStepCount}</div>
                            <div className="text-center pt-15">
                              <div className="font-extralight text-12">Verbrannte Kalorien:</div>
                              <div className="font-bold text-16">{currentStepCalories}</div>
                            </div>
                          </div>
                          <div className="w-1/2">
                            <div className="text-center text-18 font-bold text-accentColor">Workouts</div>
                            <div className="pt-20">
                              <Icon name="hantel" className="text-textColor mx-auto" height={38} />
                            </div>
                            <div className="font-bold text-30 text-center pt-10">{currentWorkoutMinutes} Min.</div>
                            <div className="text-center pt-15">
                              <div className="font-extralight text-12">Verbrannte Kalorien:</div>
                              <div className="font-bold text-16">{currentWorkoutCalories}</div>
                            </div>
                          </div>
                        </div>
                        {getIsWebView() === true && (
                          <div className="pt-20">
                            <Button onClick={() => updateHealthData()} className="w-full py-10">
                              Daten aktualisieren
                            </Button>
                          </div>
                        )}
                        <div className="pt-20">
                          <Button onClick={() => deleteActivitySettings()} className="w-full py-5 text-12">
                            Verbindung zu Health Daten entfernen
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </TransitionContainer>
          <TransitionContainer isShown={isCaloriesSectionShown}>
            <div>
              <div className="relative">
                <div className="flex justify-between">
                  <div className="pl-10 flex space-x-10">
                    <div className="my-auto">Kalorien</div>
                    {editFunction !== undefined &&
                      (tenantData?.settings?.functions?.caloriesGoal === undefined ||
                        tenantData?.settings?.functions?.caloriesGoal === true ||
                        userData?.isAdminUser ||
                        userData?.isTrainerUser ||
                        userData?.isLocationAdminUser ||
                        localStorage.getItem('currentUserToken')) && (
                        <div>
                          <Button className="text-10 py-1 px-4" onClick={() => editFunction('plan')}>
                            <PencilIcon
                              width={15}
                              height={15}
                              className="text-buttonTextColor cursor-pointer mx-auto"
                            />
                          </Button>
                        </div>
                      )}
                  </div>
                  {tenantData?.features?.health !== undefined && tenantData?.features?.health === true && (
                    <div
                      className="pl-10 flex space-x-5 cursor-pointer"
                      onClick={() => openSection('activities')}
                      aria-hidden="true"
                    >
                      <div className="my-auto">Aktivitäten</div>
                      <div className="my-auto">
                        <ChevronRightIcon width={20} height={20} className="text-accentColor cursor-pointer mx-auto" />
                      </div>
                    </div>
                  )}
                  <div className="font-extralight text-12 pr-10 hidden">
                    Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                    {Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05)}{' '}
                    kcal
                  </div>
                </div>
                <div className="">
                  <div className="relative">
                    <div className="flex justify-around items-center space-x-10">
                      <div className="font-extralight text-12 my-auto flex-1 text-center min-w-70">
                        {Math.round(kcal_value) >= Math.round(parseFloat(thisKcalTotal.toString())) &&
                        Math.round(kcal_value) <=
                          Math.round(
                            parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                          ) ? (
                          <div className="font-bold text-14 tablet:text-20">0</div>
                        ) : (
                          <div className="font-bold text-14 tablet:text-20">
                            {(Math.round(parseFloat(thisKcalTotal.toString())) - Math.round(kcal_value))
                              .toString()
                              .replaceAll('-', '')}
                          </div>
                        )}
                        {Math.round(kcal_value) >
                        Math.round(
                          parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                        ) ? (
                          <div>kcal zu viel</div>
                        ) : (
                          <div>kcal übrig</div>
                        )}
                      </div>
                      <div className="relative mx-auto">
                        <div>
                          <Circle
                            className="w-150 md:w-3/4 mx-auto"
                            percent={PercentKcalTotal}
                            strokeWidth={10}
                            trailWidth={10}
                            trailColor={theme?.bgColor ?? '#3c4044'}
                            strokeLinecap="round"
                            strokeColor={{
                              '100%': theme?.accentColor
                                ? !isLight(theme?.accentColor)
                                  ? lightenColor(theme?.bgColor, 40) ?? defaultAccentColor
                                  : darkenColor(theme?.bgColor, 40) ?? defaultAccentColor
                                : defaultAccentColor,
                              '0%': theme?.accentColor ?? defaultAccentColor,
                            }}
                          />
                        </div>
                        <div className="absolute xxl:top-70 xl:top-70 lg:top-50 md:top-70 sm:top-50 top-50 text-center h-full w-full">
                          <div>
                            <div
                              className={
                                editFunction !== undefined
                                  ? 'text-16 xl:text-20 font-bold pb-10 hidden md:block'
                                  : 'text-20 font-bold pb-10 pt-20 hidden md:block'
                              }
                            >
                              Kalorien
                            </div>
                            <div className="font-bold text-14">{Math.round(kcal_value)} kcal</div>
                            <div className="flex justify-center">
                              <div className="font-bold text-12">
                                <div>{PercentKcalTotal}%</div>
                                <div className="my-auto pl-1 md:hidden block">
                                  <MakroIcons
                                    percent={PercentKcalTotal}
                                    minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                                    maxValue={Math.round(
                                      parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                                    )}
                                    value={Math.round(kcal_value)}
                                  />
                                </div>
                              </div>
                              <div className="my-auto pl-1 hidden md:block">
                                <MakroIcons
                                  percent={PercentKcalTotal}
                                  minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                                  maxValue={Math.round(
                                    parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                                  )}
                                  value={Math.round(kcal_value)}
                                />
                              </div>
                            </div>
                            <div className="font-extralight text-12 pt-5 hidden">
                              Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                              {Math.round(
                                parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                              )}{' '}
                              kcal
                            </div>
                            {editFunction !== undefined && (
                              <>
                                <div className="pt-10 hidden">
                                  {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
                                    tenantData?.settings?.functions?.caloriesGoal === true ||
                                    userData?.isAdminUser ||
                                    userData?.isTrainerUser ||
                                    userData?.isLocationAdminUser ||
                                    localStorage.getItem('currentUserToken')) && (
                                    <Button className="text-10 py-1 mx-auto" onClick={() => editFunction('plan')}>
                                      Bearbeiten
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="font-extralight text-12 my-auto flex-1 text-center min-w-70">
                        <div className="font-bold text-14 tablet:text-20">Ziel:</div>
                        <div>
                          {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                          {Math.round(
                            parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                          )}{' '}
                          kcal
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-15 px-20 md:pt-30 pt-15">
                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="font-bold text-17 pb-1">{PercentCarbohydratesTotal}%</div>
                    <div className="pl-1 pt-1">
                      <MakroIcons
                        percent={PercentCarbohydratesTotal}
                        minValue={Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)}
                        maxValue={Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)}
                        value={Math.round(carbohydrates_value)}
                      />
                    </div>
                  </div>
                  <div className="pb-2">
                    <Line
                      percent={PercentCarbohydratesTotal}
                      strokeWidth={8}
                      trailWidth={8}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#DE4C39"
                      className="w-80 md:w-100"
                    />
                  </div>
                  <div className="text-12 md:text-13 font-extralight">
                    <div>Kohlenhydrate</div>
                    <div className="font-bold">{Math.round(carbohydrates_value)} g</div>
                    <div className="font-extralight text-10 pt-5">
                      Ziel: {Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)} -{' '}
                      {Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)} g
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="font-bold text-17 pb-1">{PercentProteinTotal}%</div>
                    <div className="pl-1 pt-1">
                      <MakroIcons
                        percent={PercentProteinTotal}
                        minValue={Math.round(thisProteinTotal - thisProteinTotal * 0.15)}
                        maxValue={Math.round(thisProteinTotal + thisProteinTotal * 0.15)}
                        value={Math.round(protein_value)}
                      />
                    </div>
                  </div>
                  <div className="pb-2">
                    <Line
                      percent={PercentProteinTotal}
                      strokeWidth={8}
                      trailWidth={8}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#8DC703"
                      className="w-80 md:w-100"
                    />
                  </div>
                  <div className="text-12 md:text-13 font-extralight">
                    <div>Eiweiß</div>
                    <div className="font-bold">{Math.round(protein_value)} g</div>
                    <div className="font-extralight text-10 pt-5">
                      Ziel: {Math.round(thisProteinTotal - thisProteinTotal * 0.15)} -{' '}
                      {Math.round(thisProteinTotal + thisProteinTotal * 0.15)} g
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="font-bold text-17 pb-1">{PercentFatTotal}%</div>
                    <div className="pl-1 pt-1">
                      <MakroIcons
                        percent={PercentFatTotal}
                        minValue={Math.round(thisFatTotal - thisFatTotal * 0.15)}
                        maxValue={Math.round(thisFatTotal + thisFatTotal * 0.15)}
                        value={Math.round(fat_value)}
                      />
                    </div>
                  </div>
                  <div className="pb-2">
                    <Line
                      percent={PercentFatTotal}
                      strokeWidth={8}
                      trailWidth={8}
                      trailColor={theme?.bgColor ?? '#3c4044'}
                      strokeColor="#FBA500"
                      className="w-80 md:w-100"
                    />
                  </div>
                  <div className="text-12 md:text-13 font-extralight">
                    <div>Fett</div>
                    <div className="font-bold">{Math.round(fat_value)} g</div>
                    <div className="font-extralight text-10 pt-5">
                      Ziel: {Math.round(thisFatTotal - thisFatTotal * 0.15)} -{' '}
                      {Math.round(thisFatTotal + thisFatTotal * 0.15)} g
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionContainer>
        </div>
      ) : (
        <div>
          <div className="relative">
            <div className="flex justify-between md:hidden">
              <div className="pl-10 flex space-x-10">
                <div className="my-auto pt-1">Kalorien</div>
                {editFunction !== undefined && (
                  <div className="pt-5">
                    {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
                      tenantData?.settings?.functions?.caloriesGoal === true ||
                      userData?.isAdminUser ||
                      userData?.isTrainerUser ||
                      userData?.isLocationAdminUser ||
                      localStorage.getItem('currentUserToken')) && (
                      <Button className="text-10 py-1 px-4" onClick={() => editFunction('plan')}>
                        <PencilIcon width={15} height={15} className="text-buttonTextColor cursor-pointer mx-auto" />
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="font-extralight text-12 pr-10">
                Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                {Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05)} kcal
              </div>
            </div>
            <div className="flex justify-center">
              <div className="relative">
                <div>
                  <Circle
                    className="w-150 md:w-3/4 mx-auto"
                    percent={PercentKcalTotal}
                    strokeWidth={10}
                    trailWidth={10}
                    trailColor={theme?.bgColor ?? '#3c4044'}
                    strokeLinecap="round"
                    strokeColor={{
                      '100%': theme?.accentColor
                        ? !isLight(theme?.accentColor)
                          ? lightenColor(theme?.bgColor, 40) ?? defaultAccentColor
                          : darkenColor(theme?.bgColor, 40) ?? defaultAccentColor
                        : defaultAccentColor,
                      '0%': theme?.accentColor ?? defaultAccentColor,
                    }}
                  />
                </div>
                <div className="absolute md:top-40 top-50 text-center h-full w-full">
                  <div>
                    <div
                      className={
                        editFunction !== undefined
                          ? 'text-12 font-bold pb-10 hidden md:block'
                          : 'text-12 font-bold pb-10 pt-20 hidden md:block'
                      }
                    >
                      Kalorien
                    </div>
                    <div className="font-bold text-14">{Math.round(kcal_value)} kcal</div>
                    <div className="flex justify-center">
                      <div className="font-bold text-12">
                        <div>{PercentKcalTotal}%</div>
                        <div className="my-auto pl-1 md:hidden block">
                          <MakroIcons
                            percent={PercentKcalTotal}
                            minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                            maxValue={Math.round(
                              parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                            )}
                            value={Math.round(kcal_value)}
                          />
                        </div>
                      </div>
                      <div className="my-auto pl-1 hidden md:block">
                        <MakroIcons
                          percent={PercentKcalTotal}
                          minValue={Math.round(thisKcalTotal - thisKcalTotal * 0.05)}
                          maxValue={Math.round(
                            parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05
                          )}
                          value={Math.round(kcal_value)}
                        />
                      </div>
                    </div>
                    <div className="font-extralight text-12 pt-5 hidden md:block">
                      Ziel: {Math.round(thisKcalTotal - thisKcalTotal * 0.05)} -
                      {Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05)}{' '}
                      kcal
                    </div>
                    {editFunction !== undefined && (
                      <>
                        <div className="pt-10 hidden md:block">
                          {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
                            tenantData?.settings?.functions?.caloriesGoal === true ||
                            userData?.isAdminUser ||
                            userData?.isTrainerUser ||
                            userData?.isLocationAdminUser ||
                            localStorage.getItem('currentUserToken')) && (
                            <Button className="text-10 py-1 mx-auto" onClick={() => editFunction('plan')}>
                              Bearbeiten
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-15 px-20 md:pt-30 pt-15">
            <div className="text-center">
              <div className="flex justify-center">
                <div className="font-bold text-17 pb-1">{PercentCarbohydratesTotal}%</div>
                <div className="pl-1 pt-1">
                  <MakroIcons
                    percent={PercentCarbohydratesTotal}
                    minValue={Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)}
                    maxValue={Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)}
                    value={Math.round(carbohydrates_value)}
                  />
                </div>
              </div>
              <div className="pb-2">
                <Line
                  percent={PercentCarbohydratesTotal}
                  strokeWidth={8}
                  trailWidth={8}
                  trailColor={theme?.bgColor ?? '#3c4044'}
                  strokeColor="#DE4C39"
                  className="w-80 md:w-100"
                />
              </div>
              <div className="text-12 md:text-13 font-extralight">
                <div>Kohlenhydrate</div>
                <div className="font-bold">{Math.round(carbohydrates_value)} g</div>
                <div className="font-extralight text-10 pt-5">
                  Ziel: {Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15)} -{' '}
                  {Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15)} g
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="flex justify-center">
                <div className="font-bold text-17 pb-1">{PercentProteinTotal}%</div>
                <div className="pl-1 pt-1">
                  <MakroIcons
                    percent={PercentProteinTotal}
                    minValue={Math.round(thisProteinTotal - thisProteinTotal * 0.15)}
                    maxValue={Math.round(thisProteinTotal + thisProteinTotal * 0.15)}
                    value={Math.round(protein_value)}
                  />
                </div>
              </div>
              <div className="pb-2">
                <Line
                  percent={PercentProteinTotal}
                  strokeWidth={8}
                  trailWidth={8}
                  trailColor={theme?.bgColor ?? '#3c4044'}
                  strokeColor="#8DC703"
                  className="w-80 md:w-100"
                />
              </div>
              <div className="text-12 md:text-13 font-extralight">
                <div>Eiweiß</div>
                <div className="font-bold">{Math.round(protein_value)} g</div>
                <div className="font-extralight text-10 pt-5">
                  Ziel: {Math.round(thisProteinTotal - thisProteinTotal * 0.15)} -{' '}
                  {Math.round(thisProteinTotal + thisProteinTotal * 0.15)} g
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="flex justify-center">
                <div className="font-bold text-17 pb-1">{PercentFatTotal}%</div>
                <div className="pl-1 pt-1">
                  <MakroIcons
                    percent={PercentFatTotal}
                    minValue={Math.round(thisFatTotal - thisFatTotal * 0.15)}
                    maxValue={Math.round(thisFatTotal + thisFatTotal * 0.15)}
                    value={Math.round(fat_value)}
                  />
                </div>
              </div>
              <div className="pb-2">
                <Line
                  percent={PercentFatTotal}
                  strokeWidth={8}
                  trailWidth={8}
                  trailColor={theme?.bgColor ?? '#3c4044'}
                  strokeColor="#FBA500"
                  className="w-80 md:w-100"
                />
              </div>
              <div className="text-12 md:text-13 font-extralight">
                <div>Fett</div>
                <div className="font-bold">{Math.round(fat_value)} g</div>
                <div className="font-extralight text-10 pt-5">
                  Ziel: {Math.round(thisFatTotal - thisFatTotal * 0.15)} -{' '}
                  {Math.round(thisFatTotal + thisFatTotal * 0.15)} g
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MakroCircles;
