import React from 'react';

import styles from './styles.module.scss';

interface ModalProps {
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children }) => {
  return (
    <>
      <div className={styles.modal}>{children}</div>
      <div className={styles.overlay} />
    </>
  );
};

export default Modal;
