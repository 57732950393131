import React from 'react';
import moment from 'moment';
import { PaperClipIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import AnalyzeOverview from './components';
import styles from './styles.module.scss';

type Props = {
  userData?: any;
};

const CoachingDashboardAnalyze: React.FC<Props> = ({ userData }) => {
  return (
    <div className="pt-30">
      <div className="flex gap-40 flex-wrap">
        <div className="flex-1 flex flex-col">
          <div className="">
            <AnalyzeOverview userData={userData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboardAnalyze;
