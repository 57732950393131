import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import ButtonBack from 'components/ButtonBack';
import firebase from 'services/firebase';
import { Answer, Question, Survey } from 'shared/types/survey';
import { AuthContext } from 'providers/AuthProvider';
import { getAnswersFromUser, getSurveyIterations, isViewOnlyForUser } from 'shared/functions/surveyFunctions';
import Button from 'components/Button';
import Headline from 'components/Headline';
import { toast } from 'react-toast';
import ReactLoading from 'react-loading';
import QuestionItem from '../components/QuestionItem';

import styles from './styles.module.scss';

type ParamsType = {
  id: string;
  iteration: string;
};

const SurveyCustomerDetail: React.FC = () => {
  const { id, iteration } = useParams<ParamsType>();
  const { tenant, user, userData } = useContext(AuthContext);
  const [isFetching, setIsFetching] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  // -------------------------------- get survey --------------------------------
  const fetchUserSurvey = async () => {
    if (!user?.uid) return;

    setIsFetching(true);

    try {
      const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(id.split('-')[0]);

      // TODO: Survey darf nur für den User sein

      if (!surveyRef) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const surveyDoc = await surveyRef.get();

      const surveyData = surveyDoc.data();

      if (!surveyData) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const surveyIterations =
        surveyData.frequency !== 'once'
          ? getSurveyIterations(surveyData.planDate, surveyData.frequency, firebase.firestore.Timestamp.now()) // TODO: TIMEZONE
          : [{ iteration: 0, active: surveyData.status === 3, startDate: surveyData.planDate.toDate() }];

      const questionsCollection = await firebase
        .firestore()
        .collection(`tenants/${tenant}/surveys/${id.split('-')[0]}/questions`)
        .get();

      const trainer = await firebase.firestore().collection(`tenants/${tenant}/users`).doc(surveyData.creator.id).get();

      const questionsData = await Promise.all(
        questionsCollection.docs.map(async questionDoc => {
          const questionData = questionDoc.data();

          const allAnswersData = await Promise.all(
            surveyIterations.map(async (iterationItem: any) => {
              const answersCollection = await firebase
                .firestore()
                .collection(
                  `tenants/${tenant}/surveys/${id.split('-')[0]}/questions/${questionDoc.id}/answers_${
                    iterationItem.iteration
                  }`
                )
                .get();

              const answersData = await Promise.all(
                answersCollection.docs.map(async answerDoc => {
                  const creator = await answerDoc.data().creator.get();
                  return {
                    ...answerDoc.data(),
                    id: answerDoc.id,
                    questionId: questionDoc.id,
                    creatorId: creator.id,
                    iteration: iterationItem.iteration,
                  };
                })
              );

              return answersData;
            })
          );

          const flattenedAnswersData = allAnswersData.flat(); // This will combine all answers into a single array

          return {
            ...questionData,
            id: questionDoc.id,
            answers: flattenedAnswersData as Answer[],
          };
        })
      );

      const surveyDataWithQuestions = {
        ...surveyData,
        id: id.split('-')[0],
        questions: questionsData as Question[],
        iterations: surveyIterations,
        iteration: parseInt(iteration, 10),
        answersFromUser: getAnswersFromUser(
          questionsData as Question[],
          user?.uid,
          parseInt(iteration, 10)
        ) as Answer[],
        active: surveyIterations[parseInt(iteration, 10)].active,
        creator: trainer.data(),
        planDate: surveyIterations[parseInt(iteration, 10)].startDate,
      };

      setSurvey(surveyDataWithQuestions as Survey);
      setAnswers(surveyDataWithQuestions.answersFromUser);

      setIsFetching(false);
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchUserSurvey();
  }, [user?.uid]);
  // ---------------------------------------------------------------------------

  // ------------------------------- upload answers -------------------------------
  const uploadAnswers = async () => {
    const hasAnswers = answers.every(answer => answer.answer !== '');

    if (!hasAnswers) {
      toast.warn('Bitte beantworten Sie alle Fragen.');
      return;
    }

    try {
      setIsUploading(true);
      const batch = firebase.firestore().batch();

      const userRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid);

      answers.forEach((answer, index) => {
        const answerRef = firebase
          .firestore()
          .collection(
            `tenants/${tenant}/surveys/${id.split('-')[0]}/questions/${answer.questionId}/answers_${iteration}`
          )
          .doc();

        batch.set(answerRef, {
          answer: answer.answer,
          createdAt: firebase.firestore.Timestamp.now(),
          creator: userRef,
          isFirst: index === 0,
        });
      });

      await batch.commit();
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
      toast.success('Antworten wurden erfolgreich gespeichert.');
      fetchUserSurvey();
    }
  };
  // ---------------------------------------------------------------------------

  if (isFetching) return <ReactLoading type="bubbles" width={50} height={20} className={styles.preloader} />;
  if (!survey) return null;

  if (userData?.role !== 5) {
    return <Redirect to="/surveys" />;
  }

  // check if survey is active and user has answered the survey
  const isViewOnly =
    survey.active && isViewOnlyForUser(survey.status)
      ? survey.answersFromUser && survey.answersFromUser?.length > 0
      : true;

  // create empty answers if user has not answered the survey yet
  // only needed if not view only
  if (answers.length === 0 && !isViewOnly) {
    const tempAnswers: Answer[] = [];
    survey.questions.forEach(question => {
      tempAnswers.push({
        questionId: question.id,
        answer: '',
        createdAt: firebase.firestore.Timestamp.now(),
        id: '',
      });
    });
    setAnswers(tempAnswers);
  }

  return (
    <section className={styles.surveyDetailContainer}>
      <Link to="/surveys" className={styles.backBtnWrapper}>
        <ButtonBack className={styles.backBtn} text="Zurück" />
      </Link>

      <div className={styles.nameWrapper}>
        <Headline level={1}>
          {survey.surveyTitle} {survey.frequency === 'once' ? '' : `${survey.iteration}`}
        </Headline>
      </div>

      <div className={styles.smallInfoWrapper}>
        <p className={styles.smallInfoText}>Von Trainer:in</p>
        <p>{survey.creator.fullName}</p>
      </div>

      <div className={styles.smallInfoWrapper}>
        <p className={styles.smallInfoText}>Erhalten am</p>
        <p>{new Date(survey.planDate).toLocaleDateString()}</p>
      </div>

      <div className={styles.questionsWrapper}>
        {survey.questions
          .sort((a, b) => (a.position || 0) - (b.position || 0))
          .map(question => (
            <QuestionItem
              key={question.id}
              question={question}
              isViewOnly={isViewOnly}
              viewAnswer={survey.answersFromUser?.find(answer => answer.questionId === question.id) as Answer}
              answerForEdit={answers.find(answer => answer.questionId === question.id) as Answer}
              onChangeAnswer={setAnswers}
            />
          ))}
      </div>

      {!isViewOnly && (
        <Button onClick={uploadAnswers} className={styles.uploadBtn} isPending={isUploading}>
          Antworten abschicken
        </Button>
      )}
    </section>
  );
};

export default SurveyCustomerDetail;
