import React from 'react';
import { Answer, Question } from 'shared/types/survey';
import CustomStarRating from '../CustomStarRating';
import OptionInput from '../OptionInput';

import styles from './styles.module.scss';

interface AnswerItemProps {
  answer: Answer;
  question: Question;
  placeholder?: string;
}

const AnswerItemsViewOnly: React.FC<AnswerItemProps> = ({
  answer,
  question,
  placeholder = 'Sie haben die Frage nicht beantwortet',
}) => {
  if (!answer) {
    return <p className={styles.noAnswer}>{placeholder}</p>;
  }
  if (question.answerOptions && (question.type === 1 || question.type === 2)) {
    return (
      <OptionInput
        questionOptions={question.answerOptions}
        type={question.type === 1 ? 'radio' : question.type === 2 ? 'checkbox' : ''}
        value={answer.answer}
        readonly
        name={question.id}
      />
    );
  }

  if (question.type === 4 && typeof answer.answer === 'string') {
    return <CustomStarRating value={answer.answer} readonly />;
  }

  return (
    <div>
      <p>{answer.answer}</p>
    </div>
  );
};

export default AnswerItemsViewOnly;
