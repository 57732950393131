import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface OptionInputProps {
  questionOptions: string[];
  type: 'radio' | 'checkbox' | '';
  value: string[] | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  name: string;
}

const OptionInput: React.FC<OptionInputProps> = ({ questionOptions, type, value, onChange, readonly, name }) => {
  if (type === '') return null;

  return (
    <div className={styles.answerOptionsWrapper}>
      {questionOptions.map((option, index) => (
        <label
          key={index}
          className={classNames(styles.answerOption, { [styles.cursorPointer]: !readonly })}
          htmlFor={option}
        >
          <input
            type={type}
            className={classNames(styles.input, {
              [styles.radio]: type === 'radio',
              [styles.checkbox]: type === 'checkbox',
              [styles.checked]: Array.isArray(value) ? value.includes(option) : value === option,
            })}
            value={option}
            checked={Array.isArray(value) ? value.includes(option) : value === option}
            disabled={readonly}
            name={name}
            onChange={onChange}
            id={option}
          />
          {option}
        </label>
      ))}
    </div>
  );
};

export default OptionInput;
