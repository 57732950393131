import React from 'react';
import moment from 'moment';
import { PaperClipIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  isActive: string;
  setIsActive: Function;
};

const CoachingDashboardTabs: React.FC<Props> = ({ isActive, setIsActive }) => {
  return (
    <div className="flex justify-between pt-20">
      {isActive === 'anamnese' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Anamnese</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('anamnese')}
          aria-hidden
        >
          <div>Anamnese</div>
        </div>
      )}

      {isActive === 'checkIn' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Check-Ins</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('checkIn')}
          aria-hidden
        >
          <div>Check-Ins</div>
        </div>
      )}

      {isActive === 'plan' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Ernährungsplan</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('plan')}
          aria-hidden
        >
          <div>Ernährungsplan</div>
        </div>
      )}

      {isActive === 'activities' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Analyse</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('activities')}
          aria-hidden
        >
          <div>Analyse</div>
        </div>
      )}

      {isActive === 'bodyData' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Körperdaten</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('bodyData')}
          aria-hidden
        >
          <div>Körperdaten</div>
        </div>
      )}
    </div>
  );
};

export default CoachingDashboardTabs;
