import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PaperClipIcon, PencilIcon, StarIcon as StarIconOutline } from '@heroicons/react/outline';
import { StarIcon as StarIconSolid, StarIcon, PencilIcon as PencilIconSolid } from '@heroicons/react/solid';
import Icon from 'components/Icon';
import axios from 'axios';
import { toast } from 'react-toast';
import { defaultTextColor } from 'layouts/Theme';
import firebase from 'services/firebase';
import ReactLoading from 'react-loading';
import { AuthContext } from 'providers/AuthProvider';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Button from 'components/Button';
import styles from './styles.module.scss';

type Props = {
  userData: any;
  deleteUserFunction: Function;
  editDeleteDateFunction: Function;
  userId: string;
};

const CoachingDashboardHeader: React.FC<Props> = ({ userData, deleteUserFunction, editDeleteDateFunction, userId }) => {
  const [impersonateLoading, setImpersonateLoading] = useState(false);
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [showAvatarEdit, setShowAvatarEdit] = useState(false);
  const [showAmpelText, setShowAmpelText] = useState(false);
  const [ampelValue, setAmpelValue] = useState('');
  const [ratingAverage, setRatingAverage] = useState(0);
  const [lastRatingValue, setLastRatingValue] = useState(0);
  const [lastRatingDate, setLastRatingDate] = useState('');
  const [ratingAvailable, setRatingAvailable] = useState(false);
  const [ratingsLoading, setRatingsLoading] = useState(true);
  const { theme, tenant, tenantData } = useContext(AuthContext);

  const handleImpersonate = async () => {
    setImpersonateLoading(true);
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut!');
    }
  };

  const onDropLogo = async (pictureFiles: ImageListType) => {
    setImageUploadPending(true);

    if (!pictureFiles.length || !pictureFiles[0].file) {
      setImageUploadPending(false);
      return;
    }

    const storageRef = firebase.storage().ref(`tenants/${tenant}/users/${userId}/avatar`);

    // Liste alle Dateien im Avatar-Ordner auf
    const listResults = await storageRef.listAll();

    // Lösche alle Dateien im Avatar-Ordner
    const deletePromises = listResults.items.map(itemRef => itemRef.delete());
    await Promise.all(deletePromises);

    // Jetzt die neue Datei hochladen
    const res = await storageRef.child(pictureFiles[0].file.name).put(pictureFiles[0].file);

    const imageUrl = await res.ref.getDownloadURL();

    // Update Firestore mit der neuen Avatar-URL
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update({ avatar: imageUrl });

    setAvatarUrl(imageUrl);
    setImageUploadPending(false);
  };

  const getRatings = async () => {
    // Erzeuge ein Datum von vor 14 Tagen
    const today = new Date();
    let date14DaysAgo = new Date(today) as any;
    date14DaysAgo.setDate(today.getDate() - 14);

    const formattedToday = moment(today).format('YYYY-MM-DD');

    date14DaysAgo = moment(date14DaysAgo).format('YYYY-MM-DD');

    const ratingRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).collection('ratings');

    // Führe die Abfrage aus, um die Bewertungen des Users in den letzten 14 Tagen zu erhalten
    const querySnapshot = await ratingRef
      .where('date', '>=', date14DaysAgo)
      .where('date', '<=', formattedToday)
      .orderBy('date', 'desc')
      .get();

    // Berechne den Durchschnitt der Bewertungen
    let sum = 0;
    let count = 0;
    querySnapshot.forEach(doc => {
      sum += doc.data().rating;
      count += 1;
    });

    const getAverage = count > 0 ? parseFloat((sum / count).toFixed(1)) : 0;

    if (getAverage === 0) {
      const getLastRating = await ratingRef.orderBy('date', 'desc').limit(1).get();

      if (getLastRating.size > 0) {
        setRatingAverage(getLastRating.docs[0].data().rating);
        setLastRatingDate(moment(getLastRating.docs[0].data().date).format('DD.MM.YYYY'));
        setLastRatingValue(getLastRating.docs[0].data().rating);
        setRatingAvailable(true);
        setRatingsLoading(false);
      } else {
        setRatingsLoading(false);
      }
    } else {
      setRatingAverage(getAverage);
      setLastRatingDate(moment(querySnapshot.docs[0].data().date).format('DD.MM.YYYY'));
      setLastRatingValue(querySnapshot.docs[0].data().rating);
      setRatingAvailable(true);
      setRatingsLoading(false);
    }
  };

  useEffect(() => {
    getRatings();

    const lastLoginDate = moment.unix(userData?.lastLoginDate?.seconds);

    // Prüfe, ob der letzte Login heute oder gestern war
    const isToday = lastLoginDate.isSame(moment(), 'day');
    const isYesterday = lastLoginDate.isSame(moment().subtract(1, 'day'), 'day');
    const isLast3Days = lastLoginDate.isSame(moment().subtract(3, 'day'), 'day');

    if (isToday || isYesterday) {
      setAmpelValue('green');
    } else if (isLast3Days) {
      setAmpelValue('orange');
    } else {
      setAmpelValue('red');
    }
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  return (
    <div className="mt-40 rounded-xl bg-lightGray py-20 px-20">
      <div className="flex justify-between flex-wrap items-center gap-10 gap-y-15">
        <div>
          <div className="flex justify-between pt-0 space-x-20 items-center">
            <div>
              {imageUploadPending ? (
                <div>
                  <div className="rounded-full w-120 h-120 bg-lightGrayDarker mr-10 relative">
                    <div className="absolute top-50 left-50 right-5 bottom-5">
                      <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                    </div>
                  </div>
                </div>
              ) : avatarUrl.length > 0 ? (
                <ImageUploading
                  value={userData?.avatar}
                  onChange={onDropLogo}
                  dataURLKey="data_url"
                  maxFileSize={5242880}
                  acceptType={['jpg', 'gif', 'png', 'gif', 'jpeg', 'svg']}
                >
                  {({ onImageUpload, dragProps }) => (
                    <div
                      onMouseOver={() => setShowAvatarEdit(true)}
                      onFocus={() => setShowAvatarEdit(true)}
                      onMouseLeave={() => setShowAvatarEdit(false)}
                      aria-hidden
                    >
                      {showAvatarEdit ? (
                        <div className="relative">
                          <div className="upload__image-wrapper">
                            <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                              <div className={styles.avatar}>
                                <img src={avatarUrl} alt="Avatar" className="opacity-25" />
                              </div>
                              <div className="absolute top-45 left-45">
                                <PencilIcon width={35} height={35} className="text-black mx-auto" />
                              </div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="upload__image-wrapper">
                          <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                            <div className={styles.avatar}>
                              <img src={avatarUrl} alt="Avatar" />
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              ) : userData?.avatar ? (
                <ImageUploading
                  value={userData?.avatar}
                  onChange={onDropLogo}
                  dataURLKey="data_url"
                  maxFileSize={5242880}
                  acceptType={['jpg', 'gif', 'png', 'gif', 'jpeg', 'svg']}
                >
                  {({ onImageUpload, dragProps }) => (
                    <div
                      onMouseOver={() => setShowAvatarEdit(true)}
                      onFocus={() => setShowAvatarEdit(true)}
                      onMouseLeave={() => setShowAvatarEdit(false)}
                      aria-hidden
                    >
                      {showAvatarEdit ? (
                        <div className="relative">
                          <div className="upload__image-wrapper">
                            <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                              <div className={styles.avatar}>
                                <img src={userData?.avatar} alt="Avatar" className="opacity-25" />
                              </div>
                              <div className="absolute top-45 left-45">
                                <PencilIcon width={35} height={35} className="text-black mx-auto" />
                              </div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="upload__image-wrapper">
                          <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                            <div className={styles.avatar}>
                              <img src={userData?.avatar} alt="Avatar" />
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              ) : (
                <ImageUploading
                  value={userData?.avatar ?? ''}
                  onChange={onDropLogo}
                  dataURLKey="data_url"
                  maxFileSize={5242880}
                  acceptType={['jpg', 'gif', 'png', 'gif', 'jpeg', 'svg']}
                >
                  {({ onImageUpload, dragProps }) => (
                    <div
                      onMouseOver={() => setShowAvatarEdit(true)}
                      onFocus={() => setShowAvatarEdit(true)}
                      onMouseLeave={() => setShowAvatarEdit(false)}
                      aria-hidden
                    >
                      {showAvatarEdit ? (
                        <div className="relative">
                          <div className="upload__image-wrapper">
                            <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                              <div className="rounded-full w-120 h-120 bg-lightGrayDarker mr-10 relative">
                                <div className="absolute top-45 left-45">
                                  <PencilIcon width={35} height={35} className="text-black mx-auto" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="upload__image-wrapper">
                          <button type="button" onClick={onImageUpload} {...dragProps} className="outline-none">
                            <div className="rounded-full w-120 h-120 bg-lightGrayDarker mr-10 relative">
                              <div className="absolute top-35 left-35 right-5 bottom-5">
                                <Icon name="profile" className={styles.headerIcon} width={50} height={50} />
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              )}
            </div>
            <div className="font-extralight">
              <div className="flex gap-10 flex-wrap pb-10">
                <div className="font-bold text-20">{userData?.fullName}</div>
                <div className="my-auto relative">
                  <div
                    className={
                      ampelValue === 'green'
                        ? 'rounded-full w-20 h-20 bg-green-400 relative border border-lightGrayDarker'
                        : ampelValue === 'orange'
                        ? 'rounded-full w-20 h-20 bg-yellow-200 relative border border-lightGrayDarker'
                        : 'rounded-full w-20 h-20 bg-red-600 relative border border-lightGrayDarker'
                    }
                    onMouseOver={() => setShowAmpelText(true)}
                    onFocus={() => setShowAmpelText(true)}
                    onMouseLeave={() => setShowAmpelText(false)}
                  >
                    <></>
                  </div>
                  {showAmpelText && (
                    <div className="absolute -top-50 -left-30">
                      {ampelValue === 'green' ? (
                        <div className="bg-lightGrayDarker text-10 w-100 px-10 py-5">Zuletzt Online vor 24 Std.</div>
                      ) : ampelValue === 'orange' ? (
                        <div className="bg-lightGrayDarker text-10 w-100 px-10 py-5">
                          Zuletzt Online vor 1 - 3 Tagen
                        </div>
                      ) : (
                        <div className="bg-lightGrayDarker text-10 w-100 px-10 py-5">
                          Zuletzt Online vor mehr als 3 Tagen
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="my-auto">
                  {userData?.country !== undefined && userData?.country === 'DE' ? (
                    <Icon name="flagDe" width={22} height={22} />
                  ) : userData?.country !== undefined && userData?.country === 'AT' ? (
                    <Icon name="flagAt" width={22} height={22} />
                  ) : userData?.country !== undefined && userData?.country === 'CH' ? (
                    <Icon name="flagCh" width={22} height={22} />
                  ) : (
                    <Icon name="flagDe" width={22} height={22} />
                  )}
                </div>
              </div>
              <div>E-Mail: {userData?.email}</div>
              <div>Kunde seit: {moment.unix(userData?.created?.seconds).format('DD.MM.YYYY') ?? ''}</div>
              <div>
                Letzter Login:{' '}
                {userData?.lastLoginDate ? moment(userData?.lastLoginDate?.toDate()).format('DD[.]MM[.]YY') : ''}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-16">Tagesbewertungen</div>
          {ratingsLoading ? (
            <div className="flex justify-center">
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            </div>
          ) : (
            <div>
              {ratingAvailable ? (
                <div>
                  <div className="pt-1 flex justify-between flex-wrap">
                    <div>
                      {lastRatingValue >= 1 ? (
                        <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                      ) : (
                        <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                      )}
                    </div>
                    <div>
                      {lastRatingValue >= 2 ? (
                        <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                      ) : (
                        <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                      )}
                    </div>
                    <div>
                      {lastRatingValue >= 3 ? (
                        <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                      ) : (
                        <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                      )}
                    </div>
                    <div>
                      {lastRatingValue >= 4 ? (
                        <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                      ) : (
                        <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                      )}
                    </div>
                    <div>
                      {lastRatingValue >= 5 ? (
                        <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                      ) : (
                        <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                      )}
                    </div>
                  </div>
                  <div className="font-extralight text-12 pt-1">
                    Letzte: <span className="font-bold">({lastRatingDate})</span>
                  </div>
                </div>
              ) : (
                <div className="font-extralight text-12">Keine Daten vorhanden!</div>
              )}
              {ratingAvailable && (
                <div className="pt-10">
                  <hr className="border-lightGrayDarker pb-5" />
                  <div>
                    <div className="pt-1 flex justify-between flex-wrap">
                      <div>
                        {ratingAverage >= 1 ? (
                          <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                        ) : (
                          <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                        )}
                      </div>
                      <div>
                        {ratingAverage >= 2 ? (
                          <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                        ) : (
                          <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                        )}
                      </div>
                      <div>
                        {ratingAverage >= 3 ? (
                          <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                        ) : (
                          <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                        )}
                      </div>
                      <div>
                        {ratingAverage >= 4 ? (
                          <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                        ) : (
                          <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                        )}
                      </div>
                      <div>
                        {ratingAverage >= 5 ? (
                          <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
                        ) : (
                          <StarIconSolid width={20} height={20} className="text-lightGrayDarker mx-auto" />
                        )}
                      </div>
                    </div>
                    <div className="font-extralight text-12 pt-1">
                      14 Tage:{' '}
                      <span className="font-bold">
                        (Ø{' '}
                        {ratingAverage.toString().includes('.')
                          ? ratingAverage.toString().replace('.', ',')
                          : `${ratingAverage.toString()},0`}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {tenantData?.features?.coachingDashboard !== undefined && tenantData?.features?.coachingDashboard === true && (
          <div>
            <Link
              to={{
                pathname: `/member/documents/${userId}`,
              }}
            >
              <div className="rounded-3xl bg-lightGrayDarker h-80 w-150 border-transparent border-2 hover:border-accentColor cursor-pointer">
                <div className="pt-15">
                  <PaperClipIcon width={25} height={25} className="text-textColor cursor-pointer mx-auto" />
                </div>
                <div className="text-center font-extralight text-16 px-20 pt-1 text-textColor">Dokumente</div>
              </div>
            </Link>
          </div>
        )}

        <div>
          <div>
            <Button
              className="py-5 text-12"
              onClick={() => handleImpersonate()}
              disabled={impersonateLoading}
              isPending={impersonateLoading}
            >
              Als Kunde einloggen
            </Button>
          </div>
          <div className="pt-10">
            {userData.deleteDate !== undefined ? (
              <div className="cursor-pointer" onClick={() => editDeleteDateFunction('block')} aria-hidden>
                <div>Wird gelöscht am:</div>
                <div className="text-12 flex gap-5">
                  <div>{moment.unix(userData?.deleteDate).format('DD.MM.YYYY')}</div>
                  <div className="my-auto">
                    <PencilIcon width={15} height={15} className="text-accentColor mx-auto" />
                  </div>
                </div>
              </div>
            ) : (
              <Button className="py-5 text-12 w-full" onClick={() => deleteUserFunction('block')}>
                Account löschen
              </Button>
            )}
          </div>
          <div className="pt-30">
            <Link
              to={{
                pathname: `/member/notices/${userId}`,
              }}
            >
              <Button className="py-5 text-12 w-full">Notizen</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboardHeader;
