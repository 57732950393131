import React, { ChangeEvent, useContext, useMemo, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor } from 'layouts/Theme';
import firebase from 'services/firebase';
import Button from 'components/Button';

import { PlusIcon, AdjustmentsIcon, XIcon, TrashIcon } from '@heroicons/react/solid';
import Overlay from 'components/Overlay';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import CustomSelect from 'components/CustomSelect';
import { toast } from 'react-toast';
import classNames from 'classnames';
import Headline from 'components/Headline';
import _debounce from 'lodash/debounce';
import ReactLoading from 'react-loading';
import ButtonBack from 'components/ButtonBack';
import CustomInput from 'components/Input/custom-input';
import FilterDrawerDocuments from '../FilterDrawerDocuments';
import styles from './styles.module.scss';

type ParamsType = {
  userId: string;
};

export default function CoachingDashBoardDocuments() {
  const { userId } = useParams<ParamsType>();
  const { tenant, theme, tenantData } = useContext(AuthContext);
  const [currentUserData, setCurrentUserData] = useState<UserInfo>({} as UserInfo);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [userDataValid, setUserDataValid] = useState(false);
  const [documentsAvailable, setDocumentsAvailable] = useState(false);
  const [newDocumentOverlayClass, setNewDocumentOverlayClass] = useState('hidden');
  const [deleteDocumentOverlayClass, setDeleteDocumentOverlayClass] = useState('hidden');
  const [uploadPending, setUploadPending] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [documents, setDocuments] = useState<any>([]);
  const [originalDocuments, setOriginalDocuments] = useState<any>([]);
  const [filterCategories, setFilterCategories] = useState<string[]>([]);
  const [isFilterOpen, setFilterOpen] = useState(false);

  const [currentFileName, setCurrentFileName] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [search, setSearch] = useState<string>('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const documentTypeOptions: any = [
    { value: 'anamnese', label: 'Anamnese' },
    { value: 'doc', label: 'Arztberichte' },
    { value: 'picture', label: 'Bilder' },
    { value: 'dataprivacy', label: 'Datenschutz' },
    { value: 'measurement', label: 'Körper-Analyse' },
    { value: 'other', label: 'Sonstiges' },
    { value: 'contract', label: 'Vertragliches' },
  ];

  // Die Funktion für das Filtern der Dokumente
  const filteredDocuments = useMemo(() => {
    let filtered = [] as any;

    if (filterCategories.length > 0) {
      // Umwandlung der `label`-Werte in `value`-Werte
      const values = filterCategories
        .map(label => documentTypeOptions.find((option: any) => option.label === label)?.value)
        .filter((value): value is string => value !== undefined); // Filtere `undefined`-Werte aus

      // Nur Dokumente auswählen, deren `category` im `filterCategories`-Array ist
      filtered = originalDocuments.filter((doc: any) => values.includes(doc.category));
    } else {
      filtered = originalDocuments;
    }

    if (startDate !== null && endDate === null) {
      filtered = filtered.filter(
        (doc: any) => moment(startDate).startOf('day').unix() <= moment.unix(doc.date).startOf('day').unix()
      );
    } else if (startDate !== null && endDate !== null) {
      filtered = filtered.filter(
        (doc: any) =>
          moment(startDate).startOf('day').unix() <= moment.unix(doc.date).startOf('day').unix() &&
          moment(endDate).startOf('day').unix() >= moment.unix(doc.date).startOf('day').unix()
      );
    }

    if (search.length > 0) {
      filtered = filtered.filter((doc: any) => doc.fileName.includes(search.toLowerCase()));
    }

    if (filtered.length > 0 || startDate !== null || endDate !== null || search.length === 0) {
      setDocuments(filtered);
    } else {
      setDocuments(originalDocuments);
    }
  }, [filterCategories, startDate, endDate, search, originalDocuments]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // onChange-Handler mit Debounce
  const handleSearchDebounced = useMemo(() => _debounce(handleSearch, 300), []);

  const getDocumentData = async () => {
    const documentArray = [] as any;

    const documentData = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('documents')
      .orderBy('date', 'desc')
      .get();

    if (documentData.docs.length > 0) {
      setDocumentsAvailable(true);

      documentData.docs.map(item => {
        documentArray.push({
          fileName: item.data().fileName,
          date: item.data().date,
          downloadUrl: item.data().downloadUrl,
          category: item.data().category,
        });
        return [];
      });
    }

    setDocuments(documentArray);
    setOriginalDocuments(documentArray);
    setUserDataLoaded(true);
  };

  const deleteCategory = (beforeArray: string[], value: string) => {
    const tempList = [...beforeArray];
    const index = tempList.indexOf(value);
    if (index > -1) {
      tempList.splice(index, 1);
      setFilterCategories(tempList);
      return tempList;
    }
    tempList.push(value);

    setFilterCategories(tempList);
    return tempList;
  };

  const onDropFile = async (file: any) => {
    if (documentType.length === 0) {
      toast.error('Bitte wählen Sie eine Kategorie für das Dokument aus!');
      return;
    }

    setDocumentType('');

    try {
      setUploadPending(true);
      const storageRef = firebase.storage().ref(`tenants/${tenant}/users/${userId}/documents`);

      const res = await storageRef.child(file[0].name).put(file[0]);

      const documentUrl = await res.ref.getDownloadURL();

      const newDocument = {
        fileName: file[0].name,
        date: moment().unix(),
        downloadUrl: documentUrl,
        category: documentType,
      };

      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('documents')
        .doc()
        .set(newDocument);

      // Dokument im State hinzufügen
      setDocuments((prevDocuments: any) => [newDocument, ...prevDocuments]);
      setOriginalDocuments((prevDocuments: any) => [newDocument, ...prevDocuments]);

      setUploadPending(false);
      setNewDocumentOverlayClass('hidden');
      toast.success('Das Dokument wurde erfolgreich hochgeladen!');
    } catch (e) {
      toast.error('Es ist etwas schief gegangen. Bitte versuchen Sie es erneut!');
      setUploadPending(false);
    }
  };

  const documentTypeChange = (val: any) => {
    setDocumentType(val.value);
  };

  const changeFilterCategories = (type: string[]) => {
    setFilterCategories(type);
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const startDeleteProcess = (fileName: string) => {
    setCurrentFileName(fileName);
    setDeleteDocumentOverlayClass('block');
  };

  const deleteDocument = async () => {
    setDeleteLoading(true);
    const storageRef = firebase.storage().ref(`tenants/${tenant}/users/${userId}/documents`);

    await storageRef
      .child(currentFileName)
      .delete()
      .then(async () => {
        try {
          const deleteObjectRef = await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('documents')
            .where('fileName', '==', currentFileName)
            .get();

          const documentId = deleteObjectRef.docs[0].id;

          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('documents')
            .doc(documentId)
            .delete();

          setDocuments((prevDocuments: any) => prevDocuments.filter((item: any) => item.fileName !== currentFileName));
          setOriginalDocuments((prevDocuments: any) =>
            prevDocuments.filter((item: any) => item.fileName !== currentFileName)
          );

          toast.success('Dokument erfolgreich gelöscht!');
          setDeleteLoading(false);
        } catch (error) {
          setDeleteLoading(false);
          toast.error('Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut!');
        }

        setDeleteDocumentOverlayClass('hidden');
      })
      .catch(() => {
        setDeleteLoading(false);
        toast.error('Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut!');
      });
  };

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .onSnapshot(
        snapshot => {
          const localUserData = snapshot.data() as UserInfo;

          setCurrentUserData(localUserData);

          if (
            localUserData &&
            localUserData.fullName !== undefined &&
            tenantData?.features?.coachingDashboard !== undefined &&
            tenantData?.features?.coachingDashboard === true &&
            localUserData.shareProfileType !== undefined &&
            localUserData.shareProfileType === 'share'
          ) {
            setUserDataValid(true);
          }

          getDocumentData();
        },
        error => {
          console.error('Error getting user data: ', error);
        }
      );
    return unsubscribe;
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  return (
    <>
      <Overlay
        overlayClass={deleteDocumentOverlayClass}
        setOverlayClass={setDeleteDocumentOverlayClass}
        headline="Dokument löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>
              Wollen Sie das Dokument <span className="font-bold">"{currentFileName}"</span> wirklich löschen?
            </div>
          </div>
          <div className="flex justify-between flex-wrap py-30 gap-20">
            <div className="flex-1">
              <Button
                className="w-full"
                isPending={deleteLoading}
                disabled={deleteLoading}
                onClick={() => deleteDocument()}
              >
                Löschen
              </Button>
            </div>
            <div className="flex-1">
              <Button onClick={() => setDeleteDocumentOverlayClass('hidden')} className="w-full" buttonStyle="white">
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={newDocumentOverlayClass}
        setOverlayClass={setNewDocumentOverlayClass}
        headline="Neues Dokument hinzufügen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>An dieser Stelle können Sie Dokumente von Ihrem Kunden hochladen.</div>
            <div className="pt-10">
              Bitte laden Sie das Dokument per Drag & Drop hoch oder klicken Sie auf die Fläche um es manuell von Ihrem
              Rechner auszuwählen.
            </div>
          </div>
          <div className="pt-30 pb-80">
            <CustomSelect
              name="country"
              dropDownOption={documentTypeOptions}
              label="Bitte wählen Sie eine Kategorie aus"
              isSearchable
              onChange={documentTypeChange}
              className="w-1/2 mb-20"
              labelClassName="text-sm text-textColor opacity-50 font-regular"
            />

            {uploadPending ? (
              <div>
                <div className="flex justify-center items-center w-full h-200 border-dashed border-textColor border">
                  <div>
                    <div className="text-center px-30 font-extralight pb-5">
                      Das Dokument wird hochgeladen, bitte schließen Sie dieses Fenster nicht...
                    </div>
                    <ReactLoading
                      type="bars"
                      width={20}
                      height={20}
                      color={theme?.textColor ?? defaultTextColor}
                      className="mx-auto"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Dropzone onDrop={acceptedFiles => onDropFile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="flex justify-center items-center h-200 border-dashed border-textColor border w-full px-30 text-center font-extralight cursor-pointer">
                        Bitte legen Sie hier das Dokument ab oder klicken Sie um es manuell auszuwählen
                      </p>
                      <div className="pt-20">
                        <Button className="w-full">Dokument hochladen</Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
        </div>
      </Overlay>

      <div className={styles.wrapper}>
        {userDataLoaded ? (
          <div>
            <Link
              to={{
                pathname: `/member/detail/${userId}`,
              }}
            >
              {' '}
              <ButtonBack
                text="Zurück"
                className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
              />
            </Link>
            {userDataValid ? (
              <div>
                <div className="flex justify-between space-x-20 w-full pt-20 xxl:w-3/4">
                  <div>
                    <div className="font-bold text-25 my-auto">Dokumentenakte von</div>
                    <div className="text-25 font-extralight">{currentUserData?.fullName}</div>
                  </div>
                  <div className="my-auto">
                    <Button onClick={() => setNewDocumentOverlayClass('block')}>Neues Dokument</Button>
                  </div>
                </div>
                {documentsAvailable ? (
                  <div className="pt-60 w-full xxl:w-3/4">
                    <div className="font-bold hidden text-20 my-auto">Liste der Dokumente</div>
                    <div className="flex justify-between flex-wrap gap-20">
                      <div className="flex-1">
                        <CustomInput
                          type="text"
                          placeholder="Dokument suchen"
                          name="search"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchDebounced(e.target.value)}
                          className="w-full"
                        />
                      </div>

                      <div className="my-auto">
                        <div
                          role="button"
                          aria-hidden
                          className="flex items-center space-x-3 pr-40"
                          onClick={() => setFilterOpen(true)}
                        >
                          <AdjustmentsIcon
                            width={28}
                            height={28}
                            aria-hidden="true"
                            className={classNames(styles.filterIcon, {})}
                          />
                          <Headline level={3}>Filter</Headline>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-10 pt-10">
                      {filterCategories?.map((item, index) => (
                        <div
                          key={index}
                          className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14"
                        >
                          <div>
                            <div className="text-buttonTextColor">{item}</div>
                            <div className={styles.editIcon}>
                              <XIcon
                                width={15}
                                height={15}
                                className="text-buttonTextColor"
                                onClick={() => deleteCategory(filterCategories, item)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                      {(startDate !== null || endDate !== null) && (
                        <div className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14">
                          <div>
                            <div className="text-buttonTextColor">
                              {moment(startDate).format('DD.MM.YYYY')}
                              {endDate !== null && startDate !== null && (
                                <span> - {moment(endDate).format('DD.MM.YYYY')}</span>
                              )}
                            </div>
                            <div className={styles.editIcon}>
                              <XIcon
                                width={15}
                                height={15}
                                className="text-buttonTextColor"
                                onClick={() => clearDates()}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {documents.length === 0 && (
                      <div className="font-bold text-20 pt-40">Es wurden leider keine Dokumente gefunden!</div>
                    )}
                    <div className="pt-20">
                      {documents.map((item: any, index: number) => (
                        <div key={index} className="hover:bg-lightGray">
                          {index === 0 && <hr />}
                          <div className="flex justify-between flex-wrap gap-0 py-20 px-20">
                            <div className="w-1/4 desktop:w-1/2">
                              <div className="font-bold truncate">{item.fileName}</div>
                              <div className="text-14 font-extralight">
                                {moment.unix(item.date).format('DD.MM.YYYY')}
                              </div>
                            </div>
                            <div className="my-auto">
                              {
                                documentTypeOptions.filter(
                                  (categoryItem: any) => categoryItem.value === item.category
                                )[0].label
                              }
                            </div>
                            <div className="flex justify-between flex-wrap gap-15">
                              <a
                                href={item.downloadUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="cursor-pointer my-auto"
                              >
                                <Button className="py-10">Download</Button>
                              </a>
                              <div className="my-auto">
                                <TrashIcon
                                  width={25}
                                  height={25}
                                  className="text-accentColor cursor-pointer"
                                  onClick={() => startDeleteProcess(item.fileName)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="font-extralight pt-40 text-25">
                    Zu diesem Nutzer wurden noch keine Dokumente gefunden!
                  </div>
                )}
              </div>
            ) : (
              <div className="font-bold text-25 pt-40">Leider konnten wir die Nutzerdaten nicht laden!</div>
            )}
          </div>
        ) : (
          <div className="h-full w-full md:flex block">
            <div className={styles.loading}>
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            </div>
          </div>
        )}
      </div>

      <FilterDrawerDocuments
        isFilterOpen={isFilterOpen}
        closeFilterOpen={setFilterOpen}
        filterCategoriesFunction={changeFilterCategories}
        filterCategoriesValues={filterCategories}
        startDateFunction={setStartDate}
        startDateValue={startDate}
        endDateFunction={setEndDate}
        endDateValue={endDate}
      />
    </>
  );
}
