import React, { Fragment } from 'react';
import Skeleton from 'components/skeleton';

import styles from '../ListComponent/styles.module.scss';

interface ListSkeletonProps {
  listHeaderItems: number;
  listRowItems: number;
  hasEmptyItem?: boolean;
}

export default function ListSkeleton({ listHeaderItems, listRowItems, hasEmptyItem }: ListSkeletonProps) {
  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeaderWrapper}>
        <div className={styles.listHeader}>
          {Array.from({ length: listHeaderItems }).map((_, index) => (
            <div key={index} className={styles.listItem}>
              <Skeleton className="w-1/2" translucent />
            </div>
          ))}
        </div>
        <div className={styles.interactionsHeader}>{hasEmptyItem && <div className={styles.listItem} />}</div>
      </div>

      {Array.from({ length: listRowItems }).map((_, index) => (
        <Fragment key={index}>
          <div className={styles.listRowWrapper}>
            <div className={styles.listRow}>
              <div className={styles.listItem}>
                <Skeleton className="w-1/2" translucent />
              </div>
              <div className={styles.listItem}>
                <Skeleton className="w-1/2" translucent />
              </div>
              <div className={styles.listItem}>
                <Skeleton className="w-1/2" translucent />
              </div>
              <div className={styles.listItem}>
                <Skeleton className="w-1/2" translucent />
              </div>
              <div className={styles.listItem}>
                <Skeleton className="w-1/2" translucent />
              </div>
            </div>
            <div className={styles.interactions}>{hasEmptyItem && <div className={styles.listItem} />}</div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
