import moment from 'moment';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import { companyRoleOptions } from 'shared/constants/global';

const remainTrialDay = (seconds: number | undefined) => {
  if (!seconds) return -1;
  const userCreated = moment.unix(seconds).utc();
  const diffDate = moment.utc().diff(userCreated, 'days');
  return 6 - diffDate;
};

const remainTrialConnectDay = (seconds: number | undefined, trialDays: number) => {
  if (!seconds) return -1;
  const userCreated = moment.unix(seconds).utc();
  const diffDate = moment.utc().diff(userCreated, 'days');
  return trialDays - diffDate;
};

const getButtonGroupList = (beforeArray: string[], value: string) => {
  const tempList = [...beforeArray];
  const index = tempList.indexOf(value);
  if (index > -1) {
    tempList.splice(index, 1);
    return tempList;
  }
  tempList.push(value);
  return tempList;
};

const scrollToTop = (scrollContainerRef: any) => {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, 1);
};

const scrollToElement = (scrollContainerRef: any) => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollIntoView();
  }
};

const generateRandomUid = () => {
  const partOne = Math.random().toString(36).substring(2);
  const partTwo = Math.random().toString(36).substring(2);

  return partOne + partTwo;
};

const handleOpenClosePopups = (closeStateObject: any, openStateObject: any, type: string) => {
  if (type === 'both') {
    closeStateObject('hidden');
    openStateObject('block');
  } else if (type === 'open') {
    openStateObject('block');
  } else if (type === 'close') {
    closeStateObject('hidden');
  }
};

const getIngridientMacros = (ingridientData: any, getIngridient: any, type: string) => {
  if (ingridientData.piece !== 'g' && ingridientData.piece !== 'ml') {
    if (type === 'kcal' || type === 'kcal-initial') {
      if (getIngridient !== undefined) {
        if (type === 'kcal') {
          return Math.round(
            ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.kcal_100g)
          );
        }
        return Math.round(
          ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
            parseFloat(getIngridient.kcal_100g)
        );
      }
      if (ingridientData.serving_data) {
        return ingridientData.kcal_total;
      }
      return 'N/A';
    }

    if (type === 'carbohydrates' || type === 'carbohydrates-initial') {
      if (getIngridient !== undefined) {
        if (type === 'carbohydrates') {
          return Math.round(
            ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.carbohydrates_100g)
          );
        }
        return Math.round(
          ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
            parseFloat(getIngridient.carbohydrates_100g)
        );
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.carbohydrates_total);
      }
      return 'N/A';
    }

    if (type === 'protein' || type === 'protein-initial') {
      if (getIngridient !== undefined) {
        if (type === 'protein') {
          return Math.round(
            ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.protein_100g)
          );
        }
        return Math.round(
          ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
            parseFloat(getIngridient.protein_100g)
        );
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.protein_total);
      }
      return 'N/A';
    }

    if (type === 'fat' || type === 'fat-initial') {
      if (getIngridient !== undefined) {
        if (type === 'fat') {
          return Math.round(
            ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.fat_100g)
          );
        }
        return Math.round(
          ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
            parseFloat(getIngridient.fat_100g)
        );
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.fat_total);
      }
      return 'N/A';
    }
  }

  if (type === 'kcal' || type === 'kcal-initial') {
    if (getIngridient !== undefined) {
      if (type === 'kcal') {
        return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.kcal_100g));
      }
      return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.kcal_100g));
    }
    if (ingridientData.serving_data) {
      return ingridientData.kcal_total;
    }
    return 'N/A';
  }

  if (type === 'carbohydrates' || type === 'carbohydrates-initial') {
    if (getIngridient !== undefined) {
      if (type === 'carbohydrates') {
        return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.carbohydrates_100g));
      }
      return Math.round(
        (parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.carbohydrates_100g)
      );
    }
    if (ingridientData.serving_data) {
      return Math.round(ingridientData.carbohydrates_total);
    }
    return 'N/A';
  }

  if (type === 'protein' || type === 'protein-initial') {
    if (getIngridient !== undefined) {
      if (type === 'protein') {
        return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.protein_100g));
      }
      return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.protein_100g));
    }
    if (ingridientData.serving_data) {
      return Math.round(ingridientData.protein_total);
    }
    return 'N/A';
  }

  if (type === 'fat' || type === 'fat-initial') {
    if (getIngridient !== undefined) {
      if (type === 'fat') {
        return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.fat_100g));
      }
      return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.fat_100g));
    }
    if (ingridientData.serving_data) {
      return Math.round(ingridientData.fat_total);
    }
    return 'N/A';
  }

  return '0';
};

const getRoundedValue = (value: string | number) => {
  if (typeof value === 'string') {
    return Math.round(parseFloat(value.replace(',', '.')));
  }
  return Math.round(value);
};

// Plan Save Function
const savePlanChangesToFirebase = async (
  userData: any,
  updateObject: any,
  documentId: any,
  userId: any,
  isDashboard?: boolean,
  updateFunction?: Function,
  tenant?: string
) => {
  if (userData?.role === 1 && updateObject.examplePlan) {
    try {
      await firebase
        .firestore()
        .collection('plans')
        .doc(documentId)
        .update(updateObject as object);

      if (updateFunction !== undefined) {
        updateFunction(updateObject);
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  } else {
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('plans')
        .doc(documentId)
        .update(updateObject as object);

      if (updateFunction !== undefined) {
        updateFunction(updateObject);
      }
    } catch (error: any) {
      if (isDashboard) {
        const thisNewDocumentId = generateRandomUid();

        const formatDate = moment.unix(updateObject.startDate.seconds).subtract(-1, 'day').format('YYYYMMDD');
        const formatUnixDate = moment(formatDate, 'YYYYMMDD').utc().unix();

        const newColumns = {
          ...updateObject,
          uid: thisNewDocumentId,
          origId: thisNewDocumentId,
          examplePlan: false,
          planType: 'manual',
          name: 'Manueller Plan',
          manualPlanDateString: moment(formatDate, 'YYYYMMDD').utc().format('YYYYMMDD'),
          description: '',
          startDate: {
            seconds: formatUnixDate,
          },
          endDate: {
            seconds: formatUnixDate,
          },
          dayEntries: updateObject.dayEntries.map((entries: any) => {
            return {
              ...entries,
              date: {
                seconds: formatUnixDate,
              },
            };
          }),
          imageUrl: '',
          created: moment().unix(),
        };

        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('plans')
            .doc(thisNewDocumentId)
            .set(newColumns as object);

          if (updateFunction !== undefined) {
            updateFunction(newColumns);
          }
        } catch (error_new_doc: any) {
          toast.error(`Es ist leider etwas schief gegangen!${error_new_doc}`);
        }
      } else {
        console.log(error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error(`Es ist leider etwas schief gegangen!${error}`);
      }
    }
  }
};

// Save last day rating
const saveLastDayRating = async (tenant: string, userId: any, date: string, ratingType: number) => {
  try {
    const checkIfExists = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('ratings')
      .where('date', '==', date)
      .get();

    if (checkIfExists.size > 0) {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('ratings')
        .doc(checkIfExists.docs[0].id)
        .update({
          date,
          rating: ratingType,
        });
    } else {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).collection('ratings').doc().set({
        date,
        rating: ratingType,
      });
    }
  } catch (error: any) {
    console.log(error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Add stripe connect fee
const changeStripeConnectFee = async (tenant: string, monthlyFee: any, tenantData: any) => {
  if (Number.isNaN(monthlyFee) || monthlyFee === 0) {
    toast.error('Ihre eingegebenen Daten enthalten keine Nummer. Bitte prüfen Sie Ihre Angaben!');
    return { status: 'failed' };
  }

  if (!tenantData.stripeConnect) {
    return { status: 'failed' };
  }

  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectMonthlyFee: parseFloat(monthlyFee),
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    return { status: 'success' };
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    return { status: 'failed' };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect email
const changeStripeConnectEmail = async (tenant: string, emailAdress: string, tenantData: any) => {
  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (emailRegexp.test(emailAdress) === false) {
    toast.error('Ihre eingegebenen Daten enthalten keine valide E-Mail Adresse. Bitte prüfen Sie Ihre Angaben!');
    return;
  }

  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectRegisterEmail: emailAdress,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('E-Mail erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect mail from
const changeStripeConnectMailFrom = async (tenant: string, emailAdress: string, tenantData: any) => {
  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (emailRegexp.test(emailAdress) === false) {
    toast.error('Ihre eingegebenen Daten enthalten keine valide E-Mail Adresse. Bitte prüfen Sie Ihre Angaben!');
    return;
  }

  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectMailFrom: emailAdress,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('E-Mail Absender erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect mail name
const changeStripeConnectMailName = async (tenant: string, mailName: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectMailName: mailName,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Absender Name erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect mail subject
const changeStripeConnectMailSubject = async (tenant: string, mailSubject: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectMailSubject: mailSubject,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Betreff erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect mail text
const changeStripeConnectMailText = async (tenant: string, mailText: any, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectMailText: mailText,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Mail Text erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect premium text
const changeStripeConnectPremiumText = async (tenant: string, premiumText: any, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectTrialPremiumText: premiumText,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Text erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect premium text
const changeStripeConnectFeatureText = async (tenant: string, featureText: any, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectTrialFeatureText: featureText,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Text erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect headline
const changeStripeConnectHeadline = async (tenant: string, headline: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectRegisterHeadline: headline,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Überschrift erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect trial
const changeStripeConnectTrial = async (tenant: string, trialValue: number, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectTrialDays: trialValue,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Kostenloser Zeitraum erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect trial type
const changeStripeConnectTrialType = async (tenant: string, trialTypeValue: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectTrialType: trialTypeValue,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Zahlungsmethode - Variante erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect trial
const deleteStripeConnectTrial = async (tenant: string) => {
  try {
    await firebase.firestore().collection('tenants').doc(tenant).update({
      'stripeConnect.stripeConnectTrialDays': firebase.firestore.FieldValue.delete(),
      'stripeConnect.stripeConnectTrialType': firebase.firestore.FieldValue.delete(),
    });

    toast.success('Kostenloser Zeitraum erfolgreich deaktiviert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect small company type
const changeStripeConnectSmallCompany = async (
  tenant: string,
  isSmallCompany: boolean,
  tenantData: any,
  setNewTenantDataFunction: any
) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectIsSmallCompany: isSmallCompany,
  };

  const newTenantObject = {
    ...tenantData,
    stripeConnect: {
      ...tenantData.stripeConnect,
      stripeConnectIsSmallCompany: isSmallCompany,
    },
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    if (setNewTenantDataFunction !== undefined) {
      setNewTenantDataFunction(newTenantObject);
    }

    toast.success('Einstellungen erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect tax id
const changeStripeConnectTaxId = async (tenant: string, taxId: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectTaxId: taxId,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });
  } catch (error: any) {
    console.log(error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect portal id
const changeStripeConnectPortalId = async (tenant: string, portalId: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectCustomerPortalId: portalId,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });
  } catch (error: any) {
    console.log(error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect text
const changeStripeConnectText = async (tenant: string, text: string, tenantData: any) => {
  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectRegisterText: text,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: { ...newObject },
      });

    toast.success('Text erfolgreich gespeichert!');
  } catch (error: any) {
    toast.error(
      'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Change stripe connect email
const changeStripeConnectPriceAndProductId = async (
  tenant: string,
  priceId: string,
  productId: string,
  tenantData: any
) => {
  if (!tenantData.stripeConnect) {
    return { status: 'failed' };
  }

  const newObject = {
    ...tenantData.stripeConnect,
    stripeConnectPriceId: priceId,
    stripeConnectProductId: productId,
  };

  try {
    await firebase
      .firestore()
      .collection('tenants')
      .doc(tenant)
      .update({
        stripeConnect: {
          ...newObject,
        },
      });

    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failed' };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Add to Quicklist Function
const addIngredientToQuickList = async (updateObject: any, userId: any, tenant: string) => {
  try {
    await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('quickList')
      .doc()
      .set(updateObject as object);
  } catch (error: any) {
    console.log(error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }
};

// Add to Favorites Ingredients Function
const addFavoriteIngredients = async (updateObject: any, userId: any, tenant: string, action: string) => {
  if (action === 'add') {
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_ingredients')
        .doc()
        .set(updateObject as object);

      toast.success('Das Lebensmittel wurde zu deinen Favoriten hinzugefügt!');
    } catch (error: any) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es später erneut.');
      console.log(error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  } else if (updateObject.food_id !== undefined) {
    try {
      const deleteObjectRef = await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_ingredients')
        .where('food_id', '==', updateObject.food_id)
        .get();

      const documentId = deleteObjectRef.docs[0].id;

      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_ingredients')
        .doc(documentId)
        .delete();

      toast.success('Das Lebensmittel wurde von deinen Favoriten entfernt!');
    } catch (error: any) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es später erneut.');
      console.log(error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  } else {
    try {
      const deleteObjectRef = await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_ingredients')
        .where('name', '==', updateObject.name)
        .get();

      const documentId = deleteObjectRef.docs[0].id;

      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_ingredients')
        .doc(documentId)
        .delete();

      toast.success('Das Lebensmittel wurde von deinen Favoriten entfernt!');
    } catch (error: any) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es später erneut.');
      console.log(error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  }
  console.log('durch');
};

const isPurchaseListCollectionExists = async (userId: any, tenant: string) => {
  const getDocId = await firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(userId)
    .collection('purchaseList')
    .get();

  if (getDocId.size === 0) {
    return false;
  }
  return true;
};

// Shared Push Function
const shareToFirebase = async (
  shareType: string,
  updateObject: any,
  shareMail: string,
  overlayClass: any,
  userData: any,
  tenant: string,
  userId: any
) => {
  if (shareType === 'plan') {
    const thisPlanUid = generateRandomUid();
    const thisMessageUid = generateRandomUid();

    const newColumnsPlan = {
      ...updateObject,
      uid: thisPlanUid,
      origId: thisPlanUid,
      sharedPlan: true,
    };

    const newColumnsMessage = {
      userMail: shareMail,
      userSenderName: userData?.fullName,
      messageRead: false,
      sendDate: moment().unix(),
      messageType: 'plan',
      messageItemUid: thisPlanUid,
      messageItemName: updateObject?.name,
      messageUid: thisMessageUid,
    };

    try {
      await firebase
        .firestore()
        .collection('sharedPlans')
        .doc(thisPlanUid)
        .set(newColumnsPlan as object);
      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(thisMessageUid)
          .set(newColumnsMessage as object);
        toast.success('Dein Plan wurde erfolgreich geteilt!');
        overlayClass('hidden');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }

      try {
        const checkIfUserMailExists = await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(userId)
          .collection('messages-emails')
          .where('mail', '==', shareMail)
          .get();

        if (checkIfUserMailExists.size === 0) {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc()
            .set({ mail: shareMail, dateSeconds: moment().unix() });
        } else {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc(checkIfUserMailExists.docs[0].id)
            .update({ mail: shareMail, dateSeconds: moment().unix() });
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.log('error');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  } else if (shareType === 'purchaseList') {
    const thisMessageUid = generateRandomUid();
    const thisPurchaseListUid = generateRandomUid();

    const newColumnsPurchaseList = {
      ...updateObject,
      uid: thisPurchaseListUid,
      origId: thisPurchaseListUid,
    };

    const newColumnsMessage = {
      userMail: shareMail,
      userSenderName: userData?.fullName,
      messageRead: false,
      sendDate: moment().unix(),
      messageType: 'purchaseList',
      messageItemUid: thisPurchaseListUid,
      messageItemName: '',
      messageUid: thisMessageUid,
    };

    try {
      await firebase
        .firestore()
        .collection('sharedPurchaselist')
        .doc(thisPurchaseListUid)
        .set(newColumnsPurchaseList as object);
      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(thisMessageUid)
          .set(newColumnsMessage as object);
        toast.success('Deine Einkaufsliste wurde erfolgreich geteilt!');
        overlayClass('hidden');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error(`Es ist leider etwas schief gegangen!${error}`);
      }

      try {
        const checkIfUserMailExists = await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(userId)
          .collection('messages-emails')
          .where('mail', '==', shareMail)
          .get();

        if (checkIfUserMailExists.size === 0) {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc()
            .set({ mail: shareMail, dateSeconds: moment().unix() });
        } else {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc(checkIfUserMailExists.docs[0].id)
            .update({ mail: shareMail, dateSeconds: moment().unix() });
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.log('error');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen2!');
    }
  } else if (shareType === 'recipe') {
    const thisRecipeUid = generateRandomUid();
    const thisMessageUid = generateRandomUid();

    const newColumnsRecipe = {
      ...updateObject,
      amount: 1,
      piece: 'Portion',
      uid: thisRecipeUid,
      origId: thisRecipeUid,
      sharedRecipe: true,
    };

    const newColumnsMessage = {
      userMail: shareMail,
      userSenderName: userData?.fullName,
      messageRead: false,
      sendDate: moment().unix(),
      messageType: 'recipe',
      messageItemUid: thisRecipeUid,
      messageItemName: updateObject?.name,
      messageUid: thisMessageUid,
    };

    try {
      await firebase
        .firestore()
        .collection('sharedRecipes')
        .doc(thisRecipeUid)
        .set(newColumnsRecipe as object);
      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(thisMessageUid)
          .set(newColumnsMessage as object);
        toast.success('Dein Rezept wurde erfolgreich geteilt!');
        overlayClass('hidden');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }

      try {
        const checkIfUserMailExists = await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(userId)
          .collection('messages-emails')
          .where('mail', '==', shareMail)
          .get();

        if (checkIfUserMailExists.size === 0) {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc()
            .set({ mail: shareMail, dateSeconds: moment().unix() });
        } else {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(userId)
            .collection('messages-emails')
            .doc(checkIfUserMailExists.docs[0].id)
            .update({ mail: shareMail, dateSeconds: moment().unix() });
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.log('error');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  }
};

// Plan Purchase List Save Function
const savePurchaseListToFirebase = async (userData: any, updateObject: any, userId: any, tenant: string) => {
  try {
    const getDocId = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('purchaseList')
      .get();

    if (getDocId.size === 0) {
      const thisNewDocumentId = generateRandomUid();

      const newObject = {
        ...updateObject,
        uid: thisNewDocumentId,
      };

      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(userId)
          .collection('purchaseList')
          .doc(thisNewDocumentId)
          .set(newObject);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      let getDocumentId = '';
      const getDocuments = getDocId.docs.map(doc => {
        getDocumentId = doc.data().uid;
        return 0;
      });

      const newObject = {
        ...updateObject,
        uid: getDocumentId,
      };

      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(userId)
          .collection('purchaseList')
          .doc(getDocumentId)
          .set(newObject);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
        console.log(error);
      }
    }
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
    console.log(error);
  }
};

// Tourr Save Function
const updateTourToFirebase = async (userData: any, tourType: string, userId: string, tenant: string) => {
  const newObject = {
    ...userData,
    tour: {
      ...userData.tour,
      [tourType]: true,
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Activites Setting Function
const updateActivitiesSettings = async (userData: any, workoutSetting: boolean, userId: string, tenant: string) => {
  const newObject = {
    ...userData,
    health: {
      useWorkouts: workoutSetting,
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
    return { status: 'success' };
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return { status: 'failed' };
  }
};

// DeleteActivites Setting Function
const deleteActivitiesSettings = async (userData: any, userId: string, tenant: string) => {
  const newObject = {
    ...userData,
    health: firebase.firestore.FieldValue.delete(),
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
    return { status: 'success' };
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return { status: 'failed' };
  }
};

// Change User View Function
const changeUserView = async (userData: any, userId: any, tenant: string, thisRole: number) => {
  if (userData.isAdminUser !== undefined) {
    const newObject = {
      ...userData,
      role: thisRole,
    };

    try {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
      return { status: 'success' };
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { status: 'failed' };
    }
  } else if (userData?.role === 4 || userData.isTrainerUser) {
    const newObject = {
      ...userData,
      role: thisRole,
      isTrainerUser: true,
    };

    try {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
      return { status: 'success' };
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { status: 'failed' };
    }
  } else if (userData?.role === 6 || userData.isLocationAdminUser) {
    const newObject = {
      ...userData,
      role: thisRole,
      isLocationAdminUser: true,
    };

    try {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
      return { status: 'success' };
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { status: 'failed' };
    }
  } else {
    return { status: 'failed' };
  }
};

// Check coupon code is valid
const checkCouponValidFirebase = async (code: string) => {
  let returnCode = 0;
  const docData = await firebase.firestore().collection('coupons').where('coupon', '==', code).get();

  if (docData.empty) {
    return 0;
  }

  const getDocumentRabatt = docData.docs.map(doc => {
    if (doc.data().used === false) {
      returnCode = doc.data().rabatt;
      return 0;
    }

    return 0;
  });

  return returnCode;
};

// Check coupon code exists
const checkCouponFirebase = async (rabatt: number, submitFunction: Function) => {
  const couponCode = Math.floor(100000 + Math.random() * 900000).toString();
  const docData = await firebase.firestore().collection('coupons').where('coupon', '==', couponCode).get();

  if (docData.empty) {
    addCouponToFirebase(rabatt, couponCode, submitFunction);
  } else {
    checkCouponFirebase(rabatt, submitFunction);
  }
};

// Add Coupon to Firebase
const addCouponToFirebase = async (rabatt: number, thisCouponCode: string, submitFunction: Function) => {
  const newEntry = {
    created: moment().unix(),
    used: false,
    rabatt,
    coupon: thisCouponCode,
  };

  try {
    await firebase.firestore().collection('coupons').doc(thisCouponCode).set(newEntry);
    submitFunction();
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

const addUserRecipe = async (recipeObject: any, userId: any, recipeUid: any, favoriteObject: any, tenant: string) => {
  try {
    await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('recipes')
      .doc(recipeUid)
      .set(recipeObject as object);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('favorites_recipes')
        .doc()
        .set(favoriteObject as object);
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
    toast.success('Dein Rezept wurde erfolgreich erstellt!');
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Analyze Add Function
const addAnalyzeToFirebase = async (
  userData: any,
  userId: any,
  custom: boolean,
  label: string,
  identifier: string,
  piece: string,
  newName: string,
  newValue: number,
  newDate: string,
  addValue: boolean,
  tenant: string
) => {
  let newEntry = {};

  if (addValue === true) {
    newEntry = {
      isCustom: custom,
      label,
      name: identifier,
      piece,
      itemValues: [
        {
          name: newName,
          value: newValue,
          date: newDate,
        },
      ],
    };
  } else {
    newEntry = {
      isCustom: custom,
      label,
      name: identifier,
      piece,
      itemValues: [],
    };
  }

  const newObject = {
    ...userData,
    analyze: {
      ...userData.analyze,
      items: userData.analyze?.items !== undefined ? [...userData.analyze?.items, newEntry] : [newEntry],
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

const getNextSubscriptionDate = (period: string, paymentDate: string) => {
  let getPeriod = 'year' as 'year';
  if (period === 'month') {
    getPeriod = 'month' as 'year';
  }

  const getDate = moment(moment().format('YYYY-MM-DD'));

  let getDuration = 0;
  let getCounter = 0;

  if (period === 'year') {
    getDuration = moment.duration(getDate.diff(paymentDate)).years();
  } else {
    getDuration = moment.duration(getDate.diff(paymentDate)).months();
  }

  if (getDuration >= 0) {
    getCounter = getDuration + 1;
  } else {
    getCounter = 1;
  }

  const getRenewalNext = moment(paymentDate).add(getCounter, `${getPeriod}s`).unix();

  return getRenewalNext;
};

// Analyze Update Function
const updateAnalyzeToFirebase = async (
  userData: any,
  identifier: any,
  userId: any,
  newName: string,
  newValue: number,
  newDate: string,
  tenant: string
) => {
  const newEntry = {
    name: newName,
    value: newValue,
    date: newDate,
  };

  const newObject = {
    ...userData,
    analyze: {
      ...userData.analyze,
      items: userData.analyze?.items?.map((entries: any) => {
        if (entries.name !== identifier) return entries;
        return {
          ...entries,
          itemValues: [...entries.itemValues, newEntry],
        };
      }),
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
    toast.success('Dein neuer Wert wurde gespeichert!');
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Analyze Update Function
const updateAnalyzeItemToFirebase = async (
  userData: any,
  identifier: any,
  userId: any,
  updateObject: any,
  tenant: string
) => {
  const newObject = {
    ...userData,
    analyze: {
      ...userData.analyze,
      items: userData.analyze?.items?.map((entries: any) => {
        if (entries.name !== identifier) return entries;
        return {
          ...entries,
          itemValues: updateObject,
        };
      }),
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
    toast.success('Dein neuer Wert wurde gespeichert!');
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Delete Analyze Function
const deleteAnalyzeItemToFirebase = async (userData: any, identifier: any, userId: any, tenant: string) => {
  const newObject = {
    ...userData,
    analyze: {
      ...userData.analyze,
      items: userData.analyze?.items?.filter((item: any) => item.name !== identifier),
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
    toast.success('Dein Wert wurde gelöscht!');
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Analyze Delete Function
const deleteAnalyzeToFirebase = async (userData: any, userId: any, identifier: string, tenant: string) => {
  const newObject = {
    ...userData,
    analyze: {
      ...userData.analyze,
      items: [...userData.analyze.items.filter((item: any) => item.name !== identifier)],
    },
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Update Incompatibility Notice
const updateIncompabilityNotice = async (userData: any, userId: any, tenant: string) => {
  const newObject = {
    ...userData,
    incompatibilityAccept: true,
    incompatibilityAcceptDate: moment().unix(),
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

// Cookie Banner Save Function
const updateCookieBannerType = async (
  userData: any,
  cookieType: string,
  trackGoogle: boolean,
  userId: any,
  tenant: string
) => {
  const newObject = {
    ...userData,
    trackGoogleAnalytics: trackGoogle,
    cookieBannerType: cookieType,
  };

  try {
    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newObject);
  } catch (error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    toast.error('Es ist leider etwas schief gegangen!');
  }
};

const checkInternetConnection = (executeFunction: any) => {
  if (navigator.onLine === false) {
    toast.error(
      'Deine Internetverbindung ist abgebrochen. Bitte stelle die Verbindung wieder her und probiere es erneut!'
    );
    return false;
  }

  executeFunction();
  return false;
};

const getRole = (role: number) => {
  return companyRoleOptions?.find((item: any) => item.value === role)?.label;
};

const getRoleByLabel = (role: string) => {
  return companyRoleOptions?.find((item: any) => item.label === role)?.value;
};

// eslint-disable-next-line consistent-return
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return navigator.userAgent;
};

// eslint-disable-next-line consistent-return
const getIsWebView = () => {
  if (typeof window === undefined) {
    return false;
  }

  const { navigator } = window as any;

  const { standalone } = navigator;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ? !standalone && !safari : userAgent.includes('wv');
};

export {
  remainTrialDay,
  remainTrialConnectDay,
  getButtonGroupList,
  scrollToTop,
  scrollToElement,
  getIngridientMacros,
  handleOpenClosePopups,
  generateRandomUid,
  getRoundedValue,
  savePlanChangesToFirebase,
  checkInternetConnection,
  savePurchaseListToFirebase,
  isPurchaseListCollectionExists,
  updateTourToFirebase,
  shareToFirebase,
  checkCouponValidFirebase,
  checkCouponFirebase,
  updateCookieBannerType,
  updateAnalyzeToFirebase,
  updateAnalyzeItemToFirebase,
  addAnalyzeToFirebase,
  deleteAnalyzeToFirebase,
  getNextSubscriptionDate,
  updateIncompabilityNotice,
  addIngredientToQuickList,
  addUserRecipe,
  getRole,
  getRoleByLabel,
  getMobileOperatingSystem,
  getIsWebView,
  changeStripeConnectFee,
  changeStripeConnectEmail,
  changeStripeConnectPriceAndProductId,
  changeUserView,
  changeStripeConnectSmallCompany,
  changeStripeConnectTrial,
  deleteStripeConnectTrial,
  changeStripeConnectTrialType,
  changeStripeConnectHeadline,
  changeStripeConnectText,
  changeStripeConnectTaxId,
  changeStripeConnectPortalId,
  addFavoriteIngredients,
  deleteAnalyzeItemToFirebase,
  updateActivitiesSettings,
  deleteActivitiesSettings,
  changeStripeConnectMailFrom,
  changeStripeConnectMailSubject,
  changeStripeConnectMailText,
  changeStripeConnectMailName,
  changeStripeConnectPremiumText,
  changeStripeConnectFeatureText,
  saveLastDayRating,
};
