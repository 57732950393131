import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Input/custom-input';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import { emailChecker } from 'services/validations';
import Button from 'components/Button';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const ChangeEmail: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const [emailCurrentChange, setEmailCurrentChange] = useState('');
  const [passwordCurrentChange, setPasswordCurrentChange] = useState('');

  const emailChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailCurrentChange(event.target.value);
  };

  const passwordCurrentChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCurrentChange(event.target.value);
  };

  const onSubmit = async (data: any) => {
    setIsUpdating(true);

    // Check if email is valid
    const checkMail = emailChecker(emailCurrentChange);

    if (checkMail === false) {
      toast.error('Bitte geben Sie eine gültige E-Mail Adresse ein!');
      setIsUpdating(false);
      return;
    }

    // Check if email is filled out
    if (emailCurrentChange.length === 0) {
      toast.error('Bitte geben Sie eine neue E-Mail Adresse ein!');
      setIsUpdating(false);
      return;
    }

    // check if user is already member
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(emailCurrentChange);
    if (signInMethods.length > 0) {
      toast.error('Diese E-Mail Adresse existiert bereits. Bitte verwenden Sie eine andere!');
      setIsUpdating(false);
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user !== null && userData !== undefined) {
        const credential = firebase.auth.EmailAuthProvider.credential(userData.email, passwordCurrentChange);

        await user.reauthenticateWithCredential(credential).then(() => {
          user
            .updateEmail(emailCurrentChange)
            .then(async () => {
              setIsUpdating(false);

              const profile: UserInfo = authContext.userData as UserInfo;

              await firebase
                .firestore()
                .collection(`tenants/${tenant}/users`)
                .doc(authContext.user?.uid)
                .set({
                  ...profile,
                  email: emailCurrentChange,
                });

              authContext.setUserData((prevProfile: UserInfo) => ({
                ...prevProfile,
                email: emailCurrentChange,
              }));

              authContext.setOriginalUserData((prevProfile: UserInfo) => ({
                ...prevProfile,
                email: emailCurrentChange,
              }));
              toast.success('Die E-Mail Adresse wurde erfolgreich geändert!');
            })
            .catch(error => {
              toast.error(`Es ist leider ein Fehler aufgetreten!`);
              setIsUpdating(false);
            });
        });
      } else {
        setIsUpdating(false);
        toast.error('Es ist leider ein Fehler aufgetreten!');
        return;
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    setEmailCurrentChange('');
    setPasswordCurrentChange('');
    setIsUpdating(false);
  }, [isShown]);

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <div className={styles.buttons}>
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
          <Button
            isPending={isUpdating}
            disabled={isUpdating}
            buttonStyle={isUpdating ? 'dark' : 'primary'}
            onClick={onSubmit}
            className="w-200"
          >
            {t('Change')}
          </Button>
        </div>
      </div>
      <div className="font-extralight pb-20">
        Wenn Sie Ihre aktuelle E-Mail Adresse ändern möchten, geben Sie bitte in den unteren Feldern ihr aktuelles
        Passwort und eine neue E-Mail Adresse ein!
      </div>
      <div className="font-extralight">Ihre aktuelle E-Mail Adresse:</div>
      <div className="font-bold pb-20">{userData?.email}</div>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            name="currentPassword"
            type="password"
            label="Aktuelles Passwort"
            placeholder="Aktuelles Passwort"
            className="pb-20"
            onChange={passwordCurrentChangeFunction}
          />
          <CustomInput
            name="newEmail"
            type="text"
            label="Neue E-Mail Adresse"
            placeholder="Neue E-Mail Adresse"
            className="pb-20"
            onChange={emailChangeFunction}
          />
        </form>
      </div>
    </TransitionContainer>
  );
};

export default ChangeEmail;
