import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import SwitchButton from 'components/SwitchButton';
import Headline from 'components/Headline';
import { eatingHabitsOptionProfile, stepLists } from 'shared/constants/profile-wizard';

type Props = {
  isProfile?: boolean;
};

export default function EatingHabits({ isProfile = false }: Props) {
  const authContext = useContext(AuthContext);
  const { tenantData } = useContext(AuthContext);
  const { t } = useTranslation();
  const elRef = useRef<HTMLDivElement>(null);

  const setEatingHabits = (status: boolean, item: string): void => {
    const prevEatingHabits = authContext.userData?.eatingHabits ?? [];
    if (status) {
      prevEatingHabits.push(item);
    } else {
      prevEatingHabits.splice(
        prevEatingHabits.findIndex(element => element === item),
        1
      );
    }
    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      eatingHabits: prevEatingHabits,
    }));

    authContext.setOriginalUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      eatingHabits: prevEatingHabits,
    }));
  };

  useEffect(() => {
    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      eatingHabits: prevProfile?.eatingHabits ?? [],
    }));

    authContext.setOriginalUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      eatingHabits: prevProfile?.eatingHabits ?? [],
    }));
    if (!isProfile) {
      elRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        authContext.userData?.role === 2 ||
        authContext.userData?.isAdminUser ||
        authContext.userData?.isTrainerUser ||
        authContext.userData?.isLocationAdminUser ||
        isProfile === false ||
        localStorage.getItem('currentUserToken') ||
        tenantData?.settings?.functions?.profileEatingHabits === undefined ||
        tenantData?.settings?.functions?.profileEatingHabits === true
          ? 'space-y-20'
          : 'space-y-20 opacity-25'
      }
    >
      <div ref={elRef}>
        <Headline className="capitalize" centered level={1}>
          {t(stepLists[3].title)}
        </Headline>
      </div>
      <div className="font-extralight">
        Bitte wähle hier alle Merkmale zu deinem Essverhalten aus, die zu deiner Ernährung passen.
      </div>
      {eatingHabitsOptionProfile.map((item: string, index: number) => (
        <SwitchButton
          key={index}
          label={item}
          isBackground={index % 2 === 0}
          enabled={authContext.userData?.eatingHabits !== undefined && authContext.userData.eatingHabits.includes(item)}
          onChange={setEatingHabits}
          isDisabled={
            !(
              authContext.userData?.role === 2 ||
              authContext.userData?.isAdminUser ||
              authContext.userData?.isTrainerUser ||
              authContext.userData?.isLocationAdminUser ||
              isProfile === false ||
              localStorage.getItem('currentUserToken') ||
              tenantData?.settings?.functions?.profileEatingHabits === undefined ||
              tenantData?.settings?.functions?.profileEatingHabits === true
            )
          }
        />
      ))}
    </div>
  );
}
