import React, { useContext, useEffect, useState } from 'react';
import Headline from 'components/Headline';
import TransitionContainer from 'components/TransitionContainer';
import firebase from 'services/firebase';
import { Survey } from 'shared/types/survey';
import { AuthContext } from 'providers/AuthProvider';
import { getUserSurveys, getUserSurveysWithIterations } from 'shared/functions/surveyFunctions';
import ListSkeleton from '../components/ListSkeleton';
import ListComponent from './components/ListComponent';

import styles from '../styles.module.scss';

const UserSurveys: React.FC = () => {
  const { tenant, user } = useContext(AuthContext);
  const [userSurveys, setUserSurveys] = useState<Survey[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  // -------------------------------- get user surveys --------------------------------
  const fetchUserSurveys = async () => {
    if (!user?.uid) return;

    setIsFetching(true);

    setIsFetching(true);

    const userRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(user.uid);

    const surveys = await getUserSurveys(userRef, tenant);

    setUserSurveys(surveys);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchUserSurveys();
  }, [user?.uid]);
  // ---------------------------------------------------------------------------

  const surveysWithIterations = getUserSurveysWithIterations(userSurveys, user?.uid);

  return (
    <TransitionContainer isShown>
      <div className={styles.surveysOverviewContainer}>
        <Headline level={1}>Umfragen</Headline>

        {isFetching ? (
          <ListSkeleton listHeaderItems={5} listRowItems={5} hasEmptyItem />
        ) : (
          <ListComponent
            surveys={surveysWithIterations
              .sort((a: Survey, b: Survey) => b.planDate.toMillis() - a.planDate.toMillis())
              .sort((a: Survey, b: Survey) => {
                return a.isViewOnly ? 1 : b.isViewOnly ? -1 : 0;
              })}
          />
        )}
      </div>
    </TransitionContainer>
  );
};

export default UserSurveys;
