import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Surveys from 'pages/Surveys';
import SurveyDetail from 'pages/Surveys/details';

import SurveyCustomerDetail from 'pages/Surveys/userSurveys/userDetails';

const SurveyRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/surveys" component={Surveys} />
    <Route exact path="/surveys/:id" component={SurveyDetail} />
    <Route exact path="/surveys/:id/:iteration" component={SurveyCustomerDetail} />
    <Redirect to="/surveys" />
  </Switch>
);

export default SurveyRoutes;
