import React, { Fragment } from 'react';
import { Survey } from 'shared/types/survey';
import Icon from 'components/Icon';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

interface ListComponentProps {
  surveys: Survey[];
}

const ListComponent: React.FC<ListComponentProps> = ({ surveys }) => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeaderWrapper}>
        <div className={styles.listHeader}>
          <p className={styles.listItem}>Umfrage</p>
          <p className={classNames(styles.listItem, styles.date)}>Erhalten am</p>
        </div>
      </div>

      {surveys.length > 0 ? (
        surveys.map(survey => {
          return (
            <Fragment key={survey.id}>
              <Link to={`/surveys/${survey.id}/${survey.iteration ?? 0}`} className={styles.listRowWrapper}>
                <div className={classNames(styles.listRow, { [styles.isUnanswered]: !survey.isViewOnly })}>
                  <p className={classNames(styles.listItem, { [styles.isUnanswered]: !survey.isViewOnly })}>
                    {survey.surveyTitle}
                  </p>
                  <p className={classNames(styles.listItem, styles.date)}>
                    {survey.planDate.toDate().toLocaleDateString()}
                  </p>
                </div>

                <div className={styles.interactions}>
                  {survey.isViewOnly ? (
                    <Icon name="eye" className={styles.icon} />
                  ) : (
                    <Icon name="arrowDoubleUpLeft" className={styles.icon} />
                  )}
                </div>
              </Link>
            </Fragment>
          );
        })
      ) : (
        <div className={styles.emptyList}>
          <p>Keine Umfragen gefunden.</p>
        </div>
      )}
    </div>
  );
};

export default ListComponent;
