import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toast';
import 'react-datepicker/dist/react-datepicker.css';
import CustomSelect from 'components/CustomSelect';
import {
  getStatusNaming,
  getChanges,
  getQuestionsChanges,
  getSurveyIterations,
  getNewStatus,
  getMinTime,
  getMaxTime,
  getQuestionTypeNaming,
  isViewOnlyForTrainer,
  getMembersAssignedToTrainer,
} from 'shared/functions/surveyFunctions';
import ButtonBack from 'components/ButtonBack';
import { Answer, Question, Survey } from 'shared/types/survey';
import firebase from 'services/firebase';
import SwitchButton from 'components/SwitchButton';
import { AuthContext } from 'providers/AuthProvider';
import Icon from 'components/Icon';
import Button from 'components/Button';
import TransparentInput from '../components/TransparentInput';
import QuestionItem from './components/QuestionItem';
import CustomAssigneeSelect from '../components/CustomAssigneeSelect';
import ViewAnswers from './components/ViewAnswers';
import NotAllowedOnMobile from './components/NotAllowdOnMobile';

import styles from './styles.module.scss';
import Modal from '../components/Modal';

type ParamsType = {
  id: string;
  createNew: string;
};

const frequencyOptions = [
  { value: 'once', label: 'Einmalig' },
  { value: 'weekly', label: 'Wöchentlich' },
  { value: 'monthly', label: 'Monatlich' },
  { value: 'quarterly', label: 'Vierteljährlich' },
];

const SurveyDetail: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const { tenant, member, userData, user } = useContext(AuthContext);
  const [temporarySurvey, setTemporarySurvey] = useState<Survey | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deletedQuestionIds, setDeletedQuestionIds] = useState<string[]>([]);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCreateNew = history.location.search.includes('createNew');

  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // ---------------------------- update window size ----------------------------
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // ---------------------------- fetch survey ----------------------------
  const fetchSurvey = async () => {
    setIsFetching(true);
    try {
      const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(id);

      if (!surveyRef) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const surveyDoc = await surveyRef.get();
      const surveyData = surveyDoc.data();

      if (!surveyData) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const isClosed = isViewOnlyForTrainer(surveyData.status);

      // if the survey is closed, get the iterations because they are needed for the answers
      // otherwise, the iterations are not needed
      const surveyIterations = !isClosed
        ? []
        : surveyData.frequency !== 'once'
        ? getSurveyIterations(surveyData.planDate, surveyData.frequency, firebase.firestore.Timestamp.now()) // TODO: TIMEZONE
        : [{ iteration: 0, active: surveyData.status === 3, startDate: surveyData.planDate.toDate() }];

      const questionsCollection = await firebase
        .firestore()
        .collection(`tenants/${tenant}/surveys/${id.split('-')[0]}/questions`)
        .get();

      const questionsData = await Promise.all(
        questionsCollection.docs.map(async questionDoc => {
          const questionData = questionDoc.data();

          // if the survey is closed, fetch the answers
          if (isClosed) {
            const allAnswersData = await Promise.all(
              surveyIterations.map(async (iterationItem: any) => {
                const answersCollection = await firebase
                  .firestore()
                  .collection(
                    `tenants/${tenant}/surveys/${id.split('-')[0]}/questions/${questionDoc.id}/answers_${
                      iterationItem.iteration
                    }`
                  )
                  .get();

                const answersData = await Promise.all(
                  answersCollection.docs.map(async answerDoc => {
                    const creator = await answerDoc.data().creator.get();
                    return {
                      ...answerDoc.data(),
                      id: answerDoc.id,
                      questionId: questionDoc.id,
                      creatorId: creator.id,
                      iteration: iterationItem.iteration,
                    };
                  })
                );
                return answersData;
              })
            );

            const flattenedAnswersData = allAnswersData.flat(); // This will combine all answers into a single array

            return {
              ...questionData,
              id: questionDoc.id,
              answers: flattenedAnswersData as Answer[],
            };
          }

          return questionData;
        })
      );

      if (isClosed) {
        setSurvey({
          ...surveyData,
          questions: questionsData as Question[],
          iterations: surveyIterations,
          assignees: surveyData.assignees.includes('all')
            ? ['all']
            : await Promise.all(
                surveyData.assignees.map(async (assignee: any) => {
                  const assigneeData = await assignee.get();
                  return {
                    ...assigneeData.data(),
                    id: assignee.id,
                  };
                })
              ),
        } as Survey);
      } else {
        setSurvey({
          ...surveyData,
          questions: questionsData as Question[],
        } as Survey);
      }

      setIsFetching(false);
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };
  // ---------------------

  // ---------------------------- update survey ----------------------------
  const updateSurvey = async (status: number, goBack?: boolean) => {
    if (isUpdating) return;
    if (!temporarySurvey) return;

    if (status === 0) {
      toast.warn('Planungsdatum liegt in der Vergangenheit oder ein anderes Problem ist aufgetreten.');
      return;
    }

    const surveyChanges: Survey = getChanges(survey, temporarySurvey);
    const questionChanges = getQuestionsChanges(survey?.questions, temporarySurvey?.questions);

    // if there are no changes at all, don't update
    if (!Object.keys(surveyChanges).length && !Object.keys(questionChanges).length) {
      if (goBack) {
        history.push('/surveys');
      } else {
        toast.warn('Es wurden keine Änderungen vorgenommen.');
      }
      return;
    }

    // if there is no survey title, don't update the survey
    if (!temporarySurvey.surveyTitle) {
      toast.warn('Es wurde kein Titel eingegeben. Entwurf wurde nicht gespeichert.');
      return;
    }

    // if there are no assignees, don't update the survey
    if (temporarySurvey.assignees && temporarySurvey.assignees.length === 0) {
      toast.warn('Es wurden keine Empfänger:innen ausgewählt. Entwurf wurde nicht gespeichert.');
      return;
    }

    if (temporarySurvey.questions?.length === 0 && !goBack) {
      toast.warn('Es wurden keine Fragen hinzugefügt. Entwurf wurde nicht gespeichert.');
      return;
    }

    if (temporarySurvey.questions.map(question => question.question).includes('')) {
      toast.warn('Es wurden leere Fragen hinzugefügt. Entwurf wurde nicht gespeichert.');
      return;
    }

    const questionWithMissingAnswerOptions = temporarySurvey.questions?.find(
      question => [1, 2].includes(question.type) && (!question.answerOptions || question.answerOptions.length < 2)
    );

    if (questionWithMissingAnswerOptions) {
      toast.warn(
        `Es wurden nicht genug Antwortoptionen für eine ${getQuestionTypeNaming(
          questionWithMissingAnswerOptions.type
        )}-Frage hinzugefügt. Entwurf wurde nicht gespeichert.`
      );
      return;
    }

    // map the assignees to the user ids but as firebase references
    const assigneesAsFirebaseReferences = temporarySurvey.assignees?.includes('all')
      ? ['all']
      : temporarySurvey.assignees?.map(assignee =>
          firebase.firestore().collection(`tenants/${tenant}/users`).doc(assignee.id)
        );
    setIsUpdating(true);

    try {
      const batch = firebase.firestore().batch();

      // Update survey document if there are survey-level changes
      if (Object.keys(surveyChanges).length > 0) {
        const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(id);
        // delete questions array before updating on firestore
        const surveyChangesWithoutQuestions: any = { ...surveyChanges }; // did this because the questions array is not a part of the survey type
        delete surveyChangesWithoutQuestions.questions;

        batch.update(surveyRef, {
          ...surveyChangesWithoutQuestions,
          assignees: assigneesAsFirebaseReferences,
          updatedAt: firebase.firestore.Timestamp.now(),
          status,
          planDate: temporarySurvey.planDate ?? firebase.firestore.Timestamp.now(),
        });
      }

      // Handle both existing and new questions
      temporarySurvey?.questions?.forEach(question => {
        const questionRef = firebase
          .firestore()
          .collection(`tenants/${tenant}/surveys/${id}/questions`)
          .doc(question.id);

        // If the question exists in questionChanges, update it
        if (questionChanges[question.id]) {
          batch.update(questionRef, questionChanges[question.id]);
        }
        // If it's a new question (not in original survey), create it
        else if (!survey?.questions?.find(q => q.id === question.id)) {
          batch.set(questionRef, question);
        }
      });

      // Delete removed questions
      deletedQuestionIds.forEach(questionId => {
        const questionRef = firebase
          .firestore()
          .collection(`tenants/${tenant}/surveys/${id}/questions`)
          .doc(questionId);
        batch.delete(questionRef);
      });

      await batch.commit();
    } catch (error) {
      console.error('Error updating survey:', error);
      toast.error('Fehler beim Aktualisieren der Umfrage.');
      setIsUpdating(false);
    } finally {
      setIsUpdating(false);

      if (goBack) {
        history.push('/surveys');
      } else {
        history.push(`/surveys/${id}`);
      }

      if (!goBack) {
        toast.success('Umfrage wurde erfolgreich aktualisiert.');
      }
      setDeletedQuestionIds([]);
    }
  };
  // ---------------------

  // ---------------------------- handle back click ----------------------------
  const handleDeleteChanges = () => {
    history.push('/surveys');
    setIsModalOpen(false);
  };

  const handleSaveChanges = () => {
    updateSurvey(1, true);
    setIsModalOpen(false);
  };

  const handleBackClick = () => {
    const surveyChanges: Survey = getChanges(survey, temporarySurvey);
    const questionChanges = getQuestionsChanges(survey?.questions, temporarySurvey?.questions);

    if (!Object.keys(surveyChanges).length && !Object.keys(questionChanges).length) {
      history.push('/surveys');
    } else {
      setIsModalOpen(true);
    }
  };

  // ------------------------------- all users -------------------------------------
  const allUsersAssignedToTrainer = useMemo(() => {
    if (!user?.uid) {
      return [];
    }

    const assignedMembers = getMembersAssignedToTrainer(member || [], user.uid);

    return assignedMembers.map(assignedMember => ({
      id: assignedMember.uid,
      fullName: assignedMember.fullName,
    }));
  }, [member, user?.uid]);

  const userOptions = allUsersAssignedToTrainer.map(assignee => ({
    value: assignee.id,
    label: assignee.fullName,
  }));
  // ---------------------

  // ---------------------------- move question ----------------------------
  const moveQuestion = (direction: 'up' | 'down', position: number) => {
    setTemporarySurvey({
      ...temporarySurvey,
      questions: temporarySurvey?.questions?.map(question => {
        if (question.position === position - 1 && direction === 'up') {
          return { ...question, position: question.position + 1 };
        }
        if (question.position === position + 1 && direction === 'down') {
          return { ...question, position: question.position - 1 };
        }
        if (question.position === position) {
          return { ...question, position: direction === 'up' ? question.position - 1 : question.position + 1 };
        }
        return question;
      }),
    } as Survey);
  };
  // ---------------------

  useEffect(() => {
    fetchSurvey();
  }, []);

  useEffect(() => {
    if (survey) {
      setTemporarySurvey(survey);
    }
  }, [survey]);

  if (isFetching) return <p>Loading...</p>;

  if (isUpdating) return <p>Updating...</p>;

  if (!temporarySurvey) return null;

  if (userData?.role !== 4) {
    return <Redirect to="/surveys" />;
  }

  if (windowSize.width < 1024) {
    return <NotAllowedOnMobile />;
  }

  // ############################################################
  // ---------------------------- view answers ----------------------------
  if (isViewOnlyForTrainer(temporarySurvey.status)) {
    return <ViewAnswers survey={temporarySurvey} allUsers={allUsersAssignedToTrainer} />;
  }

  // ############################################################
  // ---------------------------- edit survey ----------------------------
  return (
    <section className={styles.surveyDetailContainer}>
      {isModalOpen && (
        <Modal>
          <div className={styles.modalContent}>
            <p>All Ihre Änderungen gehen verloren.</p>

            <div className={styles.modalButtonsWrapper}>
              <Button onClick={handleDeleteChanges} buttonStyle="dark">
                Ok
              </Button>
              <Button onClick={handleSaveChanges}>Entwurf speichern</Button>
            </div>
          </div>
        </Modal>
      )}

      <div className={styles.backBtnWrapper}>
        <ButtonBack className={styles.backBtn} text="Zurück" goBack={handleBackClick} />
      </div>

      <div className={styles.nameWrapper}>
        <TransparentInput
          value={isCreateNew ? '' : temporarySurvey.surveyTitle}
          onInput={event => {
            const target = event.target as HTMLInputElement;
            setTemporarySurvey({
              ...temporarySurvey,
              surveyTitle: target.value,
            });
          }}
          placeholder={isCreateNew ? 'Umfrage Titel eingeben' : temporarySurvey.surveyTitle || 'Umfrage Titel eingeben'}
          className={styles.surveyTitle}
        />

        <p className={styles.status}>{getStatusNaming(temporarySurvey.status)}</p>
      </div>

      <div className={styles.infoWrapper}>
        <div className={styles.leftSide}>
          <div className={styles.assigneesWrapper}>
            <p className={styles.assigneesLabel}>Empfänger:innen</p>

            <div className={styles.assigneesSelectWrapper}>
              <CustomAssigneeSelect
                userOptions={userOptions}
                temporarySurvey={temporarySurvey}
                onSetTemporarySurvey={(newSurvey: Survey) => setTemporarySurvey(newSurvey)}
                allUsers={allUsersAssignedToTrainer}
              />

              <Button
                type="button"
                onClick={() => setTemporarySurvey({ ...temporarySurvey, assignees: ['all'] })}
                className={styles.selectAllBtn}
                buttonStyle="dark"
              >
                Alle auswählen
              </Button>
            </div>
          </div>

          <div className={styles.frequencyWrapper}>
            <p className={styles.frequencyLabel}>Frequenz:</p>
            <CustomSelect
              name="frequency"
              value={frequencyOptions.find(opt => opt.value === temporarySurvey.frequency)}
              onChange={value => {
                setTemporarySurvey({
                  ...temporarySurvey,
                  frequency: (value as { value: Survey['frequency'] }).value,
                });
              }}
              dropDownOption={frequencyOptions}
              className={styles.frequencySelect}
            />
          </div>
        </div>

        <div className={styles.planWrapper}>
          <div className={styles.planSwitchWrapper}>
            <SwitchButton
              label="Sofort senden"
              onChange={value =>
                setTemporarySurvey({
                  ...temporarySurvey,
                  planDate: value
                    ? firebase.firestore.Timestamp.fromDate(
                        new Date(new Date().getTime() + (60 - (new Date().getMinutes() % 60)) * 60000)
                      )
                    : undefined,
                })
              }
              enabled={temporarySurvey.planDate}
              notContainer
              className={styles.planSwitch}
            />
            <p className={styles.planSwitchLabel}>Planen</p>
          </div>
          {temporarySurvey.planDate && (
            <DatePicker
              selected={temporarySurvey.planDate?.toDate()}
              onChange={date =>
                setTemporarySurvey({
                  ...temporarySurvey,
                  planDate: date ? firebase.firestore.Timestamp.fromDate(date) : temporarySurvey.updatedAt,
                })
              }
              showTimeSelect
              timeIntervals={60}
              timeCaption="Uhrzeit"
              timeFormat="HH:mm"
              dateFormat="dd.MM.yyyy, HH:mm"
              minDate={new Date()}
              minTime={getMinTime(temporarySurvey.planDate?.toDate())}
              maxTime={getMaxTime()}
              locale="de"
              className={styles.datePicker}
            />
          )}
        </div>
      </div>

      <div className={styles.questionsWrapper}>
        {[...(temporarySurvey.questions || [])]
          .sort((a, b) => (a.position || 0) - (b.position || 0))
          .map(question => {
            return (
              <QuestionItem
                onSetSurvey={setTemporarySurvey}
                survey={temporarySurvey}
                question={question}
                setDeletedQuestionIds={setDeletedQuestionIds}
                deletedQuestionIds={deletedQuestionIds}
                onMoveQuestion={moveQuestion}
                key={question.id}
                showMoveBtns={temporarySurvey.questions?.length > 1}
              />
            );
          })}
      </div>

      <button
        className={styles.addQuestionBtn}
        type="button"
        onClick={() => {
          setTemporarySurvey({
            ...temporarySurvey,
            questions: [
              ...(temporarySurvey.questions || []),
              {
                creator: temporarySurvey.creator,
                id: firebase.firestore().collection('temp').doc().id,
                question: '',
                type: 3,
                createdAt: firebase.firestore.Timestamp.now(),
                position: temporarySurvey.questions ? temporarySurvey.questions.length + 1 : 1,
                answerOptions: [],
              },
            ],
          });
        }}
      >
        <Icon name="plus" width={20} height={20} />
        <p>Frage hinzufügen</p>
      </button>

      <div className={styles.buttonsWrapper}>
        <Button
          onClick={() =>
            updateSurvey(
              getNewStatus(temporarySurvey.planDate, firebase.firestore.Timestamp.now(), temporarySurvey.frequency),
              true
            )
          }
          className={styles.saveBtn}
        >
          Umfrage abschließen
        </Button>

        <button className={styles.saveBtn} type="button" onClick={() => updateSurvey(1)}>
          Entwurf speichern
        </button>
      </div>
    </section>
  );
};

export default SurveyDetail;
