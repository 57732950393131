import { Answer, Question, Survey } from 'shared/types/survey';
import firebase from 'services/firebase';
import {
  add,
  differenceInHours,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  setHours,
  setMinutes,
} from 'date-fns';

export function getStatusNaming(status: number) {
  switch (status) {
    case 1:
      return 'Entwurf';
    case 2:
      return 'Geplant';
    case 3:
      return 'Gesendet';
    case 4:
      return 'Aktiv';
    case 5:
      return 'Archiviert';
    default:
      return status;
  }
}

export const getFrequencyNaming = (frequency: Survey['frequency']) => {
  switch (frequency) {
    case 'once':
      return 'Einmalig';
    case 'weekly':
      return 'Wöchentlich';
    case 'monthly':
      return 'Monatlich';
    case 'quarterly':
      return 'Vierteljährlich';
    default:
      return frequency;
  }
};

export const getQuestionTypeNaming = (type: number) => {
  switch (type) {
    case 1:
      return 'Einfach-Antwort';
    case 2:
      return 'Mehrfach-Antwort';
    case 3:
      return 'Freitext';
    case 4:
      return 'Bewertungsskala (bis 5 Sterne)';
    default:
      return type;
  }
};

export const getChanges = (oldSurvey: Survey | undefined, newSurvey: Survey | null) => {
  if (!newSurvey) return {};
  const changes: any = {};
  Object.keys(newSurvey).forEach(key => {
    if (newSurvey[key as keyof Survey] !== oldSurvey?.[key as keyof Survey]) {
      changes[key] = newSurvey[key as keyof Survey];
    }
  });

  return changes;
};

export const getQuestionsChanges = (oldQuestions: Question[] = [], newQuestions: Question[] = []) => {
  const updates: { [key: string]: Partial<Question> } = {};

  newQuestions.forEach(newQuestion => {
    const oldQuestion = oldQuestions.find(q => q.id === newQuestion.id);
    if (oldQuestion) {
      // Only check for changes in existing questions
      const changes: Partial<Question> = {};
      Object.keys(newQuestion).forEach(key => {
        if (newQuestion[key as keyof Question] !== oldQuestion[key as keyof Question]) {
          changes[key as keyof Question] = newQuestion[key as keyof Question];
        }
      });
      if (Object.keys(changes).length > 0) {
        updates[newQuestion.id] = changes;
      }
    }
  });

  return updates;
};

export const getTotalCompletedResponses = (survey: Survey): number => {
  const surveyIterations = getSurveyIterations(survey.planDate, survey.frequency, firebase.firestore.Timestamp.now());

  // give me all answers from the last iteration
  const answersFromLastIteration = survey.questions
    .flatMap(question =>
      question.answers?.filter(answer => answer?.iteration === surveyIterations[surveyIterations.length - 1].iteration)
    )
    .filter(answer => answer?.isFirst);

  // give me the number of answers from the last iteration but remove the answers doubled by the assignees

  return answersFromLastIteration.length;
};

export const isSurveyDeleteable = (survey: Survey) => {
  return survey.status === 1 || survey.status === 2;
};

export const isSurveyArchived = (survey: Survey) => {
  return survey.status === 5;
};

export const isSurveySent = (survey: Survey) => {
  return survey.status === 3;
};

export const isSurveyActive = (survey: Survey) => {
  return survey.status === 4;
};

export const isUserTrainer = (role: number): boolean => {
  return role === 4;
};

export const isUserMember = (role: number): boolean => {
  return role === 5;
};

export const getNewSurveyStatus = (
  frequency: Survey['frequency'],
  plannedDate: firebase.firestore.Timestamp | null
) => {
  const now = firebase.firestore.Timestamp.now();

  if (frequency === 'once') {
    if (!plannedDate) {
      // if survey is not planned, it is sent immediately
      return 3; // sent
    }
    if (plannedDate.toMillis() <= now.toMillis()) {
      // if planned date is in the past, it is sent
      return 3; // sent
    }
    // if planned date is in the future, it is scheduled
    return 2; // scheduled
  }
  if (!plannedDate || plannedDate.toMillis() <= now.toMillis()) {
    // if survey is not planned or planned date is in the past and the frequency is not once, it is active
    return 4; // active
  }
  // if planned date is in the future and the frequency is not once, it is scheduled
  return 2; // scheduled
};

export const getSurveyIterations = (
  startDate: firebase.firestore.Timestamp,
  frequency: Survey['frequency'],
  actualDate: firebase.firestore.Timestamp
) => {
  const getDifference = (inputFrequency: Survey['frequency'], actualDay: Date | number, startDay: Date | number) => {
    switch (inputFrequency) {
      case 'weekly':
        return differenceInWeeks(actualDay, startDay);
      case 'monthly':
        return differenceInMonths(actualDay, startDay);
      case 'quarterly':
        return differenceInQuarters(actualDay, startDay);
      default:
        return differenceInWeeks(actualDay, startDay);
    }
  };

  const getFrequency = (inputFrequency: Survey['frequency']) => {
    switch (inputFrequency) {
      case 'weekly':
        return 'weeks';
      case 'monthly':
        return 'months';
      case 'quarterly':
        return 'quarters';
      default:
        return inputFrequency;
    }
  };

  const startDay = startDate.toDate();
  const actualDay = actualDate.toDate();

  const amountOfIterations: number = getDifference(frequency, actualDay, startDay);

  // if the last iteration startDate is in the past, add one more iteration
  const isLastIterationInThePast =
    differenceInHours(actualDay, add(startDay, { [getFrequency(frequency)]: amountOfIterations })) > 0 ||
    amountOfIterations === 0;

  return Array.from({ length: isLastIterationInThePast ? amountOfIterations + 1 : amountOfIterations }, (_, index) => ({
    iteration: index,
    startDate: add(startDay, { [getFrequency(frequency)]: index }),
    active: index === amountOfIterations,
  }));
};

export const getAnswersFromUser = (questions: Question[], userId: string | undefined, iteration: number) => {
  const answers = questions?.flatMap(question => question.answers?.filter(answer => answer.iteration === iteration));
  return answers?.filter(answer => String(answer?.creatorId) === String(userId));
};

export const getNewStatus = (
  planDate: Survey['planDate'],
  actualDate: Survey['planDate'],
  frequency: Survey['frequency']
) => {
  if (!planDate && frequency === 'once') return 3;
  if (!planDate && frequency !== 'once') return 4;
  // if planDate is in the past, return 0 for error handling later
  if (planDate?.toDate() < actualDate?.toDate()) return 0;

  // if planDate is in the future, return 2
  if (planDate?.toDate() > actualDate?.toDate()) return 2;
  return 0;
};

export const getMinTime = (selected: Date | undefined) => {
  const now = new Date();

  if (selected?.toDateString() === now.toDateString()) {
    const minutes = Math.ceil(now.getMinutes() / 15) * 15;
    return setHours(setMinutes(now, minutes), now.getHours());
  }

  // For future dates, allow all times
  return setHours(setMinutes(new Date(), 0), 0);
};

export const getMaxTime = () => {
  return setHours(setMinutes(new Date(), 45), 23);
};

// isViewOnly Mode for creating and editing surveys
export const isViewOnlyForTrainer = (status: number) => {
  return [3, 4, 5].includes(status);
};

// isViewOnly Mode for answering surveys
export const isViewOnlyForUser = (status: number) => {
  return [3, 4].includes(status);
};

export const getUserSurveys = async (userRef: firebase.firestore.DocumentReference, tenant: string) => {
  const allUsersSurveysCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/surveys`)
    .where('assignees', 'array-contains', 'all')
    .where('status', 'in', [3, 4, 5]); // Check for status

  // Second query for surveys with userRef in assignees
  const userSurveysCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/surveys`)
    .where('assignees', 'array-contains', userRef)
    .where('status', 'in', [3, 4, 5]); // Check for status

  try {
    const allUsersSurveysData = await allUsersSurveysCollection.get();
    const userSurveysData = await userSurveysCollection.get();

    const surveysData = await Promise.all(
      [...allUsersSurveysData.docs, ...userSurveysData.docs].map(async doc => {
        const surveyData = doc.data();

        const creatorData = await surveyData.creator.get();
        const creatorId = creatorData.id;

        // filter out "all" surveys if the creator is not the user's trainer
        if (surveyData.assignees.includes('all')) {
          const userData = await userRef.get();
          const userTrainerId = userData.data()?.trainer?.uid;

          if (userTrainerId !== creatorId) {
            return null;
          }
        }

        const surveyIterations =
          surveyData.frequency !== 'once'
            ? getSurveyIterations(surveyData.planDate, surveyData.frequency, firebase.firestore.Timestamp.now()) // TODO: TIMEZONE
            : [{ iteration: 0 }];

        const questionsCollection = await firebase
          .firestore()
          .collection(`tenants/${tenant}/surveys/${doc.id}/questions`)
          .get();

        const questionsData = await Promise.all(
          questionsCollection.docs.map(async questionDoc => {
            const questionData = questionDoc.data();

            const allAnswersData = await Promise.all(
              surveyIterations.map(async (iteration: any) => {
                const answersCollection = await firebase
                  .firestore()
                  .collection(
                    `tenants/${tenant}/surveys/${doc.id}/questions/${questionDoc.id}/answers_${iteration.iteration}`
                  )
                  .get();

                const answersData = await Promise.all(
                  answersCollection.docs.map(async answerDoc => {
                    const creator = await answerDoc.data().creator.get();
                    return {
                      ...answerDoc.data(),
                      id: answerDoc.id,
                      questionId: questionDoc.id,
                      creatorId: creator.id,
                      iteration: iteration.iteration,
                    };
                  })
                );

                return answersData;
              })
            );

            const flattenedAnswersData = allAnswersData.flat(); // This will combine all answers into a single array

            return {
              ...questionData,
              id: questionDoc.id,
              answers: flattenedAnswersData as Answer[],
            };
          })
        );

        return {
          ...surveyData,
          id: doc.id,
          questions: questionsData as Question[],
          iterations: surveyIterations,
          assignees: surveyData.assignees.includes('all')
            ? ['all']
            : await Promise.all(
                surveyData.assignees.map(async (assignee: any) => {
                  const assigneeData = await assignee.get();
                  return {
                    ...assigneeData.data(),
                    id: assignee.id,
                  };
                })
              ),
        };
      })
    );

    // Filter out null values and cast to Survey type
    return surveysData.filter(Boolean) as Survey[];
  } catch (error) {
    console.error('Error fetching user surveys:', error);
    return [];
  }
};

export const getUserSurveysWithIterations = (userSurveys: Survey[], userId: string | undefined) => {
  if (!userSurveys.length) return [];
  if (!userId) return [];

  const onceSurveys = userSurveys
    .filter(survey => survey.frequency === 'once')
    .map(survey => {
      const answersFromUser = getAnswersFromUser(survey.questions, userId, survey.iterations[0].iteration) as Answer[];
      const isViewOnly = survey.status === 3 ? answersFromUser && answersFromUser?.length > 0 : true;
      return {
        ...survey,
        answersFromUser,
        active: survey.status === 3,
        isViewOnly,
      };
    });

  // create a new array where each survey is repeated as many times as the iterations but not in an array
  const surveysWithIterations = userSurveys
    .filter(survey => survey.frequency !== 'once')
    .map(survey => {
      return Array.from({ length: survey.iterations.length }, (_, index) => {
        const answersFromUser = getAnswersFromUser(
          survey.questions,
          userId,
          survey.iterations[index].iteration
        ) as Answer[];
        const isViewOnly =
          survey.iterations[index].active && survey.status === 4
            ? answersFromUser && answersFromUser?.length > 0
            : true;
        return {
          ...survey,
          iteration: survey.iterations[index].iteration,
          planDate: firebase.firestore.Timestamp.fromDate(survey.iterations[index].startDate),
          active: survey.iterations[index].active,
          id: `${survey.id}-${index}`,
          surveyTitle: `${survey.surveyTitle} ${index}`,
          answersFromUser,
          isViewOnly,
        };
      });
    })
    .flat();

  return [...onceSurveys, ...surveysWithIterations];
};

export const getMembersAssignedToTrainer = (members: UserInfo[], trainerId: string) => {
  if (!members?.length || !trainerId) return [];

  return members.filter(member => isUserMember(member.role) && member.trainer?.uid === trainerId);
};
