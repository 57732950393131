import React, { Fragment } from 'react';
import { SortCriteria, SortDirection } from 'pages/Surveys';
import { Survey } from 'shared/types/survey';
import classNames from 'classnames';
import Icon from 'components/Icon';
import ListItem from './ListItem';

import styles from './styles.module.scss';

interface ListComponentProps {
  surveys: Survey[];
  onDuplicateSurvey: (survey: Survey) => void;
  onDeleteSurvey: (surveyId: string) => void;
  onArchiveSurvey: (surveyId: string) => void;
  onSort: (column: SortCriteria) => void;
  sortColumn: SortCriteria;
  sortDirection: SortDirection;
  assignedUsersToTrainer?: UserInfo[];
}

const ListComponent: React.FC<ListComponentProps> = ({
  surveys,
  onDuplicateSurvey,
  onDeleteSurvey,
  onArchiveSurvey,
  onSort,
  sortColumn,
  sortDirection,
  assignedUsersToTrainer,
}) => {
  const renderSortIcon = (column: SortCriteria) => {
    if (sortColumn !== column) {
      return <Icon height={15} name="sortUpDown" />;
    }
    if (sortDirection === 'asc') {
      return <Icon height={15} name="sortUp" />;
    }
    if (sortDirection === 'desc') {
      return <Icon height={15} name="sortDown" />;
    }
    return <Icon height={15} name="sortUpDown" />;
  };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeaderWrapper}>
        <div className={styles.listHeader}>
          <button type="button" className={styles.listItem} onClick={() => onSort('surveyTitle')}>
            Umfrage {renderSortIcon('surveyTitle')}
          </button>
          <button type="button" className={styles.listItem} onClick={() => onSort('updatedAt')}>
            Geändert am {renderSortIcon('updatedAt')}
          </button>
          <button type="button" className={styles.listItem} onClick={() => onSort('status')}>
            Status {renderSortIcon('status')}
          </button>
          <button
            type="button"
            className={classNames(styles.listItem, styles.hide)}
            onClick={() => onSort('assignees')}
          >
            Empfänger {renderSortIcon('assignees')}
          </button>
          <button
            type="button"
            className={classNames(styles.listItem, styles.hide)}
            onClick={() => onSort('responses')}
          >
            Antworten {renderSortIcon('responses')}
          </button>
        </div>
        <div className={styles.interactionsHeader}>
          <div className={styles.listItem} />
        </div>
      </div>

      {surveys.length > 0 ? (
        surveys.map(survey => (
          <Fragment key={survey.id}>
            <ListItem
              survey={survey}
              onDuplicateSurvey={onDuplicateSurvey}
              onDeleteSurvey={onDeleteSurvey}
              onArchiveSurvey={onArchiveSurvey}
              assignedUsersToTrainer={assignedUsersToTrainer}
            />
          </Fragment>
        ))
      ) : (
        <div className={styles.emptyList}>
          <p>Keine Umfragen gefunden.</p>
        </div>
      )}
    </div>
  );
};

export default ListComponent;
