import React from 'react';
import Icon from 'components/Icon';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface CustomStarRatingProps {
  value: string;
  onChange?: (value: number) => void;
  readonly?: boolean;
}

const CustomStarRating: React.FC<CustomStarRatingProps> = ({ value, onChange, readonly }) => {
  return (
    <div className={classNames(styles.starRatingWrapper, { [styles.readonly]: readonly })}>
      {[...Array(5)].map((_, index) => (
        <button
          key={index}
          type="button"
          onClick={onChange ? () => onChange(index + 1) : undefined}
          disabled={readonly}
        >
          <Icon
            name="star"
            className={classNames(styles.star, { [styles.checked]: parseInt(value, 10) >= index + 1 })}
          />
        </button>
      ))}
    </div>
  );
};

export default CustomStarRating;
