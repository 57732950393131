import React from 'react';
import { Answer, Question } from 'shared/types/survey';
import AnswerItemsViewOnly from './AnswerItemsViewOnly';
import CustomTextArea from './CustomTextArea';
import CustomStarRating from './CustomStarRating';
import OptionInput from './OptionInput';

import styles from './styles.module.scss';

type QuestionItemProps = {
  question: Question;
  isViewOnly: boolean | undefined;
  viewAnswer: Answer;
  onChangeAnswer: Function;
  answerForEdit: Answer;
  placeholder?: string;
};

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  isViewOnly,
  viewAnswer,
  onChangeAnswer,
  answerForEdit,
  placeholder = 'Nicht beantwortet',
}) => {
  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeAnswer((prevAnswers: Answer[]) => {
      return prevAnswers.map(answer =>
        answer.questionId === question.id ? { ...answer, answer: event.target.value } : answer
      );
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAnswer((prevAnswers: Answer[]) => {
      return prevAnswers.map(answer =>
        answer.questionId === question.id ? { ...answer, answer: event.target.value } : answer
      );
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (answerForEdit.answer && answerForEdit.answer.includes(event.target.value)) {
      onChangeAnswer((prevAnswers: Answer[]) => {
        return prevAnswers.map(answer =>
          answer.questionId === question.id
            ? {
                ...answer,
                answer: (answer.answer as string[]).filter((valueItem: string) => valueItem !== event.target.value),
              }
            : answer
        );
      });
    } else {
      onChangeAnswer((prevAnswers: Answer[]) => {
        return prevAnswers.map(answer =>
          answer.questionId === question.id ? { ...answer, answer: [...answer.answer, event.target.value] } : answer
        );
      });
    }
  };

  const handleStarRatingChange = (ratingValue: number) => {
    onChangeAnswer((prevAnswers: Answer[]) => {
      return prevAnswers.map(answer =>
        answer.questionId === question.id ? { ...answer, answer: ratingValue.toString() } : answer
      );
    });
  };

  return (
    <div className={styles.questionItem}>
      <p className={styles.questionText}>{question.question}</p>
      <div className={styles.rightInputFields}>
        {isViewOnly && <AnswerItemsViewOnly answer={viewAnswer} question={question} placeholder={placeholder} />}

        {!isViewOnly && question.answerOptions && (
          <OptionInput
            questionOptions={question.answerOptions}
            type={question.type === 1 ? 'radio' : question.type === 2 ? 'checkbox' : ''}
            value={answerForEdit.answer}
            onChange={question.type === 1 ? handleRadioChange : question.type === 2 ? handleCheckboxChange : undefined}
            name={question.id}
          />
        )}

        {!isViewOnly && question.type === 3 && typeof answerForEdit.answer === 'string' && (
          <CustomTextArea
            name={question.id}
            value={answerForEdit.answer}
            onChange={handleTextAreaChange}
            placeholder="Antwort eingeben"
          />
        )}

        {!isViewOnly && question.type === 4 && typeof answerForEdit.answer === 'string' && (
          <CustomStarRating value={answerForEdit.answer} onChange={handleStarRatingChange} />
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
