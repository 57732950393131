export const stepLists: StepList[] = [
  { id: '01', title: 'Personal information' },
  { id: '02', title: 'Your Goal' },
  { id: '03', title: 'Form of nutrition' },
  { id: '04', title: 'Eating habits' },
  { id: '05', title: 'Intolerances' },
];

export const genderOption: any = [
  { value: 'woman', label: 'Weiblich' },
  { value: 'man', label: 'Männlich' },
];

export const countryOption: any = [
  { value: 'DE', label: 'Deutschland' },
  { value: 'AT', label: 'Österreich' },
  { value: 'CH', label: 'Schweiz' },
];

export const physicalStrainOption: any = [
  { value: 'Sleep', label: 'Nur sitzend oder liegend', pal: 1.2 },
  { value: 'Sitting or lying only', label: 'Sitzend, kaum körperliche Aktivität (z.B. Büro-Job)', pal: 1.4 },
  {
    value: 'Exclusively sedentary work with little or no physical activity in leisure time, e.g. office work',
    label: 'Überwiegend sitzend, gehend und stehend (z.B. Studenten, Schüler, Taxifahrer)',
    pal: 1.6,
  },
  {
    value:
      'Sedentary work with temporarily walking or standing work, e.g. students, assembly line workers, laboratory technicians, drivers',
    label: 'Hauptsächlich stehend und gehend (z.B. Verkäufer, Kellner, Handwerker)',
    pal: 1.8,
  },
  {
    value: 'Physically demanding professional work',
    label: 'Körperlich anstrengende Arbeit (z.B. Landwirte, Hochleistungssportler)',
    pal: 2.0,
  },
];

export const formOfNutrition = ['Flexitarian', 'Vegan', 'Vegetarian', 'Ketogen'];
export const yourGoal = ['Decrease', 'Gain', 'Maintain'];
export const GoalDescription = ['Reduce body fat', 'Muscle building', 'Optimize nutrition'];

export const eatingHabitsOption: string[] = [
  'Warm meals',
  'Snacks',
  'Hearty',
  'Sweets',
  'Bread - baked goods',
  'Breakfast',
  'Lunch',
  'Dinner',
  'Meal prep',
  'Smoothies & Shakes',
];

export const eatingHabitsOptionProfile: string[] = [
  'Warm meals',
  'Snacks',
  'Hearty',
  'Sweets',
  'Bread - baked goods',
  'Meal prep',
  'Smoothies & Shakes',
];

export const eatingHabitsAlghorithmus: string[] = [
  'Warm meals',
  'Hearty',
  'Sweets',
  'Bread - baked goods',
  'Meal prep',
  'Smoothies & Shakes',
];

export const intolerancesOption: string[] = [
  'Lactose',
  'Gluten',
  'Sorbitol',
  'wheat',
  'Fructose',
  'Peanuts',
  'Nuts',
  'Soy',
  'Egg',
  'Celery',
];
