import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Header from 'components/Header';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  RefreshIcon,
  XIcon,
  ChevronDownIcon,
  BanIcon,
  ChartPieIcon,
  DotsVerticalIcon,
  CheckCircleIcon,
  SwitchHorizontalIcon,
  CalendarIcon,
  EmojiHappyIcon,
  HeartIcon,
  DocumentDuplicateIcon,
  ExternalLinkIcon,
  PencilIcon,
  UserCircleIcon,
  TrashIcon,
  CollectionIcon,
} from '@heroicons/react/outline';
import MakroCircles from 'components/MakroCircles';
import LogoImg from 'assets/img/svg/logo.svg';
import Checkbox from 'components/Checkbox';
import CustomSwitchSelector from 'components/CustomSwitchSelector';
import TrialContainer from 'components/TrialContainer';
import TransitionContainer from 'components/TransitionContainer';
import SwitchButton from 'components/SwitchButton';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { MobileView } from 'react-device-detect';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import Select from 'react-select';
import ButtonBack from 'components/ButtonBack';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import Button from 'components/Button';
import { Link, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import GenerateAlghorithmus from 'components/GenerateAlghorithmus';
import SwitchSelector from 'react-switch-selector';
import ReactLoading from 'react-loading';
import firebase from 'services/firebase';
import BarcodePicker from 'components/BarcodePicker';
import CustomUserInput from 'components/CustomUserInput';
import { toast } from 'react-toast';
import Overlay from 'components/Overlay';
import {
  savePlanChangesToFirebase,
  handleOpenClosePopups,
  generateRandomUid,
  updateTourToFirebase,
  addUserRecipe,
  scrollToElement,
  addFavoriteIngredients,
  changeUserView,
  getIsWebView,
  saveLastDayRating,
} from 'shared/functions/global';
import { formOfNutrition, intolerancesOption, eatingHabitsOption } from 'shared/constants/profile-wizard';
import IngredientSearchPopup from 'components/IngredientSearchPopup';
import Icon from 'components/Icon';
import { defaultButtonTextColor, defaultTextColor } from 'layouts/Theme';
import IncompatibilityOverlay from 'components/IncompabilityOverlay';
import axios from 'axios';
import DashBoardWeekOverview from './components/DashBoardWeekOverview';
import DashboardMealCard from './components/DashboardMealCard';
import DashboardMealCardHorizontal from './components/DashBoardMealCardHorizontal';
import WaterSection from './components/WaterSection';
import RateSection from './components/RateSection';
import DayRating from './components/DayRating';
import Headline from '../../components/Headline';
import IngridientPopupItem from '../Plans/components/IngridientPopupItem';
import NewPlanStep1 from '../Plans/components/NewPlanStep1';
import NewPlanStep2 from '../Plans/components/NewPlanStep2';
import NewPlanStep3 from '../Plans/components/NewPlanStep3';
import NewPlanStep4 from '../Plans/components/NewPlanStep4';
import NewPlanStep5 from '../Plans/components/NewPlanStep5';
import NewPlanStep6 from '../Plans/components/NewPlanStep6';
import CopyPlanStep1 from '../Plans/components/CopyPlanStep1';
import CopyPlanStep2 from '../Plans/components/CopyPlanStep2';

import '../../assets/css/shepherd.css';
import styles from './style.module.scss';

registerLocale('de', de);

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const db = firebase.firestore();
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme, tenantData } = useContext(AuthContext);

  const checkboxRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [changeSwitchLoading, setChangeSwitchLoading] = useState(false);

  const [multiCopyPending, setMultiCopyPending] = useState(false);

  const { userPlanList, favoritesIngredientsList } = useContext(MainContext);

  const location = useLocation<LocationState>();
  const { tempRecipeObject, setTempRecipeObject, ingredientList } = useContext(MainContext);

  const wrapperRef = useRef(null);

  const momentExtended = extendMoment(Moment);

  const [visibleIncompatibilityPopup, setVisibleIncompatibilityPopup] = useState(false);

  const [triggeredStepCount, setTriggeredStepCount] = useState(0);
  const [triggeredWorkoutMinutes, setTriggeredWorkoutMinutes] = useState(0);
  const [triggeredWorkoutCalories, setTriggeredWorkoutCalories] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [ingredientFavoriteStatus, setIngredientFavoriteStatus] = useState(false);

  const [visibleScrollCircles, setVisibleScrollCircles] = useState(false);

  const [isUserSwitch, setIsUserSwitch] = useState(false);

  const [newWorkoutOverlayClass, setNewWorkoutOverlayClass] = useState('hidden');

  const [updateTriggerFavorites, setUpdateTriggerFavorites] = useState(0);

  const [weekDayCopyArray, setWeekDayCopyArray] = useState<any>([]);
  const [mealTypesCopyArray, setMealTypesCopyArray] = useState<any>([]);

  const [startDate, setStartDate] = useState(null);
  const [startDateChangeDay, setStartDateChangeDay] = useState(null);
  const [startDateMultiCopy, setStartDateMultiCopy] = useState(null);
  const [endDateMultiCopy, setEndDateMultiCopy] = useState(null);
  const changeCopyDate = (dates: any) => {
    setStartDate(dates);
  };
  const changeChangeDayDate = (dates: any) => {
    changeDayFromDate(dates);
  };
  const changeMultiCopyStartDate = (dates: any) => {
    setStartDateMultiCopy(dates);
  };
  const changeMultiCopyEndDate = (dates: any) => {
    setEndDateMultiCopy(dates);
  };
  const CustomCalendarInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  const [eatingHabitsChevronClass, setEatingHabitsChevronClass] = useState('text-accentColor');
  const [eatingHabitsClass, setEatingHabitsClass] = useState('hidden');
  const [eatingHabitsArray, setEatingHabitsArray] = useState<any>([]);

  const history = useHistory();

  const datepickerRef = useRef<any>(null);

  const [actionClass, setActionClass] = useState('hidden');

  const [actionClassMobile, setActionClassMobile] = useState('hidden');

  const [openRecipeOnload, setOpenRecipeOnload] = useState(false);

  const [currentDayDateMoment, setCurrentDayDateMoment] = useState(moment());

  const [currentAddMealTypeValue, setCurrentAddMealTypeValue] = useState('breakfast');

  const [editMenuClass, setEditMenuClass] = useState('hidden');

  const [mobileWaterNeedClass, setMobileWaterNeedClass] = useState('hidden');
  const [mobileNoticeClass, setMobileNoticeClass] = useState('hidden');
  const [mobileDayRatingClass, setMobileDayRatingClass] = useState('hidden');
  const [newRecipeClass, setNewRecipeClass] = useState('hidden');

  const [currentApiFoodId, setCurrentApiFoodId] = useState('');

  const [clearDayPopupClass, setClearDayPopupClass] = useState('hidden');

  const [currentRecipeName, setCurrentRecipeName] = useState('');

  const [refreshTransition, setRefreshTransition] = useState(false);

  const [refreshFromPopup, setRefreshFromPopup] = useState(false);

  const horizontalBreakfastRef = useRef<HTMLDivElement>(null);
  const horizontalLunchRef = useRef<HTMLDivElement>(null);
  const horizontalDinnerRef = useRef<HTMLDivElement>(null);
  const horizontalSnacksRef = useRef<HTMLDivElement>(null);

  const [userMacroPopupClass, setUserMacroPopupClass] = useState('hidden');
  const [currentMakroChangeCalories, setCurrentMakroChangeCalories] = useState<any>(0);
  const [currentMakroChangeCarbohydrates, setCurrentMakroChangeCarbohydrates] = useState<any>(0);
  const [currentMakroChangeProtein, setCurrentMakroChangeProtein] = useState<any>(0);
  const [currentMakroChangeFat, setCurrentMakroChangeFat] = useState<any>(0);
  const [currentMakroChangeCarbohydratesPercent, setCurrentMakroChangeCarbohydratesPercent] = useState('');
  const [currentMakroChangeProteinPercent, setCurrentMakroChangeProteinPercent] = useState('');
  const [currentMakroChangeFatPercent, setCurrentMakroChangeFatPercent] = useState('');
  const [currentMakroChangeType, setCurrentMakroChangeType] = useState('standard');
  const changeMakroCaloriesRef = useRef<HTMLInputElement>(null);
  const newRecipeRef = useRef<HTMLInputElement>(null);

  const [refreshKcalValue, setRefreshKcalValue] = useState(0);

  const [checkBoxArray, setCheckBoxArray] = useState<any>([]);
  const [checkBoxArrayOnlyIngredients, setCheckBoxArrayOnlyIngredients] = useState(false);
  const [checkBoxArrayOnlyOneMealType, setCheckBoxArrayOnlyOneMealType] = useState(true);
  const [checkBoxArrayOnlyOneActiveStatus, setCheckBoxArrayOnlyOneActiveStatus] = useState(true);
  const [checkBoxArrayDeactivatedItemsCount, setCheckBoxArrayDeactivatedItemsCount] = useState(0);

  const [currentCarbohydratesPercent, setCurrentCarbohydratesPercent] = useState('');
  const [currentProteinPercent, setCurrentProteinPercent] = useState('');
  const [currentFatPercent, setCurrentFatPercent] = useState('');

  const [changeCaloriesNeed, setChangeCaloriesNeed] = useState(false);

  const [copyRecipeArray, setCopyRecipeArray] = useState<any>([]);
  const [copyIngredientsArray, setCopyIngredientsArray] = useState<any>([]);
  const [copyManualItemsArray, setCopyManualItemsArray] = useState<any>([]);
  const [copyStatus, setCopyStatus] = useState('1');

  const [copyItemOverlayClass, setCopyItemOverlayClass] = useState('hidden');
  const [moveItemOverlayClass, setMoveItemOverlayClass] = useState('hidden');

  const [addManualCaloriesClass, setAddManualCaloriesClass] = useState('hidden');
  const [manualDescription, setManualDescription] = useState('');
  const [manualKcal, setManualKcal] = useState(0);
  const [manualCarbohydrates, setManualCarbohydrates] = useState(0);
  const [manualProtein, setManualProtein] = useState(0);
  const [manualFat, setManualFat] = useState(0);
  const [manualId, setManualId] = useState('');
  const manualDescriptionRef = useRef<HTMLInputElement>(null);
  const manualCaloriesRef = useRef<HTMLInputElement>(null);
  const manualCarbohydratesRef = useRef<HTMLInputElement>(null);
  const manualProteinRef = useRef<HTMLInputElement>(null);
  const manualFatRef = useRef<HTMLInputElement>(null);
  const changeMakroCarbohydrateRef = useRef<HTMLInputElement>(null);
  const changeMakroProteinRef = useRef<HTMLInputElement>(null);
  const changeMakroFatRef = useRef<HTMLInputElement>(null);
  const changeMakroCaloriesDayRef = useRef<HTMLInputElement>(null);

  const [currentEditIngridientKcalTotal, setCurrentEditIngridientKcalTotal] = useState(0);
  const [currentEditIngridientCarbohydratesTotal, setCurrentEditIngridientCarbohydratesTotal] = useState(0);
  const [currentEditIngridientProteinTotal, setCurrentEditIngridientProteinTotal] = useState(0);
  const [currentEditIngridientFatTotal, setCurrentEditIngridientFatTotal] = useState(0);
  const [currentEditIngridientPiece, setCurrentEditIngridientPiece] = useState('g');
  const [currentEditIngridientAmount, setCurrentEditIngridientAmount] = useState('1');
  const [currentEditIngridientPortionValues, setCurrentEditIngridientPortionValues] = useState<any>([]);
  const [currentEditIngridientDefaultPiece, setCurrentEditIngridientDefaultPiece] = useState('g');
  const editIngridientRef = useRef<HTMLInputElement>(null);

  const [jumpToRecipeNode, setJumpToRecipeNode] = useState(false);
  const [jumpToPlansNode, setJumpToPlansNode] = useState(false);

  const [barcodePickerVisible, setBarcodePickerVisible] = useState(false);
  const [barcodePickerContentVisible, setBarcodePickerContentVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPlanExists, setCurrentPlanExists] = useState(false);
  const [currentPlanDateString, setCurrentPlanDateString] = useState('');
  const [waterEditPopupClass, setWaterEditPopupClass] = useState('hidden');
  const [barcodeEditPopupClass, setBarcodeEditPopupClass] = useState('hidden');
  const [currentWaterChangeValue, setCurrentWaterChangeValue] = useState(0);
  const [currentPlan, setCurrentPlan] = useState<any>({
    startDate: {
      seconds: moment(currentDayDateMoment).unix(),
    },
    endDate: {
      seconds: moment(currentDayDateMoment).unix(),
    },
    dayEntries: [
      {
        breakfast: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Frühstück',
        },
        lunch: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Mittagessen',
        },
        dinner: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Abendessen',
        },
        snacks: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Snacks',
        },
        id: 1,
        name: 'Tag 1',
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        date: {
          seconds: moment(currentDayDateMoment).unix(),
        },
      },
    ],
  });
  const [currentDayItem, setCurrentDayItem] = useState<any>([]);
  const [currentDayId, setCurrentDayId] = useState(1);
  const [planGenerateType, setPlanGenerateType] = useState('day');

  const [ingredientSearchPopupClass, setIngredientSearchPopupClass] = useState('hidden');

  const [generateDayPopupClass, setGenerateDayPopupClass] = useState('hidden');

  const [viewMealPopupClass, setViewMealPopupClass] = useState('hidden');
  const [viewIngredientPopupClass, setViewIngredientPopupClass] = useState('hidden');
  const [currentIngredientName, setCurrentIngredientName] = useState('');
  const [currentIngredientId, setCurrentIngredientId] = useState('');

  const [currentMealType, setCurrentMealType] = useState('breakfast');
  const [currentMealLabel, setCurrentMealLabel] = useState('Frühstück');
  const [changedMealType, setChangedMealType] = useState('');

  const [changedMealTypeCopy, setChangedMealTypeCopy] = useState('');

  const [currentAddPlan, setCurrentAddPlan] = useState<any>({
    startDate: null,
    endDate: null,
    kcal_total: 0,
    carbohydrates_total: 0,
    dayEntries: [],
    protein_total: 0,
    fat_total: 0,
    imageUrl: '',
    ketogen: true,
    vegan: true,
    vegetarian: true,
    flexitarian: true,
    egg: false,
    fructose: false,
    histamine: false,
    lactose: false,
    nuts: false,
    peanuts: false,
    sorbitol: false,
    soy: false,
  });

  const [currentCopyTypeValue, setCurrentCopyTypeValue] = useState('date');

  const makroTypes: any[] = [
    { value: 'standard', label: 'Standard', carbohydrates: '50', protein: '25', fat: '25' },
    { value: 'ketogen', label: 'Ketogen', carbohydrates: '5', protein: '30', fat: '65' },
    { value: 'user', label: 'Benutzerdefiniert' },
  ];

  const copyTypeValues: any = [
    { value: 'date', label: 'Auf ein bestimmtes Datum kopieren' },
    { value: 'multi', label: 'Auf mehrere Tage kopieren' },
  ];

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const [createPlanPopupClass, setCreatePlanPopupClass] = useState('hidden');

  const [currentCreatePlanStep, setCurrentCreatePlanStep] = useState('1');

  const changeWaterGoalRef = useRef<HTMLInputElement>(null);
  const recipePopupContentRef = useRef<HTMLDivElement>(null);
  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);
  const currentRecipeRef = useRef<HTMLDivElement>(null);
  const currentPlansRef = useRef<HTMLDivElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);

  const [currentWaterCounterValue, setCurrentWaterCounterValue] = useState(0);

  const displayTypeOptionsDashboard = [
    {
      label: 'Tag',
      value: 'day',
    },
    {
      label: 'Woche',
      value: 'week',
    },
  ];

  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const mealTypeValuesForCheckbox: any = [
    { value: 'breakfast', label: 'Frühstück', index: 0 },
    { value: 'lunch', label: 'Mittagessen', index: 1 },
    { value: 'dinner', label: 'Abendessen', index: 2 },
    { value: 'snacks', label: 'Snacks', index: 3 },
  ];

  const [currentSection, setCurrentSection] = useState<any>(displayTypeOptionsDashboard[0].value);
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState(0);
  const [currentDayLoaded, setCurrentDayLoaded] = useState(false);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  function keyPress(event: any, submitFunction: any) {
    if (event.charCode === 13) {
      event.preventDefault();
      if (submitFunction !== undefined) {
        submitFunction();
      }
    }
  }

  const addUpdateWeekDayCopyArray = (weekDay: number) => {
    // Check if day already exists in array
    const checkWeekDay = weekDayCopyArray.filter((item: any) => item.value === weekDay);
    let currentArray = weekDayCopyArray; // Initialisieren von currentArray

    if (checkWeekDay.length === 0) {
      // Füge den Wochentag hinzu, wenn er noch nicht existiert
      currentArray = [...currentArray, { value: weekDay }];
    } else {
      // Entferne den Wochentag, wenn er bereits existiert
      currentArray = currentArray.filter((item: any) => item.value !== weekDay);
    }

    setWeekDayCopyArray(currentArray);
  };

  const addUpdateMealtypesCopyArray = (mealTypeValue: number) => {
    // Check if mealtype already exists in array
    const checkMealType = mealTypesCopyArray.filter((item: any) => item.value === mealTypeValue);
    let currentArray = mealTypesCopyArray; // Initialisieren von currentArray

    if (checkMealType.length === 0) {
      // Füge die Mahlzeit hinzu, wenn er noch nicht existiert
      currentArray = [...currentArray, { value: mealTypeValue }];
    } else {
      // Entferne die Mahlzeit, wenn er bereits existiert
      currentArray = currentArray.filter((item: any) => item.value !== mealTypeValue);
    }

    setMealTypesCopyArray(currentArray);
  };

  const checkCurrentPlanExists = (date: any) => {
    const checkPlan = [] as any;
    if (userPlanList !== undefined) {
      for (let i = 0; i <= userPlanList.length; i += 1) {
        if (checkPlan.length > 0) {
          break;
        } else {
          getCurrentPlanData(userPlanList[i], date, checkPlan);
        }
      }
    }

    setCurrentDayLoaded(true);
  };

  const getCurrentPlanData = (plan: PlanType, date: any, checkPlan: any) => {
    const thisStartDate = moment.unix(plan?.startDate.seconds).utc().format('YYYY-MM-DD');
    const thisEndDate = moment.unix(plan?.endDate.seconds).utc().format('YYYY-MM-DD');

    if (
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '(]') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[)') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[]')
    ) {
      const newDayItem = plan?.dayEntries?.filter(
        (item: any) => moment.unix(item.date.seconds).utc().format('YYYYMMDD') === date.format('YYYYMMDD')
      )[0];

      if (newDayItem !== undefined) {
        setCurrentDayId(newDayItem.id);

        setCurrentPlan(plan);
        if (newDayItem.waterCounter !== undefined) {
          setCurrentWaterCounterValue(newDayItem.waterCounter);
        } else {
          setCurrentWaterCounterValue(0);
        }
        setCurrentPlanExists(true);
        checkPlan.push('true');

        setCurrentDayItem(newDayItem);
      }
    }
  };

  function saveMakroDayChange() {
    let newColumns = {} as any;
    let thisCarbohydratesPercent = '';
    let thisProteinPercent = '';
    let thisFatPercent = '';

    if (currentMakroChangeCarbohydratesPercent !== '') {
      thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
    } else {
      thisCarbohydratesPercent = '50';
    }

    if (currentMakroChangeProteinPercent !== '') {
      thisProteinPercent = currentMakroChangeProteinPercent;
    } else {
      thisProteinPercent = '25';
    }

    if (currentMakroChangeFatPercent !== '') {
      thisFatPercent = currentMakroChangeFatPercent;
    } else {
      thisFatPercent = '25';
    }

    if (changeCaloriesNeed && parseFloat(currentMakroChangeCalories) < 1000) {
      toast.error('Bitte gebe mindestens 1000 Kalorien als Bedarf ein!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
    ) {
      toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
    ) {
      toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else {
      if (currentMakroChangeType === 'user') {
        newColumns = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              custom_kcal_total: currentMakroChangeCalories,
              custom_carbohydrates_total: currentMakroChangeCarbohydrates,
              custom_protein_total: currentMakroChangeProtein,
              custom_fat_total: currentMakroChangeFat,
              custom_makro_type: currentMakroChangeType,
              custom_carbohydrates_percent:
                currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
              custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
              custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
            };
          }),
        };
      } else {
        newColumns = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              custom_kcal_total: currentMakroChangeCalories,
              custom_carbohydrates_total: currentMakroChangeCarbohydrates,
              custom_protein_total: currentMakroChangeProtein,
              custom_fat_total: currentMakroChangeFat,
              custom_makro_type: currentMakroChangeType,
              custom_carbohydrates_percent:
                currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
              custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
              custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
            };
          }),
        };
      }

      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        true,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumns);
      setUserMacroPopupClass('hidden');
      toast.success('Dein Bedarf wurde erfolgreich angepasst!');
    }
  }

  const updateMakroType = (value: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter(st => st.value === value.value)[0].carbohydrates;
    const thisProteinPercent = makroTypes.filter(st => st.value === value.value)[0].protein;
    const thisFatPercent = makroTypes.filter(st => st.value === value.value)[0].fat;

    if (value.value === 'user') {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }

      thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = thisProteinPercent;
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = thisFatPercent;
      }

      thisCalculatedCarbohydrates = Math.round(
        (currentMakroChangeCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeType(value.value as string);
  };

  const openMakroChangePopup = (changeType: string) => {
    setChangeCaloriesNeed(false);
    setCurrentMakroChangeCalories(0);

    if (currentDayItem?.custom_carbohydrates_total !== undefined) {
      if (currentDayItem?.custom_makro_type === 'user') {
        setCurrentCarbohydratesPercent(
          currentDayItem?.custom_carbohydrates_percent !== undefined
            ? currentDayItem?.custom_carbohydrates_percent
            : '50'
        );
        setCurrentProteinPercent(
          currentDayItem?.custom_protein_percent !== undefined ? currentDayItem?.custom_protein_percent : '25'
        );
        setCurrentFatPercent(
          currentDayItem?.custom_fat_percent !== undefined ? currentDayItem?.custom_fat_percent : '25'
        );
      } else {
        setCurrentCarbohydratesPercent(
          makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].carbohydrates
        );
        setCurrentProteinPercent(makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].protein);
        setCurrentFatPercent(makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].fat);
      }
      setCurrentMakroChangeCarbohydrates(currentDayItem?.custom_carbohydrates_total);
      setCurrentMakroChangeProtein(currentDayItem?.custom_protein_total);
      setCurrentMakroChangeFat(currentDayItem?.custom_fat_total);
      // setCurrentMakroChangeCalories(currentDayItem?.custom_kcal_total);

      /* if (changeMakroCaloriesRef.current) {
        changeMakroCaloriesRef.current.value = currentDayItem?.custom_kcal_total.toString();
        setTimeout(() => {
          if (changeMakroCaloriesRef.current) {
            changeMakroCaloriesRef.current.focus();
          }
        }, 1);
      } */
    } else if (currentPlan?.custom_carbohydrates_total) {
      if (currentPlan?.custom_makro_type === 'user') {
        setCurrentCarbohydratesPercent(currentPlan?.custom_carbohydrates_percent!);
        setCurrentProteinPercent(currentPlan?.custom_protein_percent!);
        setCurrentFatPercent(currentPlan?.custom_fat_percent!);
      } else {
        setCurrentCarbohydratesPercent(
          makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].carbohydrates
        );
        setCurrentProteinPercent(makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].protein);
        setCurrentFatPercent(makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].fat);
      }
      setCurrentMakroChangeCarbohydrates(currentPlan?.custom_carbohydrates_total);
      setCurrentMakroChangeProtein(currentPlan?.custom_protein_total);
      setCurrentMakroChangeFat(currentPlan?.custom_fat_total);
      // setCurrentMakroChangeCalories(currentPlan?.custom_kcal_total);
      /* if (changeMakroCaloriesRef.current && changeType === 'plan') {
        changeMakroCaloriesRef.current.value = currentPlan?.custom_kcal_total.toString();
        setTimeout(() => {
          if (changeMakroCaloriesRef.current) {
            changeMakroCaloriesRef.current.focus();
          }
        }, 1);
      } */
    } else {
      setCurrentMakroChangeCarbohydrates(userData?.carbohydrates_total);
      setCurrentMakroChangeProtein(userData?.protein_total);
      setCurrentMakroChangeFat(userData?.fat_total);

      if (userData?.custom_nutrition_type !== undefined) {
        if (userData?.custom_nutrition_type === 'user') {
          setCurrentCarbohydratesPercent(userData?.custom_carbohydrates_percent!);
          setCurrentProteinPercent(userData?.custom_protein_percent!);
          setCurrentFatPercent(userData?.custom_fat_percent!);
        } else {
          setCurrentCarbohydratesPercent(
            makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].carbohydrates
          );
          setCurrentProteinPercent(makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].protein);
          setCurrentFatPercent(makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].fat);
        }
      } else {
        setCurrentCarbohydratesPercent('50');
        setCurrentProteinPercent('25');
        setCurrentFatPercent('25');
      }
    }

    if (currentDayItem?.custom_makro_type) {
      const thisCarbohydratesPercent = makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].protein;
      const thisFatPercent = makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].fat;

      if (currentDayItem?.custom_makro_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = currentDayItem?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = currentDayItem?.custom_protein_percent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = currentDayItem?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else if (currentPlan?.custom_makro_type) {
      const thisCarbohydratesPercent = makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].protein;
      const thisFatPercent = makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].fat;

      if (currentPlan?.custom_makro_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = currentPlan?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = currentPlan?.custom_protein_percent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = currentPlan?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else if (userData?.custom_nutrition_type !== undefined && userData?.custom_carbohydrates_percent !== undefined) {
      const thisCarbohydratesPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].protein;
      const thisFatPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].fat;

      if (userData?.custom_nutrition_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = userData?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current && userData?.custom_protein_percent !== undefined) {
          changeMakroProteinRef.current.value = userData?.custom_protein_percent;
        }
        if (changeMakroFatRef.current && userData?.custom_fat_percent !== undefined) {
          changeMakroFatRef.current.value = userData?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }
    }

    if (currentDayItem?.custom_makro_type) {
      // setCurrentMakroChangeType(currentDayItem?.custom_makro_type);

      if (currentDayItem?.custom_carbohydrates_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(currentDayItem?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].carbohydrates
        );
      }
      if (currentDayItem?.custom_protein_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeProteinPercent(currentDayItem?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].protein
        );
      }
      if (currentDayItem?.custom_fat_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeFatPercent(currentDayItem?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(makroTypes.filter(st => st.value === currentDayItem?.custom_makro_type)[0].fat);
      }
    } else if (currentPlan?.custom_makro_type) {
      // setCurrentMakroChangeType(currentPlan?.custom_makro_type);

      if (currentPlan?.custom_carbohydrates_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(currentPlan?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].carbohydrates
        );
      }
      if (currentPlan?.custom_protein_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeProteinPercent(currentPlan?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].protein
        );
      }
      if (currentPlan?.custom_fat_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeFatPercent(currentPlan?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(makroTypes.filter(st => st.value === currentPlan?.custom_makro_type)[0].fat);
      }
    } else if (userData?.custom_nutrition_type !== undefined) {
      // setCurrentMakroChangeType(userData?.custom_nutrition_type);
      if (userData?.custom_carbohydrates_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(userData?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].carbohydrates
        );
      }
      if (userData?.custom_protein_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeProteinPercent(userData?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].protein
        );
      }
      if (userData?.custom_fat_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeFatPercent(userData?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].fat);
      }
    } else {
      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');
    }

    setUserMacroPopupClass('block');
  };

  function updateWaterCounter(waterValue: number) {
    setCurrentWaterCounterValue(currentWaterCounterValue + waterValue);

    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          waterCounter: currentWaterCounterValue + waterValue,
        };
      }),
    };

    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      true,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
    history.replace({ state: {} });
  }

  function updateRateText(noticeText: string) {
    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          notice: noticeText,
        };
      }),
    };

    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      true,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
    history.replace({ state: {} });

    toast.success('Die Notiz wurde erfolgreich gespeichert!');
  }

  function updateDayRating(dayRatingType: number) {
    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          dayRating: dayRatingType,
        };
      }),
    };

    saveLastDayRating(tenant, authContext.user?.uid, currentDayDateMoment.format('YYYY-MM-DD'), dayRatingType);

    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      true,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
    history.replace({ state: {} });

    toast.success('Die Bewertung wurde erfolgreich gespeichert!');
  }

  const closeHealthWorkout = () => {
    setTriggeredStepCount(0);
    setTriggeredWorkoutCalories(0);
    setTriggeredWorkoutMinutes(0);
    setTriggerUpdate(false);
    setNewWorkoutOverlayClass('hidden');
  };

  const saveNewCaloriesForHealth = () => {
    setTriggeredStepCount(0);
    setTriggeredWorkoutCalories(0);
    setTriggeredWorkoutMinutes(0);
    saveMakroDayChange();
    setNewWorkoutOverlayClass('hidden');
  };

  const updateHealthData = async (stepCount: number, workoutMinutes: number, workoutCalories: number) => {
    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          healthData: {
            stepCount,
            workoutMinutes,
            workoutCalories,
          },
        };
      }),
    };

    // Only update if anything changed
    if (
      (stepCount > 0 || (workoutMinutes > 0 && workoutCalories > 0)) &&
      userData?.health !== undefined &&
      (currentDayItem?.healthData === undefined ||
        (currentDayItem?.healthData?.stepCount !== undefined &&
          currentDayItem?.healthData?.workoutMinutes !== undefined &&
          currentDayItem?.healthData?.workoutCalories !== undefined &&
          (currentDayItem?.healthData?.stepCount !== stepCount ||
            currentDayItem?.healthData?.workoutMinutes < workoutMinutes ||
            currentDayItem?.healthData?.workoutCalories < workoutCalories)))
    ) {
      await savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        true,
        setCurrentPlan,
        tenant
      );

      const currentKcal = Math.round(parseFloat(userData.calories));

      const updatedKcal = currentKcal + triggeredWorkoutCalories;

      if (
        triggeredWorkoutCalories > 0 &&
        triggeredWorkoutMinutes > 0 &&
        currentDayItem?.healthData?.workoutCalories === undefined
      ) {
        updateMakroCalories({ target: { value: updatedKcal.toString() } });

        if (
          tenantData?.settings?.functions?.caloriesGoal === undefined ||
          tenantData?.settings?.functions?.caloriesGoal === true
        ) {
          setNewWorkoutOverlayClass('block');
        }
      } else if (
        currentDayItem?.healthData?.workoutCalories !== undefined &&
        currentDayItem?.healthData?.workoutCalories < triggeredWorkoutCalories &&
        currentDayItem?.healthData?.workoutMinutes < triggeredWorkoutMinutes
      ) {
        updateMakroCalories({ target: { value: updatedKcal.toString() } });

        if (
          tenantData?.settings?.functions?.caloriesGoal === undefined ||
          tenantData?.settings?.functions?.caloriesGoal === true
        ) {
          setNewWorkoutOverlayClass('block');
        }
      } else {
        setTriggeredStepCount(0);
        setTriggeredWorkoutCalories(0);
        setTriggeredWorkoutMinutes(0);
      }
    }

    setTriggerUpdate(false);
    history.replace({ state: {} });
  };

  const changeRecipeName = (event: any) => {
    setCurrentRecipeName(event.target.value);
  };

  const changeUserViewExecute = async () => {
    setChangeSwitchLoading(true);
    if (userData?.role === 5 && (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser)) {
      const updateUser = await changeUserView(
        userData,
        authContext.user?.uid,
        tenant,
        userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4
      );

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        authContext.setOriginalUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    } else if (userData?.isAdminUser || userData?.role === 4 || userData?.role === 6) {
      const updateUser = await changeUserView(userData, authContext.user?.uid, tenant, 5);

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        authContext.setOriginalUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    }
  };

  const saveUserGeneratedRecipe = () => {
    if (currentRecipeName.length > 0) {
      const objectAfterDelete = deleteBatch(true);
      let thisPortionG = 0;

      const thisRecipeUid = generateRandomUid();

      const thisIngredientArray = [] as any;
      let thisMealType = '';

      checkBoxArray.forEach(function (key: any) {
        const thisItem = currentDayItem?.[key.mealType].ingredients.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisMealType = key.mealType;

        thisIngredientArray.push(thisItem);
      });

      const calculatedKcalTotalFromIngredients = thisIngredientArray.reduce((prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.kcal_total);
      }, 0);

      const calculatedCarbohydratesTotalFromIngredients = thisIngredientArray.reduce((prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.carbohydrates_total);
      }, 0);

      const calculatedProteinTotalFromIngredients = thisIngredientArray.reduce((prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.protein_total);
      }, 0);

      const calculatedFatTotalFromIngredients = thisIngredientArray.reduce((prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.fat_total);
      }, 0);

      thisIngredientArray.map((ingridient: any) => {
        if (ingridient.piece !== 'g' && ingridient.piece !== 'ml') {
          thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g));
        } else {
          thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount));
        }
        return null;
      });

      let newRecipe = {
        amount: 1,
        piece: 'Portion',
        ingredients: [...thisIngredientArray],
        description: [{ id: 0, text: 'Bitte gebe hier einen Text ein!' }],
        category: 'Specials',
        name: currentRecipeName,
        uid: thisRecipeUid,
        id: Math.random(),
        created: moment().unix(),
        userRecipe: true,
        difficulty_level: 1,
        imageUrl: '',
        portion_g: thisPortionG.toString(),
        kcal_total: calculatedKcalTotalFromIngredients,
        carbohydrates_total: calculatedCarbohydratesTotalFromIngredients,
        protein_total: calculatedProteinTotalFromIngredients,
        fat_total: calculatedFatTotalFromIngredients,
        flexitarian: true,
        vegetarian: true,
        ketogen: true,
        vegan: true,
        celery: false,
        egg: false,
        fructose: false,
        histamine: false,
        lactose: false,
        nuts: false,
        peanuts: false,
        sorbitol: false,
        soy: false,
        thermomix: false,
        wheat: false,
        gluten: false,
      };

      let newRecipeUserObject = {
        ingredients: [...thisIngredientArray],
        description: [{ id: 0, text: 'Bitte gebe hier einen Text ein!' }],
        category: 'Specials',
        name: currentRecipeName,
        uid: thisRecipeUid,
        created: moment().unix(),
        userRecipe: true,
        difficulty_level: 1,
        imageUrl: '',
        portion_g: thisPortionG.toString(),
        kcal_total: calculatedKcalTotalFromIngredients,
        carbohydrates_total: calculatedCarbohydratesTotalFromIngredients,
        protein_total: calculatedProteinTotalFromIngredients,
        fat_total: calculatedFatTotalFromIngredients,
        flexitarian: true,
        vegetarian: true,
        ketogen: true,
        vegan: true,
        celery: false,
        egg: false,
        fructose: false,
        histamine: false,
        lactose: false,
        nuts: false,
        peanuts: false,
        sorbitol: false,
        soy: false,
        thermomix: false,
        wheat: false,
        gluten: false,
      };

      newRecipe = {
        ...newRecipe,
        ...eatingHabitsArray,
      };

      newRecipeUserObject = {
        ...newRecipe,
        ...eatingHabitsArray,
      };

      const newColumnsFavorite = {
        uid: thisRecipeUid,
        name: currentRecipeName,
        origId: thisRecipeUid,
      };

      formOfNutrition.forEach(function (key) {
        thisIngredientArray.forEach(function (keyIngredient: any) {
          const getIngridient = ingredientList?.filter(
            (item: any) => item.name.toLowerCase() === keyIngredient.name.toLowerCase()
          )[0] as any;

          if (getIngridient !== undefined) {
            if (getIngridient[key.toLowerCase()] === false) {
              newRecipe = {
                ...newRecipe,
                [key.toLowerCase()]: false,
              };

              newRecipeUserObject = {
                ...newRecipeUserObject,
                [key.toLowerCase()]: false,
              };
            }
          }
        });
      });

      intolerancesOption.forEach(function (key) {
        thisIngredientArray.forEach(function (keyIngredient: any) {
          const getIngridient = ingredientList?.filter(
            (item: any) => item.name.toLowerCase() === keyIngredient.name.toLowerCase()
          )[0] as any;

          if (getIngridient !== undefined) {
            if (getIngridient[key.toLowerCase()] === true) {
              newRecipe = {
                ...newRecipe,
                [key.toLowerCase()]: true,
              };

              newRecipeUserObject = {
                ...newRecipeUserObject,
                [key.toLowerCase()]: true,
              };
            }
          }
        });
      });

      const newColumns = {
        ...objectAfterDelete,
        dayEntries: objectAfterDelete?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            kcal_total: parseFloat(entries.kcal_total) + parseFloat(calculatedKcalTotalFromIngredients),
            carbohydrates_total:
              parseFloat(entries.carbohydrates_total) + parseFloat(calculatedCarbohydratesTotalFromIngredients),
            protein_total: parseFloat(entries.protein_total) + parseFloat(calculatedProteinTotalFromIngredients),
            fat_total: parseFloat(entries.fat_total) + parseFloat(calculatedFatTotalFromIngredients),
            [thisMealType]: {
              ...entries[thisMealType],
              kcal_total: parseFloat(entries[thisMealType].kcal_total) + parseFloat(calculatedKcalTotalFromIngredients),
              carbohydrates_total:
                parseFloat(entries[thisMealType].carbohydrates_total) +
                parseFloat(calculatedCarbohydratesTotalFromIngredients),
              protein_total:
                parseFloat(entries[thisMealType].protein_total) + parseFloat(calculatedProteinTotalFromIngredients),
              fat_total: parseFloat(entries[thisMealType].fat_total) + parseFloat(calculatedFatTotalFromIngredients),
              recipes: [newRecipe, ...entries[thisMealType].recipes],
            },
          };
        }),
      };

      addUserRecipe(newRecipeUserObject, authContext.user?.uid, thisRecipeUid, newColumnsFavorite, tenant);

      setNewRecipeClass('hidden');
      savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, undefined, tenant);
    } else {
      toast.error('Bitte gebe einen Namen für das Rezept ein!');
    }
  };

  const openUserGeneratedRecipePopup = () => {
    if (newRecipeRef.current) {
      newRecipeRef.current.value = '';
    }

    setEatingHabitsArray([]);
    setNewRecipeClass('block');
  };

  const triggerAddIngredientsToFavorites = async () => {
    const updateObject = currentDayItem?.[currentMealType].ingredients?.filter(
      (ingredientItem: any) => ingredientItem.id === currentIngredientId
    )[0];

    const thisFirebaseObject = {
      ...updateObject,
      dateAdded: moment().unix(),
    };

    await addFavoriteIngredients(
      thisFirebaseObject,
      authContext.user?.uid,
      tenant,
      ingredientFavoriteStatus === false ? 'add' : 'delete'
    );

    setUpdateTriggerFavorites(updateTriggerFavorites + 1);
  };

  const updateMakroCalories = (event: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter(st => st.value === currentMakroChangeType)[0].carbohydrates;
    const thisProteinPercent = makroTypes.filter(st => st.value === currentMakroChangeType)[0].protein;
    const thisFatPercent = makroTypes.filter(st => st.value === currentMakroChangeType)[0].fat;

    if (currentMakroChangeType === 'standard') {
      thisCalculatedCarbohydrates = Math.round((event.target.value * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else if (currentMakroChangeType === 'user') {
      thisCalculatedCarbohydrates = Math.round(
        (event.target.value * parseFloat(currentMakroChangeCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round(
        (event.target.value * parseFloat(currentMakroChangeProteinPercent)) / 100 / 4.1
      );
      thisCalculatedFat = Math.round((event.target.value * parseFloat(currentMakroChangeFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      thisCalculatedCarbohydrates = Math.round((event.target.value * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeCalories(event.target.value);
  };

  const changeSwitch = () => {
    if (currentSwitchIndex === 0) {
      setCurrentSection('week');
      setCurrentSwitchIndex(1);
      setCurrentWeekIndex(0);
    } else {
      setCurrentSection('day');
      setCurrentSwitchIndex(0);
    }
  };

  const dayBackward = () => {
    // Reset triggered health values
    setTriggeredStepCount(0);
    setTriggeredWorkoutCalories(0);
    setTriggeredWorkoutMinutes(0);
    setTriggerUpdate(false);

    setCurrentDayDateMoment(moment(currentDayDateMoment).subtract(+1, 'days'));

    const thisDate = moment(currentDayDateMoment)
      .subtract(+1, 'days')
      .unix();
    let thisDayEntriesData = [] as any;

    const thisObject = {
      breakfast: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Frühstück',
      },
      lunch: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Mittagessen',
      },
      dinner: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Abendessen',
      },
      snacks: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Snacks',
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      id: 1,
      name: 'Tag 1',
      date: {
        seconds: thisDate,
      },
    };

    thisDayEntriesData = [thisObject];

    const newColumns = {
      startDate: {
        seconds: thisDate,
      },
      endDate: {
        seconds: thisDate,
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      dayEntries: thisDayEntriesData,
      protein_total: 0,
      fat_total: 0,
      imageUrl: '',
      ketogen: false,
      vegan: false,
      vegetarian: false,
      flexitarian: true,
      egg: false,
      fructose: false,
      histamine: false,
      lactose: false,
      nuts: false,
      peanuts: false,
      sorbitol: false,
      soy: false,
    };

    if (currentPlanExists) {
      // alle pläne checken
      const thisItem = currentPlan.dayEntries.filter(
        (item: any) =>
          moment.unix(item.date.seconds).utc().format('YYYYMMDD') ===
          moment(currentDayDateMoment)
            .subtract(+1, 'days')
            .format('YYYYMMDD')
      )[0];

      if (thisItem !== undefined) {
        setCurrentPlanExists(true);
        setCurrentDayItem(thisItem);
        setCurrentDayId(thisItem.id);
        if (thisItem.waterCounter !== undefined) {
          setCurrentWaterCounterValue(thisItem.waterCounter);
        } else {
          setCurrentWaterCounterValue(0);
        }
      } else {
        setCurrentPlanExists(false);
        setCurrentWaterCounterValue(0);
        setCurrentDayItem([]);
        setCurrentDayId(1);
        setCurrentPlan(newColumns);
        checkCurrentPlanExists(moment(currentDayDateMoment).subtract(+1, 'days'));
      }
    } else {
      setCurrentPlanExists(false);
      setCurrentWaterCounterValue(0);
      setCurrentDayItem([]);
      setCurrentDayId(1);
      setCurrentPlan(newColumns);
      checkCurrentPlanExists(moment(currentDayDateMoment).subtract(+1, 'days'));
    }

    setCurrentDayLoaded(false);
    setRefreshTransition(true);
  };

  const dayForward = () => {
    // Reset triggered health values
    setTriggeredStepCount(0);
    setTriggeredWorkoutCalories(0);
    setTriggeredWorkoutMinutes(0);
    setTriggerUpdate(false);

    setCurrentDayDateMoment(moment(currentDayDateMoment).subtract(-1, 'days'));

    let thisDayEntriesData = [] as any;
    const thisDate = moment(currentDayDateMoment).subtract(-1, 'days').unix();

    const thisObject = {
      breakfast: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Frühstück',
      },
      lunch: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Mittagessen',
      },
      dinner: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Abendessen',
      },
      snacks: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Snacks',
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      id: 1,
      name: 'Tag 1',
      date: {
        seconds: thisDate,
      },
    };

    thisDayEntriesData = [thisObject];

    const newColumns = {
      startDate: {
        seconds: thisDate,
      },
      endDate: {
        seconds: thisDate,
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      dayEntries: thisDayEntriesData,
      protein_total: 0,
      fat_total: 0,
      imageUrl: '',
      ketogen: false,
      vegan: false,
      vegetarian: false,
      flexitarian: true,
      egg: false,
      fructose: false,
      histamine: false,
      lactose: false,
      nuts: false,
      peanuts: false,
      sorbitol: false,
      soy: false,
    };

    if (currentPlanExists) {
      const thisItem = currentPlan.dayEntries.filter(
        (item: any) =>
          moment.unix(item.date.seconds).utc().format('YYYYMMDD') ===
          moment(currentDayDateMoment).subtract(-1, 'days').format('YYYYMMDD')
      )[0];

      if (thisItem !== undefined) {
        setCurrentPlanExists(true);
        setCurrentDayItem(thisItem);
        setCurrentDayId(thisItem.id);
        if (thisItem.waterCounter !== undefined) {
          setCurrentWaterCounterValue(thisItem.waterCounter);
        } else {
          setCurrentWaterCounterValue(0);
        }
      } else {
        setCurrentPlanExists(false);
        setCurrentDayItem([]);
        setCurrentDayId(1);
        setCurrentWaterCounterValue(0);
        setCurrentPlan(newColumns);
        checkCurrentPlanExists(moment(currentDayDateMoment).subtract(-1, 'days'));
      }
    } else {
      setCurrentPlanExists(false);
      setCurrentWaterCounterValue(0);
      setCurrentDayItem([]);
      setCurrentDayId(1);
      setCurrentPlan(newColumns);
      checkCurrentPlanExists(moment(currentDayDateMoment).subtract(-1, 'days'));
    }

    setRefreshTransition(true);
    setCurrentDayLoaded(false);
  };

  // Change eating habits items
  const setEatingHabits = (status: boolean, item: any): void => {
    const newColumns = {
      ...eatingHabitsArray,
      [item.toLowerCase()]: status,
    };
    setEatingHabitsArray(newColumns);
  };

  // Toggle eating habits area
  const toggleEatingHabits = (): void => {
    if (eatingHabitsClass === 'hidden') {
      setEatingHabitsClass('block');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setEatingHabitsClass('hidden');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const changeDayFromDate = (date: any) => {
    // Reset triggered health values
    setTriggeredStepCount(0);
    setTriggeredWorkoutCalories(0);
    setTriggeredWorkoutMinutes(0);
    setTriggerUpdate(false);

    setCurrentDayDateMoment(moment(date));

    let thisDayEntriesData = [] as any;
    const thisDate = moment(date).unix();

    const thisObject = {
      breakfast: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Frühstück',
      },
      lunch: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Mittagessen',
      },
      dinner: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Abendessen',
      },
      snacks: {
        ingredients: [],
        recipes: [],
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        label: 'Snacks',
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      id: 1,
      name: 'Tag 1',
      date: {
        seconds: thisDate,
      },
    };

    thisDayEntriesData = [thisObject];

    const newColumns = {
      startDate: {
        seconds: thisDate,
      },
      endDate: {
        seconds: thisDate,
      },
      kcal_total: 0,
      carbohydrates_total: 0,
      dayEntries: thisDayEntriesData,
      protein_total: 0,
      fat_total: 0,
      imageUrl: '',
      ketogen: false,
      vegan: false,
      vegetarian: false,
      flexitarian: true,
      egg: false,
      fructose: false,
      histamine: false,
      lactose: false,
      nuts: false,
      peanuts: false,
      sorbitol: false,
      soy: false,
    };

    if (currentPlanExists) {
      const thisItem = currentPlan.dayEntries.filter(
        (item: any) => moment.unix(item.date.seconds).utc().format('YYYYMMDD') === moment(date).format('YYYYMMDD')
      )[0];

      if (thisItem !== undefined) {
        setCurrentPlanExists(true);
        setCurrentDayItem(thisItem);
        setCurrentDayId(thisItem.id);
        if (thisItem.waterCounter !== undefined) {
          setCurrentWaterCounterValue(thisItem.waterCounter);
        } else {
          setCurrentWaterCounterValue(0);
        }
      } else {
        setCurrentPlanExists(false);
        setCurrentDayItem([]);
        setCurrentDayId(1);
        setCurrentWaterCounterValue(0);
        setCurrentPlan(newColumns);
        checkCurrentPlanExists(moment(date));
      }
    } else {
      setCurrentPlanExists(false);
      setCurrentWaterCounterValue(0);
      setCurrentDayItem([]);
      setCurrentDayId(1);
      setCurrentPlan(newColumns);
      checkCurrentPlanExists(moment(date));
    }

    setRefreshTransition(true);
    setCurrentDayLoaded(false);
  };

  const getWaterGoal = (getType: string) => {
    if (currentDayItem?.waterGoal !== undefined) {
      if (getType === 'number') {
        return currentDayItem?.waterGoal;
      }
      return 'aktuellen Tag';
    }
    if (userData !== undefined && userData?.settings?.waterGoal === undefined) {
      if (getType === 'number') {
        return 35 * parseFloat(userData.bodyWeight);
      }
      return 'Profil';
    }
    if (userData !== undefined && userData?.settings?.waterGoal !== undefined) {
      if (getType === 'number') {
        return userData.settings.waterGoal;
      }
      return 'Profil';
    }
    return 0;
  };

  const getFavoriteIngredientStatus = () => {
    const getIngredientObject = currentDayItem?.[currentMealType].ingredients?.filter(
      (ingredientItem: any) => ingredientItem.id === currentIngredientId
    )[0];

    if (getIngredientObject?.food_id !== undefined) {
      const getIngredientFromFavoritesList = favoritesIngredientsList?.filter(
        item => item.food_id === getIngredientObject.food_id
      )[0];

      if (getIngredientFromFavoritesList !== undefined) {
        setIngredientFavoriteStatus(true);
      } else {
        setIngredientFavoriteStatus(false);
      }
    } else {
      const getIngredientFromFavoritesList = favoritesIngredientsList?.filter(
        item => item.name === getIngredientObject.name
      )[0];

      if (getIngredientFromFavoritesList !== undefined) {
        setIngredientFavoriteStatus(true);
      } else {
        setIngredientFavoriteStatus(false);
      }
    }
  };

  const saveUserSettingsToFirebase = async () => {
    try {
      const profile: UserInfo = authContext.userData as UserInfo;
      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .set({
          ...profile,
          settings: {
            waterGoal: currentWaterChangeValue,
          },
        });
      authContext.setUserData((prevProfile: UserInfo) => ({
        ...prevProfile,
        settings: {
          waterGoal: currentWaterChangeValue,
        },
      }));

      authContext.setOriginalUserData((prevProfile: UserInfo) => ({
        ...prevProfile,
        settings: {
          waterGoal: currentWaterChangeValue,
        },
      }));
      toast.success('Dein Profil wurde erfolgreich gespeichert!');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const changeWaterGoal = (goalType: string) => {
    if (currentWaterChangeValue >= 1000) {
      handleOpenClosePopups(setWaterEditPopupClass, '', 'close');

      if (goalType === 'day') {
        const newColumns = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              waterGoal: currentWaterChangeValue,
            };
          }),
        };

        const thisNewDayItem = newColumns?.dayEntries.filter((item: any) => item.id === currentDayId)[0];

        setCurrentDayItem(thisNewDayItem);
        // setCurrentPlan(newColumns);
        savePlanChangesToFirebase(
          userData,
          newColumns,
          newColumns.uid,
          authContext.user?.uid,
          true,
          setCurrentPlan,
          tenant
        );
        history.replace({ state: {} });
      } else {
        saveUserSettingsToFirebase();
      }
    } else {
      toast.error('Dein Ziel ist zu gering. Bitte gebe mindestens 1000 ml ein!');
    }
  };

  const updateWaterGoal = (event: any) => {
    setCurrentWaterChangeValue(event.target.value);
  };

  const clearDay = () => {
    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayItem?.id) return entries;

        const { notice, waterCounter, dayRating, ...rest } = entries;

        return {
          ...rest,
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          breakfast: {
            ...entries.breakfast,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
            manualItems: [],
            deactivatedItems: [],
          },
          lunch: {
            ...entries.lunch,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
            manualItems: [],
            deactivatedItems: [],
          },
          dinner: {
            ...entries.dinner,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
            manualItems: [],
            deactivatedItems: [],
          },
          snacks: {
            ...entries.snacks,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
            manualItems: [],
            deactivatedItems: [],
          },
        };
      }),
    };

    setClearDayPopupClass('hidden');
    toast.success('Tag wurde erfolgreich geleert!');
    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      false,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
    const thisNewDayItem = newColumns?.dayEntries.filter((item: any) => item.id === currentDayItem?.id)[0];

    setCurrentDayItem(thisNewDayItem);
  };

  function getCurrentMealTotal(makroType: string, mealType: string) {
    const thisItem = currentPlan?.dayEntries?.filter((item: any) => item.id === currentDayId)[0][mealType];

    if (makroType === 'kcal') {
      return thisItem?.kcal_total;
    }
    if (makroType === 'carbohydrates') {
      return thisItem?.carbohydrates_total;
    }
    if (makroType === 'protein') {
      return thisItem?.protein_total;
    }
    if (makroType === 'fat') {
      return thisItem?.fat_total;
    }

    return '0';
  }

  const closeAddPlanPopup = () => {
    handleOpenClosePopups(setCreatePlanPopupClass, '', 'close');
    setCurrentCreatePlanStep('1');
  };

  const changeUserFat = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedFat = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 9.3);

    setCurrentMakroChangeFatPercent(thisValue);
    setCurrentMakroChangeFat(thisCalculatedFat);
  };

  const changeUserProtein = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedProtein = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeProteinPercent(thisValue);
    setCurrentMakroChangeProtein(thisCalculatedProtein);
  };

  const changeUserCarbohydrates = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedCarbohydrates = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeCarbohydratesPercent(thisValue);
    setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
  };

  const openMealPopup = (mealType: any, mealLabel: any) => {
    setActionClass('hidden');
    setViewMealPopupClass('block');
    setCurrentMealType(mealType);
    setChangedMealType(mealType);
    setCurrentMealLabel(mealLabel);
    setCheckBoxArray([]);
  };

  const openIngredientPopup = (mealType: any) => {
    setIngredientSearchPopupClass('block');
    setCurrentMealType(mealType);
  };

  const openMobileDatePicker = () => {
    if (datepickerRef.current) {
      datepickerRef.current.setFocus(true);
    }
  };

  const openGenerateMealPopup = () => {
    setPlanGenerateType('meal');
    setViewMealPopupClass('hidden');
    setGenerateDayPopupClass('block');
  };

  const openCopyPopup = () => {
    if (currentDayItem?.[currentMealType]?.ingredients?.length > 0) {
      setCopyIngredientsArray(currentDayItem?.[currentMealType]?.ingredients);
    } else {
      setCopyIngredientsArray([]);
    }
    if (currentDayItem?.[currentMealType]?.recipes?.length > 0) {
      setCopyRecipeArray(currentDayItem?.[currentMealType]?.recipes);
    } else {
      setCopyRecipeArray([]);
    }
    if (currentDayItem?.[currentMealType]?.manualItems?.length > 0) {
      setCopyManualItemsArray(currentDayItem?.[currentMealType]?.manualItems);
    } else {
      setCopyManualItemsArray([]);
    }

    setCopyStatus('1');
    setStartDate(null);
    setStartDateMultiCopy(null);
    setEndDateMultiCopy(null);
    setWeekDayCopyArray([]);
    setCurrentCopyTypeValue('date');
    setViewMealPopupClass('hidden');
    setCopyItemOverlayClass('block');
  };

  const copyMultiItems = async (copyType: string) => {
    setMultiCopyPending(true);
    const userId = authContext.user?.uid;

    if (startDateMultiCopy === null || endDateMultiCopy === null) {
      toast.error('Bitte gebe ein Start- und Enddatum ein!');
      setMultiCopyPending(false);
      return;
    }

    if (moment(startDateMultiCopy).unix() > moment(endDateMultiCopy).unix()) {
      toast.error('Das Startdatum darf nicht älter sein als das Enddatum!');
      setMultiCopyPending(false);
      return;
    }

    if (weekDayCopyArray.length === 0) {
      toast.error('Bitte wähle mindestens einen Wochentag aus!');
      setMultiCopyPending(false);
      return;
    }

    if (mealTypesCopyArray.length === 0) {
      toast.error('Bitte wähle mindestens eine Mahlzeit aus!');
      setMultiCopyPending(false);
      return;
    }

    // Erstelle ein Moment-Objekt für das Start- und Enddatum
    const start = moment(startDateMultiCopy);
    const end = moment(endDateMultiCopy);

    // Berechne die Differenz in Tagen
    const differenceInDays = end.diff(start, 'days');

    // Prüfe, ob die Differenz maximal 30 Tage beträgt
    if (differenceInDays > 30) {
      toast.error(
        'Der Zeitraum zwischen den beiden Daten beträgt mehr als 30 Tage. Bitte wählen Sie ein Enddatum, das innerhalb von 30 Tagen nach dem Startdatum liegt.'
      );
      setMultiCopyPending(false);
      return;
    }

    // Extrahiere die Wochentage aus den Objekten
    const weekdays = weekDayCopyArray.map((weekdayObj: any) => weekdayObj.value);

    // Array, um die passenden Datums zu speichern
    const dates = [] as any;

    // Iteriere durch die Daten von Start bis Ende
    while (start <= end) {
      // Überprüfe, ob der aktuelle Tag in den ausgewählten Wochentagen ist
      if (weekdays.includes(start.day())) {
        const dateToAdd = start.clone().format('YYYY-MM-DD');
        if (!dates.includes(dateToAdd)) {
          dates.push(dateToAdd); // Füge das Datum zur Liste hinzu
        }
      }
      start.add(1, 'days'); // Gehe zum nächsten Tag
    }

    const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
    const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
    const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

    const thisIngredientArray = [] as any;
    const thisRecipesArray = [] as any;
    const thisManualItemsArray = [] as any;

    let thisCopyArray = [
      {
        breakfast: {
          ingredients: [],
          recipes: [],
          manualItems: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
        },
        lunch: {
          ingredients: [],
          recipes: [],
          manualItems: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
        },
        dinner: {
          ingredients: [],
          recipes: [],
          manualItems: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
        },
        snacks: {
          ingredients: [],
          recipes: [],
          manualItems: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
        },
      },
    ] as any;

    if (copyType === 'batch') {
      if (splitArrayIngredients.length > 0) {
        splitArrayIngredients.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].ingredients.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem !== undefined) {
            thisIngredientArray.push(thisItem);

            if (checkBoxArrayOnlyOneMealType) {
              mealTypesCopyArray.forEach(function (subKey: any) {
                // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
                thisCopyArray[0][subKey.value].ingredients.push(thisItem);

                // Die Felder aktualisieren
                thisCopyArray[0][subKey.value].kcal_total =
                  parseFloat(thisCopyArray[0][subKey.value].kcal_total) + parseFloat(thisItem.kcal_total);
                thisCopyArray[0][subKey.value].carbohydrates_total =
                  parseFloat(thisCopyArray[0][subKey.value].carbohydrates_total) +
                  parseFloat(thisItem.carbohydrates_total);
                thisCopyArray[0][subKey.value].protein_total =
                  parseFloat(thisCopyArray[0][subKey.value].protein_total) + parseFloat(thisItem.protein_total);
                thisCopyArray[0][key.mealType].fat_total =
                  parseFloat(thisCopyArray[0][subKey.value].fat_total) + parseFloat(thisItem.fat_total);
              });
            } else {
              // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
              thisCopyArray[0][key.mealType].ingredients.push(thisItem);

              // Die Felder aktualisieren
              thisCopyArray[0][key.mealType].kcal_total =
                parseFloat(thisCopyArray[0][key.mealType].kcal_total) + parseFloat(thisItem.kcal_total);
              thisCopyArray[0][key.mealType].carbohydrates_total =
                parseFloat(thisCopyArray[0][key.mealType].carbohydrates_total) +
                parseFloat(thisItem.carbohydrates_total);
              thisCopyArray[0][key.mealType].protein_total =
                parseFloat(thisCopyArray[0][key.mealType].protein_total) + parseFloat(thisItem.protein_total);
              thisCopyArray[0][key.mealType].fat_total =
                parseFloat(thisCopyArray[0][key.mealType].fat_total) + parseFloat(thisItem.fat_total);
            }
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }

      if (splitArrayRecipes.length > 0) {
        splitArrayRecipes.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].recipes?.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem !== undefined) {
            thisRecipesArray.push(thisItem);

            if (checkBoxArrayOnlyOneMealType) {
              mealTypesCopyArray.forEach(function (subKey: any) {
                // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
                thisCopyArray[0][subKey.value].recipes.push(thisItem);

                // Die Felder aktualisieren
                thisCopyArray[0][subKey.value].kcal_total =
                  parseFloat(thisCopyArray[0][subKey.value].kcal_total) + parseFloat(thisItem.kcal_total);
                thisCopyArray[0][subKey.value].carbohydrates_total =
                  parseFloat(thisCopyArray[0][subKey.value].carbohydrates_total) +
                  parseFloat(thisItem.carbohydrates_total);
                thisCopyArray[0][subKey.value].protein_total =
                  parseFloat(thisCopyArray[0][subKey.value].protein_total) + parseFloat(thisItem.protein_total);
                thisCopyArray[0][subKey.value].fat_total =
                  parseFloat(thisCopyArray[0][subKey.value].fat_total) + parseFloat(thisItem.fat_total);
              });
            } else {
              // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
              thisCopyArray[0][key.mealType].recipes.push(thisItem);

              // Die Felder aktualisieren
              thisCopyArray[0][key.mealType].kcal_total =
                parseFloat(thisCopyArray[0][key.mealType].kcal_total) + parseFloat(thisItem.kcal_total);
              thisCopyArray[0][key.mealType].carbohydrates_total =
                parseFloat(thisCopyArray[0][key.mealType].carbohydrates_total) +
                parseFloat(thisItem.carbohydrates_total);
              thisCopyArray[0][key.mealType].protein_total =
                parseFloat(thisCopyArray[0][key.mealType].protein_total) + parseFloat(thisItem.protein_total);
              thisCopyArray[0][key.mealType].fat_total =
                parseFloat(thisCopyArray[0][key.mealType].fat_total) + parseFloat(thisItem.fat_total);
            }
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }

      if (splitArrayManualItems.length > 0) {
        splitArrayManualItems.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].manualItems?.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem !== undefined) {
            thisManualItemsArray.push(thisItem);

            if (checkBoxArrayOnlyOneMealType) {
              mealTypesCopyArray.forEach(function (subKey: any) {
                // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
                thisCopyArray[0][subKey.value].manualItems.push(thisItem);

                // Die Felder aktualisieren
                thisCopyArray[0][subKey.value].kcal_total =
                  parseFloat(thisCopyArray[0][subKey.value].kcal_total) + parseFloat(thisItem.kcal_total);
                thisCopyArray[0][subKey.value].carbohydrates_total =
                  parseFloat(thisCopyArray[0][subKey.value].carbohydrates_total) +
                  parseFloat(thisItem.carbohydrates_total);
                thisCopyArray[0][subKey.value].protein_total =
                  parseFloat(thisCopyArray[0][subKey.value].protein_total) + parseFloat(thisItem.protein_total);
                thisCopyArray[0][subKey.value].fat_total =
                  parseFloat(thisCopyArray[0][subKey.value].fat_total) + parseFloat(thisItem.fat_total);
              });
            } else {
              // Das neue Objekt in das entsprechende `ingredients`-Array einfügen
              thisCopyArray[0][key.mealType].manualItems.push(thisItem);

              // Die Felder aktualisieren
              thisCopyArray[0][key.mealType].kcal_total =
                parseFloat(thisCopyArray[0][key.mealType].kcal_total) + parseFloat(thisItem.kcal_total);
              thisCopyArray[0][key.mealType].carbohydrates_total =
                parseFloat(thisCopyArray[0][key.mealType].carbohydrates_total) +
                parseFloat(thisItem.carbohydrates_total);
              thisCopyArray[0][key.mealType].protein_total =
                parseFloat(thisCopyArray[0][key.mealType].protein_total) + parseFloat(thisItem.protein_total);
              thisCopyArray[0][key.mealType].fat_total =
                parseFloat(thisCopyArray[0][key.mealType].fat_total) + parseFloat(thisItem.fat_total);
            }
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }
    } else {
      let breakFastObj = null;
      let lunchObj = null;
      let dinnerObj = null;
      let snacksObj = null;

      if (mealTypesCopyArray.some((item: any) => item.value === 'breakfast')) {
        breakFastObj = currentDayItem.breakfast;
      } else {
        breakFastObj = thisCopyArray[0].breakfast;
      }

      if (mealTypesCopyArray.some((item: any) => item.value === 'lunch')) {
        lunchObj = currentDayItem.lunch;
      } else {
        lunchObj = thisCopyArray[0].lunch;
      }

      if (mealTypesCopyArray.some((item: any) => item.value === 'dinner')) {
        dinnerObj = currentDayItem.dinner;
      } else {
        dinnerObj = thisCopyArray[0].dinner;
      }

      if (mealTypesCopyArray.some((item: any) => item.value === 'snacks')) {
        snacksObj = currentDayItem.snacks;
      } else {
        snacksObj = thisCopyArray[0].snacks;
      }

      thisCopyArray = [
        {
          breakfast: {
            kcal_total: breakFastObj.kcal_total,
            carbohydrates_total: breakFastObj.carbohydrates_total,
            protein_total: breakFastObj.protein_total,
            fat_total: breakFastObj.fat_total,
            ingredients: [...breakFastObj.ingredients],
            recipes: [...breakFastObj.recipes],
            manualItems: breakFastObj.manualItems !== undefined ? [...breakFastObj.manualItems] : [],
          },
          lunch: {
            kcal_total: lunchObj.kcal_total,
            carbohydrates_total: lunchObj.carbohydrates_total,
            protein_total: lunchObj.protein_total,
            fat_total: lunchObj.fat_total,
            ingredients: [...lunchObj.ingredients],
            recipes: [...lunchObj.recipes],
            manualItems: lunchObj.manualItems !== undefined ? [...lunchObj.manualItems] : [],
          },
          dinner: {
            kcal_total: dinnerObj.kcal_total,
            carbohydrates_total: dinnerObj.carbohydrates_total,
            protein_total: dinnerObj.protein_total,
            fat_total: dinnerObj.fat_total,
            ingredients: [...dinnerObj.ingredients],
            recipes: [...dinnerObj.recipes],
            manualItems: dinnerObj.manualItems !== undefined ? [...dinnerObj.manualItems] : [],
          },
          snacks: {
            kcal_total: snacksObj.kcal_total,
            carbohydrates_total: snacksObj.carbohydrates_total,
            protein_total: snacksObj.protein_total,
            fat_total: snacksObj.fat_total,
            ingredients: [...snacksObj.ingredients],
            recipes: [...snacksObj.recipes],
            manualItems: snacksObj.manualItems !== undefined ? [...snacksObj.manualItems] : [],
          },
        },
      ] as any;

      console.log(thisCopyArray);
    }

    const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/copyMultiItems`, {
      tenant,
      uid: userId,
      weekDayList: dates,
      copyData: thisCopyArray,
    });

    setMultiCopyPending(false);

    if (res.data.success === true) {
      toast.success('Der Kopiervorgang war erfolgreich!');
    } else {
      toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
    }

    setCheckBoxArray([]);
    setCopyItemOverlayClass('hidden');
    setViewMealPopupClass('hidden');
  };

  const copyItem = (copyType: string) => {
    let updateObjectId = '' as any;
    let calculatedKcalSum = 0;
    let calculatedCarbohydratesSum = 0;
    let calculatedProteinSum = 0;
    let calculatedFatSum = 0;

    if (startDate === null) {
      toast.error('Bitte gebe ein Startdatum ein!');
      return;
    }

    // Set empty plan object
    let planObject = {
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      startDate: {
        seconds: moment(startDate, 'DD.MM.YYYY').unix(),
      },
      endDate: {
        seconds: moment(startDate, 'DD.MM.YYYY').unix(),
      },
      dayEntries: [
        {
          breakfast: {
            ingredients: [],
            recipes: [],
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            label: 'Frühstück',
          },
          lunch: {
            ingredients: [],
            recipes: [],
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            label: 'Mittagessen',
          },
          dinner: {
            ingredients: [],
            recipes: [],
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            label: 'Abendessen',
          },
          snacks: {
            ingredients: [],
            recipes: [],
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            label: 'Snacks',
          },
          id: 1,
          name: 'Tag 1',
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          date: {
            seconds: moment(startDate, 'DD.MM.YYYY').unix(),
          },
        },
      ],
    } as any;

    if (copyStatus !== 'batch' && copyStatus !== 'day') {
      copyRecipeArray.forEach(function (key: any) {
        calculatedKcalSum += key.kcal_total;
        calculatedCarbohydratesSum += key.carbohydrates_total;
        calculatedProteinSum += key.protein_total;
        calculatedFatSum += key.fat_total;
      });

      copyIngredientsArray.forEach(function (key: any) {
        calculatedKcalSum += key.kcal_total;
        calculatedCarbohydratesSum += key.carbohydrates_total;
        calculatedProteinSum += key.protein_total;
        calculatedFatSum += key.fat_total;
      });

      copyManualItemsArray.forEach(function (key: any) {
        calculatedKcalSum += key.kcal_total;
        calculatedCarbohydratesSum += key.carbohydrates_total;
        calculatedProteinSum += key.protein_total;
        calculatedFatSum += key.fat_total;
      });
    }

    // Check if plan on copy date exists
    userPlanList?.forEach(function (key) {
      const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
      const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

      const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
      const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

      const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
      const thisUserEndDate = moment(startDate);

      const thisPlanRange = momentExtended.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
      const thisUserRange = momentExtended.range(moment(thisUserStartDate), moment(thisUserEndDate));

      if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
        updateObjectId = key.uid;
        planObject = key;
      }
    });

    if (copyStatus === 'batch') {
      const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
      const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
      const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

      const thisIngredientArray = [] as any;
      const thisRecipesArray = [] as any;
      const thisManualItemsArray = [] as any;

      let copyIsValid = true;

      let newColumnsCopy = planObject;

      if (splitArrayIngredients.length > 0) {
        splitArrayIngredients.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].ingredients.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem === undefined) {
            copyIsValid = false;
          }

          newColumnsCopy = {
            ...newColumnsCopy,
            dayEntries: newColumnsCopy.dayEntries.map((entries: any) => {
              let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
              if (updateObjectId.length === 0) {
                thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
              }

              if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
              return {
                ...entries,
                kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
                [key.mealType]: {
                  ...entries[key.mealType],
                  kcal_total: parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
                  carbohydrates_total:
                    parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                  protein_total: parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
                  fat_total: parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
                  ingredients: [thisItem, ...entries[key.mealType].ingredients],
                },
              };
            }),
          };

          if (thisItem !== undefined) {
            thisIngredientArray.push(thisItem);
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }

      if (splitArrayRecipes.length > 0) {
        splitArrayRecipes.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].recipes?.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem === undefined) {
            copyIsValid = false;
          }

          newColumnsCopy = {
            ...newColumnsCopy,
            dayEntries: newColumnsCopy.dayEntries.map((entries: any) => {
              let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
              if (updateObjectId.length === 0) {
                thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
              }

              if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
              return {
                ...entries,
                kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
                [key.mealType]: {
                  ...entries[key.mealType],
                  kcal_total: parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
                  carbohydrates_total:
                    parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                  protein_total: parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
                  fat_total: parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
                  recipes: [thisItem, ...entries[key.mealType].recipes],
                },
              };
            }),
          };

          if (thisItem !== undefined) {
            thisRecipesArray.push(thisItem);
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }

      if (splitArrayManualItems.length > 0) {
        splitArrayManualItems.forEach(function (key: any) {
          let thisItem = currentDayItem?.[key.mealType].manualItems?.filter(
            (item: any) => parseFloat(item.id) === parseFloat(key.id)
          )[0];

          thisItem = {
            ...thisItem,
            id: Math.random(),
          };

          if (thisItem === undefined) {
            copyIsValid = false;
          }

          newColumnsCopy = {
            ...newColumnsCopy,
            dayEntries: newColumnsCopy.dayEntries.map((entries: any) => {
              let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
              if (updateObjectId.length === 0) {
                thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
              }

              if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
              return {
                ...entries,
                kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
                [key.mealType]: {
                  ...entries[key.mealType],
                  kcal_total: parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
                  carbohydrates_total:
                    parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                  protein_total: parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
                  fat_total: parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
                  manualItems:
                    entries[key.mealType].manualItems !== undefined
                      ? [thisItem, ...entries[key.mealType].manualItems]
                      : [thisItem],
                },
              };
            }),
          };

          if (thisItem !== undefined) {
            thisManualItemsArray.push(thisItem);
          }
        });
      } else {
        setCopyIngredientsArray([]);
      }

      if (copyIsValid) {
        savePlanChangesToFirebase(
          userData,
          newColumnsCopy,
          updateObjectId,
          authContext.user?.uid,
          true,
          undefined,
          tenant
        );
        setCopyItemOverlayClass('hidden');
        setViewMealPopupClass('hidden');
        toast.success('Der Kopiervorgang war erfolgreich!');
      } else {
        toast.error('Es ist leider etwas schief gelaufen!');
      }
    } else if (copyStatus === 'day') {
      const newColumns = {
        ...planObject,
        dayEntries: planObject.dayEntries.map((entries: any) => {
          let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
          if (updateObjectId.length === 0) {
            thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
          }

          const { notice, waterCounter, dayRating, ...rest } = currentDayItem;

          if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
          return {
            ...rest,
            date: {
              seconds: entries.date.seconds,
            },
          };
        }),
      };

      savePlanChangesToFirebase(userData, newColumns, updateObjectId, authContext.user?.uid, true, undefined, tenant);
      setCopyItemOverlayClass('hidden');
      setViewMealPopupClass('hidden');
      toast.success('Der Kopiervorgang war erfolgreich!');
    } else {
      // Update and insert new objects
      const newColumns = {
        ...planObject,
        dayEntries: planObject.dayEntries.map((entries: any) => {
          let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
          if (updateObjectId.length === 0) {
            thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
          }

          if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
          return {
            ...entries,
            kcal_total: parseFloat(entries.kcal_total) + calculatedKcalSum,
            carbohydrates_total: parseFloat(entries.carbohydrates_total) + calculatedCarbohydratesSum,
            protein_total: parseFloat(entries.protein_total) + calculatedProteinSum,
            fat_total: parseFloat(entries.fat_total) + calculatedFatSum,
            [changedMealTypeCopy]: {
              ...entries[changedMealTypeCopy],
              kcal_total: parseFloat(entries[changedMealTypeCopy].kcal_total) + calculatedKcalSum,
              carbohydrates_total:
                parseFloat(entries[changedMealTypeCopy].carbohydrates_total) + calculatedCarbohydratesSum,
              protein_total: parseFloat(entries[changedMealTypeCopy].protein_total) + calculatedProteinSum,
              fat_total: parseFloat(entries[changedMealTypeCopy].fat_total) + calculatedFatSum,
              recipes: [...copyRecipeArray, ...entries[changedMealTypeCopy].recipes],
              ingredients: [...copyIngredientsArray, ...entries[changedMealTypeCopy].ingredients],
              manualItems:
                entries[changedMealTypeCopy].manualItems !== undefined
                  ? [...copyManualItemsArray, ...entries[changedMealTypeCopy].manualItems]
                  : [...copyManualItemsArray],
            },
          };
        }),
      };

      savePlanChangesToFirebase(userData, newColumns, updateObjectId, authContext.user?.uid, true, undefined, tenant);
      setCopyItemOverlayClass('hidden');
      setViewMealPopupClass('hidden');
      toast.success('Der Kopiervorgang war erfolgreich!');
    }
  };

  const openGeneratePopup = (generateType: string) => {
    setPlanGenerateType(generateType);
    handleOpenClosePopups('', setGenerateDayPopupClass, 'open');
  };

  function getPrimaryPieceValue(ingridientData: any) {
    let thisPieceValue = '';

    if (ingridientData.serving_data.serving && ingridientData.serving_data.serving instanceof Array) {
      ingridientData.serving_data.serving.map((serving: any, index: any) => {
        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          !serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.length)) ||
            `${serving.serving_description} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.indexOf('(') - 3)) ||
            `${serving.serving_description.substr(0, serving.serving_description.indexOf('(') - 3)} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        return '';
      });

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      !ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.length
          )) ||
        ingridientData.serving_data.serving.serving_description;

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
          )) ||
        ingridientData.serving_data.serving.serving_description.substr(
          0,
          ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
        );

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }
    return thisPieceValue;
  }

  const openIngredient = (mealType: string, ingredientItem: any) => {
    setChangedMealType(mealType);
    setCurrentEditIngridientKcalTotal(parseFloat(ingredientItem.kcal_total));
    setCurrentEditIngridientCarbohydratesTotal(parseFloat(ingredientItem.carbohydrates_total));
    setCurrentEditIngridientProteinTotal(parseFloat(ingredientItem.protein_total));
    setCurrentEditIngridientFatTotal(parseFloat(ingredientItem.fat_total));
    setViewMealPopupClass('hidden');
    setViewIngredientPopupClass('block');
    setCurrentMealType(mealType);
    setCurrentEditIngridientAmount(ingredientItem.amount);
    setCurrentIngredientName(ingredientItem.name);
    setCurrentIngredientId(ingredientItem.id);
    if (!ingredientItem.serving_data) {
      const thisPortionValues: any = [
        {
          value: ingredientItem.preselect_type.toLowerCase(),
          label: `${ingredientItem.preselect_type} (${ingredientItem.preselect_g}g)`,
        },
        { value: 'g', label: 'Gramm' },
      ];

      if (ingredientItem.default_piece !== undefined) {
        const thisPortionValuesWithDefaultPiece: any = [
          {
            value: ingredientItem.preselect_type.toLowerCase(),
            label: `${ingredientItem.preselect_type} (${ingredientItem.preselect_g}${ingredientItem.default_piece})`,
          },
          {
            value: ingredientItem.default_piece === 'ml' ? 'ml' : 'g',
            label: ingredientItem.default_piece === 'ml' ? 'Milliliter' : 'Gramm',
          },
        ];

        setCurrentEditIngridientPortionValues(thisPortionValuesWithDefaultPiece);
        setCurrentEditIngridientDefaultPiece(ingredientItem.default_piece);
      } else {
        setCurrentEditIngridientPortionValues(thisPortionValues);
      }

      setCurrentEditIngridientPiece(ingredientItem.piece);
    } else {
      let thisPortionValues: any;
      let thisPieceValue = '';

      if (getPrimaryPieceValue(ingredientItem).length > 0) {
        thisPortionValues = [
          {
            value: getPrimaryPieceValue(ingredientItem)?.toLowerCase(),
            label: getPrimaryPieceValue(ingredientItem),
          },
          { value: 'g', label: 'Gramm' },
        ];
        thisPieceValue = getPrimaryPieceValue(ingredientItem);
      } else {
        thisPortionValues = [{ value: 'g', label: 'Gramm' }];
        thisPieceValue = 'g';
      }

      setCurrentEditIngridientPortionValues(thisPortionValues);
      if (ingredientItem.piece === 'g' || ingredientItem.piece === 'ml') {
        setCurrentEditIngridientPiece(ingredientItem.piece);
      } else {
        setCurrentEditIngridientPiece(thisPieceValue);
      }
    }
  };

  const changeIngridientAmount = (event: any, itemObject: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    const getIngridientRecipeData = [] as any;
    const getIngridient = [] as any;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    /* getIngridient = ingredientList?.filter(
      (item: any) => item.name.toLowerCase() === currentEditIngridientName.toLowerCase()
    ); */

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) * parseFloat(itemObject.kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) * parseFloat(itemObject.fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round((parseFloat(itemObject.kcal_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientKhNew = Math.round((parseFloat(itemObject.carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientEwNew = Math.round((parseFloat(itemObject.protein_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientFtNew = Math.round((parseFloat(itemObject.fat_100g) / 100) * parseFloat(thisCurrentAmount));
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  const changeIngredientMealType = (event: any, itemObject: any) => {
    setChangedMealType(event.value);
  };

  const changeIngredientMealTypeCopy = (event: any) => {
    setChangedMealTypeCopy(event.value);
  };

  const changeIngridientPiece = (event: any, itemObject: any) => {
    const thisCurrentPiece = event.value;
    let thisCurrentPieceLabel = event.label;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPiece !== 'g' && thisCurrentPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round((parseFloat(itemObject.kcal_100g) / 100) * parseFloat(thisEditIngredientAmount));

      ingridientKhNew = Math.round(
        (parseFloat(itemObject.carbohydrates_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientEwNew = Math.round((parseFloat(itemObject.protein_100g) / 100) * parseFloat(thisEditIngredientAmount));

      ingridientFtNew = Math.round((parseFloat(itemObject.fat_100g) / 100) * parseFloat(thisEditIngredientAmount));
    }

    if (!itemObject.serving_data) {
      if (thisCurrentPieceLabel === 'Gramm') {
        setCurrentEditIngridientPiece('g');
      } else if (thisCurrentPieceLabel === 'Milliliter') {
        setCurrentEditIngridientPiece('ml');
      } else {
        setCurrentEditIngridientPiece(thisCurrentPieceLabel);
      }
    } else {
      if (event.value.includes('(')) {
        thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
      }

      if (thisCurrentPieceLabel === 'Gramm') {
        setCurrentEditIngridientPiece('g');
      } else {
        setCurrentEditIngridientPiece(event.value);
      }
    }

    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  const changeIngridientExecute = (itemObject: any) => {
    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    let newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          kcal_total: Math.round(
            parseFloat(entries.kcal_total) - itemObject.kcal_total + currentEditIngridientKcalTotal
          ),
          carbohydrates_total: Math.round(
            parseFloat(entries.carbohydrates_total) -
              itemObject.carbohydrates_total +
              currentEditIngridientCarbohydratesTotal
          ),
          protein_total: Math.round(
            parseFloat(entries.protein_total) - itemObject.protein_total + currentEditIngridientProteinTotal
          ),
          fat_total: Math.round(parseFloat(entries.fat_total) - itemObject.fat_total + currentEditIngridientFatTotal),
          [currentMealType]: {
            ...entries[currentMealType],
            kcal_total:
              parseFloat(entries[currentMealType].kcal_total) -
              itemObject.kcal_total +
              Math.round(currentEditIngridientKcalTotal),
            carbohydrates_total:
              parseFloat(entries[currentMealType].carbohydrates_total) -
              itemObject.carbohydrates_total +
              Math.round(currentEditIngridientCarbohydratesTotal),
            protein_total:
              parseFloat(entries[currentMealType].protein_total) -
              itemObject.protein_total +
              Math.round(currentEditIngridientProteinTotal),
            fat_total:
              parseFloat(entries[currentMealType].fat_total) -
              itemObject.fat_total +
              Math.round(currentEditIngridientFatTotal),
            ingredients: entries[currentMealType].ingredients.map((item: any) => {
              if (parseFloat(item.id) !== parseFloat(itemObject.id)) return item;
              return {
                ...item,
                amount: parseFloat(thisEditIngredientAmount),
                kcal_total: currentEditIngridientKcalTotal,
                carbohydrates_total: currentEditIngridientCarbohydratesTotal,
                protein_total: currentEditIngridientProteinTotal,
                fat_total: currentEditIngridientFatTotal,
                piece: currentEditIngridientPiece,
              };
            }),
          },
        };
      }),
    };

    if (currentMealType !== changedMealType) {
      // Delete from previous meal
      newColumns = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [currentMealType]: {
              ...entries[currentMealType],
              kcal_total:
                parseFloat(entries[currentMealType].kcal_total) - Math.round(currentEditIngridientKcalTotal) < 0
                  ? 0
                  : parseFloat(entries[currentMealType].kcal_total) - Math.round(currentEditIngridientKcalTotal),
              carbohydrates_total:
                parseFloat(entries[currentMealType].carbohydrates_total) -
                  Math.round(currentEditIngridientCarbohydratesTotal) <
                0
                  ? 0
                  : parseFloat(entries[currentMealType].carbohydrates_total) -
                    Math.round(currentEditIngridientCarbohydratesTotal),
              protein_total:
                parseFloat(entries[currentMealType].protein_total) - Math.round(currentEditIngridientProteinTotal) < 0
                  ? 0
                  : parseFloat(entries[currentMealType].protein_total) - Math.round(currentEditIngridientProteinTotal),
              fat_total:
                parseFloat(entries[currentMealType].fat_total) - Math.round(currentEditIngridientFatTotal) < 0
                  ? 0
                  : parseFloat(entries[currentMealType].fat_total) - Math.round(currentEditIngridientFatTotal),
              ingredients: [
                ...entries[currentMealType].ingredients.filter(
                  (item: any) => parseFloat(item.id) !== parseFloat(itemObject.id)
                ),
              ],
            },
          };
        }),
      };

      const thisNewItemObject = {
        ...itemObject,
        amount: parseFloat(thisEditIngredientAmount),
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
        piece: currentEditIngridientPiece,
      };

      // Add to new meal
      newColumns = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [changedMealType]: {
              ...entries[changedMealType],
              kcal_total: parseFloat(entries[changedMealType].kcal_total) + Math.round(currentEditIngridientKcalTotal),
              carbohydrates_total:
                parseFloat(entries[changedMealType].carbohydrates_total) +
                Math.round(currentEditIngridientCarbohydratesTotal),
              protein_total:
                parseFloat(entries[changedMealType].protein_total) + Math.round(currentEditIngridientProteinTotal),
              fat_total: parseFloat(entries[changedMealType].fat_total) + Math.round(currentEditIngridientFatTotal),
              ingredients: [...entries[changedMealType].ingredients, thisNewItemObject],
            },
          };
        }),
      };
    }

    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      toast.success('Deine Änderungen wurden erfolgreich gespeichert!');
      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        true,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumns);
    } else {
      toast.error('Die eingegebenen Daten enthielten Fehler!');
    }
    handleOpenClosePopups(setViewIngredientPopupClass, '', 'close');
    setRefreshTransition(true);
    setCurrentDayLoaded(false);
  };

  const openManualItem = (mealType: string, item: PlanManualItem) => {
    if (manualDescriptionRef.current) {
      manualDescriptionRef.current.value = '';
    }
    if (manualCaloriesRef.current) {
      manualCaloriesRef.current.value = '';
    }
    if (manualCarbohydratesRef.current) {
      manualCarbohydratesRef.current.value = '';
    }
    if (manualProteinRef.current) {
      manualProteinRef.current.value = '';
    }
    if (manualFatRef.current) {
      manualFatRef.current.value = '';
    }

    setViewMealPopupClass('hidden');
    setAddManualCaloriesClass('block');

    setManualId(item.id);
    setManualDescription(item.description);
    setManualKcal(item.kcal_total);
    setManualCarbohydrates(item.carbohydrates_total);
    setManualProtein(item.protein_total);
    setManualFat(item.fat_total);

    setCurrentMealType(mealType);
  };

  const changeManualDescription = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualDescription(thisCurrentValue);
  };

  const changeManualCalories = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualKcal(parseFloat(thisCurrentValue));
  };

  const changeManualCarbohydrates = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualCarbohydrates(parseFloat(thisCurrentValue));
  };

  const changeManualProtein = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualProtein(parseFloat(thisCurrentValue));
  };

  const changeManualFat = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualFat(parseFloat(thisCurrentValue));
  };

  const isManualAddValid = useMemo(() => {
    if (
      manualDescription.length > 0 &&
      manualKcal > 0 &&
      manualCarbohydrates > 0 &&
      manualProtein > 0 &&
      manualFat > 0
    ) {
      return true;
    }
    return false;
  }, [manualDescription, manualKcal, manualCarbohydrates, manualProtein, manualFat]);

  const changeManualItem = () => {
    const getCurrentMealItem = currentDayItem[currentMealType].manualItems.filter(
      (element: any) => parseFloat(element.id) === parseFloat(manualId)
    )[0];

    if (getCurrentMealItem === undefined) {
      toast.error('Es ist leider etwas schief gelaufen!');
    } else {
      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            kcal_total: Math.round(parseFloat(entries.kcal_total) - getCurrentMealItem.kcal_total + manualKcal),
            carbohydrates_total: Math.round(
              parseFloat(entries.carbohydrates_total) - getCurrentMealItem.carbohydrates_total + manualCarbohydrates
            ),
            protein_total: Math.round(
              parseFloat(entries.protein_total) - getCurrentMealItem.protein_total + manualProtein
            ),
            fat_total: Math.round(parseFloat(entries.fat_total) - getCurrentMealItem.fat_total + manualFat),
            [currentMealType]: {
              ...entries[currentMealType],
              kcal_total:
                parseFloat(entries[currentMealType].kcal_total) -
                getCurrentMealItem.kcal_total +
                Math.round(manualKcal),
              carbohydrates_total:
                parseFloat(entries[currentMealType].carbohydrates_total) -
                getCurrentMealItem.carbohydrates_total +
                Math.round(manualCarbohydrates),
              protein_total:
                parseFloat(entries[currentMealType].protein_total) -
                getCurrentMealItem.protein_total +
                Math.round(manualProtein),
              fat_total:
                parseFloat(entries[currentMealType].fat_total) - getCurrentMealItem.fat_total + Math.round(manualFat),
              manualItems: entries[currentMealType].manualItems.map((item: any) => {
                if (parseFloat(item.id) !== parseFloat(manualId)) return item;
                return {
                  ...item,
                  description: manualDescription,
                  kcal_total: manualKcal,
                  carbohydrates_total: manualCarbohydrates,
                  protein_total: manualProtein,
                  fat_total: manualFat,
                };
              }),
            },
          };
        }),
      };

      toast.success('Deine Änderungen wurden erfolgreich gespeichert!');
      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        true,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumns);
    }

    setAddManualCaloriesClass('hidden');
    setRefreshTransition(true);
    setCurrentDayLoaded(false);
  };

  const clickIngridientChange = () => {
    if (editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  };

  const deleteCopyItem = (copyType: string, copyId: string) => {
    if (copyType === 'ingredient') {
      const newColumns = copyIngredientsArray.filter((item: any) => parseFloat(item.id) !== parseFloat(copyId));

      setCopyIngredientsArray(newColumns);
    } else if (copyType === 'manual') {
      const newColumns = copyManualItemsArray.filter((item: any) => parseFloat(item.id) !== parseFloat(copyId));

      setCopyManualItemsArray(newColumns);
    } else if (copyType === 'recipe') {
      const newColumns = copyRecipeArray.filter((item: any) => parseFloat(item.id) !== parseFloat(copyId));

      setCopyRecipeArray(newColumns);
    } else {
      toast.error('Es ist ein Fehler aufgetreten!');
    }
  };

  const listenToScroll = () => {
    const heightToHide = document.getElementById('scrollMobile')?.getBoundingClientRect().top;
    const windowScrollHeight = document.body.scrollTop || document.documentElement.scrollTop;

    if (windowScrollHeight > heightToHide!) {
      setVisibleScrollCircles(true);
    } else {
      setVisibleScrollCircles(false);
    }
  };

  const openMoveBatchPopup = () => {
    setChangedMealType('breakfast');
    setMoveItemOverlayClass('block');
  };

  const openCopyBatchPopup = () => {
    setCopyStatus('batch');
    setStartDate(null);
    setStartDateMultiCopy(null);
    setEndDateMultiCopy(null);
    setWeekDayCopyArray([]);
    setMealTypesCopyArray([{ value: currentMealType }]);
    setCurrentCopyTypeValue('date');
    setCopyItemOverlayClass('block');
  };

  const openCopyDayPopup = () => {
    setCopyStatus('day');
    setStartDate(null);
    setStartDateMultiCopy(null);
    setEndDateMultiCopy(null);
    setWeekDayCopyArray([]);
    setMealTypesCopyArray([{ value: currentMealType }]);
    setCurrentCopyTypeValue('date');
    setCopyItemOverlayClass('block');
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const activateBatch = () => {
    const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
    const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
    const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

    let thisPlanState = currentPlan;
    let isChangeValid = true;

    splitArrayIngredients.forEach(function (key: any) {
      const thisType = 'ingredients';
      const thisId = key.id;

      let validCount = 0;

      const newColumns = {
        ...thisPlanState,
        dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
          const thisItem = entries[key.mealType]?.deactivatedItems
            ? entries[key.mealType]?.deactivatedItems[thisType]?.filter(
                (item: any) => parseFloat(item.id) === parseFloat(thisId)
              )[0]
            : undefined;

          if (thisItem === undefined) {
            isChangeValid = false;
          } else {
            validCount += 1;
          }

          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            kcal_total:
              parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total) < 0
                ? 0
                : parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                ? 0
                : parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
            protein_total:
              parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total) < 0
                ? 0
                : parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
            fat_total:
              parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total) < 0
                ? 0
                : parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
            [key.mealType]: {
              ...entries[key.mealType],
              kcal_total:
                parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total:
                parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total:
                parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
              fat_total:
                parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
              [thisType]: [thisItem, ...entries[key.mealType][thisType]],
              deactivatedItems: {
                ...entries[key.mealType].deactivatedItems,
                ingredients: [
                  ...entries[key.mealType].deactivatedItems?.ingredients?.filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            },
          };
        }),
      };

      if (validCount > 0) {
        isChangeValid = true;
      }
      /* eslint-disable no-param-reassign */
      thisPlanState = newColumns;
    });

    splitArrayRecipes.forEach(function (key: any) {
      const thisType = 'recipes';
      const thisId = key.id;

      let validCount = 0;

      const newColumns = {
        ...thisPlanState,
        dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
          const thisItem = entries[key.mealType]?.deactivatedItems
            ? entries[key.mealType]?.deactivatedItems[thisType]?.filter(
                (item: any) => parseFloat(item.id) === parseFloat(thisId)
              )[0]
            : undefined;
          if (thisItem === undefined) {
            isChangeValid = false;
          } else {
            validCount += 1;
          }

          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            kcal_total:
              parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total) < 0
                ? 0
                : parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                ? 0
                : parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
            protein_total:
              parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total) < 0
                ? 0
                : parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
            fat_total:
              parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total) < 0
                ? 0
                : parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
            [key.mealType]: {
              ...entries[key.mealType],
              kcal_total:
                parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total:
                parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total:
                parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
              fat_total:
                parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
              [thisType]: [thisItem, ...entries[key.mealType][thisType]],
              deactivatedItems: {
                ...entries[key.mealType].deactivatedItems,
                recipes: [
                  ...entries[key.mealType].deactivatedItems?.recipes?.filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            },
          };
        }),
      };

      if (validCount > 0) {
        isChangeValid = true;
      }
      /* eslint-disable no-param-reassign */
      thisPlanState = newColumns;
    });

    splitArrayManualItems.forEach(function (key: any) {
      const thisType = 'manualItems';
      const thisId = key.id;

      let validCount = 0;

      const newColumns = {
        ...thisPlanState,
        dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
          const thisItem = entries[key.mealType]?.deactivatedItems
            ? entries[key.mealType]?.deactivatedItems[thisType]?.filter(
                (item: any) => parseFloat(item.id) === parseFloat(thisId)
              )[0]
            : undefined;

          if (thisItem === undefined) {
            isChangeValid = false;
          } else {
            validCount += 1;
          }

          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            kcal_total:
              parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total) < 0
                ? 0
                : parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                ? 0
                : parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
            protein_total:
              parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total) < 0
                ? 0
                : parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
            fat_total:
              parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total) < 0
                ? 0
                : parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
            [key.mealType]: {
              ...entries[key.mealType],
              kcal_total:
                parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total:
                parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total:
                parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].protein_total) + parseFloat(thisItem.protein_total),
              fat_total:
                parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total) < 0
                  ? 0
                  : parseFloat(entries[key.mealType].fat_total) + parseFloat(thisItem.fat_total),
              [thisType]: [thisItem, ...entries[key.mealType][thisType]],
              deactivatedItems: {
                ...entries[key.mealType].deactivatedItems,
                manualItems: [
                  ...entries[key.mealType].deactivatedItems?.manualItems?.filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            },
          };
        }),
      };

      if (validCount > 0) {
        isChangeValid = true;
      }
      /* eslint-disable no-param-reassign */
      thisPlanState = newColumns;
    });

    if (isChangeValid) {
      savePlanChangesToFirebase(
        userData,
        thisPlanState,
        thisPlanState.uid,
        authContext.user?.uid,
        false,
        undefined,
        tenant
      );
      toast.success('Die Elemente wurden erfolgreich aktiviert!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut');
    }
    return true;
  };

  const deleteBatch = (onlyDelete?: boolean) => {
    const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
    const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
    const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

    let thisPlanState = currentPlan;
    let isChangeValid = true;

    splitArrayIngredients.forEach(function (key: any) {
      const thisType = 'ingredients';
      const thisId = key.id;
      if (key.deactivate === false) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType][thisType]?.filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: entries.kcal_total - thisItem.kcal_total < 0 ? 0 : entries.kcal_total - thisItem.kcal_total,
              carbohydrates_total:
                entries.carbohydrates_total - thisItem.carbohydrates_total < 0
                  ? 0
                  : entries.carbohydrates_total - thisItem.carbohydrates_total,
              protein_total:
                entries.protein_total - thisItem.protein_total < 0 ? 0 : entries.protein_total - thisItem.protein_total,
              fat_total: entries.fat_total - thisItem.fat_total < 0 ? 0 : entries.fat_total - thisItem.fat_total,
              [key.mealType]: {
                ...entries[key.mealType],
                kcal_total:
                  entries[key.mealType].kcal_total - thisItem.kcal_total < 0
                    ? 0
                    : entries[key.mealType].kcal_total - thisItem.kcal_total,
                carbohydrates_total:
                  entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total < 0
                    ? 0
                    : entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total,
                protein_total:
                  entries[key.mealType].protein_total - thisItem.protein_total < 0
                    ? 0
                    : entries[key.mealType].protein_total - thisItem.protein_total,
                fat_total:
                  entries[key.mealType].fat_total - thisItem.fat_total < 0
                    ? 0
                    : entries[key.mealType].fat_total - thisItem.fat_total,
                [thisType]: [
                  ...entries[key.mealType][thisType].filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            };
          }),
        };

        if (validCount > 0) {
          isChangeValid = true;
        }
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;
      } else if (key.deactivate === true) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType]?.deactivatedItems?.ingredients?.filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  ingredients: [
                    ...entries[key.mealType].deactivatedItems?.ingredients?.filter(
                      (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                    ),
                  ],
                },
              },
            };
          }),
        };

        if (validCount > 0) {
          isChangeValid = true;
        }
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;
      }
    });

    splitArrayRecipes.forEach(function (key: any) {
      const thisType = 'recipes';
      const thisId = key.id;

      if (key.deactivate === false) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType][thisType].filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: entries.kcal_total - thisItem.kcal_total < 0 ? 0 : entries.kcal_total - thisItem.kcal_total,
              carbohydrates_total:
                entries.carbohydrates_total - thisItem.carbohydrates_total < 0
                  ? 0
                  : entries.carbohydrates_total - thisItem.carbohydrates_total,
              protein_total:
                entries.protein_total - thisItem.protein_total < 0 ? 0 : entries.protein_total - thisItem.protein_total,
              fat_total: entries.fat_total - thisItem.fat_total < 0 ? 0 : entries.fat_total - thisItem.fat_total,
              [key.mealType]: {
                ...entries[key.mealType],
                kcal_total:
                  entries[key.mealType].kcal_total - thisItem.kcal_total < 0
                    ? 0
                    : entries[key.mealType].kcal_total - thisItem.kcal_total,
                carbohydrates_total:
                  entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total < 0
                    ? 0
                    : entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total,
                protein_total:
                  entries[key.mealType].protein_total - thisItem.protein_total < 0
                    ? 0
                    : entries[key.mealType].protein_total - thisItem.protein_total,
                fat_total:
                  entries[key.mealType].fat_total - thisItem.fat_total < 0
                    ? 0
                    : entries[key.mealType].fat_total - thisItem.fat_total,
                [thisType]: [
                  ...entries[key.mealType][thisType].filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            };
          }),
        };
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;

        if (validCount > 0) {
          isChangeValid = true;
        }
      } else if (key.deactivate === true) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType]?.deactivatedItems?.recipes?.filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  recipes: [
                    ...entries[key.mealType].deactivatedItems?.recipes?.filter(
                      (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                    ),
                  ],
                },
              },
            };
          }),
        };

        if (validCount > 0) {
          isChangeValid = true;
        }
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;
      }
    });

    splitArrayManualItems.forEach(function (key: any) {
      const thisType = 'manualItems';
      const thisId = key.id;

      if (key.deactivate === false) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType][thisType]?.filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: entries.kcal_total - thisItem.kcal_total < 0 ? 0 : entries.kcal_total - thisItem.kcal_total,
              carbohydrates_total:
                entries.carbohydrates_total - thisItem.carbohydrates_total < 0
                  ? 0
                  : entries.carbohydrates_total - thisItem.carbohydrates_total,
              protein_total:
                entries.protein_total - thisItem.protein_total < 0 ? 0 : entries.protein_total - thisItem.protein_total,
              fat_total: entries.fat_total - thisItem.fat_total < 0 ? 0 : entries.fat_total - thisItem.fat_total,
              [key.mealType]: {
                ...entries[key.mealType],
                kcal_total:
                  entries[key.mealType].kcal_total - thisItem.kcal_total < 0
                    ? 0
                    : entries[key.mealType].kcal_total - thisItem.kcal_total,
                carbohydrates_total:
                  entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total < 0
                    ? 0
                    : entries[key.mealType].carbohydrates_total - thisItem.carbohydrates_total,
                protein_total:
                  entries[key.mealType].protein_total - thisItem.protein_total < 0
                    ? 0
                    : entries[key.mealType].protein_total - thisItem.protein_total,
                fat_total:
                  entries[key.mealType].fat_total - thisItem.fat_total < 0
                    ? 0
                    : entries[key.mealType].fat_total - thisItem.fat_total,
                [thisType]: [
                  ...entries[key.mealType][thisType].filter(
                    (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                  ),
                ],
              },
            };
          }),
        };

        if (validCount > 0) {
          isChangeValid = true;
        }
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;
      } else if (key.deactivate === true) {
        let validCount = 0;

        const newColumns = {
          ...thisPlanState,
          dayEntries: thisPlanState?.dayEntries.map((entries: any) => {
            const thisItem = entries[key.mealType]?.deactivatedItems?.manualItems?.filter(
              (item: any) => parseFloat(item.id) === parseFloat(thisId)
            )[0];

            if (thisItem === undefined) {
              isChangeValid = false;
            } else {
              validCount += 1;
            }

            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  manualItems: [
                    ...entries[key.mealType].deactivatedItems?.manualItems?.filter(
                      (item: any) => parseFloat(item.id) !== parseFloat(thisItem.id)
                    ),
                  ],
                },
              },
            };
          }),
        };

        if (validCount > 0) {
          isChangeValid = true;
        }
        /* eslint-disable no-param-reassign */
        thisPlanState = newColumns;
      }
    });

    if (onlyDelete !== undefined) {
      return thisPlanState;
    }
    if (isChangeValid) {
      savePlanChangesToFirebase(
        userData,
        thisPlanState,
        thisPlanState.uid,
        authContext.user?.uid,
        false,
        undefined,
        tenant
      );
      toast.success('Die Elemente wurden erfolgreich gelöscht!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut');
    }
    return true;
  };

  const deactivateBatch = () => {
    let objectAfterDelete = deleteBatch(true);
    const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
    const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
    const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

    let deactivateIsValid = true;

    if (splitArrayIngredients.length > 0) {
      let validCount = 0;

      splitArrayIngredients.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].ingredients.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          deactivateIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  ingredients:
                    entries[key.mealType].deactivatedItems?.ingredients !== undefined
                      ? [thisItem, ...entries[key.mealType].deactivatedItems?.ingredients]
                      : [thisItem],
                },
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        deactivateIsValid = true;
      }
    }

    if (splitArrayRecipes.length > 0) {
      let validCount = 0;

      splitArrayRecipes.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].recipes.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          deactivateIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  recipes:
                    entries[key.mealType].deactivatedItems?.recipes !== undefined
                      ? [thisItem, ...entries[key.mealType].deactivatedItems?.recipes]
                      : [thisItem],
                },
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        deactivateIsValid = true;
      }
    }

    if (splitArrayManualItems.length > 0) {
      let validCount = 0;

      splitArrayManualItems.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].manualItems?.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          deactivateIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              [key.mealType]: {
                ...entries[key.mealType],
                deactivatedItems: {
                  ...entries[key.mealType].deactivatedItems,
                  manualItems:
                    entries[key.mealType].deactivatedItems?.manualItems !== undefined
                      ? [thisItem, ...entries[key.mealType].deactivatedItems?.manualItems]
                      : [thisItem],
                },
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        deactivateIsValid = true;
      }
    }

    if (deactivateIsValid && currentPlan?.uid !== undefined) {
      savePlanChangesToFirebase(
        userData,
        objectAfterDelete,
        currentPlan?.uid,
        authContext.user?.uid,
        true,
        undefined,
        tenant
      );
      toast.success('Die ausgewählten Elemente wurden deaktiviert!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen!');
    }
  };

  const moveBatch = () => {
    let objectAfterDelete = deleteBatch(true);

    const splitArrayIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
    const splitArrayRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes');
    const splitArrayManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems');

    let moveIsValid = true;

    if (splitArrayIngredients.length > 0) {
      let validCount = 0;

      splitArrayIngredients.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].ingredients.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          moveIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
              [changedMealType]: {
                ...entries[changedMealType],
                kcal_total: parseFloat(entries[changedMealType].kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[changedMealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries[changedMealType].protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries[changedMealType].fat_total) + parseFloat(thisItem.fat_total),
                ingredients: [thisItem, ...entries[changedMealType].ingredients],
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        moveIsValid = true;
      }
    }

    if (splitArrayRecipes.length > 0) {
      let validCount = 0;

      splitArrayRecipes.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].recipes?.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          moveIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
              [changedMealType]: {
                ...entries[changedMealType],
                kcal_total: parseFloat(entries[changedMealType].kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[changedMealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries[changedMealType].protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries[changedMealType].fat_total) + parseFloat(thisItem.fat_total),
                recipes: [thisItem, ...entries[changedMealType].recipes],
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        moveIsValid = true;
      }
    }

    if (splitArrayManualItems.length > 0) {
      let validCount = 0;

      splitArrayManualItems.forEach(function (key: any) {
        let thisItem = currentDayItem?.[key.mealType].manualItems?.filter(
          (item: any) => parseFloat(item.id) === parseFloat(key.id)
        )[0];

        thisItem = {
          ...thisItem,
          id: Math.random(),
        };

        if (thisItem === undefined) {
          moveIsValid = false;
        } else {
          validCount += 1;
        }

        objectAfterDelete = {
          ...objectAfterDelete,
          dayEntries: objectAfterDelete.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== currentDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItem.kcal_total),
              carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(thisItem.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(thisItem.fat_total),
              [changedMealType]: {
                ...entries[changedMealType],
                kcal_total: parseFloat(entries[changedMealType].kcal_total) + parseFloat(thisItem.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[changedMealType].carbohydrates_total) + parseFloat(thisItem.carbohydrates_total),
                protein_total: parseFloat(entries[changedMealType].protein_total) + parseFloat(thisItem.protein_total),
                fat_total: parseFloat(entries[changedMealType].fat_total) + parseFloat(thisItem.fat_total),
                manualItems:
                  entries[changedMealType].manualItems !== undefined
                    ? [thisItem, ...entries[changedMealType].manualItems]
                    : [thisItem],
              },
            };
          }),
        };
      });

      if (validCount > 0) {
        moveIsValid = true;
      }
    }

    if (moveIsValid && currentPlan?.uid !== undefined) {
      savePlanChangesToFirebase(
        userData,
        objectAfterDelete,
        currentPlan?.uid,
        authContext.user?.uid,
        true,
        undefined,
        tenant
      );
      setMoveItemOverlayClass('hidden');
      toast.success('Der Verschiebevorgang war erfolgreich!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen!');
    }
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current) {
      setActionClassMobile('hidden');
    }
  };

  useEffect(() => {
    if (
      (triggeredStepCount > 0 || (triggeredWorkoutCalories > 0 && triggeredWorkoutMinutes > 0)) &&
      triggerUpdate === true
    ) {
      updateHealthData(triggeredStepCount, triggeredWorkoutMinutes, triggeredWorkoutCalories);
    }
  }, [triggerUpdate]);

  useEffect(() => {
    if (currentCopyTypeValue === 'multi') {
      if (copyStatus === 'day') {
        setTimeout(() => {
          const checkBoxEffectArray = [] as any;

          mealTypeValuesForCheckbox.forEach(function (key: any) {
            if (checkboxRefs.current[key.index] !== undefined) {
              checkboxRefs.current[key.index]!.checked = true;
              checkBoxEffectArray.push({ value: key.value });
            }
          });

          setMealTypesCopyArray(checkBoxEffectArray);
        }, 1);
      } else {
        setTimeout(() => {
          const getMealTypeIndex = mealTypeValuesForCheckbox.filter((item: any) => item.value === currentMealType)[0];

          if (getMealTypeIndex !== undefined && checkboxRefs.current[getMealTypeIndex.index]) {
            checkboxRefs.current[getMealTypeIndex.index]!.checked = true;
          }
        }, 1);
      }
    }
  }, [currentCopyTypeValue]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (viewIngredientPopupClass === 'block') {
      getFavoriteIngredientStatus();
    } else {
      setIngredientFavoriteStatus(false);
    }
  }, [viewIngredientPopupClass]);

  useEffect(() => {
    document.querySelector('#mainContainer')?.addEventListener('scroll', listenToScroll);
    return () => document.querySelector('#mainContainer')?.removeEventListener('scroll', listenToScroll);
  }, []);

  useEffect(() => {
    setCurrentMakroChangeType('');
    setCurrentMakroChangeType('standard');
    setCurrentMakroChangeCarbohydrates(0);
    setCurrentMakroChangeProtein(0);
    setCurrentMakroChangeFat(0);
  }, [changeCaloriesNeed]);

  useEffect(() => {
    if (checkBoxArray.length > 0) {
      const countIngredients = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'ingredients');
      const countRecipes = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'recipes').length;
      const countManualItems = checkBoxArray.filter((arrayItem: any) => arrayItem.type === 'manualItems').length;
      const deactivatedItems = checkBoxArray.filter((arrayItem: any) => arrayItem.deactivate === true).length;

      const countIngredientsWithDifferentMealType = new Set(countIngredients.map((item: any) => item.mealType)).size;
      const countItemsWithDifferentMealType = new Set(checkBoxArray.map((item: any) => item.mealType)).size;
      const countItemsWithDifferentActiveStatus = new Set(checkBoxArray.map((item: any) => item.deactivate)).size;

      if (
        countIngredients.length > 0 &&
        countIngredientsWithDifferentMealType === 1 &&
        countRecipes === 0 &&
        countManualItems === 0
      ) {
        setCheckBoxArrayOnlyIngredients(true);
      } else {
        setCheckBoxArrayOnlyIngredients(false);
      }

      if (countItemsWithDifferentMealType > 1) {
        setCheckBoxArrayOnlyOneMealType(false);
      } else {
        setCheckBoxArrayOnlyOneMealType(true);
      }

      if (countItemsWithDifferentActiveStatus > 1) {
        setCheckBoxArrayOnlyOneActiveStatus(false);
      } else {
        setCheckBoxArrayOnlyOneActiveStatus(true);
      }

      setCheckBoxArrayDeactivatedItemsCount(deactivatedItems);

      setEditMenuClass('block');
    } else {
      setEditMenuClass('hidden');
    }
  }, [checkBoxArray]);

  useEffect(() => {
    if (userPlanList && userPlanList.length > 0) {
      if (location.state?.uid !== undefined && location.state?.id !== undefined && location.state?.uid.length > 0) {
        const getPlan = userPlanList.filter(element => element.uid === location.state.uid)[0];

        if (getPlan !== undefined) {
          const getPlanDay = getPlan.dayEntries.filter(element => element.id === location.state.id)[0];

          // setCurrentDayDateMoment(moment.unix(getPlanDay.date.seconds).utc());
          // Set day date +1 because saveplanchanges set date -1
          setCurrentDayDateMoment(moment.unix(getPlanDay.date.seconds).subtract(+1, 'day'));
          checkCurrentPlanExists(moment.unix(getPlanDay.date.seconds).utc());
        }
      } else if (location.state?.planId === 'noPlan') {
        setCurrentDayDateMoment(moment(location?.state.planDateString, 'DD.MM.YY'));
        checkCurrentPlanExists(moment(location?.state.planDateString, 'DD.MM.YY'));
        history.replace({ state: {} });
      } else {
        checkCurrentPlanExists(currentDayDateMoment);
      }
    } else if (userPlanList && userPlanList.length === 0) {
      setCurrentDayLoaded(true);
      console.log('day loaded');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPlanList]);

  useEffect(() => {
    if (changeWaterGoalRef.current) {
      changeWaterGoalRef.current.value = '';
      changeWaterGoalRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterEditPopupClass]);

  useEffect(() => {
    if (updateTriggerFavorites > 0) {
      getFavoriteIngredientStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTriggerFavorites]);

  useEffect(() => {
    if (location.state?.type !== undefined && location.state?.mealLabel !== undefined) {
      if (location.state.mealLabel !== 'mobile') {
        openMealPopup(location.state.type, location.state.mealLabel);
      }
      if (location.state.mealLabel === 'mobile') {
        if (location.state.type === 'breakfast') {
          setTimeout(() => {
            if (horizontalBreakfastRef.current) {
              // horizontalBreakfastRef.current.scrollIntoView();
            }
          }, 1);
        } else if (location.state.type === 'lunch') {
          setTimeout(() => {
            if (horizontalLunchRef.current) {
              // horizontalLunchRef.current.scrollIntoView();
            }
          }, 1);
        } else if (location.state.type === 'dinner') {
          setTimeout(() => {
            if (horizontalDinnerRef.current) {
              // horizontalDinnerRef.current.scrollIntoView();
            }
          }, 1);
        } else if (location.state.type === 'snacks') {
          setTimeout(() => {
            if (horizontalSnacksRef.current) {
              // horizontalSnacksRef.current.scrollIntoView();
            }
          }, 1);
        }
      }
    }

    if (
      location.state?.scrollPos !== undefined &&
      location.state?.from === 'nutrition-detail' &&
      location.state?.mealLabel !== undefined
    ) {
      setOpenRecipeOnload(true);
      setActionClass('hidden');
      setIngredientSearchPopupClass('block');
      setCurrentMealType(location.state?.mealLabel);
      setCurrentMealLabel(location.state?.mealLabel);
    } else {
      setOpenRecipeOnload(false);
    }

    if (location.state?.isBackLink === true) {
      history.replace({ state: {} });
    }

    // send user uid to RN
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ uid: authContext.user?.uid }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentPlanExists &&
      typeof location.state !== undefined &&
      location.state !== null &&
      location.state?.from === 'recipe-detail-change' &&
      currentPlan.uid !== undefined
    ) {
      const thisDayItem = currentPlan?.dayEntries.filter((item: any) => item.id === location.state.id)[0];

      const thisItemType = thisDayItem?.[location.state.type as string].recipes.filter(
        (item: any) => item.id === tempRecipeObject.id
      )[0];

      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== location.state.id) return entries;
          return {
            ...entries,
            kcal_total:
              (parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total) < 0
                ? 0
                : parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total)) +
              parseFloat(tempRecipeObject.kcal_total),
            carbohydrates_total:
              (parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total) < 0
                ? 0
                : parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total)) +
              parseFloat(tempRecipeObject.carbohydrates_total),
            protein_total:
              (parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total) < 0
                ? 0
                : parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total)) +
              parseFloat(tempRecipeObject.protein_total),
            fat_total:
              (parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total) < 0
                ? 0
                : parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total)) +
              parseFloat(tempRecipeObject.fat_total),
            [location.state.type as string]: {
              ...entries[location.state.type as string],
              kcal_total:
                (parseFloat(entries[location.state.type as string].kcal_total) - parseFloat(thisItemType.kcal_total) < 0
                  ? 0
                  : parseFloat(entries[location.state.type as string].kcal_total) -
                    parseFloat(thisItemType.kcal_total)) + parseFloat(tempRecipeObject.kcal_total),
              carbohydrates_total:
                (parseFloat(entries[location.state.type as string].carbohydrates_total) -
                  parseFloat(thisItemType.carbohydrates_total) <
                0
                  ? 0
                  : parseFloat(entries[location.state.type as string].carbohydrates_total) -
                    parseFloat(thisItemType.carbohydrates_total)) + parseFloat(tempRecipeObject.carbohydrates_total),
              protein_total:
                (parseFloat(entries[location.state.type as string].protein_total) -
                  parseFloat(thisItemType.protein_total) <
                0
                  ? 0
                  : parseFloat(entries[location.state.type as string].protein_total) -
                    parseFloat(thisItemType.protein_total)) + parseFloat(tempRecipeObject.protein_total),
              fat_total:
                (parseFloat(entries[location.state.type as string].fat_total) - parseFloat(thisItemType.fat_total) < 0
                  ? 0
                  : parseFloat(entries[location.state.type as string].fat_total) - parseFloat(thisItemType.fat_total)) +
                parseFloat(tempRecipeObject.fat_total),
              recipes: [
                ...entries[location.state.type as string].recipes.filter(
                  (item: any) => item.id !== tempRecipeObject.id
                ),
                tempRecipeObject,
              ],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== location.state.planDayId) return entries;
          return {
            ...entries,
            [location.state.type as string]: {
              ...entries[location.state.type as string],
              recipes: entries[location.state.type as string].recipes
                .sort((a: any, b: any) => a.id - b.id)
                .map((item: any) => {
                  return {
                    ...item,
                  };
                }),
            },
          };
        }),
      };

      const checkIncompabilityArray = [] as any;
      const checkFormOfNutritionArray = [] as any;

      intolerancesOption.forEach(function (key) {
        if (typeof tempRecipeObject[key.toLowerCase()] !== undefined && tempRecipeObject[key.toLowerCase()] !== null) {
          if (tempRecipeObject[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      formOfNutrition.forEach(function (key) {
        if (typeof tempRecipeObject[key.toLowerCase()] !== undefined && tempRecipeObject[key.toLowerCase()] !== null) {
          if (tempRecipeObject[key.toLowerCase()] === true) {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
          } else {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
          }
        }
      });

      const checkFormOfNutritionArrayMerged = [
        ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
        ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
      ];

      // Copy newColumns to variable because this needs to be updated in the following sections
      let thisRecipeData = { ...newColumnsSetNewIds };

      // Set elements true if incompatibility exists
      for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
        thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
      }

      // Set elements true if incompatibility exists
      for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
        thisRecipeData = {
          ...thisRecipeData,
          [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
        };
      }

      if (thisItemType !== undefined) {
        // setCurrentDayId(location.state.planDayId);
        savePlanChangesToFirebase(
          userData,
          thisRecipeData,
          thisRecipeData.uid,
          authContext.user?.uid,
          false,
          undefined,
          tenant
        );
        history.replace({ state: {} });
        toast.success('Das Rezept wurde erfolgreich geändert!');
      } else {
        toast.error('Es ist leider etwas schief gelaufen!');
      }
    }

    if (typeof location.state !== undefined && location.state !== null && location.state?.from === 'recipe-detail') {
      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== location.state.id) return entries;
          return {
            ...entries,
            kcal_total: parseFloat(entries.kcal_total) + parseFloat(tempRecipeObject.kcal_total),
            carbohydrates_total:
              parseFloat(entries.carbohydrates_total) + parseFloat(tempRecipeObject.carbohydrates_total),
            protein_total: parseFloat(entries.protein_total) + parseFloat(tempRecipeObject.protein_total),
            fat_total: parseFloat(entries.fat_total) + parseFloat(tempRecipeObject.fat_total),
            [location.state.mealLabel]: {
              ...entries[location.state.mealLabel],
              kcal_total:
                parseFloat(entries[location.state.mealLabel].kcal_total) + parseFloat(tempRecipeObject.kcal_total),
              carbohydrates_total:
                parseFloat(entries[location.state.mealLabel].carbohydrates_total) +
                parseFloat(tempRecipeObject.carbohydrates_total),
              protein_total:
                parseFloat(entries[location.state.mealLabel].protein_total) +
                parseFloat(tempRecipeObject.protein_total),
              fat_total:
                parseFloat(entries[location.state.mealLabel].fat_total) + parseFloat(tempRecipeObject.fat_total),
              recipes: [tempRecipeObject, ...entries[location.state.mealLabel].recipes],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== location.state.planDayId) return entries;
          return {
            ...entries,
            [location.state.mealLabel]: {
              ...entries[location.state.mealLabel],
              recipes: entries[location.state.mealLabel].recipes.map((item: any, index: any) => {
                return {
                  ...item,
                  id: Math.random(),
                };
              }),
            },
          };
        }),
      };

      const checkIncompabilityArray = [] as any;
      const checkFormOfNutritionArray = [] as any;

      intolerancesOption.forEach(function (key) {
        if (typeof tempRecipeObject[key.toLowerCase()] !== undefined && tempRecipeObject[key.toLowerCase()] !== null) {
          if (tempRecipeObject[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      formOfNutrition.forEach(function (key) {
        if (typeof tempRecipeObject[key.toLowerCase()] !== undefined && tempRecipeObject[key.toLowerCase()] !== null) {
          if (tempRecipeObject[key.toLowerCase()] === true) {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      // Copy newColumns to variable because this needs to be updated in the following sections
      let thisRecipeData = { ...newColumnsSetNewIds };

      // Set elements true if incompatibility exists
      for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
        thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
      }

      // Set elements true if form of nutrition exists
      for (let g = 0; g < checkFormOfNutritionArray.length; g += 1) {
        thisRecipeData = { ...thisRecipeData, [checkFormOfNutritionArray[g].name]: true };
      }

      if (currentPlan.uid !== undefined) {
        if (tempRecipeObject.kcal_total !== undefined) {
          // setCurrentDayId(location.state.planDayId);
          savePlanChangesToFirebase(
            userData,
            thisRecipeData,
            thisRecipeData.uid,
            authContext.user?.uid,
            true,
            undefined,
            tenant
          );
          toast.success('Das Rezept wurde erfolgreich hinzugefügt!');
          setTempRecipeObject({});
          history.replace({ state: {} });
        }
      }

      if (location.state?.planId === 'noPlan') {
        const newColumnsNoPlan = {
          ...thisRecipeData,
          kcal_total: tempRecipeObject.kcal_total,
          startDate: {
            seconds: moment(location.state?.planDateString, 'DD.MM.YY').utc().unix(),
          },
          endDate: {
            seconds: moment(location.state?.planDateString, 'DD.MM.YY').utc().unix(),
          },
          dayEntries: thisRecipeData.dayEntries.map((entries: any) => {
            return {
              ...entries,
              date: {
                seconds: moment(location.state?.planDateString, 'DD.MM.YY').utc().unix(),
              },
            };
          }),
        };

        savePlanChangesToFirebase(userData, newColumnsNoPlan, 1, authContext.user?.uid, true, undefined, tenant);
        // checkCurrentPlanExists(moment(location.state?.planDateString, 'DD.MM.YY').subtract(1, 'day'));
        setTempRecipeObject({});
        history.replace({ state: {} });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanExists]);

  useEffect(() => {
    if (currentDayLoaded === false && refreshTransition === true) {
      setCurrentDayLoaded(true);
      setRefreshTransition(false);
    }

    if (currentDayLoaded && tourRef.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
      setTimeout(() => {
        if (document.getElementById('tourDesktop')?.offsetParent !== null) {
          // alert('1');
          if (
            currentDayLoaded &&
            tourRef.current &&
            (userData?.cookieBannerType !== 'initial' || userData?.role === 5)
          ) {
            if (userData?.tour?.dashboard === undefined) {
              tourRef.current.click();
            }
          }
        }

        if (document.getElementById('tourMobile')?.offsetParent !== null) {
          // alert('2');
          if (
            currentDayLoaded &&
            tourRefMobile.current &&
            (userData?.cookieBannerType !== 'initial' || userData?.role === 5)
          ) {
            if (userData?.tour?.dashboard === undefined) {
              tourRefMobile.current.click();
            }
          }
        }
      }, 1);
    }

    if (currentDayLoaded && currentRecipeRef.current && jumpToRecipeNode === true) {
      scrollToElement(currentRecipeRef);
    }

    if (currentDayLoaded && currentPlansRef.current && jumpToPlansNode === true) {
      scrollToElement(currentPlansRef);
    }

    if (location.state?.isCurrentRecipeDashboard === true) {
      setJumpToRecipeNode(true);
    }

    if (location.state?.isCurrentPlansDashboard === true) {
      setJumpToPlansNode(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDayLoaded, userData?.cookieBannerType]);

  useEffect(() => {
    setCheckBoxArray([]);
  }, [currentPlan]);

  useEffect(() => {
    if (refreshFromPopup === true && currentDayLoaded === true && getIsWebView() === true) {
      setRefreshTransition(true);
      setCurrentDayLoaded(false);
      setRefreshFromPopup(false);
    }
  }, [refreshFromPopup]);

  useEffect(() => {
    if (userData && userData.makroHistory) {
      const targetTimestamp = moment(currentDayDateMoment).endOf('day').utc().unix();

      const sortedHistory = [...userData.makroHistory].sort((a, b) => (a.dateTimeStamp || 0) - (b.dateTimeStamp || 0));

      const filteredHistory = sortedHistory.filter(entry => entry.dateTimeStamp !== undefined);

      const applicableEntry = filteredHistory.reduce<any | null>(
        (acc, entry) => (entry.dateTimeStamp <= targetTimestamp ? { ...entry } : acc),
        null
      );

      const invalidEntries = userData.makroHistory.filter(entry => entry.dateTimeStamp === undefined);

      if (applicableEntry) {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          calories: applicableEntry.calories,
          carbohydrates_total: applicableEntry.carbohydrates_total,
          protein_total: applicableEntry.protein_total,
          fat_total: applicableEntry.fat_total,
        }));
      } else {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          calories: userData.calories,
          carbohydrates_total: userData.carbohydrates_total,
          protein_total: userData.protein_total,
          fat_total: userData.fat_total,
        }));
      }
    }
  }, [currentDayDateMoment]);

  return (
    <>
      <IngredientSearchPopup
        mealType={currentMealType}
        popupType="plan"
        openerClass={ingredientSearchPopupClass}
        setOpenerClass={setIngredientSearchPopupClass}
        updateRecipeState={setCurrentPlan}
        recipeStateValue={currentPlan}
        updateDayId={currentDayId}
        updateDayString={
          currentDayItem?.date?.seconds !== undefined
            ? moment(moment.unix(currentDayItem?.date?.seconds).utc()).locale('de').startOf('day').format('DD.MM.YY')
            : moment(currentDayDateMoment).format('DD.MM.YY')
        }
        isDashBoard
        barcodeFoodId={currentApiFoodId}
        setBarcodeFoodId={setCurrentApiFoodId}
        barcodePopupCloser={setBarcodePickerVisible}
        barcodeContentPopupClass={setBarcodePickerContentVisible}
        openGenerateMealPopup={openGenerateMealPopup}
        setVisibleIncompabilityPopup={setVisibleIncompatibilityPopup}
        openRecipeOnLoad={openRecipeOnload}
        setOpenRecipeOnLoad={setOpenRecipeOnload}
        setRefreshDashboard={setRefreshFromPopup}
      />

      <GenerateAlghorithmus
        currentDayItem={currentDayItem}
        currentPlan={currentPlan}
        currentDayId={currentDayId}
        setCurrentPlan={setCurrentPlan}
        generateDayPopupClass={generateDayPopupClass}
        setGenerateDayPopupClass={setGenerateDayPopupClass}
        currentMealType={currentMealType}
        planGenerateType={planGenerateType}
        setVisibleIncompabilityPopup={setVisibleIncompatibilityPopup}
      />

      <IncompatibilityOverlay visible={visibleIncompatibilityPopup} setVisible={setVisibleIncompatibilityPopup} />

      <Overlay
        headline="Neues Workout"
        overlayClass={newWorkoutOverlayClass}
        setOverlayClass={setNewWorkoutOverlayClass}
        withoutCloseButton
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pt-10 font-extralight text-base pl-20 pr-15">
          <div className="font-bold pb-15">Du hast ein neues Workout absolviert!</div>
          <div>
            Sollen wir die verbrannten Kalorien von deinen Workouts berücksichtgen und deinen Kalorienbedarf anhand
            deines Profils für diesen Tag anpassen?
          </div>
          <div className="pt-10">
            Du hast insgesamt <span className="font-bold">{triggeredWorkoutCalories} Kalorien</span> verbrannt und damit
            würde sich dein Bedarf folgendermaßen ändern:
          </div>
          <div className="pt-10 flex md:pl-20">
            <div className={styles.nutritionBorderItemFirst}>
              <div>
                <div className="font-semibold text-base">{currentMakroChangeCalories}</div>
                <div className={styles.recipeLabel}>Kalorien</div>
              </div>
              <span className="divider" />
            </div>
            <div className={styles.nutritionBorderItem}>
              <div className="font-semibold text-base">{currentMakroChangeCarbohydrates}g</div>
              <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
            </div>
            <div className={styles.nutritionBorderItem}>
              <div className="font-semibold text-base">{currentMakroChangeProtein}g</div>
              <div className={styles.recipeLabel}>{t('Protein')}</div>
            </div>
            <div className="text-center px-20">
              <div className="font-semibold text-base">{currentMakroChangeFat}g</div>
              <div className={styles.recipeLabel}>{t('Fat')}</div>
            </div>
          </div>
        </div>
        <div className="pt-30 pl-20 pb-40">
          <div>
            <Button className="w-full" onClick={() => saveNewCaloriesForHealth()}>
              Bedarf für diesen Tag anpassen
            </Button>
          </div>
          <div className="mt-15">
            <Button className="w-full" buttonStyle="white" onClick={() => closeHealthWorkout()}>
              Schließen
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        headline="Neue Mahlzeit erstellen"
        overlayClass={newRecipeClass}
        setOverlayClass={setNewRecipeClass}
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="font-extralight pt-20 pb-10">Vergebe bitte noch einen Namen für deine neue Mahlzeit!</div>
        <div className="w-full rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2">
          <input
            id="recipeName"
            name="recipeName"
            type="text"
            onFocus={setBodyClassForMobileSafari}
            onBlur={removeBodyClassForMobileSafari}
            className="w-full my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor"
            onChange={changeRecipeName}
            ref={newRecipeRef}
            onKeyPress={e => keyPress(e, saveUserGeneratedRecipe)}
          />
        </div>
        <div className="font-extralight pt-10">
          Damit unser Alghorithmus deine neue Mahlzeit auch berücksichtigt, markiere bitte die Essverhalten-Merkmale,
          die zu der Mahlzeit passen!
        </div>
        <div
          className="flex pt-20 mb-14 cursor-pointer"
          id="descriptionJumpContainerDesktop"
          onClick={() => toggleEatingHabits()}
          onKeyDown={() => toggleEatingHabits()}
          aria-hidden="true"
        >
          <div className="font-light text-2xl">{t('Eating habits')}</div>
          <div className="pl-5 my-auto">
            <ChevronDownIcon className={eatingHabitsChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={eatingHabitsClass}>
          {eatingHabitsOption.map((item: string, index: number) => (
            <SwitchButton
              key={index}
              label={item}
              enabled={
                typeof eatingHabitsArray[item.toLowerCase()] !== undefined
                  ? Boolean(eatingHabitsArray[item.toLowerCase()])
                  : false
              }
              isBackground={index % 2 === 0}
              onChange={setEatingHabits}
            />
          ))}
        </div>
        <div className="pt-30 pb-20">
          <Button onClick={() => saveUserGeneratedRecipe()} className="w-full">
            Mahlzeit erstellen
          </Button>
        </div>
      </Overlay>

      <Overlay
        headline="Wasserbedarf"
        overlayClass={mobileWaterNeedClass}
        setOverlayClass={setMobileWaterNeedClass}
        icon={<Icon name="waterDrop" className="text-accentColor" width={20} height={20} />}
      >
        <div className="rounded-3xl bg-lightGray mt-15 pt-10 pb-10 md:pb-20">
          <WaterSection
            waterCounterValue={currentWaterCounterValue}
            updateFunction={updateWaterCounter}
            customDayWaterGoal={currentDayItem?.waterGoal !== undefined ? currentDayItem?.waterGoal : undefined}
            openWaterPopup={setWaterEditPopupClass}
            closeEditPopup={setMobileWaterNeedClass}
            isMobile
          />
        </div>
        <div className="mt-20 pb-20">
          <Button onClick={() => setMobileWaterNeedClass('hidden')} className="w-full">
            Fertig
          </Button>
        </div>
      </Overlay>

      <Overlay
        headline="Tagesbewertung"
        overlayClass={mobileDayRatingClass}
        setOverlayClass={setMobileDayRatingClass}
        icon={<Icon name="emojiHappy" className="text-accentColor" width={20} height={20} />}
      >
        <div className="rounded-3xl bg-lightGray mt-15 pt-10 pb-10 md:pb-20">
          <DayRating
            updateFunction={updateDayRating}
            isMobile
            ratingDayType={currentDayItem?.dayRating !== undefined ? currentDayItem?.dayRating : undefined}
          />
        </div>
        <div className="pb-20 mt-20">
          <Button onClick={() => setMobileDayRatingClass('hidden')} className="w-full">
            Fertig
          </Button>
        </div>
      </Overlay>

      <Overlay
        headline="Notizen"
        overlayClass={mobileNoticeClass}
        setOverlayClass={setMobileNoticeClass}
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="rounded-3xl bg-lightGray mt-20 pt-0 pb-0 md:pb-20">
          <RateSection
            updateFunction={updateRateText}
            defaultValue={currentDayItem?.notice !== undefined ? currentDayItem?.notice : ''}
            changedDate={currentDayDateMoment}
            mobilePopupClass={mobileNoticeClass}
          />
        </div>
        <div className="mt-20 pb-20">
          <Button onClick={() => setMobileNoticeClass('hidden')} className="w-full">
            Fertig
          </Button>
        </div>
      </Overlay>

      <Overlay
        headline="Kopieren"
        overlayClass={copyItemOverlayClass}
        setOverlayClass={setCopyItemOverlayClass}
        icon={<DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        {copyStatus === 'day' ? (
          <div className="pb-40">
            <div className="flex gap-20 pt-4">
              <div className="font-light my-auto w-130">Aktuelles Datum:</div>
              <div className="font-light">
                {moment.unix(currentDayItem?.date?.seconds).utc().format('DD[.]MM[.]YY')}
              </div>
            </div>
            <div>
              <div className="pb-10 pt-20 font-extralight">Wie möchtest du kopieren?</div>
              <Select
                options={copyTypeValues}
                className="react-select-container w-full"
                classNamePrefix="react-select"
                unstyled
                value={copyTypeValues.filter((item: any) => item.value === currentCopyTypeValue)}
                onChange={e => setCurrentCopyTypeValue(e?.value)}
                isSearchable={false}
              />
            </div>
            {currentCopyTypeValue === 'date' ? (
              <div>
                <div className="pt-20">
                  <div className="font-extralight">Auf welchen Tag soll kopiert werden?</div>
                </div>
                <div className="pt-5">
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    locale="de"
                    onChange={changeCopyDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="pt-30 pb-10 font-extralight">Welche Mahlzeiten sollen kopiert werden?</div>
                <div className="flex gap-x-20 flex-wrap gap-y-15 desktop:justify-between">
                  {mealTypeValues.map((item: any, index: number) => (
                    <div key={index}>
                      <Checkbox
                        label={item.label}
                        onClick={() => addUpdateMealtypesCopyArray(item.value)}
                        name={item.label}
                        aria-hidden
                        ref={el => {
                          checkboxRefs.current[index] = el as HTMLInputElement;
                        }}
                        register="test"
                      />
                    </div>
                  ))}
                </div>
                <div className="pt-30 pb-10 font-extralight">Auf welche Wochentage soll kopiert werden?</div>
                <div className="flex gap-x-20 flex-wrap gap-y-15 desktop:justify-between desktop:gap-x-10">
                  <div className="w-50">
                    <Checkbox
                      label="Mo"
                      onClick={() => addUpdateWeekDayCopyArray(1)}
                      aria-hidden
                      name="monday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Di"
                      onClick={() => addUpdateWeekDayCopyArray(2)}
                      aria-hidden
                      name="tuesday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Mi"
                      onClick={() => addUpdateWeekDayCopyArray(3)}
                      aria-hidden
                      name="wednesday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Do"
                      onClick={() => addUpdateWeekDayCopyArray(4)}
                      aria-hidden
                      name="thursday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Fr"
                      onClick={() => addUpdateWeekDayCopyArray(5)}
                      aria-hidden
                      name="friday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Sa"
                      onClick={() => addUpdateWeekDayCopyArray(6)}
                      aria-hidden
                      name="saturday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="So"
                      onClick={() => addUpdateWeekDayCopyArray(0)}
                      aria-hidden
                      name="sunday"
                      register="t"
                    />
                  </div>
                </div>
                <div className="pt-30 pb-10 font-extralight">Über welchen Zeitraum soll kopiert werden?</div>
                <div>
                  <div className="font-extralight">Beginnt:</div>
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={startDateMultiCopy}
                    locale="de"
                    onChange={changeMultiCopyStartDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
                <div className="pt-15">
                  <div className="font-extralight">Endet:</div>
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={endDateMultiCopy}
                    locale="de"
                    onChange={changeMultiCopyEndDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
              </div>
            )}

            <div className="pt-50">
              <div>
                {currentCopyTypeValue === 'date' ? (
                  <Button className="w-full" onClick={() => copyItem('ingredient')}>
                    Kopieren
                  </Button>
                ) : (
                  <Button
                    isPending={multiCopyPending}
                    disabled={multiCopyPending}
                    className="w-full"
                    onClick={() => copyMultiItems('day')}
                  >
                    Kopieren
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : copyStatus === 'batch' ? (
          <div className="pb-40">
            <div className="flex gap-20 pt-4">
              <div className="font-light my-auto w-130">Aktuelles Datum:</div>
              <div className="font-light">
                {moment.unix(currentDayItem?.date?.seconds).utc().format('DD[.]MM[.]YY')}
              </div>
            </div>
            <div>
              <div className="pb-10 pt-20 font-extralight">Wie möchtest du kopieren?</div>
              <Select
                options={copyTypeValues}
                className="react-select-container w-full"
                classNamePrefix="react-select"
                unstyled
                value={copyTypeValues.filter((item: any) => item.value === currentCopyTypeValue)}
                onChange={e => setCurrentCopyTypeValue(e?.value)}
                isSearchable={false}
              />
            </div>
            {currentCopyTypeValue === 'date' ? (
              <div>
                <div className="pt-20">
                  <div className="font-extralight">Auf welchen Tag soll kopiert werden?</div>
                </div>
                <div className="pt-5">
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    locale="de"
                    onChange={changeCopyDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
              </div>
            ) : (
              <div>
                {checkBoxArrayOnlyOneMealType && (
                  <div>
                    <div className="pt-30 pb-10 font-extralight">Auf welche Mahlzeiten soll kopiert werden?</div>
                    <div className="flex gap-x-20 flex-wrap gap-y-15 desktop:justify-between">
                      {mealTypeValues.map((item: any, index: number) => (
                        <div key={index}>
                          <Checkbox
                            label={item.label}
                            onClick={() => addUpdateMealtypesCopyArray(item.value)}
                            name={item.label}
                            aria-hidden
                            ref={el => {
                              checkboxRefs.current[index] = el as HTMLInputElement;
                            }}
                            register="test"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="pt-30 pb-10 font-extralight">Auf welche Wochentage soll kopiert werden?</div>
                <div className="flex gap-x-20 flex-wrap gap-y-15 desktop:justify-between desktop:gap-x-10">
                  <div className="w-50">
                    <Checkbox
                      label="Mo"
                      onClick={() => addUpdateWeekDayCopyArray(1)}
                      aria-hidden
                      name="monday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Di"
                      onClick={() => addUpdateWeekDayCopyArray(2)}
                      aria-hidden
                      name="tuesday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Mi"
                      onClick={() => addUpdateWeekDayCopyArray(3)}
                      aria-hidden
                      name="wednesday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Do"
                      onClick={() => addUpdateWeekDayCopyArray(4)}
                      aria-hidden
                      name="thursday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Fr"
                      onClick={() => addUpdateWeekDayCopyArray(5)}
                      aria-hidden
                      name="friday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="Sa"
                      onClick={() => addUpdateWeekDayCopyArray(6)}
                      aria-hidden
                      name="saturday"
                      register="t"
                    />
                  </div>
                  <div className="w-50">
                    <Checkbox
                      label="So"
                      onClick={() => addUpdateWeekDayCopyArray(0)}
                      aria-hidden
                      name="sunday"
                      register="t"
                    />
                  </div>
                </div>
                <div className="pt-30 pb-10 font-extralight">Über welchen Zeitraum soll kopiert werden?</div>
                <div>
                  <div className="font-extralight">Beginnt:</div>
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={startDateMultiCopy}
                    locale="de"
                    onChange={changeMultiCopyStartDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
                <div className="pt-15">
                  <div className="font-extralight">Endet:</div>
                  <DatePicker
                    className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                    dateFormat="dd.MM.yyyy"
                    selected={endDateMultiCopy}
                    locale="de"
                    onChange={changeMultiCopyEndDate}
                    customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
              </div>
            )}

            <div className="pt-50">
              <div>
                {currentCopyTypeValue === 'date' ? (
                  <Button className="w-full" onClick={() => copyItem('ingredient')}>
                    Kopieren
                  </Button>
                ) : (
                  <Button
                    isPending={multiCopyPending}
                    disabled={multiCopyPending}
                    className="w-full"
                    onClick={() => copyMultiItems('batch')}
                  >
                    Kopieren
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : copyIngredientsArray.length === 0 && copyManualItemsArray.length === 0 && copyRecipeArray.length === 0 ? (
          <div className="pb-40">
            <div className="pt-20 text-16">
              <div className="font-extralight">Es sind keine Elemente zum kopieren vorhanden.</div>
              <div className="font-extralight pt-10">Schließe dieses Fenster und starte den Vorgang erneut.</div>
            </div>
            <div className="pt-40">
              <Button className="w-full" onClick={() => setCopyItemOverlayClass('hidden')}>
                Schließen
              </Button>
            </div>
          </div>
        ) : copyStatus === '1' ? (
          <div className="pb-40">
            <div className="pt-20 text-14">
              <div className="font-extralight">Diese Elemente werden kopiert.</div>
              <div className="font-extralight">
                Du kannst einzelne Elemente löschen, wenn du sie nicht kopieren möchtest.
              </div>
            </div>
            <div>
              {copyIngredientsArray?.length > 0 && (
                <div className="pt-10">
                  <div className="font-bold pb-10">Lebensmittel</div>
                  {copyIngredientsArray.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="w-full rounded-20 mb-20 bg-lightGray bg-opacity-10 cursor-pointer border-transparent border-2 hover:border-accentColor py-10 px-20"
                      onClick={() => deleteCopyItem('ingredient', item.id)}
                      onKeyDown={() => deleteCopyItem('ingredient', item.id)}
                      aria-hidden="true"
                    >
                      <div className="flex justify-between">
                        <div>
                          <div>{item.name}</div>
                          <div className="font-extralight text-10">
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              `(${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="my-auto">
                          <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {copyRecipeArray.length > 0 && (
                <div className="pt-10">
                  <div className="font-bold pb-10">Rezepte</div>
                  {copyRecipeArray.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="w-full rounded-20 mb-20 bg-lightGray bg-opacity-10 cursor-pointer border-transparent border-2 hover:border-accentColor py-10 px-20"
                      onClick={() => deleteCopyItem('recipe', item.id)}
                      onKeyDown={() => deleteCopyItem('recipe', item.id)}
                      aria-hidden="true"
                    >
                      <div className="flex justify-between">
                        <div>
                          <div>{item.name}</div>
                          <div className="font-extralight text-10">
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              `(${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="my-auto">
                          <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {copyManualItemsArray.length > 0 && (
                <div className="pt-10">
                  <div className="font-bold pb-10">Manuelle Einträge</div>
                  {copyManualItemsArray.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="w-full rounded-20 mb-20 bg-lightGray bg-opacity-10 cursor-pointer border-transparent border-2 hover:border-accentColor py-10 px-20"
                      onClick={() => deleteCopyItem('manual', item.id)}
                      onKeyDown={() => deleteCopyItem('manual', item.id)}
                      aria-hidden="true"
                    >
                      <div className="flex justify-between">
                        <div>
                          <div>{item.description}</div>
                          <div className="font-extralight text-10">Manuelle Kalorieneingabe</div>
                        </div>
                        <div className="my-auto">
                          <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="pt-10">
                <Button className="w-full" onClick={() => setCopyStatus('2')}>
                  Weiter
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pb-40">
            <div className="pt-20">
              <ButtonBack
                className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                text="Zurück"
                goBack={() => setCopyStatus('1')}
              />
            </div>
            <div className="flex gap-20 pt-4">
              <div className="font-light my-auto w-130">Aktuelles Datum:</div>
              <div className="font-light">
                {moment.unix(currentDayItem?.date?.seconds).utc().format('DD[.]MM[.]YY')}
              </div>
            </div>
            <div className="pt-20 text-14">
              <div className="font-extralight">Auf welchen Tag soll kopiert werden?</div>
            </div>
            <div className="pt-5 ">
              <DatePicker
                className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-accentColor border-opacity-30"
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                locale="de"
                onChange={changeCopyDate}
                customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                calendarStartDay={1}
                onFocus={e => e.target.blur()}
              />
            </div>
            <div className="pt-20 text-14">
              <div className="font-extralight">Auf welche Mahlzeit soll kopiert werden?</div>
            </div>
            <div className="pt-5">
              <Select
                options={mealTypeValues}
                className="react-select-container"
                classNamePrefix="react-select"
                unstyled
                onChange={e => changeIngredientMealTypeCopy(e)}
                value={mealTypeValues.filter((itemPiece: any) => itemPiece.value === changedMealTypeCopy)}
                isSearchable={false}
              />
            </div>

            <div className="pt-50">
              <div>
                <Button
                  disabled={startDate === null || changedMealTypeCopy.length === 0}
                  className="w-full"
                  onClick={() => copyItem('ingredient')}
                >
                  Kopieren
                </Button>
              </div>
            </div>
          </div>
        )}
      </Overlay>

      <Overlay
        headline="Verschieben"
        overlayClass={moveItemOverlayClass}
        setOverlayClass={setMoveItemOverlayClass}
        icon={<SwitchHorizontalIcon width={25} height={25} className="text-brownSemiDark mx-auto" />}
      >
        <div>
          <div className="pt-20 font-extralight">Wohin sollen die Einträge verschoben werden?</div>
          <div>
            <div className="flex gap-20 pt-20">
              <div className="font-light my-auto w-130">Mahlzeit:</div>
              <div className="flex-1">
                <div>
                  <Select
                    options={mealTypeValues}
                    className="react-select-container-full"
                    classNamePrefix="react-select"
                    unstyled
                    value={mealTypeValues.filter((item: any) => item.value === changedMealType)}
                    onChange={e => setChangedMealType(e?.value)}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full pt-100 flex gap-30 pb-40">
            <Button className="w-full" onClick={() => moveBatch()}>
              Verschieben
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        headline="Manuelle Kalorien"
        overlayClass={addManualCaloriesClass}
        setOverlayClass={setAddManualCaloriesClass}
        icon={<RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-20 font-extralight">
            Hier kannst du die manuellen Nährwerte deiner Mahlzeit eingeben und tracken!
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-1/2">Beschreibung:</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualDescriptionRef}
                  thisValue={manualDescription}
                  name="description"
                  onChange={e => changeManualDescription(e)}
                  textCenter={false}
                  type="text"
                  placeHolder="Beschreibung"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-3/4 md:w-1/2">Kalorien (kcal):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualCaloriesRef}
                  thisValue={manualKcal.toString()}
                  name="manualKcal"
                  onChange={e => changeManualCalories(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Kalorien"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-3/4 md:w-1/2">Kohlenhydrate (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualCarbohydratesRef}
                  thisValue={manualCarbohydrates.toString()}
                  name="manualCarbohydrates"
                  onChange={e => changeManualCarbohydrates(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Kohlenhydrate"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-3/4 md:w-1/2">Eiweiß (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualProteinRef}
                  thisValue={manualProtein.toString()}
                  name="manualProtein"
                  onChange={e => changeManualProtein(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Eiweiß"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-3/4 md:w-1/2">Fett (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualFatRef}
                  thisValue={manualFat.toString()}
                  name="manualFat"
                  onChange={e => changeManualFat(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Fett"
                />
              </div>
            </div>
          </div>

          <div className="w-full pt-40 flex gap-30 pb-40">
            <Button disabled={!isManualAddValid} className="w-full" onClick={() => changeManualItem()}>
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <div className={editMenuClass}>
        <div className={styles.mobileButtonGroup}>
          <div className="w-full items-center flex justify-center px-20 space-x-15">
            {checkBoxArrayOnlyIngredients && checkBoxArrayDeactivatedItemsCount === 0 ? (
              <div
                onClick={() => openUserGeneratedRecipePopup()}
                onKeyDown={() => openUserGeneratedRecipePopup()}
                aria-hidden="true"
              >
                <div>
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-10">Mahlzeit</div>
              </div>
            ) : (
              <div>
                <div>
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-10 text-accentColor">Mahlzeit</div>
              </div>
            )}
            {checkBoxArrayOnlyOneActiveStatus ? (
              <>
                {checkBoxArrayDeactivatedItemsCount > 0 ? (
                  <div onClick={() => activateBatch()} onKeyDown={() => activateBatch()} aria-hidden="true">
                    <div>
                      <BanIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                    </div>

                    <div className="text-10">Aktivieren</div>
                  </div>
                ) : (
                  <div onClick={() => deactivateBatch()} onKeyDown={() => deactivateBatch()} aria-hidden="true">
                    <div>
                      <BanIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                    </div>

                    <div className="text-10">Deaktivieren</div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />
                <div>
                  <BanIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                {checkBoxArrayDeactivatedItemsCount > 0 ? (
                  <div className="text-10 text-accentColor">Aktivieren</div>
                ) : (
                  <div className="text-10 text-accentColor">Deaktivieren</div>
                )}
              </div>
            )}
            {checkBoxArrayOnlyOneMealType && checkBoxArrayDeactivatedItemsCount === 0 ? (
              <div onClick={() => openMoveBatchPopup()} onKeyDown={() => openMoveBatchPopup()} aria-hidden="true">
                <div>
                  <SwitchHorizontalIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                </div>
                <div className="text-10">Verschieben</div>
              </div>
            ) : (
              <div>
                <div>
                  <SwitchHorizontalIcon width={25} height={25} className="text-gray-500 mx-auto" />
                </div>
                <div className="text-10 text-gray-500">Verschieben</div>
              </div>
            )}
            {checkBoxArrayDeactivatedItemsCount === 0 ? (
              <>
                <div onClick={() => openCopyBatchPopup()} onKeyDown={() => openCopyBatchPopup()} aria-hidden="true">
                  <div>
                    <DocumentDuplicateIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                  </div>
                  <div className="text-10">Kopieren</div>
                </div>
              </>
            ) : (
              <div>
                <div>
                  <DocumentDuplicateIcon width={25} height={25} className="text-gray-500 mx-auto" />
                </div>
                <div className="text-10 text-gray-500">Kopieren</div>
              </div>
            )}
            <div onClick={() => deleteBatch()} onKeyDown={() => deleteBatch()} aria-hidden="true">
              <div>
                <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
              </div>
              <div className="text-10">Löschen</div>
            </div>
          </div>
        </div>
      </div>

      <div className={clearDayPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <TransitionContainer isShown={clearDayPopupClass === 'block'} isOverlay>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-xl my-auto font-light">Tag leeren</div>
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => handleOpenClosePopups(setClearDayPopupClass, '', 'close')}
                  />
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="pt-30 font-extralight text-base pl-20 pr-15">
                  <div>Wollen Sie diesen Tag wirklich leeren?</div>
                  <div>Alle gespeicherten Daten von diesem Tag werden dabei gelöscht!</div>
                </div>
                <div className="pt-30 pl-20 flex gap-30 pb-40">
                  <div>
                    <Button className="w-full" onClick={() => clearDay()}>
                      Tag leeren
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-full"
                      onClick={() => handleOpenClosePopups(setClearDayPopupClass, '', 'close')}
                    >
                      Abbrechen
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionContainer>
        </div>
      </div>

      <div className={viewIngredientPopupClass}>
        {currentPlanExists && (
          <div className={styles.backgroundPopupLayer}>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <CollectionIcon width={25} height={25} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-xl my-auto font-light">Lebensmittel</div>
                </div>
                <div className="flex gap-20">
                  <div className="my-auto">
                    <HeartIcon
                      width={25}
                      height={25}
                      className="text-accentColor mx-auto cursor-pointer"
                      onClick={() => triggerAddIngredientsToFavorites()}
                      fill={(ingredientFavoriteStatus && '#C97132') || 'none'}
                    />
                  </div>
                  <div className="my-auto pr-20 hidden md:block">
                    <XIcon
                      width={25}
                      height={25}
                      className="text-accentColor mx-auto cursor-pointer"
                      onClick={() => handleOpenClosePopups(setViewIngredientPopupClass, setViewMealPopupClass, 'both')}
                    />
                  </div>
                  <div className="my-auto pr-20 md:hidden">
                    <XIcon
                      width={25}
                      height={25}
                      className="text-accentColor mx-auto cursor-pointer"
                      onClick={() => handleOpenClosePopups(setViewIngredientPopupClass, '', 'close')}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className={styles.editPictureIngridientPopupContent}>
                  {currentDayItem?.[currentMealType].ingredients
                    ?.filter((ingredientItem: any) => ingredientItem.id === currentIngredientId)
                    .map((item: any, index: number) => (
                      <div key={index}>
                        <div className="tablet:pt-30 pt-15">
                          <div className="text-xl pl-25">
                            <div>{item.name}</div>
                          </div>
                        </div>
                        <div className="flex gap-20 pt-4 pl-25">
                          <div className="font-extralight my-auto w-80 tablet:w-130">Menge:</div>
                          <div onClick={() => clickIngridientChange()} aria-hidden="true">
                            <CustomUserInput
                              name="amount"
                              customPaddingY="10"
                              customPaddingX="10"
                              thisRef={editIngridientRef}
                              thisValue={currentEditIngridientAmount}
                              width={
                                currentEditIngridientAmount.toString().length === 0
                                  ? 1
                                  : currentEditIngridientAmount.toString().length
                              }
                              onChange={e => changeIngridientAmount(e, item)}
                              submitFunction={() => changeIngridientExecute(item)}
                            />
                          </div>
                        </div>
                        <div className="flex gap-20 pt-4 pl-25">
                          <div className="font-extralight my-auto w-80 tablet:w-130">Einheit:</div>
                          <div className="flex-1">
                            <div>
                              <Select
                                options={currentEditIngridientPortionValues}
                                className="react-select-container-full"
                                classNamePrefix="react-select"
                                unstyled
                                onChange={e => changeIngridientPiece(e, item)}
                                value={currentEditIngridientPortionValues.filter(
                                  (itemPiece: any) => itemPiece.value === currentEditIngridientPiece.toLowerCase()
                                )}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-20 pt-4 pl-25">
                          <div className="font-extralight my-auto w-80 tablet:w-130">Mahlzeit:</div>
                          <div className="flex-1">
                            <div>
                              <Select
                                options={mealTypeValues}
                                className="react-select-container-full"
                                classNamePrefix="react-select"
                                unstyled
                                onChange={e => changeIngredientMealType(e, item)}
                                value={mealTypeValues.filter((itemPiece: any) => itemPiece.value === changedMealType)}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pt-20 tablet:pt-30">
                          <div className="text-16 tablet:text-xl pl-25">Nährwertangabe</div>
                          <div className="pt-10 flex pl-25">
                            <div className={styles.nutritionBorderItemFirst}>
                              <div>
                                <div className="font-semibold text-base">{currentEditIngridientKcalTotal}</div>
                                <div className={styles.recipeLabel}>kcal</div>
                              </div>
                              <span className="divider" />
                            </div>
                            <div className={styles.nutritionBorderItem}>
                              <div className="font-semibold text-base">{currentEditIngridientCarbohydratesTotal}g</div>
                              <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                            </div>
                            <div className={styles.nutritionBorderItem}>
                              <div className="font-semibold text-base">{currentEditIngridientProteinTotal}g</div>
                              <div className={styles.recipeLabel}>{t('Protein')}</div>
                            </div>
                            <div className="text-center px-20">
                              <div className="font-semibold text-base">{currentEditIngridientFatTotal}g</div>
                              <div className={styles.recipeLabel}>{t('Fat')}</div>
                            </div>
                          </div>

                          {item.imageUrl?.length > 0 && (
                            <>
                              <div className="tablet:pt-40 pt-20 pl-25">
                                <img
                                  src={item.imageUrl}
                                  alt="Ingridient"
                                  className="rounded-2xl w-full h-100 object-cover"
                                  loading="lazy"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="pl-20 pt-30 large:pt-40 pb-20 md:pb-40">
                          {isLoading ? (
                            <div className="flex justify-center items-center pt-20">
                              <ReactLoading
                                type="bars"
                                width={20}
                                height={20}
                                color={theme?.textColor ?? defaultTextColor}
                              />
                            </div>
                          ) : (
                            <>
                              <Button className="w-full" onClick={() => changeIngridientExecute(item)}>
                                {t('Save')}
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={viewMealPopupClass}>
        {currentPlanExists && (
          <div className={styles.backgroundPopupLayer}>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <CollectionIcon width={25} height={25} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-xl my-auto font-light">Mahlzeit Übersicht</div>
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => handleOpenClosePopups(setViewMealPopupClass, '', 'close')}
                  />
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="relative">
                  <div className="pt-20 hidden">
                    <div className="flex w-full gap-4">
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">{getCurrentMealTotal('kcal', currentMealType)}</div>
                        <div className={styles.recipeLabel}>kcal</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {getCurrentMealTotal('carbohydrates', currentMealType)}g
                        </div>
                        <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {getCurrentMealTotal('protein', currentMealType)}g
                        </div>
                        <div className={styles.recipeLabel}>{t('Protein')}</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">{getCurrentMealTotal('fat', currentMealType)}g</div>
                        <div className={styles.recipeLabel}>{t('Fat')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-between flex-wrap gap-10 ml-20 pt-20 hidden">
                    <div className="relative">
                      <div>
                        <Button
                          onClick={() =>
                            handleOpenClosePopups(setViewMealPopupClass, setIngredientSearchPopupClass, 'both')
                          }
                          className="text-12 py-2 pr-40"
                        >
                          Neu
                        </Button>
                      </div>
                      <div className="absolute right-15 top-10">
                        <PlusIcon width={17} height={17} className="text-white" />
                      </div>
                    </div>
                    <div className="relative">
                      <div>
                        <Button onClick={() => setActionClass('block')} className="text-12 py-2 pr-40">
                          Mehr Funktionen
                        </Button>
                      </div>
                      <div className="absolute right-15 top-10">
                        <DotsVerticalIcon width={17} height={17} className="text-white" />
                      </div>
                      <div
                        className={`absolute bg-lightDarkGray shadow-2xl rounded-xl top-40 w-300 border z-100 border-brownSemiDark transition-opacity duration-1000 ${actionClass} ${styles.actionsMenu}`}
                      >
                        <div className="pb-20 relative font-extralight">
                          <div className="flex justify-between px-20 pt-20 pb-20">
                            <div className="flex">
                              <div className="my-auto pr-10">
                                <DotsVerticalIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                              </div>
                              <div className="text-xl my-auto font-light">Funktions-Menü</div>
                            </div>

                            <div>
                              <XIcon
                                width={25}
                                height={25}
                                className="text-brownSemiDark cursor-pointer"
                                onClick={() => setActionClass('hidden')}
                              />
                            </div>
                          </div>

                          <div className="border-transparent border-2 hover:border-brownSemiDark rounded-lg mx-20">
                            <div
                              className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                              onClick={() => openGenerateMealPopup()}
                              onMouseDown={() => openGenerateMealPopup()}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Mahlzeit neu generieren</div>
                              <div>
                                <RefreshIcon width={25} height={25} className="text-brownSemiDark mr-10" />
                              </div>
                            </div>
                          </div>
                          <div className="border-transparent border-2 hover:border-brownSemiDark rounded-lg mx-20">
                            <div
                              className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                              onClick={() => openCopyPopup()}
                              onMouseDown={() => openCopyPopup()}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Kopieren</div>
                              <div>
                                <DocumentDuplicateIcon width={25} height={25} className="text-brownSemiDark mr-10" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-15 pb-30 pl-20">
                    <DashboardMealCardHorizontal
                      kcal_value={currentDayItem[currentMealType]?.kcal_total}
                      carbohydrates_value={currentDayItem[currentMealType]?.carbohydrates_total}
                      protein_value={currentDayItem[currentMealType]?.protein_total}
                      fat_value={currentDayItem[currentMealType]?.fat_total}
                      label={currentMealLabel}
                      mealLabel={
                        currentDayItem[currentMealType]?.recipes?.length > 0
                          ? currentDayItem[currentMealType]?.recipes[0].name
                          : currentDayItem[currentMealType]?.ingredients?.length > 0
                          ? currentDayItem[currentMealType]?.ingredients[0].name
                          : ''
                      }
                      mealCounter={
                        currentDayItem[currentMealType]?.recipes?.length === 0 &&
                        currentDayItem[currentMealType]?.ingredients?.length === 0 &&
                        (currentDayItem[currentMealType]?.manualItems?.length === 0 ||
                          currentDayItem[currentMealType]?.manualItems === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.recipes?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.recipes === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.ingredients?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.ingredients === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.manualItems?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.manualItems === undefined)
                          ? 0
                          : parseFloat(currentDayItem[currentMealType]?.recipes?.length) +
                            parseFloat(currentDayItem[currentMealType]?.ingredients?.length) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.recipes === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.recipes?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.ingredients === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.ingredients?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.manualItems === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.manualItems?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.manualItems === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.manualItems?.length
                            ) -
                            1
                      }
                      mealAndIngredientCount={
                        currentDayItem[currentMealType]?.recipes?.length === 0 &&
                        currentDayItem[currentMealType]?.ingredients?.length === 0 &&
                        (currentDayItem[currentMealType]?.manualItems?.length === 0 ||
                          currentDayItem[currentMealType]?.manualItems === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.recipes?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.recipes === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.ingredients?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.ingredients === undefined) &&
                        (currentDayItem[currentMealType]?.deactivatedItems?.manualItems?.length === 0 ||
                          currentDayItem[currentMealType]?.deactivatedItems?.manualItems === undefined)
                          ? 0
                          : !currentPlanExists
                          ? 0
                          : parseFloat(currentDayItem[currentMealType]?.recipes?.length) +
                            parseFloat(currentDayItem[currentMealType]?.ingredients?.length) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.recipes === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.recipes?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.ingredients === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.ingredients?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.deactivatedItems?.manualItems === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.deactivatedItems?.manualItems?.length
                            ) +
                            parseFloat(
                              currentDayItem[currentMealType]?.manualItems === undefined
                                ? 0
                                : currentDayItem[currentMealType]?.manualItems?.length
                            )
                      }
                      imageUrl={
                        currentDayItem[currentMealType]?.recipes?.length > 0
                          ? currentDayItem[currentMealType]?.recipes[0].imageUrl
                          : currentDayItem[currentMealType]?.ingredients?.length > 0
                          ? currentDayItem[currentMealType]?.ingredients[0].imageUrl
                          : ''
                      }
                      type={
                        currentDayItem[currentMealType]?.recipes?.length === 0 &&
                        currentDayItem[currentMealType]?.ingredients?.length === 0 &&
                        currentDayItem[currentMealType]?.manualItems?.length === 0
                          ? 'add'
                          : 'test'
                      }
                      mealType={currentMealType}
                      onClick={openIngredient}
                      openIngredientSearchPopupClass={openIngredientPopup}
                      dayItem={currentDayItem}
                      openIngredientPopup={openIngredient}
                      openGenerateMealPopup={openGenerateMealPopup}
                      setMealType={setCurrentMealType}
                      setMenuClass={setEditMenuClass}
                      menuClassValue={editMenuClass}
                      dayId={currentDayId}
                      planUid={currentPlan?.origId}
                      openManualItemPopup={openManualItem}
                      checkBoxArray={checkBoxArray}
                      setCheckBoxArray={setCheckBoxArray}
                      isDesktop
                      setDesktopPopupClass={setViewMealPopupClass}
                      desktopPopupClassValue={viewMealPopupClass}
                    />
                  </div>
                </div>

                <div className={editMenuClass}>
                  <div className="pt-30">
                    <></>
                  </div>
                </div>

                <div className={editMenuClass}>
                  <div className={styles.desktopButtonGroup}>
                    <div className="w-full items-center flex justify-between px-20 mx-30 space-x-15">
                      {checkBoxArrayOnlyIngredients && checkBoxArrayDeactivatedItemsCount === 0 ? (
                        <div
                          onClick={() => openUserGeneratedRecipePopup()}
                          onKeyDown={() => openUserGeneratedRecipePopup()}
                          aria-hidden="true"
                          className="cursor-pointer"
                        >
                          <div>
                            <PlusIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                          </div>
                          <div className="text-10">Mahlzeit</div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <PlusIcon width={25} height={25} className="text-gray-500 mx-auto" />
                          </div>
                          <div className="text-10 text-gray-500">Mahlzeit</div>
                        </div>
                      )}
                      {checkBoxArrayOnlyOneActiveStatus ? (
                        <>
                          {checkBoxArrayDeactivatedItemsCount > 0 ? (
                            <div
                              onClick={() => activateBatch()}
                              onKeyDown={() => activateBatch()}
                              aria-hidden="true"
                              className="cursor-pointer"
                            >
                              <div>
                                <BanIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                              </div>

                              <div className="text-10">Aktivieren</div>
                            </div>
                          ) : (
                            <div
                              onClick={() => deactivateBatch()}
                              onKeyDown={() => deactivateBatch()}
                              aria-hidden="true"
                              className="cursor-pointer"
                            >
                              <div>
                                <BanIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                              </div>

                              <div className="text-10">Deaktivieren</div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div>
                          <div>
                            <BanIcon width={25} height={25} className="text-gray-500 mx-auto" />
                          </div>
                          {checkBoxArrayDeactivatedItemsCount > 0 ? (
                            <div className="text-10 text-gray-500">Aktivieren</div>
                          ) : (
                            <div className="text-10 text-gray-500">Deaktivieren</div>
                          )}
                        </div>
                      )}
                      {checkBoxArrayOnlyOneMealType && checkBoxArrayDeactivatedItemsCount === 0 ? (
                        <div
                          onClick={() => openMoveBatchPopup()}
                          onKeyDown={() => openMoveBatchPopup()}
                          aria-hidden="true"
                          className="cursor-pointer"
                        >
                          <div>
                            <SwitchHorizontalIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                          </div>
                          <div className="text-10">Verschieben</div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <SwitchHorizontalIcon width={25} height={25} className="text-gray-500 mx-auto" />
                          </div>
                          <div className="text-10 text-gray-500">Verschieben</div>
                        </div>
                      )}
                      {checkBoxArrayDeactivatedItemsCount === 0 ? (
                        <>
                          <div
                            onClick={() => openCopyBatchPopup()}
                            onKeyDown={() => openCopyBatchPopup()}
                            aria-hidden="true"
                            className="cursor-pointer"
                          >
                            <div>
                              <DocumentDuplicateIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                            </div>
                            <div className="text-10">Kopieren</div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div>
                            <DocumentDuplicateIcon width={25} height={25} className="text-gray-500 mx-auto" />
                          </div>
                          <div className="text-10 text-gray-500">Kopieren</div>
                        </div>
                      )}
                      <div
                        onClick={() => deleteBatch()}
                        onKeyDown={() => deleteBatch()}
                        aria-hidden="true"
                        className="cursor-pointer"
                      >
                        <div>
                          <TrashIcon width={25} height={25} className="text-brownSemiDark mx-auto" />
                        </div>
                        <div className="text-10">Löschen</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={waterEditPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Wasserbedarf ändern</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setWaterEditPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15">
                <div className="text-16">
                  <span className="font-semibold">Aktuelles Ziel:</span> {getWaterGoal('number')} ml
                </div>
                <div className="font-extralight text-12">Dieser Zielwert kommt aus deinem {getWaterGoal('name')}!</div>
                <div className="pt-4">
                  <div className="block font-extralight pb-5">Bitte trage hier dein gewünschtes Ziel ein:</div>
                  <div className="w-100 flex rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2">
                    <div>
                      <input
                        id="calories"
                        name="calories"
                        type="number"
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        className="bg-transparent w-80 mr-10 appearance-none block text-18 font-semibold placeholder-textColor focus:outline-none text-textColor text-center"
                        onChange={updateWaterGoal}
                        ref={changeWaterGoalRef}
                      />
                    </div>
                    <div className="pl-10 font-semibold">ml</div>
                  </div>
                </div>
              </div>
              <div className="pt-30 pl-20 flex flex-wrap gap-30 pb-40">
                <div>
                  <Button className="w-full" onClick={() => changeWaterGoal('profile')}>
                    Im Profil ändern
                  </Button>
                </div>
                <div>
                  <Button className="w-full" onClick={() => changeWaterGoal('day')}>
                    Im aktuellen Tag ändern
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={barcodeEditPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setBarcodeEditPopupClass, '', 'close')}
                />
              </div>
            </div>
            {isLoading ? (
              <div>Loading</div>
            ) : (
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="px-30 pt-20">
                  <div className="font-bold text-18">Coca Cola</div>
                  <div className="font-light text-gray-400">Cola Zero Flasche</div>
                </div>
                <div className="pl-30">
                  <div className="pt-20">
                    <div className="font-extralight my-auto pb-10">Zu welcher Mahlzeit hinzufügen?</div>
                    <div className="w-1/2">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentAddMealTypeValue)}
                          onChange={e => setCurrentAddMealTypeValue(e?.value as string)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-xl pl-30 pt-20">Nährwertangabe</div>
                  <div className="pt-10 flex pl-30">
                    <div className={styles.nutritionBorderItemFirst}>
                      <div>
                        <div className="font-semibold text-base">{currentEditIngridientKcalTotal}</div>
                        <div className={styles.recipeLabel}>kcal</div>
                      </div>
                      <span className="divider" />
                    </div>
                    <div className={styles.nutritionBorderItem}>
                      <div className="font-semibold text-base">{currentEditIngridientCarbohydratesTotal}g</div>
                      <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                    </div>
                    <div className={styles.nutritionBorderItem}>
                      <div className="font-semibold text-base">{currentEditIngridientProteinTotal}g</div>
                      <div className={styles.recipeLabel}>{t('Protein')}</div>
                    </div>
                    <div className="text-center px-20">
                      <div className="font-semibold text-base">{currentEditIngridientFatTotal}g</div>
                      <div className={styles.recipeLabel}>{t('Fat')}</div>
                    </div>
                  </div>
                </div>
                <div className="pt-30 pl-20 flex flex-wrap gap-30 pb-40">
                  <div>
                    <Button className="w-full" onClick={() => changeWaterGoal('profile')}>
                      Hinzufügen
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={barcodePickerVisible ? 'block' : 'hidden'}>
        <div className={styles.backgroundPopupLayer}>
          {barcodePickerContentVisible ? (
            <>
              <div className="absolute top-55 left-20 z-100">
                <div
                  className="rounded-3xl bg-lightGray px-20"
                  onClick={() => setBarcodePickerVisible(false)}
                  onKeyDown={() => setBarcodePickerVisible(false)}
                  aria-hidden="true"
                >
                  <ButtonBack
                    text={t('Return')}
                    className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                  />
                </div>
              </div>
              <div id="barcodeContainer" className="w-screen h-screen absolute" />
              <MobileView>
                <BarcodePicker
                  visible={barcodePickerContentVisible}
                  setVisible={setBarcodePickerVisible}
                  setFoodId={setCurrentApiFoodId}
                  setContentVisible={setBarcodePickerContentVisible}
                  visiblePopup={barcodePickerVisible}
                />
              </MobileView>
            </>
          ) : (
            <div className="h-full w-full md:flex block">
              <div className={styles.loading}>
                <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={userMacroPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <TransitionContainer isShown={userMacroPopupClass === 'block'} isOverlay>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <ChartPieIcon width={25} height={25} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-xl my-auto font-light">Bedarf anpassen</div>
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => handleOpenClosePopups(setUserMacroPopupClass, '', 'close')}
                  />
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="pt-30 font-extralight text-base pl-20 pr-15">
                  <div>Dein aktueller Bedarf:</div>
                  <div className="pt-10 flex md:pl-20">
                    <div className={styles.nutritionBorderItemFirst}>
                      <div>
                        <div className="font-semibold text-base">
                          {currentDayItem?.custom_kcal_total !== undefined
                            ? currentDayItem?.custom_kcal_total
                            : currentPlan?.custom_kcal_total !== undefined
                            ? currentPlan?.custom_kcal_total
                            : Math.round(parseFloat(userData!.calories))}
                        </div>
                        <div className={styles.recipeLabel}>Kalorien</div>
                      </div>
                      <span className="divider" />
                    </div>
                    <div className={styles.nutritionBorderItem}>
                      <div className="font-semibold text-base">
                        {currentDayItem?.custom_carbohydrates_total !== undefined
                          ? currentDayItem?.custom_carbohydrates_total
                          : currentPlan?.custom_carbohydrates_total !== undefined
                          ? currentPlan?.custom_carbohydrates_total
                          : userData?.carbohydrates_total}
                        g
                      </div>
                      <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                    </div>
                    <div className={styles.nutritionBorderItem}>
                      <div className="font-semibold text-base">
                        {currentDayItem?.custom_protein_total !== undefined
                          ? currentDayItem?.custom_protein_total
                          : currentPlan?.custom_protein_total !== undefined
                          ? currentPlan?.custom_protein_total
                          : userData?.protein_total}
                        g
                      </div>
                      <div className={styles.recipeLabel}>{t('Protein')}</div>
                    </div>
                    <div className="text-center px-20">
                      <div className="font-semibold text-base">
                        {currentDayItem?.custom_fat_total !== undefined
                          ? currentDayItem?.custom_fat_total
                          : currentPlan?.custom_fat_total !== undefined
                          ? currentPlan?.custom_fat_total
                          : userData?.fat_total}
                        g
                      </div>
                      <div className={styles.recipeLabel}>{t('Fat')}</div>
                    </div>
                  </div>
                  <div className="font-extralight text-base pt-30">Deine aktuelle Makronährstoff Verteilung:</div>
                  <div className="flex">
                    <div className={styles.makroBorderFirst}>
                      <span className="font-semibold">{currentMakroChangeCarbohydratesPercent}%</span> Kohlenhydrate
                    </div>
                    <div className={styles.makroBorder}>
                      <span className="font-semibold">{currentMakroChangeProteinPercent}%</span> Eiweiß
                    </div>
                    <div className="px-15 text-12">
                      <span className="font-semibold">{currentMakroChangeFatPercent}%</span> Fett
                    </div>
                  </div>
                  {changeCaloriesNeed ? (
                    <>
                      <div className="pt-20">
                        <hr />
                      </div>
                      <div className="pt-10 flex justify-between">
                        <div className="pt-10 text-20 font-semibold">Neuer Bedarf</div>
                        <div className="pt-10 my-auto">
                          <Button onClick={() => setChangeCaloriesNeed(false)} buttonStyle="white" className="py-5">
                            Abbrechen
                          </Button>
                        </div>
                      </div>

                      <div className="pt-4">
                        <div className="block font-extralight pb-5">
                          Wenn du für diesen Tag einen anderen Bedarf nutzen möchtest, trage hier bitte deine Kalorien
                          ein:
                        </div>
                        <div className="w-120 rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2">
                          <input
                            id="calories"
                            name="calories"
                            type="number"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            className="w-100 bg-transparent my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none text-textColor text-center"
                            onChange={updateMakroCalories}
                            ref={changeMakroCaloriesRef}
                            onKeyPress={e => keyPress(e, saveMakroDayChange)}
                          />
                        </div>
                      </div>
                      <div className="font-semibold pt-30">Makronährstoff Verteilung</div>
                      <div className="flex w-300 gap-40 pt-2">
                        <div className="flex-1">
                          <Select
                            name="makroType"
                            className="react-select-container-full"
                            classNamePrefix="react-select"
                            unstyled
                            options={makroTypes}
                            value={
                              currentMakroChangeType !== ''
                                ? makroTypes.filter(st => st.value === currentMakroChangeType)
                                : makroTypes[0]
                            }
                            onChange={updateMakroType}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                      <div className="flex gap-20 md:gap-40 pt-20">
                        <div>
                          <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                          <div className="w-80 rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="carbohydrates"
                              name="carbohydrates"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserCarbohydrates}
                              defaultValue="50"
                              className="w-40 my-auto bg-transparent appearance-none block text-18 pl-5 font-semibold  focus:outline-none text-textColor "
                              ref={changeMakroCarbohydrateRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeCarbohydrates}g
                          </div>
                        </div>

                        <div>
                          <div className="block text-sm font-regular mb-1">Eiweiß</div>
                          <div className="w-80 rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="protein"
                              name="protein"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserProtein}
                              defaultValue="25"
                              className="w-40 my-auto bg-transparent appearance-none block text-18 pl-5 font-semibold focus:outline-none text-textColor "
                              ref={changeMakroProteinRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeProtein}g
                          </div>
                        </div>

                        <div>
                          <div className="block text-sm font-regular mb-1">Fett</div>
                          <div className="w-80 rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                            <input
                              id="fat"
                              name="fat"
                              type="number"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              disabled={currentMakroChangeType !== 'user'}
                              onChange={changeUserFat}
                              defaultValue="25"
                              className="w-40 my-auto bg-transparent appearance-none block text-18 pl-5 font-semibold  focus:outline-none text-textColor "
                              ref={changeMakroFatRef}
                            />
                            <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                              %
                            </span>
                          </div>
                          <div className="w-80 block text-sm font-extralight text-center pt-5">
                            {currentMakroChangeFat}g
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pt-30">
                        <Button onClick={() => setChangeCaloriesNeed(true)}>Bedarf anpassen</Button>
                      </div>
                      <div className="font-extralight pt-20 text-14 pb-40">
                        <span className="font-semibold">Hinweis:</span> In deinem Profil unter Einstellungen -
                        Kalorienbedarf kannst du deinen Bedarf auch für alle Tage einstellen!
                      </div>
                    </>
                  )}
                </div>

                {changeCaloriesNeed && (
                  <div className="pl-20 pt-20 pr-10 pb-40">
                    <Button className="w-full" onClick={() => saveMakroDayChange()}>
                      Bedarf für diesen Tag ändern
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </TransitionContainer>
        </div>
      </div>

      <div className={createPlanPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neuen Plan erstellen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeAddPlanPopup()}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent} ref={recipePopupContentRef}>
              <div className="pt-15 pb-30 px-20">
                {currentCreatePlanStep === '1' && (
                  <NewPlanStep1
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentCreatePlanStep === '2' && (
                  <NewPlanStep2
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentCreatePlanStep === '3' && (
                  <NewPlanStep3
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentCreatePlanStep === '4' && (
                  <NewPlanStep4
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentCreatePlanStep === '5' && (
                  <NewPlanStep5
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}
                {currentCreatePlanStep === '6' && (
                  <NewPlanStep6
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}

                {currentCreatePlanStep === 'copy' && (
                  <CopyPlanStep1
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentCreatePlanStep === 'copy2' && (
                  <CopyPlanStep2
                    currentStep={setCurrentCreatePlanStep}
                    currentStepValue={currentCreatePlanStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentDayLoaded ? (
        <>
          <TransitionContainer isShown={currentDayLoaded}>
            <div className={styles.container}>
              <div className="large:flex block">
                <div className="large:w-1/2 w-full mr-40 large:max-w-2xl">
                  <div className="md:sticky md:top-0" id="mobileScrollViewContainer">
                    <Header>
                      <div className="flex justify-between w-full" id="mobileQuickNav">
                        <div className="h-40 relative desktop:hidden">
                          <img
                            className="h-40 object-contain"
                            width={160}
                            height={40}
                            src={theme?.logo ?? LogoImg}
                            alt="Logo"
                            onClick={() =>
                              (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
                              setIsUserSwitch(true)
                            }
                            aria-hidden
                          />
                          {(userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) && (
                            <>
                              {changeSwitchLoading ? (
                                <div className="w-full flex justify-center">
                                  <ReactLoading
                                    type="bars"
                                    width={20}
                                    height={20}
                                    color={theme?.textColor ?? defaultTextColor}
                                  />
                                </div>
                              ) : (
                                <div onClick={() => setIsUserSwitch(true)} aria-hidden>
                                  <div className="desktop:hidden font-extralight text-12 flex justify-center space-x-5">
                                    <UserCircleIcon
                                      width={15}
                                      height={15}
                                      className="text-textColor cursor-pointer my-auto"
                                    />
                                    <div>
                                      {userData.role === 3
                                        ? 'Admin'
                                        : userData?.role === 6
                                        ? 'Leiter'
                                        : userData?.role === 4
                                        ? 'Trainer'
                                        : 'Nutzer'}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {isUserSwitch && (
                            <div className={styles.userSwitch}>
                              <div className="relative p-25">
                                <div className="absolute right-10 top-15 z-150">
                                  <XIcon
                                    width={20}
                                    height={20}
                                    className="mr-5 my-auto text-accentColor cursor-pointer"
                                    onClick={() => setIsUserSwitch(false)}
                                  />
                                </div>
                                {userData?.role === 3 ? (
                                  <div
                                    className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                                    onClick={() => changeUserViewExecute()}
                                    aria-hidden
                                  >
                                    <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                                    <div className="my-auto">Admin</div>
                                  </div>
                                ) : (
                                  <div
                                    className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                                    onClick={() => changeUserViewExecute()}
                                    aria-hidden
                                  >
                                    <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                                      <></>
                                    </div>
                                    <div className="my-auto">
                                      {userData?.role === 4 || userData?.isTrainerUser
                                        ? 'Trainer'
                                        : userData?.role === 6 || userData?.isLocationAdminUser
                                        ? 'Leiter'
                                        : 'Admin'}
                                    </div>
                                  </div>
                                )}

                                {userData?.role !== 3 ? (
                                  <div
                                    className="flex space-x-5 cursor-pointer"
                                    onClick={() => changeUserViewExecute()}
                                    aria-hidden
                                  >
                                    <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                                    <div className="my-auto">Nutzer</div>
                                  </div>
                                ) : (
                                  <div
                                    className="flex space-x-5 cursor-pointer"
                                    onClick={() => changeUserViewExecute()}
                                    aria-hidden
                                  >
                                    <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                                      <></>
                                    </div>
                                    <div className="my-auto">Nutzer</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <Headline level={1} className="pr-30 hidden md:block">
                          {t('Dashboard')}
                        </Headline>
                        <div className="my-auto tablet:hidden">
                          <div className="relative">
                            <DotsVerticalIcon
                              onClick={() => setActionClassMobile('block')}
                              width={20}
                              height={20}
                              className="text-textColor cursor-pointer"
                            />
                            <div
                              ref={wrapperRef}
                              className={`absolute bg-lightGray shadow-2xl rounded-xl top-40 w-300 border z-100 border-accentColor transition-opacity duration-1000 ${actionClassMobile} ${styles.actionsMenu}`}
                            >
                              <div className="pb-20 relative font-extralight">
                                <div className="flex justify-between px-20 pt-20 pb-20">
                                  <div className="flex">
                                    <div className="my-auto pr-10">
                                      <DotsVerticalIcon width={25} height={25} className="text-accentColor mx-auto" />
                                    </div>
                                    <div className="text-xl my-auto font-light">Funktionen</div>
                                  </div>

                                  <div>
                                    <XIcon
                                      width={25}
                                      height={25}
                                      className="text-accentColor cursor-pointer"
                                      onClick={() => setActionClassMobile('hidden')}
                                    />
                                  </div>
                                </div>
                                {(userData?.role === 2 ||
                                  userData?.isAdminUser ||
                                  userData?.isTrainerUser ||
                                  userData?.isLocationAdminUser ||
                                  localStorage.getItem('currentUserToken') ||
                                  tenantData?.settings?.functions?.notices === undefined ||
                                  tenantData?.settings?.functions?.notices === true) && (
                                  <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                                    <div
                                      className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                                      onClick={() => setMobileNoticeClass('block')}
                                      onMouseDown={() => setMobileNoticeClass('block')}
                                      aria-hidden="true"
                                    >
                                      <div className=" cursor-pointer px-7">Notizen</div>
                                      <div>
                                        <PencilIcon width={25} height={25} className="text-accentColor mr-10" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(userData?.role === 2 ||
                                  userData?.isAdminUser ||
                                  userData?.isTrainerUser ||
                                  userData?.isLocationAdminUser ||
                                  localStorage.getItem('currentUserToken') ||
                                  tenantData?.settings?.functions?.dayRate === undefined ||
                                  tenantData?.settings?.functions?.dayRate === true) && (
                                  <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                                    <div
                                      className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                                      onClick={() => setMobileDayRatingClass('block')}
                                      onMouseDown={() => setMobileDayRatingClass('block')}
                                      aria-hidden="true"
                                    >
                                      <div className=" cursor-pointer px-7">Tagesbewertung</div>
                                      <div>
                                        <EmojiHappyIcon width={25} height={25} className="text-accentColor mr-10" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="my-auto tablet:hidden"
                          onClick={() => openMobileDatePicker()}
                          onKeyDown={() => openMobileDatePicker()}
                          aria-hidden="true"
                        >
                          <DatePicker
                            className="mt-1"
                            dateFormat="dd.MM.yyyy"
                            locale="de"
                            selected={startDateChangeDay}
                            onChange={changeChangeDayDate}
                            calendarStartDay={1}
                            withPortal
                            ref={datepickerRef}
                            customInput={
                              <CalendarIcon width={20} height={20} className="text-textColor cursor-pointer" />
                            }
                          />
                        </div>
                        {(userData?.role === 2 ||
                          tenantData?.settings?.functions?.waterTracker === undefined ||
                          userData?.isAdminUser ||
                          userData?.isTrainerUser ||
                          userData?.isLocationAdminUser ||
                          localStorage.getItem('currentUserToken') ||
                          tenantData?.settings?.functions?.waterTracker === true) && (
                          <div
                            className="my-auto tablet:hidden"
                            onClick={() => setMobileWaterNeedClass('block')}
                            onKeyDown={() => setMobileWaterNeedClass('block')}
                            aria-hidden="true"
                          >
                            <Icon name="waterDrop" className="text-textColor" width={20} height={20} />
                          </div>
                        )}
                        <div
                          className="my-auto pr-10 tablet:hidden"
                          onClick={() => setBarcodePickerVisible(true)}
                          onKeyDown={() => setBarcodePickerVisible(true)}
                          aria-hidden="true"
                        >
                          <Icon name="barCode" className="text-textColor" width={25} height={25} />
                        </div>
                      </div>
                    </Header>

                    {currentPlan.planType === 'manual' ? (
                      <></>
                    ) : currentPlanExists ? (
                      <Link
                        to={{
                          pathname: `/plans/${currentPlan.uid}`,
                          state: {
                            from: 'dashboard',
                            planDayId: currentDayId,
                          },
                        }}
                      >
                        <div>
                          <div className="font-light text-14">
                            Aktueller Plan - {moment.unix(currentPlan.startDate.seconds).utc().format('DD[.]MM[.]')} -{' '}
                            {moment.unix(currentPlan.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                          </div>
                          <div className="flex">
                            <div className="font-extralight text-25 hover:underline">{currentPlan.name}</div>
                            <div className="my-auto">
                              <ChevronRightIcon width={25} height={25} className="text-accentColor" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="hidden md:block">
                        <div className="font-light text-14">An diesem Tag hast du noch keinen Plan.</div>
                        <div className="font-light text-14">Möchtest du dir einen erstellen?</div>
                        <div
                          className="flex cursor-pointer"
                          onClick={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                          onKeyDown={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                          aria-hidden="true"
                        >
                          <div className="font-extralight text-25 hover:underline">Neuen Plan erstellen</div>
                          <div className="my-auto">
                            <ChevronRightIcon width={25} height={25} className="text-accentColor" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mx-20 pt-10 md:hidden">
                      <SwitchSelector
                        onChange={changeSwitch}
                        options={displayTypeOptionsDashboard}
                        initialSelectedIndex={0}
                        forcedSelectedIndex={currentSwitchIndex}
                        fontSize={16}
                        selectionIndicatorMargin={0}
                        selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                        fontColor={theme?.textColor ?? defaultTextColor}
                      />
                    </div>
                    <div className="mx-20 pt-30 hidden md:block">
                      <SwitchSelector
                        onChange={changeSwitch}
                        options={displayTypeOptionsDashboard}
                        initialSelectedIndex={0}
                        forcedSelectedIndex={currentSwitchIndex}
                        fontSize={20}
                        selectionIndicatorMargin={0}
                        selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                        fontColor={theme?.textColor ?? defaultTextColor}
                      />
                    </div>
                    {currentSection === 'day' ? (
                      <>
                        <div className="pt-20">
                          <div className="flex tablet:hidden justify-between mx-30">
                            <div className="my-auto ">
                              <ChevronLeftIcon
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                                onClick={() => dayBackward()}
                              />
                            </div>
                            <div className="flex gap-20">
                              <div>
                                {moment().format('YYYYMMDD') === currentDayDateMoment.format('YYYYMMDD') ? (
                                  <>
                                    <div className="text-center font-extralight text-13">Heute</div>
                                    <div className="font-light text-16 md:text-25">
                                      {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                    </div>
                                  </>
                                ) : moment().subtract(1, 'day').format('YYYYMMDD') ===
                                  currentDayDateMoment.format('YYYYMMDD') ? (
                                  <>
                                    <div className="text-center font-extralight text-13">Gestern</div>
                                    <div className="font-light text-16 md:text-25">
                                      {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                    </div>
                                  </>
                                ) : moment().subtract(-1, 'day').format('YYYYMMDD') ===
                                  currentDayDateMoment.format('YYYYMMDD') ? (
                                  <>
                                    <div className="text-center font-extralight text-13">Morgen</div>
                                    <div className="font-light text-16 md:text-25">
                                      {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-center font-extralight text-13">
                                      {currentDayDateMoment.format('dddd')}
                                    </div>
                                    <div className="font-light text-16 md:text-25">
                                      {currentDayDateMoment.format('DD[.]MM[.]YY')}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="my-auto">
                              <ChevronRightIcon
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                                onClick={() => dayForward()}
                              />
                            </div>
                          </div>
                          <div className="mx-30 hidden tablet:block">
                            <div className="hidden tablet:flex justify-center">
                              <div className="hidden xl:block ml-auto w-100">
                                <></>
                              </div>
                              <div className="flex gap-20">
                                <div>
                                  {moment().format('YYYYMMDD') === currentDayDateMoment.format('YYYYMMDD') ? (
                                    <>
                                      <div className="text-center font-extralight text-13">Heute</div>
                                      <div className="font-light text-16 md:text-25">
                                        {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                      </div>
                                    </>
                                  ) : moment().subtract(1, 'day').format('YYYYMMDD') ===
                                    currentDayDateMoment.format('YYYYMMDD') ? (
                                    <>
                                      <div className="text-center font-extralight text-13">Gestern</div>
                                      <div className="font-light text-16 md:text-25">
                                        {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                      </div>
                                    </>
                                  ) : moment().subtract(-1, 'day').format('YYYYMMDD') ===
                                    currentDayDateMoment.format('YYYYMMDD') ? (
                                    <>
                                      <div className="text-center font-extralight text-13">Morgen</div>
                                      <div className="font-light text-16 md:text-25">
                                        {currentDayDateMoment.format('dd[, ]DD[.]MM[.]YY')}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="text-center font-extralight text-13">
                                        {currentDayDateMoment.format('dddd')}
                                      </div>
                                      <div className="font-light text-16 md:text-25">
                                        {currentDayDateMoment.format('DD[.]MM[.]YY')}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="flex gap-20 ml-auto w-100">
                                <div className="my-auto ">
                                  <ChevronLeftIcon
                                    width={25}
                                    height={25}
                                    className="text-brownSemiDark cursor-pointer"
                                    onClick={() => dayBackward()}
                                  />
                                </div>
                                <div
                                  className="hidden tablet:block my-auto"
                                  onClick={() => openMobileDatePicker()}
                                  onKeyDown={() => openMobileDatePicker()}
                                  aria-hidden="true"
                                >
                                  <DatePicker
                                    className="mt-1"
                                    dateFormat="dd.MM.yyyy"
                                    locale="de"
                                    selected={startDateChangeDay}
                                    onChange={changeChangeDayDate}
                                    calendarStartDay={1}
                                    withPortal
                                    ref={datepickerRef}
                                    customInput={
                                      <CalendarIcon width={20} height={20} className="text-textcolor cursor-pointer" />
                                    }
                                  />
                                </div>
                                <div className="my-auto">
                                  <ChevronRightIcon
                                    width={25}
                                    height={25}
                                    className="text-brownSemiDark cursor-pointer"
                                    onClick={() => dayForward()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="rounded-3xl bg-lightGray md:mt-40 mt-20 md:py-40 py-20 px-10 md:px-20 daySwitch"
                          id="scrollMobile"
                        >
                          <MakroCircles
                            editFunction={openMakroChangePopup}
                            kcal_value={currentDayItem?.kcal_total}
                            carbohydrates_value={currentDayItem?.carbohydrates_total}
                            protein_value={currentDayItem?.protein_total}
                            fat_value={currentDayItem?.fat_total}
                            custom_kcal_value={
                              currentDayItem?.custom_kcal_total
                                ? currentDayItem?.custom_kcal_total
                                : currentPlan?.custom_kcal_total
                                ? currentPlan?.custom_kcal_total
                                : undefined
                            }
                            custom_carbohydrates_value={
                              currentDayItem?.custom_carbohydrates_total
                                ? currentDayItem?.custom_carbohydrates_total
                                : currentPlan?.custom_carbohydrates_total
                                ? currentPlan?.custom_carbohydrates_total
                                : undefined
                            }
                            custom_protein_value={
                              currentDayItem?.custom_protein_total
                                ? currentDayItem?.custom_protein_total
                                : currentPlan?.custom_protein_total
                                ? currentPlan?.custom_protein_total
                                : undefined
                            }
                            custom_fat_value={
                              currentDayItem?.custom_fat_total
                                ? currentDayItem?.custom_fat_total
                                : currentPlan?.custom_fat_total
                                ? currentPlan?.custom_fat_total
                                : undefined
                            }
                            withActivities
                            dayDate={currentDayDateMoment.format('YYYY-MM-DD')}
                            activitiesSaveFunction={updateHealthData}
                            planDayHealthData={currentDayItem?.healthData}
                            setTriggeredStepCount={setTriggeredStepCount}
                            setTriggeredWorkoutCalories={setTriggeredWorkoutCalories}
                            setTriggeredWorkoutMinutes={setTriggeredWorkoutMinutes}
                            setTriggerUpdate={setTriggerUpdate}
                          />
                        </div>
                        <div className="md:hidden">
                          <TrialContainer />
                        </div>
                      </>
                    ) : (
                      <div>
                        <DashBoardWeekOverview
                          planStateValue={currentPlan}
                          currentDate={currentDayDateMoment}
                          withClick
                          changeDayFunction={changeDayFromDate}
                          switchIndex={setCurrentSwitchIndex}
                          switchSection={setCurrentSection}
                        />
                      </div>
                    )}
                  </div>
                  <div className="absolute top-0 w-full mr-40 max-w-2xl">
                    <div
                      className={classNames(visibleScrollCircles ? 'sticky md:hidden' : 'hidden', styles.mobileCircles)}
                    >
                      <div className="">
                        <MakroCircles
                          editFunction={openMakroChangePopup}
                          kcal_value={currentDayItem?.kcal_total}
                          carbohydrates_value={currentDayItem?.carbohydrates_total}
                          protein_value={currentDayItem?.protein_total}
                          fat_value={currentDayItem?.fat_total}
                          custom_kcal_value={
                            currentDayItem?.custom_kcal_total
                              ? currentDayItem?.custom_kcal_total
                              : currentPlan?.custom_kcal_total
                              ? currentPlan?.custom_kcal_total
                              : undefined
                          }
                          custom_carbohydrates_value={
                            currentDayItem?.custom_carbohydrates_total
                              ? currentDayItem?.custom_carbohydrates_total
                              : currentPlan?.custom_carbohydrates_total
                              ? currentPlan?.custom_carbohydrates_total
                              : undefined
                          }
                          custom_protein_value={
                            currentDayItem?.custom_protein_total
                              ? currentDayItem?.custom_protein_total
                              : currentPlan?.custom_protein_total
                              ? currentPlan?.custom_protein_total
                              : undefined
                          }
                          custom_fat_value={
                            currentDayItem?.custom_fat_total
                              ? currentDayItem?.custom_fat_total
                              : currentPlan?.custom_fat_total
                              ? currentPlan?.custom_fat_total
                              : undefined
                          }
                          smallCircles
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden gap-10 font-extralight">
                  <div className="text-12">Quelle Lebensmittel-Datenbank:</div>
                  <a
                    target="_blank"
                    href="https://www.fatsecret.com/calories-nutrition/"
                    rel="noreferrer"
                    className="my-auto underline"
                  >
                    <div className="text-12 flex gap-5">
                      <div>fatsecret.com</div>
                      <div className="cursor-pointer">
                        <div className="my-auto">
                          <ExternalLinkIcon className="text-accentColor" height={15} width={15} />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="large:w-1/2 w-full pt-30 md:pt-130 large:max-w-2xl" ref={leftColumnRef}>
                  <div>
                    <div className="flex flex-wrap">
                      <div className="font-extralight text-22 md:text-25 md:pb-10 my-auto pr-15">Tagesplan</div>
                      <div>
                        {currentDayLoaded && (
                          <>
                            <ShepherdTour
                              steps={[
                                {
                                  id: 'intro',
                                  attachTo: { element: '#mobileQuickNav', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Schnellstart-Menü!',
                                  text: [
                                    '<p>Dieses Menü wird immer oben angezeigt und du hast Zugriff auf die wichtigsten Funktionen wie z.B. Barcode-Scanner, Wassertracker oder den Kalendar zum wechseln des Tages</p>',
                                  ],
                                  when: {
                                    destroy() {
                                      updateTourToFirebase(userData, 'dashboard', authContext.user?.uid!, tenant);
                                      setTimeout(() => {
                                        document.getElementById('mainContainer')?.scrollIntoView();
                                      }, 1);
                                    },
                                  },
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '.generateDay', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tag automatisch generieren!',
                                  text: [
                                    '<p>An dieser Stelle kannst du dir deinen Tag von unserem Alghorithmus vorschlagen lassen.</p> <p>In dem folgenden Dialog kannst du noch weitere Einstellungen vornehmen.</p>',
                                  ],
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '#tourMobile', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Zurück',
                                      type: 'back',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Beenden',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tages Mahlzeiten!',
                                  text: [
                                    '<p>An dieser Stelle werden dir die bereits hinzugefügten Mahlzeiten des Tages angezeigt.</p> <p>Durch Klick auf einen Eintrag wird dir ein Menü angezeigt wo du auf weitere Funktionen wie z.B. Kopieren, Löschen oder auch eigene Mahlzeiten erstellen kannst.</p>',
                                  ],
                                },
                              ]}
                              tourOptions={tourOptions}
                            >
                              <TourButtonMobile />
                            </ShepherdTour>

                            <ShepherdTour
                              steps={[
                                {
                                  id: 'intro',
                                  attachTo: { element: '.daySwitch', on: 'auto' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tages und Wochenansicht!',
                                  text: [
                                    'An dieser Stelle kannst du zwischen der Tagesansicht und der Wochenansicht umschalten.',
                                  ],
                                  when: {
                                    destroy() {
                                      updateTourToFirebase(userData, 'dashboard', authContext.user?.uid!, tenant);
                                      setTimeout(() => {
                                        document.getElementById('mainContainer')?.scrollIntoView();
                                      }, 1);
                                    },
                                  },
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '.generateDay', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tag automatisch generieren!',
                                  text: [
                                    '<p>An dieser Stelle kannst du dir deinen Tag von unserem Alghorithmus vorschlagen lassen.</p> <p>In dem folgenden Dialog kannst du noch weitere Einstellungen vornehmen.</p>',
                                  ],
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '.mealCards', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tages Mahlzeiten!',
                                  text: [
                                    '<p>An dieser Stelle werden dir die bereits hinzugefügten Mahlzeiten des Tages angezeigt.</p> <p>Durch Klick auf eine Mahlzeit kannst du neue Elemente hinzufügen, ändern oder löschen.</p>',
                                  ],
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '.waterNeed', on: 'top' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-secondary',
                                      text: 'Beenden',
                                      type: 'cancel',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Weiter',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Wasserbedarf tracken!',
                                  text: [
                                    'An dieser Stelle kannst du deinen Wasserbedarf tracken und deine benötigte Menge bei Bedarf einstellen.',
                                  ],
                                },
                                {
                                  id: 'intro',
                                  attachTo: { element: '.dayRating', on: 'bottom' },
                                  buttons: [
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Zurück',
                                      type: 'back',
                                    },
                                    {
                                      classes: 'shepherd-button-primary',
                                      text: 'Beenden',
                                      type: 'next',
                                    },
                                  ],
                                  classes: 'custom-class-name-1 custom-class-name-2',
                                  highlightClass: 'highlight',
                                  scrollTo: true,
                                  cancelIcon: {
                                    enabled: true,
                                  },
                                  title: 'Tagesbewertung!',
                                  text: [
                                    '<p>An dieser Stelle kannst du die Tagesbewertung von dem aktuellen Tag einstellen.</p> <p>Das hilft dir um später einmal zu prüfen wie du dich an den einzelnen Tagen gefühlt hast!</p>',
                                  ],
                                },
                              ]}
                              tourOptions={tourOptions}
                            >
                              <TourButton />
                            </ShepherdTour>
                          </>
                        )}
                      </div>
                      {(userData?.role === 2 ||
                        tenantData?.settings?.functions?.recipeGenerator === undefined ||
                        userData?.isAdminUser ||
                        userData?.isTrainerUser ||
                        userData?.isLocationAdminUser ||
                        localStorage.getItem('currentUserToken') ||
                        tenantData?.settings?.functions?.recipeGenerator === true) && (
                        <div className="md:hidden relative my-auto">
                          <div className="">
                            <Button onClick={() => openGeneratePopup('day')} className="text-12 py-1 pr-40 my-auto">
                              Tag neu generieren
                            </Button>
                          </div>
                          <div className="absolute right-15 top-5 md:hidden block">
                            <RefreshIcon width={15} height={15} className="text-buttonTextColor" />
                          </div>
                        </div>
                      )}

                      <div className="flex flex-wrap">
                        {currentDayItem?.kcal_total === 0 &&
                        (currentDayItem?.breakfast?.deactivatedItems?.recipes?.length > 0 ||
                          currentDayItem?.breakfast?.deactivatedItems?.ingredients?.length > 0 ||
                          currentDayItem?.breakfast?.deactivatedItems?.manualItems?.length > 0 ||
                          currentDayItem?.lunch?.deactivatedItems?.recipes?.length > 0 ||
                          currentDayItem?.lunch?.deactivatedItems?.ingredients?.length > 0 ||
                          currentDayItem?.lunch?.deactivatedItems?.manualItems?.length > 0 ||
                          currentDayItem?.dinner?.deactivatedItems?.recipes?.length > 0 ||
                          currentDayItem?.dinner?.deactivatedItems?.ingredients?.length > 0 ||
                          currentDayItem?.dinner?.deactivatedItems?.manualItems?.length > 0 ||
                          currentDayItem?.snacks?.deactivatedItems?.recipes?.length > 0 ||
                          currentDayItem?.snacks?.deactivatedItems?.ingredients?.length > 0 ||
                          currentDayItem?.snacks?.deactivatedItems?.manualItems?.length > 0) ? (
                          <div className="pt-10 md:pt-0 pr-20 md:pr-15 md:mb-10">
                            <div className="relative">
                              <div>
                                <Button
                                  onClick={() => setClearDayPopupClass('block')}
                                  className="text-12 md:py-2 py-1 pr-40"
                                >
                                  Tag leeren
                                </Button>
                              </div>
                              <div className="absolute right-15 top-10 hidden md:block">
                                <TrashIcon width={17} height={17} className="text-white" />
                              </div>
                              <div className="absolute right-15 top-5 block md:hidden">
                                <TrashIcon width={15} height={15} className="text-white" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {currentDayItem?.kcal_total > 0 && (
                          <div className="flex">
                            <div className="pt-10 md:pt-0 pr-20 md:pr-15 md:mb-10">
                              <div className="relative">
                                <div>
                                  <Button
                                    onClick={() => setClearDayPopupClass('block')}
                                    className="text-12 md:py-2 py-1 pr-40"
                                  >
                                    Tag leeren
                                  </Button>
                                </div>
                                <div className="absolute right-15 top-10 hidden md:block">
                                  <TrashIcon width={17} height={17} className="text-buttonTextColor" />
                                </div>
                                <div className="absolute right-15 top-5 block md:hidden">
                                  <TrashIcon width={15} height={15} className="text-buttonTextColor" />
                                </div>
                              </div>
                            </div>
                            <div className="pt-10 md:pt-0 md:pr-15">
                              <div className="relative">
                                <div>
                                  <Button onClick={() => openCopyDayPopup()} className="text-12 md:py-2 py-1 pr-40">
                                    Tag kopieren
                                  </Button>
                                </div>
                                <div className="absolute right-15 top-10 hidden md:block">
                                  <DocumentDuplicateIcon width={17} height={17} className="text-buttonTextColor" />
                                </div>
                                <div className="absolute right-15 top-5 block md:hidden">
                                  <DocumentDuplicateIcon width={15} height={15} className="text-buttonTextColor" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {(userData?.role === 2 ||
                          tenantData?.settings?.functions?.recipeGenerator === undefined ||
                          userData?.isAdminUser ||
                          userData?.isTrainerUser ||
                          userData?.isLocationAdminUser ||
                          localStorage.getItem('currentUserToken') ||
                          tenantData?.settings?.functions?.recipeGenerator === true) && (
                          <div className="relative generateDay">
                            <div className="hidden md:block">
                              <Button onClick={() => openGeneratePopup('day')} className="text-12 py-2 pr-40">
                                Tag neu generieren
                              </Button>
                            </div>
                            <div className="absolute right-15 top-10 hidden md:block">
                              <RefreshIcon width={17} height={17} className="text-buttonTextColor" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="md:hidden pt-30 pb-40" id="tourMobile">
                      <div ref={horizontalBreakfastRef}>
                        <DashboardMealCardHorizontal
                          kcal_value={currentDayItem?.breakfast?.kcal_total}
                          carbohydrates_value={currentDayItem?.breakfast?.carbohydrates_total}
                          protein_value={currentDayItem?.breakfast?.protein_total}
                          fat_value={currentDayItem?.breakfast?.fat_total}
                          label="Frühstück"
                          mealLabel={
                            currentDayItem?.breakfast?.recipes?.length > 0
                              ? currentDayItem?.breakfast?.recipes[0].name
                              : currentDayItem?.breakfast?.ingredients?.length > 0
                              ? currentDayItem?.breakfast?.ingredients[0].name
                              : ''
                          }
                          mealCounter={
                            currentDayItem?.breakfast?.recipes?.length === 0 &&
                            currentDayItem?.breakfast?.ingredients?.length === 0 &&
                            (currentDayItem?.breakfast?.manualItems?.length === 0 ||
                              currentDayItem?.breakfast?.manualItems === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : parseFloat(currentDayItem?.breakfast?.recipes?.length) +
                                parseFloat(currentDayItem?.breakfast?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.manualItems?.length
                                ) -
                                1
                          }
                          mealAndIngredientCount={
                            currentDayItem?.breakfast?.recipes?.length === 0 &&
                            currentDayItem?.breakfast?.ingredients?.length === 0 &&
                            (currentDayItem?.breakfast?.manualItems?.length === 0 ||
                              currentDayItem?.breakfast?.manualItems === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.breakfast?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.breakfast?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : !currentPlanExists
                              ? 0
                              : parseFloat(currentDayItem?.breakfast?.recipes?.length) +
                                parseFloat(currentDayItem?.breakfast?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.breakfast?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.breakfast?.manualItems?.length
                                )
                          }
                          imageUrl={
                            currentDayItem?.breakfast?.recipes?.length > 0
                              ? currentDayItem?.breakfast?.recipes[0].imageUrl
                              : currentDayItem?.breakfast?.ingredients?.length > 0
                              ? currentDayItem?.breakfast?.ingredients[0].imageUrl
                              : ''
                          }
                          type={
                            currentDayItem?.breakfast?.recipes?.length === 0 &&
                            currentDayItem?.breakfast?.ingredients?.length === 0 &&
                            currentDayItem?.breakfast?.manualItems?.length === 0
                              ? 'add'
                              : 'test'
                          }
                          mealType="breakfast"
                          onClick={openIngredient}
                          openIngredientSearchPopupClass={openIngredientPopup}
                          dayItem={currentDayItem}
                          openIngredientPopup={openIngredient}
                          openGenerateMealPopup={openGenerateMealPopup}
                          setMealType={setCurrentMealType}
                          setMenuClass={setEditMenuClass}
                          menuClassValue={editMenuClass}
                          dayId={currentDayId}
                          planUid={currentPlan?.origId}
                          openManualItemPopup={openManualItem}
                          checkBoxArray={checkBoxArray}
                          setCheckBoxArray={setCheckBoxArray}
                        />
                      </div>
                      <div ref={horizontalLunchRef}>
                        <DashboardMealCardHorizontal
                          kcal_value={currentDayItem?.lunch?.kcal_total}
                          carbohydrates_value={currentDayItem?.lunch?.carbohydrates_total}
                          protein_value={currentDayItem?.lunch?.protein_total}
                          fat_value={currentDayItem?.lunch?.fat_total}
                          label="Mittagessen"
                          mealLabel={
                            currentDayItem?.lunch?.recipes?.length > 0
                              ? currentDayItem?.lunch?.recipes[0].name
                              : currentDayItem?.lunch?.ingredients?.length > 0
                              ? currentDayItem?.lunch?.ingredients[0].name
                              : ''
                          }
                          mealCounter={
                            currentDayItem?.lunch?.recipes?.length === 0 &&
                            currentDayItem?.lunch?.ingredients?.length === 0 &&
                            (currentDayItem?.lunch?.manualItems?.length === 0 ||
                              currentDayItem?.lunch?.manualItems === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : parseFloat(currentDayItem?.lunch?.recipes?.length) +
                                parseFloat(currentDayItem?.lunch?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.manualItems?.length
                                ) -
                                1
                          }
                          mealAndIngredientCount={
                            currentDayItem?.lunch?.recipes?.length === 0 &&
                            currentDayItem?.lunch?.ingredients?.length === 0 &&
                            (currentDayItem?.lunch?.manualItems?.length === 0 ||
                              currentDayItem?.lunch?.manualItems === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.lunch?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.lunch?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : !currentPlanExists
                              ? 0
                              : parseFloat(currentDayItem?.lunch?.recipes?.length) +
                                parseFloat(currentDayItem?.lunch?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.lunch?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.lunch?.manualItems?.length
                                )
                          }
                          imageUrl={
                            currentDayItem?.lunch?.recipes?.length > 0
                              ? currentDayItem?.lunch?.recipes[0].imageUrl
                              : currentDayItem?.lunch?.ingredients?.length > 0
                              ? currentDayItem?.lunch?.ingredients[0].imageUrl
                              : ''
                          }
                          type={
                            currentDayItem?.lunch?.recipes?.length === 0 &&
                            currentDayItem?.lunch?.ingredients?.length === 0 &&
                            currentDayItem?.lunch?.manualItems?.length === 0
                              ? 'add'
                              : 'test'
                          }
                          mealType="lunch"
                          onClick={openMealPopup}
                          openIngredientSearchPopupClass={openIngredientPopup}
                          dayItem={currentDayItem}
                          openIngredientPopup={openIngredient}
                          openGenerateMealPopup={openGenerateMealPopup}
                          setMealType={setCurrentMealType}
                          setMenuClass={setEditMenuClass}
                          menuClassValue={editMenuClass}
                          dayId={currentDayId}
                          planUid={currentPlan?.origId}
                          openManualItemPopup={openManualItem}
                          checkBoxArray={checkBoxArray}
                          setCheckBoxArray={setCheckBoxArray}
                        />
                      </div>
                      <div ref={horizontalDinnerRef}>
                        <DashboardMealCardHorizontal
                          kcal_value={currentDayItem?.dinner?.kcal_total}
                          carbohydrates_value={currentDayItem?.dinner?.carbohydrates_total}
                          protein_value={currentDayItem?.dinner?.protein_total}
                          fat_value={currentDayItem?.dinner?.fat_total}
                          label="Abendessen"
                          mealLabel={
                            currentDayItem?.dinner?.recipes?.length > 0
                              ? currentDayItem?.dinner?.recipes[0].name
                              : currentDayItem?.dinner?.ingredients?.length > 0
                              ? currentDayItem?.dinner?.ingredients[0].name
                              : ''
                          }
                          mealCounter={
                            currentDayItem?.dinner?.recipes?.length === 0 &&
                            currentDayItem?.dinner?.ingredients?.length === 0 &&
                            (currentDayItem?.dinner?.manualItems?.length === 0 ||
                              currentDayItem?.dinner?.manualItems === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : parseFloat(currentDayItem?.dinner?.recipes?.length) +
                                parseFloat(currentDayItem?.dinner?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.manualItems?.length
                                ) -
                                1
                          }
                          mealAndIngredientCount={
                            currentDayItem?.dinner?.recipes?.length === 0 &&
                            currentDayItem?.dinner?.ingredients?.length === 0 &&
                            (currentDayItem?.dinner?.manualItems?.length === 0 ||
                              currentDayItem?.dinner?.manualItems === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.dinner?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.dinner?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : !currentPlanExists
                              ? 0
                              : parseFloat(currentDayItem?.dinner?.recipes?.length) +
                                parseFloat(currentDayItem?.dinner?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.dinner?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.dinner?.manualItems?.length
                                )
                          }
                          imageUrl={
                            currentDayItem?.dinner?.recipes?.length > 0
                              ? currentDayItem?.dinner?.recipes[0].imageUrl
                              : currentDayItem?.dinner?.ingredients?.length > 0
                              ? currentDayItem?.dinner?.ingredients[0].imageUrl
                              : ''
                          }
                          type={
                            currentDayItem?.dinner?.recipes?.length === 0 &&
                            currentDayItem?.dinner?.ingredients?.length === 0 &&
                            currentDayItem?.dinner?.manualItems?.length === 0
                              ? 'add'
                              : 'test'
                          }
                          mealType="dinner"
                          onClick={openMealPopup}
                          openIngredientSearchPopupClass={openIngredientPopup}
                          dayItem={currentDayItem}
                          openIngredientPopup={openIngredient}
                          openGenerateMealPopup={openGenerateMealPopup}
                          setMealType={setCurrentMealType}
                          setMenuClass={setEditMenuClass}
                          menuClassValue={editMenuClass}
                          dayId={currentDayId}
                          planUid={currentPlan?.origId}
                          openManualItemPopup={openManualItem}
                          checkBoxArray={checkBoxArray}
                          setCheckBoxArray={setCheckBoxArray}
                        />
                      </div>
                      <div ref={horizontalSnacksRef}>
                        <DashboardMealCardHorizontal
                          kcal_value={currentDayItem?.snacks?.kcal_total}
                          carbohydrates_value={currentDayItem?.snacks?.carbohydrates_total}
                          protein_value={currentDayItem?.snacks?.protein_total}
                          fat_value={currentDayItem?.snacks?.fat_total}
                          label="Snacks"
                          mealLabel={
                            currentDayItem?.snacks?.recipes?.length > 0
                              ? currentDayItem?.snacks?.recipes[0].name
                              : currentDayItem?.snacks?.ingredients?.length > 0
                              ? currentDayItem?.snacks?.ingredients[0].name
                              : ''
                          }
                          mealCounter={
                            currentDayItem?.snacks?.recipes?.length === 0 &&
                            currentDayItem?.snacks?.ingredients?.length === 0 &&
                            (currentDayItem?.snacks?.manualItems?.length === 0 ||
                              currentDayItem?.snacks?.manualItems === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : parseFloat(currentDayItem?.snacks?.recipes?.length) +
                                parseFloat(currentDayItem?.snacks?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.manualItems?.length
                                ) -
                                1
                          }
                          mealAndIngredientCount={
                            currentDayItem?.snacks?.recipes?.length === 0 &&
                            currentDayItem?.snacks?.ingredients?.length === 0 &&
                            (currentDayItem?.snacks?.manualItems?.length === 0 ||
                              currentDayItem?.snacks?.manualItems === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.recipes?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.recipes === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.ingredients?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.ingredients === undefined) &&
                            (currentDayItem?.snacks?.deactivatedItems?.manualItems?.length === 0 ||
                              currentDayItem?.snacks?.deactivatedItems?.manualItems === undefined)
                              ? 0
                              : !currentPlanExists
                              ? 0
                              : parseFloat(currentDayItem?.snacks?.recipes?.length) +
                                parseFloat(currentDayItem?.snacks?.ingredients?.length) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.recipes === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.recipes?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.ingredients === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.ingredients?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.deactivatedItems?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.deactivatedItems?.manualItems?.length
                                ) +
                                parseFloat(
                                  currentDayItem?.snacks?.manualItems === undefined
                                    ? 0
                                    : currentDayItem?.snacks?.manualItems?.length
                                )
                          }
                          imageUrl={
                            currentDayItem?.snacks?.recipes?.length > 0
                              ? currentDayItem?.snacks?.recipes[0].imageUrl
                              : currentDayItem?.snacks?.ingredients?.length > 0
                              ? currentDayItem?.snacks?.ingredients[0].imageUrl
                              : ''
                          }
                          type={
                            currentDayItem?.snacks?.recipes?.length === 0 &&
                            currentDayItem?.snacks?.ingredients?.length === 0 &&
                            currentDayItem?.snacks?.manualItems?.length === 0
                              ? 'add'
                              : 'test'
                          }
                          mealType="snacks"
                          onClick={openMealPopup}
                          openIngredientSearchPopupClass={openIngredientPopup}
                          dayItem={currentDayItem}
                          openIngredientPopup={openIngredient}
                          openGenerateMealPopup={openGenerateMealPopup}
                          setMealType={setCurrentMealType}
                          setMenuClass={setEditMenuClass}
                          menuClassValue={editMenuClass}
                          dayId={currentDayId}
                          planUid={currentPlan?.origId}
                          openManualItemPopup={openManualItem}
                          checkBoxArray={checkBoxArray}
                          setCheckBoxArray={setCheckBoxArray}
                        />
                      </div>

                      <div className="hidden">
                        <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-10 my-auto">
                          <></>
                        </div>
                      </div>
                    </div>

                    <div className="hidden md:grid grid-cols-2 gap-30 pt-15 xl:pt-5 mealCards" id="tourDesktop">
                      <DashboardMealCard
                        kcal_value={currentDayItem?.breakfast?.kcal_total}
                        carbohydrates_value={currentDayItem?.breakfast?.carbohydrates_total}
                        protein_value={currentDayItem?.breakfast?.protein_total}
                        fat_value={currentDayItem?.breakfast?.fat_total}
                        label="Frühstück"
                        mealLabel={
                          currentDayItem?.breakfast?.recipes?.length > 0
                            ? currentDayItem?.breakfast?.recipes[0].name
                            : currentDayItem?.breakfast?.ingredients?.length > 0
                            ? currentDayItem?.breakfast?.ingredients[0].name
                            : ''
                        }
                        mealCounter={
                          currentDayItem?.breakfast?.recipes?.length === 0 &&
                          currentDayItem?.breakfast?.ingredients?.length === 0 &&
                          currentDayItem?.breakfast?.manualItems?.length === 0
                            ? 0
                            : parseFloat(currentDayItem?.breakfast?.recipes?.length) +
                              parseFloat(currentDayItem?.breakfast?.ingredients?.length) +
                              (currentDayItem?.breakfast?.manualItems !== undefined
                                ? parseFloat(currentDayItem?.breakfast?.manualItems?.length)
                                : 0) -
                              1
                        }
                        mealAndIngredientCount={
                          currentDayItem?.breakfast?.recipes?.length === 0 &&
                          currentDayItem?.breakfast?.ingredients?.length === 0 &&
                          (currentDayItem?.breakfast?.manualItems?.length === 0 ||
                            currentDayItem?.breakfast?.manualItems === undefined)
                            ? 0
                            : !currentPlanExists
                            ? 0
                            : parseFloat(currentDayItem?.breakfast?.recipes?.length) +
                              parseFloat(currentDayItem?.breakfast?.ingredients?.length) +
                              parseFloat(currentDayItem?.breakfast?.manualItems?.length)
                        }
                        imageUrl={
                          currentDayItem?.breakfast?.recipes?.length > 0
                            ? currentDayItem?.breakfast?.recipes[0].imageUrl
                            : currentDayItem?.breakfast?.ingredients?.length > 0
                            ? currentDayItem?.breakfast?.ingredients[0].imageUrl
                            : ''
                        }
                        type={
                          currentDayItem?.breakfast?.recipes?.length === 0 &&
                          currentDayItem?.breakfast?.ingredients?.length === 0 &&
                          currentDayItem?.breakfast?.manualItems?.length === 0
                            ? 'add'
                            : 'test'
                        }
                        mealType="breakfast"
                        onClick={openMealPopup}
                        openIngredientSearchPopupClass={openIngredientPopup}
                      />
                      <DashboardMealCard
                        kcal_value={currentDayItem?.lunch?.kcal_total}
                        carbohydrates_value={currentDayItem?.lunch?.carbohydrates_total}
                        protein_value={currentDayItem?.lunch?.protein_total}
                        fat_value={currentDayItem?.lunch?.fat_total}
                        label="Mittagessen"
                        mealLabel={
                          currentDayItem?.lunch?.recipes?.length > 0
                            ? currentDayItem?.lunch?.recipes[0].name
                            : currentDayItem?.lunch?.ingredients?.length > 0
                            ? currentDayItem?.lunch?.ingredients[0].name
                            : ''
                        }
                        mealCounter={
                          currentDayItem?.lunch?.recipes?.length === 0 &&
                          currentDayItem?.lunch?.ingredients?.length === 0 &&
                          currentDayItem?.lunch?.manualItems?.length === 0
                            ? 0
                            : parseFloat(currentDayItem?.lunch?.recipes?.length) +
                              parseFloat(currentDayItem?.lunch?.ingredients?.length) +
                              (currentDayItem?.lunch?.manualItems !== undefined
                                ? parseFloat(currentDayItem?.lunch?.manualItems?.length)
                                : 0) -
                              1
                        }
                        mealAndIngredientCount={
                          currentDayItem?.lunch?.recipes?.length === 0 &&
                          currentDayItem?.lunch?.ingredients?.length === 0 &&
                          (currentDayItem?.lunch?.manualItems?.length === 0 ||
                            currentDayItem?.lunch?.manualItems === undefined)
                            ? 0
                            : !currentPlanExists
                            ? 0
                            : parseFloat(currentDayItem?.lunch?.recipes?.length) +
                              parseFloat(currentDayItem?.lunch?.ingredients?.length) +
                              parseFloat(currentDayItem?.lunch?.manualItems?.length)
                        }
                        imageUrl={
                          currentDayItem?.lunch?.recipes?.length > 0
                            ? currentDayItem?.lunch?.recipes[0].imageUrl
                            : currentDayItem?.lunch?.ingredients?.length > 0
                            ? currentDayItem?.lunch?.ingredients[0].imageUrl
                            : ''
                        }
                        type={
                          currentDayItem?.lunch?.recipes?.length === 0 &&
                          currentDayItem?.lunch?.ingredients?.length === 0 &&
                          currentDayItem?.lunch?.manualItems?.length === 0
                            ? 'add'
                            : 'test'
                        }
                        mealType="lunch"
                        onClick={openMealPopup}
                        openIngredientSearchPopupClass={openIngredientPopup}
                      />
                      <DashboardMealCard
                        kcal_value={currentDayItem?.dinner?.kcal_total}
                        carbohydrates_value={currentDayItem?.dinner?.carbohydrates_total}
                        protein_value={currentDayItem?.dinner?.protein_total}
                        fat_value={currentDayItem?.dinner?.fat_total}
                        label="Abendessen"
                        mealLabel={
                          currentDayItem?.dinner?.recipes?.length > 0
                            ? currentDayItem?.dinner?.recipes[0].name
                            : currentDayItem?.dinner?.ingredients?.length > 0
                            ? currentDayItem?.dinner?.ingredients[0].name
                            : ''
                        }
                        mealCounter={
                          currentDayItem?.dinner?.recipes?.length === 0 &&
                          currentDayItem?.dinner?.ingredients?.length === 0 &&
                          currentDayItem?.dinner?.manualItems?.length === 0
                            ? 0
                            : parseFloat(currentDayItem?.dinner?.recipes?.length) +
                              parseFloat(currentDayItem?.dinner?.ingredients?.length) +
                              (currentDayItem?.dinner?.manualItems !== undefined
                                ? parseFloat(currentDayItem?.dinner?.manualItems?.length)
                                : 0) -
                              1
                        }
                        mealAndIngredientCount={
                          currentDayItem?.dinner?.recipes?.length === 0 &&
                          currentDayItem?.dinner?.ingredients?.length === 0 &&
                          (currentDayItem?.dinner?.manualItems?.length === 0 ||
                            currentDayItem?.dinner?.manualItems === undefined)
                            ? 0
                            : !currentPlanExists
                            ? 0
                            : parseFloat(currentDayItem?.dinner?.recipes?.length) +
                              parseFloat(currentDayItem?.dinner?.ingredients?.length) +
                              parseFloat(currentDayItem?.dinner?.manualItems?.length)
                        }
                        imageUrl={
                          currentDayItem?.dinner?.recipes?.length > 0
                            ? currentDayItem?.dinner?.recipes[0].imageUrl
                            : currentDayItem?.dinner?.ingredients?.length > 0
                            ? currentDayItem?.dinner?.ingredients[0].imageUrl
                            : ''
                        }
                        type={
                          currentDayItem?.dinner?.recipes?.length === 0 &&
                          currentDayItem?.dinner?.ingredients?.length === 0 &&
                          currentDayItem?.dinner?.manualItems?.length === 0
                            ? 'add'
                            : 'test'
                        }
                        mealType="dinner"
                        onClick={openMealPopup}
                        openIngredientSearchPopupClass={openIngredientPopup}
                      />
                      <DashboardMealCard
                        kcal_value={currentDayItem?.snacks?.kcal_total}
                        carbohydrates_value={currentDayItem?.snacks?.carbohydrates_total}
                        protein_value={currentDayItem?.snacks?.protein_total}
                        fat_value={currentDayItem?.snacks?.fat_total}
                        label="Snacks"
                        mealLabel={
                          currentDayItem?.snacks?.recipes?.length > 0
                            ? currentDayItem?.snacks?.recipes[0].name
                            : currentDayItem?.snacks?.ingredients?.length > 0
                            ? currentDayItem?.snacks?.ingredients[0].name
                            : ''
                        }
                        mealCounter={
                          currentDayItem?.snacks?.recipes?.length === 0 &&
                          currentDayItem?.snacks?.ingredients?.length === 0 &&
                          currentDayItem?.snacks?.manualItems?.length === 0
                            ? 0
                            : parseFloat(currentDayItem?.snacks?.recipes?.length) +
                              parseFloat(currentDayItem?.snacks?.ingredients?.length) +
                              (currentDayItem?.snacks?.manualItems !== undefined
                                ? parseFloat(currentDayItem?.snacks?.manualItems?.length)
                                : 0) -
                              1
                        }
                        mealAndIngredientCount={
                          currentDayItem?.snacks?.recipes?.length === 0 &&
                          currentDayItem?.snacks?.ingredients?.length === 0 &&
                          (currentDayItem?.snacks?.manualItems?.length === 0 ||
                            currentDayItem?.snacks?.manualItems === undefined)
                            ? 0
                            : !currentPlanExists
                            ? 0
                            : parseFloat(currentDayItem?.snacks?.recipes?.length) +
                              parseFloat(currentDayItem?.snacks?.ingredients?.length) +
                              parseFloat(currentDayItem?.snacks?.manualItems?.length)
                        }
                        imageUrl={
                          currentDayItem?.snacks?.recipes?.length > 0
                            ? currentDayItem?.snacks?.recipes[0].imageUrl
                            : currentDayItem?.snacks?.ingredients?.length > 0
                            ? currentDayItem?.snacks?.ingredients[0].imageUrl
                            : ''
                        }
                        type={
                          currentDayItem?.snacks?.recipes?.length === 0 &&
                          currentDayItem?.snacks?.ingredients?.length === 0 &&
                          currentDayItem?.snacks?.manualItems?.length === 0
                            ? 'add'
                            : 'test'
                        }
                        mealType="snacks"
                        onClick={openMealPopup}
                        openIngredientSearchPopupClass={openIngredientPopup}
                      />
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <TrialContainer />
                  </div>
                  {tenantData?.settings?.linkList !== undefined &&
                    tenantData?.settings?.linkList.length > 0 &&
                    tenantData?.settings?.linkListPosition !== undefined &&
                    tenantData?.settings?.linkListPosition === 'dashboard' && (
                      <div className="pt-0 desktop:pt-60 md:pt-80">
                        <div className="font-extralight text-25 pb-15">Links</div>
                        {tenantData?.settings?.linkList?.map((item: any, index: number) => (
                          <Link
                            to={{
                              pathname: item.url,
                            }}
                            target="_blank"
                            key={index}
                            className="cursor-pointer"
                          >
                            <div className="rounded-2xl bg-lightGray mb-15 p-20 border-transparent border-2 hover:border-accentColor max-w-quarter">
                              <div className="flex space-x-10 flex-wrap">
                                <div className="font-bold max-w-quarter">{item.name}</div>
                                <div className="cursor-pointer">
                                  <div className="my-auto">
                                    <ExternalLinkIcon className="text-accentColor" height={20} width={20} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}

                  {(userData?.role === 2 ||
                    tenantData?.settings?.functions?.notices === undefined ||
                    userData?.isAdminUser ||
                    userData?.isTrainerUser ||
                    userData?.isLocationAdminUser ||
                    localStorage.getItem('currentUserToken') ||
                    tenantData?.settings?.functions?.notices === true) && (
                    <div className="pt-60 md:pt-80 hidden md:block">
                      <div className="font-extralight text-25">Notizen</div>
                      <RateSection
                        updateFunction={updateRateText}
                        defaultValue={currentDayItem?.notice !== undefined ? currentDayItem?.notice : ''}
                        changedDate={currentDayDateMoment}
                      />
                    </div>
                  )}
                  {(userData?.role === 2 ||
                    tenantData?.settings?.functions?.dayRate === undefined ||
                    userData?.isAdminUser ||
                    userData?.isTrainerUser ||
                    userData?.isLocationAdminUser ||
                    localStorage.getItem('currentUserToken') ||
                    tenantData?.settings?.functions?.dayRate === true) && (
                    <div className="pt-60 md:pt-80 hidden md:block dayRating">
                      <div className="font-extralight text-25">Tagesbewertung</div>
                      <div className="rounded-3xl bg-lightGray mt-15 py-20 px-40">
                        <DayRating
                          updateFunction={updateDayRating}
                          isMobile={false}
                          ratingDayType={
                            currentDayItem?.dayRating !== undefined ? currentDayItem?.dayRating : undefined
                          }
                        />
                      </div>
                    </div>
                  )}
                  {(userData?.role === 2 ||
                    tenantData?.settings?.functions?.waterTracker === undefined ||
                    userData?.isAdminUser ||
                    userData?.isTrainerUser ||
                    userData?.isLocationAdminUser ||
                    localStorage.getItem('currentUserToken') ||
                    tenantData?.settings?.functions?.waterTracker === true) && (
                    <div className="pt-60 md:pt-80 hidden md:block">
                      <div className="font-extralight text-25">Wasserbedarf</div>
                      <div className="rounded-3xl bg-lightGray mt-15 py-40 waterNeed">
                        <WaterSection
                          waterCounterValue={currentWaterCounterValue}
                          updateFunction={updateWaterCounter}
                          customDayWaterGoal={
                            currentDayItem?.waterGoal !== undefined ? currentDayItem?.waterGoal : undefined
                          }
                          openWaterPopup={setWaterEditPopupClass}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TransitionContainer>
        </>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
