import { XIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import React from 'react';

import firebase from 'services/firebase';

type Props = {};

const ImpersonateHeader: React.FC<Props> = () => {
  const currentUserToken = localStorage.getItem('currentUserToken');
  const history = useHistory();

  if (!currentUserToken) {
    return null;
  }

  const handleExit = async () => {
    const userUid = firebase.auth().currentUser?.uid;

    await firebase
      .auth()
      .signInWithCustomToken(currentUserToken)
      .catch(async error => {
        console.log(error);
        await firebase.auth().signOut();
        localStorage.removeItem('currentUserToken');
        window.location.reload();
      });
    localStorage.removeItem('currentUserToken');
    if (userUid) {
      window.location.href = `/member/detail/${userUid}`;
    } else {
      window.location.href = '/member?reload=true';
    }
  };

  return (
    <div className="bg-lightGray p-15 w-full desktop:w-500 bottom-80 desktop:bottom-auto text-12 right-0 desktop:text-14 desktop:top-0 absolute z-30 flex flex-wrap justify-between">
      <div>
        <span className="font-bold">Ansicht:</span> {firebase.auth().currentUser?.email}
      </div>
      <button type="button" onClick={handleExit} className="flex items-center">
        <XIcon className="mr-5" height={20} />
        verlassen
      </button>
    </div>
  );
};

export default ImpersonateHeader;
